
.FeaturesComparison-header {
  display: flex;
  align-items: center;
  margin-bottom: $v * 3;
  @media (#{$medium-bp}) {
    >div {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        right: 0;
        height: 40px;
        border-right: 1px solid $charcoal-4;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.FeaturesComparison-header-title {
  color: $charcoal-2;
  padding-left: 0;
  text-align: center;

  @media (#{$medium-bp}) {
    text-align: left;
  }
}

.FeaturesComparison-header-logos {
  img {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
  }
}

.FeaturesComparison-body-slide {

  .FeaturesComparison-header {
    margin-top: $v * 1.5;
    margin-bottom: $v;
  }

  .slick-track {
    overflow: visible;
  }

  .slick-slide {
    margin: $v * 2;
  }
}

.FeaturesComparison-title.PriceAccordion-title {
  cursor: default;
  padding-left: $v;
  padding-top: $v * 2;
  padding-bottom: $v * 2;

  &:hover {
    color: $charcoal-0;
  }
}

.FeaturesComparison-actions {
  margin-top: $v * 4;
  text-align: center;
}

.FeaturesComparison-footer {
  margin-top: $v * 4;
  text-align: center;
  color: $charcoal-2;
}
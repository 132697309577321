.StickyGroup {
    @extend .u-vSpacingLG-top;
    @extend .u-vSpacingLG-bottom;
}

.StickyGroup-nav {
    position: sticky;
    top: 140px;

    list-style: none;
    padding: 0;
    margin: 0;
    li {
        border-top: 1px solid $border-grey;

        &:last-child {
            border-bottom: 1px solid $border-grey;
        }
    }

    @media (#{$small-bp}) {
        li {
            border-top: 0;

            &:last-child {
                border-bottom: 0;
            }
        }
        background: $hot-coral;
        a.StickyGroup-nav-item {
            color: $white !important;
            text-align: center;
        }
    }
}

.StickyGroup-nav-item {
    padding-top: $v * 1.5;
    padding-bottom: $v * 1.5;
    display: block;
    line-height: 2.4rem;
    transition: all 0.3s ease-in-out;
    color: $coral-2;

    @media (#{$medium-bp}) {
        &:hover {
            transform: translateX(25px);
        }
        &:focus {
            color: $hot-coral;
        }
        &.is-active {
            transform: translateX(25px);
            color: $coral-hover;
        }
    }
}

.StickyGroup-group {
    @extend .u-vSpacingXL-top;
    clear: both;

    .Brochure-privacy {
        padding-bottom: 0;
    }

    &:first-child {
        padding-top: 0;
    }

    h3 {
        padding-bottom: $v * 2;

        @media (#{$medium-bp}) {
            padding-bottom: $v * 4;
        }
    }

    h4 {
        padding-bottom: $v * 2;
        margin-bottom: 0;
    }

    p:not(.mdc-text-field-helper-text):not(.mdc-select-helper-text) {
        // padding-bottom: $v * 4;
        // margin-bottom: 0;

        img {
            margin-bottom: 0;
        }

        &.disclaimer {
            padding-bottom: 0;
        }
    }

    .input {
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

.StickyGroup-container {
    @media (#{$medium-bp}) {
        display: flex;
    }
}

.StickyGroup-sidebar {
    display: none;
    @media (#{$medium-bp}) {
        display: block;
        margin-bottom: $v * 6;
    }
}
.StickyGroup-sidebar--reduced {
    @media (#{$medium-bp}) {
        display: none;
    }
    @media (#{$large-bp}) {
        display: block;
    }
}

.StickyGroup-img {
    margin-bottom: $v * 4;
    padding-left: $v * 2;
    padding-right: $v * 2;
    @media (#{$medium-bp}) {
        margin-bottom: 0;
    }

    @media (#{$xxlarge-bp}) {
        width: 75%;
        margin-left: auto;
        margin-right: auto;
    }
}

p.StickyGroup-padding-sm {
    padding-bottom: $v * 2;
}

.StickyGroup-feature-img {
    padding-top: math.div($v, 2);
    margin-bottom: $v * 2;

    @media (#{$medium-bp}) {
        margin-bottom: 0;
    }
}

.StickyGroup-img,
.StickyGroup-row {
    padding-bottom: $v * 4;
    margin-bottom: 0;
    @media (#{$medium-bp}) {
        padding-bottom: $v * 6;
    }

    &.disclaimer {
        padding-bottom: 0;
    }

    p {
        padding-bottom: 0;
        @media (#{$medium-bp}) {
            padding-bottom: 0;
        }
    }
}

.StickyGroup-actions {
    justify-content: center;
    text-align: center;
    @media (#{$medium-bp}) {
        justify-content: start;
        text-align: left;
    }
}

.StickyGroup-title {
    text-align: center;
    margin-top: $v * 3;
    margin-bottom: 0;

    @media (#{$medium-bp}) {
        text-align: left;
        margin-top: 0;
    }
}

.StickyGroup-description {
    text-align: center;
}
.StickyGroup-item-desc {
    padding-bottom: $v * 3;
    @media (#{$medium-bp}) {
        padding-bottom: $v * 6;
        text-align: left;
    }
}
.StickyGroup-img-card {
    padding-bottom: $v * 2;
    padding-left: 0;
    padding-right: 0;
}

.StickySection--display {
    .StickyGroup-group {
        h3 {
            font-size: $v * 3.5;
            line-height: $v * 4.5;
            letter-spacing: 0.15px;
        }
        h4.h6 {
            font-family: $domaintext;
            font-weight: 400;
            font-size: $v * 2.8;
            line-height: $v * 3.6;
            letter-spacing: 0.15px;
            a {
                color: $charcoal-0;
            }
        }
    }
}

.StickyGroup-container,
.StickyGroup-content {
    @media (#{$small-bp}) {
        padding-left: 0;
        padding-right: 0;
    }
}

.Article {
    padding: 0 $v * 1.5;
}

img.Article-logo-white {
    display: none;
}

.Article-content {
    &,
    > div {
        padding-left: 0;
        padding-right: 0;
        @media (#{$large-bp}) {
            padding: 0 $v * 1.5;
        }
    }
    img {
        max-width: 100%;
        height: auto;
    }

    p,
    li,
    h1,
    h2,
    h3,
    h4,
    h5 {
        color: $black;
    }

    ol,
    ul,
    p {
        font-family: $domaintext;
        color: $black;
    }

    li {
        margin-left: 0;
        margin-bottom: 0;
        padding: 0 math.div($v, 2);
        color: $black;
        font-size: $v * 1.8;
        a {
            color: inherit;
            text-decoration: underline;
            text-underline-position: under;
            text-decoration-color: $charcoal-3;
            transition: all 0.2s ease-in-out;
        }
    }

    div:not(.Article-authorCard-description) > p {
        line-height: $v * 3.4;

        &:not(.Article-byline):first-of-type {
            font-size: $v * 2;
            // margin-top: $v * 2;

            &::first-letter {
                font-family: $domaindisplay;
                font-weight: 400;
                float: left;
                font-size: $v * 7;
                letter-spacing: -1.75px;
                line-height: $v * 6.1;
                padding-right: 5px;
            }
        }
    }

    a {
        color: inherit;
        text-decoration: underline;
    }
}

.Article-list {
    list-style: none;
    padding: 0;
    display: flex;
    margin: 0;
    li::before {
        display: none;
    }
}

.Article-list-item {
    padding: $v $v math.div($v, 2) math.div($v, 2);
    margin-left: 0;
    margin: 0;
}

.Article-list-link {
    color: $charcoal;
}

.Article-category-list {
    list-style: none;
    padding: 0;
    li {
        padding: 0;
        margin-left: 0;
        margin-top: $v * 1.5;
        line-height: 1rem;

        @media (#{$large-bp}) {
            text-align: left;
        }
    }

    a {
        font-family: $domaindisplay;
        font-size: $v * 2;
        font-weight: 400;
        letter-spacing: -0.5px;
        line-height: $v * 2.2;
        color: $black;
        text-decoration: none;

        &:hover {
            text-decoration: underline !important;
        }
    }

    .current-cat a {
        color: $hot-coral;
    }
}
.Article-category-list--insideTimely {
    a.Button--external-grey {
        font-family: $text-font;
        font-size: $v * 1.5;
        font-weight: 400;
        letter-spacing: -0.5px;
        line-height: $v * 1.5;
        // color: $black;

        &:hover {
            color: $coral-hover;
            &::after {
                background-color: $coral-hover;
            }
        }
    }
}

.Article-insideTimely-logo {
    margin-top: $v * 1.5;
    margin-bottom: math.div($v, 2);
    // opacity: .75;
    display: block;
    g {
        transition: all 0.2s ease-in-out;
    }

    &:hover {
        g {
            fill: $charcoal;
        }
    }
}

.Article {
    .wp-block-columns {
        padding-left: 0;
        padding-right: 0;
        @media (#{$medium-bp}) {
            margin-left: -$v * 1.5;
            margin-right: -$v * 1.5;
            max-width: calc(100% + #{$v} * 3) !important;
        }
    }
}

.Article-description {
    margin-top: $v * 2;
    margin-bottom: $v * 3;
}

.Article-authorCard {
    margin-left: -$v * 1.5;
    margin-right: -$v * 1.5;
    padding: $v * 3.5;
    background-color: $dusk-2;
    border-radius: 4px;

    @media (#{$medium-bp}) {
        margin-left: 0;
        margin-right: 0;
    }
}

.Article-authorCard-description {
    // padding-top: $v * 2;
    color: $black;
    font-family: $domaintext;
    font-size: 18px;
    line-height: 34px;

    a {
        color: inherit;
        text-decoration: underline;
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.Article--fancy {
    .headroom--top {
        transition-duration: 0 !important;
        background-color: transparent !important;
        .Article-logo-color {
            display: none;
        }

        img.Article-logo-white {
            display: block;
        }

        .icon-bar {
            background-color: $white !important;
        }

        &.is-open {
            .Article-logo-color {
                display: block !important;
            }
            .Article-logo-white {
                display: none !important;
            }
        }

        .is-open .icon-bar {
            background-color: $charcoal-0 !important;
        }

        @media (#{$medium-bp}) {
            background-color: $cream-0 !important;
            .Article-logo-color {
                display: block;
            }

            img.Article-logo-white {
                display: none;
            }
            .icon-bar {
                background-color: $charcoal-0 !important;
            }
        }
    }

    .Article-body {
        > div {
            @media (#{$small-bp}) {
                margin-top: 0 !important;
            }
        }
    }
}

.Article-body {
    @extend %container-wide;
    @media (#{$small-bp}) {
        padding-left: 0;
        padding-right: 0;
    }

    > div {
        margin-top: $v * 3.5;

        @media (#{$small-bp}) {
            padding-left: 0;
            padding-right: 0;
        }

        @media (#{$large-bp}) {
            margin-top: $v * 7.5;
        }
    }
}

.Article-sidebar {
    min-height: 100vh;
    background-color: $cream-0;
    position: fixed;
    top: 0;
    left: 0;
    transition: all 1s cubic-bezier(0.77, 0, 0.175, 1);
    text-align: center;
    z-index: 0;
    transform: translateY(-100%);

    &.is-open {
        margin-top: 0;
        z-index: 11;
        transform: translateY(0);
        .Article-widget {
            margin-bottom: $v * 6;
        }
    }

    .Article-category-list {
        text-align: center;
        @media (#{$large-bp}) {
            text-align: left;
        }
    }

    @media (#{$small-bp}) {
        width: 100%;
        height: 100%;
        padding-top: 0;
        > div {
            overflow: scroll;
            height: 100%;
            padding-top: $v * 15;
        }
    }

    @media (#{$medium-bp-only}) {
        width: 100%;
    }

    @media (#{$large-bp}) {
        transform: translateY(0);
        left: $v * 3;
        top: 0;
        position: fixed;
        padding-top: $v * 14;
        background: transparent;
        text-align: left;
        // padding-left: $v * 3;
        width: 16.666666667%;

        > div {
            min-height: calc(100vh - 11.25rem);
            display: flex;
            flex-direction: column;
        }
    }
}
h5.Article-body-label,
.Article-body-label {
    margin-bottom: 0;
    color: $charcoal-2;
    // text-align: center;

    // @media (#{$medium-bp}) {
    //   text-align: left;
    // }
}

.Article-socialIcon {
    .Article-list-link {
        font-size: 22px;

        &:hover {
            color: $black;
        }
    }
}

.Article-tag {
    padding: 0;
    margin-top: $v;
    margin-bottom: $v * 3;
    margin-left: -$v;
    margin-right: -$v;

    li {
        padding: 0;
        margin: $v;
        display: inline-block;
        list-style: none;

        &::before {
            display: none;
        }

        a {
            background-color: $dusk-2;
            border-radius: 4px;
            display: inline-block;
            padding: $v * 0.8 $v * 1.5 $v * 0.9;
            line-height: $v * 2.4;
            font-size: $v * 1.6;
            color: $charcoal;
            transition: background 0.2s ease-in-out;
            text-transform: capitalize;

            &:hover {
                background-color: $dusk-0;
            }
        }
    }
}

.Article-header {
    position: fixed;
    top: 0;
    background-color: $cream-0;
    transition: all 0.2s ease-in-out;
    width: 100%;
    padding-top: $v * 1.5;
    padding-bottom: $v * 1.5;
    z-index: 12;

    h2 {
        margin-bottom: 0;
        width: 160px;
    }

    &.is-down:not(.is-open) {
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    }
}

.Article-toc {
    position: relative;
}

.Article-toc-number {
    position: absolute;
    color: $dusk-3;
    font-family: $domaindisplay;
    font-weight: bold;
    line-height: $v * 48;
    z-index: -1;
    left: -$v * 3;
    top: -$v * 20;
    font-size: $v * 20;

    @media (#{$medium-bp}) {
        left: -$v * 8.5;
        top: -$v * 12.5;
        font-size: $v * 48;
    }
}

.Article-featureImage {
    margin-top: $v * 3;
    margin-bottom: $v * 3;
}

.Article .Article-smallNav {
    display: flex;
    h2 {
        margin-left: auto;
        margin-bottom: 0;
        margin-top: 0;
        width: 200px;
    }

    @media (#{$large-bp}) {
        display: block;

        h2 {
            margin-left: $v * 1.5;
            margin-bottom: 0;
            margin-top: $v * 0.25;
        }
    }
    .icon-bar {
        display: block;
        height: 3px;
        width: 26px;
        position: relative;
        background-color: $charcoal-0;
        margin-top: 7px;
        border-radius: 4px;

        &.top-bar {
            margin-top: 0;
        }
    }

    .is-open {
        .top-bar {
            left: 3px;
            transform: rotate(45deg);
            transform-origin: 20% 30%;
        }
        .middle-bar {
            display: none;
        }
        .bottom-bar {
            left: 3px;
            transform: rotate(-45deg);
            transform-origin: 24% 90%;
        }
    }

    .is-dark {
        .icon-bar {
            background-color: $white;
        }
    }

    &.Article-smallNav--menu {
        display: flex;
        padding: $v * 1.5 0 $v * 6;
        @media (#{$large-bp}) {
            display: none;
        }
    }
}

.Article-toc-list {
    counter-reset: toc-counter;
    list-style: none;
    color: $black;
    font-weight: 400;
    letter-spacing: -0.5px;
    line-height: $v * 2.5;
    padding-left: 0;
    margin-bottom: $v * 5;
    li {
        display: flex;
        align-items: baseline;

        margin-top: $v * 1.5;
        font-size: $v * 2;
        line-height: $v * 2.2;
        padding-left: 0;
        counter-increment: toc-counter;
        font-family: $domaindisplay;

        &::before {
            content: counter(toc-counter) ' —';
            margin-right: $v * 0.25;
            min-width: 35px;
            display: block;
        }

        a {
            color: $black;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.Article-spacer {
    padding-top: $v * 1.5;
}

.Article-progress {
    height: 3px;
    background: $hot-coral;
    position: absolute;
    width: 0;
    bottom: 0;
    transition: width 0.2s ease-out;
}

.Article-smallNav-toggle {
    display: block;
    padding-top: 0;
    @media (#{$large-bp}) {
        display: none;
    }
}

.Article-fancy {
    position: relative;
    height: 0;
    padding-top: 80vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media (#{$medium-bp}) {
        padding-top: 56.25%;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.65),
            rgba(0, 0, 0, 0) 60%
        );
    }
}

.Article-content-wrap {
    margin-top: $v * 4;

    @media (#{$small-bp}) {
        margin: 0;
        padding-left: 0;
        padding-right: 0;
    }
}

.Article-fancy-content {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-top: $v * 16;
    padding-left: $v * 3;
    padding-right: $v * 3;
    padding-bottom: $v * 3;

    .Article-byline {
        a {
            text-decoration: none;
        }
    }

    @media (#{$medium-bp}) {
        padding-top: 0;
        padding-right: 0;
    }
    @media (#{$large-bp}) {
        padding-left: $v * 6;
        padding-bottom: $v * 6;
    }
}

.is-dark {
    .Article-byline {
        a {
            &:hover {
                color: $hot-coral !important;
            }
        }
    }
}

.Article-byline {
    margin-bottom: 0;
    margin-top: $v * 3;
}

.Article-fancy-wrap {
    padding-left: 0;
    @media (#{$small-bp}) {
        margin-top: -1px;
    }
}

.PostRecent-title {
    margin-bottom: $v * 3;
    color: $hot-coral;
    position: relative;
    span {
        display: inline-block;
        padding-right: 2px;
        position: relative;
        background-color: $cream-0;
        z-index: 1;

        &.Article-see-title {
            text-transform: none;
            font-family: $domaindisplay;
            font-weight: 400;
            font-size: $v * 2.8;
            line-height: $v * 4;
            letter-spacing: -1px;
            @media (#{$medium-bp}) {
                font-size: $v * 4;
            }
        }

        &.Article-see-more {
            display: none;
            margin-left: auto;
            @media (#{$large-bp}) {
                padding-left: 5px;
                display: block;
            }
        }
    }
    &::after {
        content: '';
        position: absolute;
        height: 1px;
        left: 0;
        bottom: 5px;
        width: 100%;
        background-color: $hot-coral;
    }
}

.Article-widget {
    margin-bottom: $v * 3;

    @media (#{$medium-bp}) {
        margin-bottom: $v * 6;
    }
}

.Article-progress--footer {
    bottom: initial;
    top: 0;
    left: 0;
}

.Article-searchResults {
    min-height: 110vh;
}

.Article-insideTimely {
    @media (#{$large-bp}) {
        margin-top: auto;
        margin-bottom: $v * 3.5;
    }
}

.Article-aside {
    overflow: hidden;

    // padding-left: $v * 3;
    // padding-right: $v * 3; // Not sure why we have this, it doesnt work for topic pages
    padding-left: 0;
    padding-right: 0;
    display: none;

    @media (#{$large-bp}) {
        display: block;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.Article-body-main {
    position: relative;
    min-height: 1px;
    padding-left: 0;
    padding-right: 0;

    @media (#{$large-bp}) {
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (#{$large-bp}) {
        // width: 75%;
        width: 83.333333333%;
        margin-left: 16.666667%;
    }

    @media (#{$xlarge-bp}) {
        width: 83.333333333%;
    }
}

.Article-aside--standard {
    @media (#{$large-bp}) {
        margin-top: $v * 2.5;
    }
}

.Article-body-resources {
    margin-top: $v * 6;
}

.Article--search {
    h2 {
        margin-top: $v * 3;
    }
}

.PostRecent-title {
    a {
        display: flex;
        align-items: baseline;
    }
}

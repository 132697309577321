//
// Tooltips FROM BS
// --------------------------------------------------
//** Tooltip max width
$tooltip-max-width: 320px !default;
//** Tooltip text color
$tooltip-color: #fff !default;
//** Tooltip background color
$tooltip-bg: $charcoal-1;
$tooltip-opacity: 0.9 !default;

//** Tooltip arrow width
$tooltip-arrow-width: 10px !default;
//** Tooltip arrow color
$tooltip-arrow-color: $tooltip-bg !default;

$border-radius-base: 3px;

div.react-hint__content {
    max-width: 250px;
    min-width: 250px;

    @media (#{$medium-bp}) {
        max-width: 350px;
        min-width: 350px;
    }
}
div.react-hint__content {
    background-color: $charcoal-1;
    padding: 10px;
    p {
        margin: 0;
        font-size: $v * 1.4;
        line-height: $v * 2;
    }
}

div.react-hint--auto:after,
div.react-hint--top:after {
    top: auto;
    border-bottom: none;
    border-top-color: $charcoal-1;
}

div.react-hint--left:after {
    left: auto;
    border-right: none;
    border-left-color: $charcoal-1;
}

div.react-hint--right:after {
    right: auto;
    border-left: none;
    border-right-color: $charcoal-1;
}

div.react-hint--bottom:after {
    bottom: auto;
    border-top: none;
    border-bottom-color: $charcoal-1;
}
// Base class
.tooltip {
    position: absolute;
    z-index: 10;
    display: block;
    // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
    // So reset our font and text properties to avoid inheriting weird values.
    font-size: 1.4rem;
    text-align: left;
    opacity: 0;

    p:last-child {
        margin-bottom: 0;
    }

    &.in {
        opacity: 1;
    }

    &.top {
        margin-top: -3px;
        padding: $tooltip-arrow-width 0;
    }

    &.right {
        margin-left: 3px;
        padding: 0 $tooltip-arrow-width;
    }

    &.bottom {
        margin-top: 3px;
        padding: $tooltip-arrow-width 0;
    }

    &.left {
        margin-left: -3px;
        padding: 0 $tooltip-arrow-width;
    }
}

// Wrapper for the tooltip content
.tooltip-inner {
    max-width: $tooltip-max-width;
    padding: $v * 2;
    color: $tooltip-color;
    background-color: $tooltip-bg;
    border-radius: $border-radius-base;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
}

// Arrows
.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

// Note: Deprecated .top-left, .top-right, .bottom-left, and .bottom-right as of v3.3.1
.tooltip {
    &.top .tooltip-arrow {
        bottom: 0;
        left: 50%;
        margin-left: -$tooltip-arrow-width;
        border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
        border-top-color: $tooltip-arrow-color;
    }

    &.top-left .tooltip-arrow {
        bottom: 0;
        right: $tooltip-arrow-width;
        margin-bottom: -$tooltip-arrow-width;
        border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
        border-top-color: $tooltip-arrow-color;
    }

    &.top-right .tooltip-arrow {
        bottom: 0;
        left: $tooltip-arrow-width;
        margin-bottom: -$tooltip-arrow-width;
        border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
        border-top-color: $tooltip-arrow-color;
    }

    &.right .tooltip-arrow {
        top: 50%;
        left: 0;
        margin-top: -$tooltip-arrow-width;
        border-width: $tooltip-arrow-width $tooltip-arrow-width
            $tooltip-arrow-width 0;
        border-right-color: $tooltip-arrow-color;
    }

    &.left .tooltip-arrow {
        top: 50%;
        right: 0;
        margin-top: -$tooltip-arrow-width;
        border-width: $tooltip-arrow-width 0 $tooltip-arrow-width
            $tooltip-arrow-width;
        border-left-color: $tooltip-arrow-color;
    }

    &.bottom .tooltip-arrow {
        top: 0;
        left: 50%;
        margin-left: -$tooltip-arrow-width;
        border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
        border-bottom-color: $tooltip-arrow-color;
    }

    &.bottom-left .tooltip-arrow {
        top: 0;
        right: $tooltip-arrow-width;
        margin-top: -$tooltip-arrow-width;
        border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
        border-bottom-color: $tooltip-arrow-color;
    }

    &.bottom-right .tooltip-arrow {
        top: 0;
        left: $tooltip-arrow-width;
        margin-top: -$tooltip-arrow-width;
        border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
        border-bottom-color: $tooltip-arrow-color;
    }
}

.Tooltip-trigger {
    font-size: 1.4rem;
    border: 0;
    background: none;
    // border-bottom: 1px dashed $charcoal-2;
    text-decoration: underline;
    text-decoration-style: dashed;
    text-decoration-color: $charcoal-2;
    text-underline-position: under;
    margin-left: $v;
    margin-right: $v;
    padding: $v * 2 0 0;
    outline: none;
    &:hover {
        border-bottom-color: $charcoal;
    }
}

.Tooltip-trigger--link {
    border: 0;
    padding: 0;
    background: none;
    outline: none;
    line-height: 2.2rem;
    text-decoration: underline;
    text-decoration-style: dashed;
    text-decoration-color: $charcoal-3;
    text-underline-position: under;

    &.Tooltip-trigger--noDash {
        text-decoration: none;
    }
}

div.react-hint__small {
    width: 30%;
}

.Chapters {
  margin: 0;
}

.Chapters-button {
  color: $hot-coral;

  h5 {
    margin-bottom: 0;
  }
}


.Chapters-links .Chapters-number {
  margin-top: $v * 4;
  margin-bottom: 0;
  font-size: $v * 1.4;
  color: $charcoal-2;

  &:first {
    margin-top: $v * 2;
  }
}

.Chapters-title {
  color: $black;
  font-family: $domaindisplay;
  font-weight: 400;
  font-size: $v * 2;
  letter-spacing: -0.5px;
  line-height: $v * 2.5;
  padding-left: 0;
  margin-bottom: $v * 2;
}

.Chapters-links {
  transition: all 1s cubic-bezier(0.77, 0, 0.175, 1);
  position: fixed;
  top: 69px;
  left: 0;
  background: $white;
  z-index: 1;
  width: 100%;
  padding: $v * 2 0 $v * 4;
  transform: translateY(-100%);


  &.is-open {
    transform: translateY(0);
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.1);
  }
}

.Chapters-links--bottom {
  top: initial;
  bottom: 55px;
  max-height: 70vh;
  overflow: scroll;
  text-align: center;
  transform: translateY(100%);

  &.is-open {
    top: initial;
    transform: translateY(0);
  }
}

.Chapters-footer {
  position: fixed;
  bottom: 0;
  background: $cream-0;
  padding: $v * 1.5 0;
  width: 100%;
  left: 0;
  z-index: 10;
  box-shadow: 0 0 30px 0 rgba(0,0,0,0.1);
  border-top: 1px solid $charcoal-4;
}

.Chapters-link a:hover .Chapters-title {
  text-decoration: underline;
}
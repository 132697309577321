// Bootstrap 5 custom extension
.mb-6{
    margin-bottom: 10rem;
}

// Older styling
.u-ml-sm {
    margin-left: $v;
}

.u-mt-sm {
    margin-top: $v;
}

.mrp5 {
    margin-right: 0.5em;
}

.u-marginAuto {
    float: none !important;
    margin-left: auto;
    margin-right: auto;
}

/****************
* Top Margin
****************/

.u-vSpacingXL-topM {
    margin-top: $v * 9;

    @media (#{$medium-bp}) {
        margin-top: $v * 15;
    }

    @media (#{$large-bp}) {
        margin-top: $v * 21;
    }
}

.u-vSpacingLG-topM {
    margin-top: $v * 6;

    @media (#{$medium-bp}) {
        margin-top: $v * 9;
    }
    @media (#{$large-bp}) {
        margin-top: $v * 15;
    }
}

.u-vSpacingMD-topM {
    margin-top: $v * 6;

    @media (#{$medium-bp}) {
        margin-top: $v * 9;
    }
}

.u-vSpacingSM-topM {
    margin-top: $v !important;

    @media (#{$medium-bp}) {
        margin-top: $v * 5 !important;
    }
}

.u-vSpacing0-topM {
    margin-top: 0 !important;
}

/****************
* Bottom Padding
****************/

.u-vSpacingXL-bottomM {
    margin-bottom: $v * 9;

    @media (#{$medium-bp}) {
        margin-bottom: $v * 15;
    }
    @media (#{$large-bp}) {
        margin-bottom: $v * 21;
    }
}

.u-vSpacingLG-bottomM {
    margin-bottom: $v * 6;

    @media (#{$medium-bp}) {
        margin-bottom: $v * 6;
    }
    @media (#{$large-bp}) {
        margin-bottom: $v * 9;
    }
}
.u-vSpacingMD-bottomM {
    margin-bottom: $v * 3;

    @media (#{$medium-bp}) {
        margin-bottom: $v * 6;
    }
}

.u-vSpacingSM-bottomM {
    margin-bottom: $v;

    @media (#{$medium-bp}) {
        margin-bottom: $v * 3;
    }
}

.u-vSpacing0-bottomM {
    margin-bottom: 0;
}

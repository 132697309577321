#footer {
    background-color: #fff;
    clear: both;
}

.Footer-list {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        margin: 0;
        padding: 0;
    }
    li.Footer-title {
        padding-top: $v * 3;
    }

    a {
        color: $charcoal-1;
        line-height: $v * 3.3;
        &:hover {
            color: $coral-hover;
        }
    }
}

.Footer-title {
    &,
    a {
        font-weight: 700;
        font-size: 1.6 * $v;
        color: $charcoal-0;
    }
}

.Footer-subtitle a {
    font-weight: 700;
    font-size: 1.4 * $v;
}
.Footer-link a {
    font-weight: 400;
    font-size: 1.4 * $v;
}

.Footer-logo {
    width: 147px;
    height: 38px;
}

.Footer-social {
    list-style: none;
    padding: 0;
    margin: math.div($v, 2) 0 0;

    li {
        display: inline-block;
    }
    a {
        font-size: 2.5rem;
        color: $charcoal-1;
    }
    .fa {
        margin-left: math.div(-$v, 2);
        margin-right: $v;
    }
}

.Footer-copyright {
    color: $charcoal-3;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;
}

.Footer-downloadIcon {
    text-align: right;
    float: right;
}

.Footer-item {
    text-decoration: none;
}


.footer-social-icons {
    list-style: none;
    padding: 0;
    display: flex;
    margin-top: 2px;
  }

  .footer-social-icons li {
    margin-right: 8px;
  }

  .footer-social-icons li:last-child {
    margin-right: 0;
  }

  .footer-social-icons a {
    text-decoration: none;
  }

  .footer-social-icons svg {
    width: 25px;
    height: 25px;
    fill: $charcoal-1;
    transition: fill 0.1s ease-in-out;
  }

  .footer-social-icons path {
    fill: $charcoal-1;
    transition: fill 0.1s ease-in-out;
  }

  .footer-social-icons a:hover path {
    fill: $coral-1; /* hover colour */
  }

  .footer-social-icons a:hover svg {
    fill: $coral-1; /* hover colour */
  }

// Responsive design for logo
@media (max-width: 1023px) {
    .footer-responsive-logo{
        flex: 0 0 auto;
        width: 100%;
    }

    .Footer-copyright {
        letter-spacing: 0;
    }
}
.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(57, 70, 78, 0.85);
    z-index: 9100000001;
    overflow: scroll;
}

.Overlay--light {
    background-color: rgba(254, 245, 244, 0.85);

    .Overlay-close {
        svg g {
            stroke: $charcoal-0;

        }
    }
}

.Overlay-close {
    background: none;
    border: none;
    position: fixed;
    cursor: pointer;
    // right: $v * 2;
    // top: $v * 2;
    top: $v * 6;
    right: $v * 6;
    outline: none;
    transition: all 0.03s ease-in-out;
    svg g {
        transition: stroke 0.2s ease-in-out;
    }

    &:hover {
        svg g {
            stroke: $coral-base;
        }
    }
    @media (#{$small-bp}) {
        right: $v * 1.5;
        top: $v * 1.5;
    }
}

.Overlay-blur,
.Overlay-on {
    .Overlay-close {
        top: $v * 6;
        right: $v * 6;

        @media (#{$small-bp}) {
            right: $v * 2;
            top: $v;
            padding: 0;
            width: 43px;
            svg {
                width: 22px;
            }
        }
    }
}
.Overlay-blur {
    overflow: hidden;

    .navbar,
    .Article,
    .page-wrapper,
    .footer-strip,
    #footer {
        filter: blur(3px);
    }

    .PriceList-container,
    .navbar-default {
        z-index: 0;
    }
}

.notransition {
    .SearchOverlay,
    .Overlay-close {
        transition: none !important;
    }
}

.VideoOverlay-wrap {
    width: 100%;
    height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    iframe {
        width: 100%;
        position: absolute;
        height: 100%;
    }

    @media (#{$medium-bp}) {
        width: 65%;
    }
}

.Overlay-fixed {
    overflow: hidden;
}

.Overlay.Overlay--left {
    .Overlay-close {
        left: 0;
        top: 0;
        padding: $v * 2;
        @media (#{$medium-bp}) {
            padding: $v * 6;
        }
    }
}

.Overlay--white {
    background-color: $white;
    opacity: 0;
    .Overlay-close {
        position: absolute !important;
        svg g {
            stroke: $charcoal-0;
        }
        &:hover {
            svg g {
                stroke: $hot-coral;
            }
        }
    }
}

.Overlay--leadCapture {
    h4 {
        padding-top: $v * 2;
        margin-bottom: $v;
    }
    .AskQuestion-title {
        margin-top: $v * 2;
    }
}

.PricingCalculator {
    padding-top: $v * 6;
    padding-bottom: $v * 6;
    // @media (#{$large-bp}) {
    //   padding-top: 0;
    //   display: flex;
    //   align-items: center;
    // }

    .Pricing-recurrence {
        padding-top: math.div($v, 2);
        padding-bottom: $v * 2;
    }
    .PricingList-frequency-toggle {
        font-size: $v * 1.4;
        margin-top: $v * 2;
        align-items: flex-start;
        justify-content: center;
        > div {
            width: auto;
        }
    }
}
.Card.PricingCalculator-card {
    margin-left: -$v * 3;
    margin-right: -$v * 3;
    margin-top: $v * 5;
    padding-left: $v;
    padding-right: $v;
    width: 100vw;
    box-shadow: $card-box-shadow;

    @media (#{$medium-bp}) {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        border-radius: $card-border-radius;
        overflow: hidden;
        padding: $v * 4;
        width: 100%;
    }
}

.PricingCalculator-input {
    width: 60px;
    text-align: center;
    background-color: $charcoal-5;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid $charcoal;
    padding-left: math.div($v, 2);
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        opacity: 1;
    }
}

.PricingCalculator-results {
    background-color: $charcoal-5;
    color: $charcoal-0;
    padding: $v $v * 3;
    border-radius: 4px;
    margin-top: $v * 2;
    > img {
        position: relative;
        top: 6px;
    }
}

.PricingCalculator-desc {
    margin-top: $v * 3;
    margin-bottom: $v * 2;
    border-top: 1px solid $charcoal-4;
    padding-top: $v * 4;
    line-height: $v * 1.8;
    font-style: italic;
}
.PricingCalculator-fineprint {
    font-size: $v * 1.4;
}

.PricingCard-discountDescription {
    color: $midnight-2;
    font-size: 1.6 * $v;
    font-weight: 600;
    padding-top: $v * 1;
}

.PricingCard--hasDiscount {
    .PricingCard-discount {
        padding-top: $v * 1.5;
        padding-bottom: $v * 0.5;
        text-decoration: line-through;
    }
    .Pricing-price {
        padding-top: 0;
    }
}

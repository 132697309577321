$carousel-progress__size: rem(10px);
$carousel-progress__gap: rem(10px);

$carousel-progress__size--large: $carousel-progress__size + ($carousel-progress__gap * 0.6);

.carousel-progress {}

.carousel-progress__controls {
	display: flex;
	gap: $carousel-progress__gap;
}

.carousel-progress__controls__item {
	appearance: none;
	margin: 0;
	padding: 0;

	height: $carousel-progress__size;
	width: $carousel-progress__size;

	display: block;
	position: relative;
	background-color: transparent;
	border: none;

	cursor: pointer;

	position: relative;
	&::before {
		// The coloured dot, which zooms in on hover/focus
		content: "";
		position: absolute;
		top: 50%;
		right: 50%;
		transform: translate(50%, -50%);

		width: 100%;
		height: 100%;
		border-radius: $carousel-progress__size;
		background-color: currentColor;
	}
	&::after {
		// The clickable area, which extends halfway into the gap between items
		content: "";
		position: absolute;
		top: calc(-0.5 * #{$carousel-progress__gap});
		right: calc(-0.5 * #{$carousel-progress__gap});
		bottom: calc(-0.5 * #{$carousel-progress__gap});
		left: calc(-0.5 * #{$carousel-progress__gap});
	}

	&:hover,
	&:focus {
		&::before {
			width: $carousel-progress__size--large;
			height: $carousel-progress__size--large;
		}
	}

	&[aria-current="true"] {
		width: rem(30px);
		cursor: default;

		&::before {
			width: 100%;
			height: 100%;
			background: transparent;
		}
	}

	&,
	&::before {
		transition: all 0.2s ease-in-out;
	}
}

.carousel-progress__controls__item__progress-wrap {
	display: block;
	width: 100%;
	height: 100%;

	border-radius: $carousel-progress__size;
	overflow: hidden;

	.carousel-progress__controls__item[aria-current="true"] & {
		box-shadow: inset 0 0 0 1px currentColor;
	}
	.carousel-progress__controls--paused & {
		opacity: 0.5;
	}
	transition:
		opacity 0.2s ease-in-out,
		box-shadow 0.2s ease-in-out;
}

.carousel-progress__controls__item__progress {
	display: block;
	height: 100%;
	width: 100%;

	.carousel-progress__controls__item[aria-current="true"] & {
		background-color: currentColor;
	}
	transition: background-color 0.2s ease-in-out;
}

.carousel-progress__controls__item__paused {
	position: absolute;
	top: 50%;
	right: 50%;
	transform: translate(50%, -50%);

	width: $carousel-progress__size--large;
	height: $carousel-progress__size--large;

	display: flex;
	align-items: stretch;
	justify-content: space-between;
	&::before,
	&::after {
		content: "";
		width: 40%;
		background-color: currentColor;
	}

	pointer-events: none;
	opacity: 0;

	transition: opacity 0.2s ease-in-out;

	.carousel-progress__controls--paused .carousel-progress__controls__item[aria-current="true"] & {
		opacity: 1;
	}
}

.StyledHeader {
  h1 {
    text-align: center;

    @media (#{$medium-bp}) {
      text-align: left;
      margin-bottom: $v * 3;
    }
  }
  .u-textCenter {
    h1,
    .StyledHeader-description {
      text-align: center;
    }
  }
}

.StyledHeader-description {
  text-align: center;

  @media (#{$medium-bp}) {
    text-align: left;
  }
}

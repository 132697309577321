.ContentList-image {
  max-width: 100%;
  margin-bottom: $v * 3;
  transition: all .2s ease-in-out;

  &:hover {
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.15);
  }
}

.ContentList-contentType {
  @extend %smallLabel;
  color: $charcoal-3;
  margin-bottom: 0;
}

.ContentList-label {
  margin-bottom: $v * 3;
}

.ContentList {
  h4 {
    margin-bottom: $v * 2;
  }
}

.PriceList-gradient {
    background: linear-gradient(to bottom, $white, $dusk-4);
    padding-bottom: $v * 2;

    @media (#{$medium-bp}) {
        padding-bottom: $v * 7;
    }

    @media (#{$large-bp}) {
        padding-bottom: $v * 10;
    }
}
.PriceList {
    // border-top: 1px solid $charcoal-4;
    // border-bottom: 1px solid $charcoal-4;
    // background-color: $dusk-4;

    padding-top: $v * 9;
    overflow-x: hidden;

    @media (#{$medium-bp}) {
        padding-top: $v * 9;
    }

    .billing-period.h5 {
        line-height: 2.5rem;
        @media (#{$medium-bp}) {
            line-height: 5.5rem;
        }
    }
    .Pricing-price {
        padding-top: 0;
        margin-left: $v;
        margin-right: $v;
        margin-top: auto;
    }
}

.PriceComparison {
    background-color: $dusk-4;
    z-index: 1;
}

.PricingList-container {
    margin-top: -$most-popular-height;
    display: flex;
    // flex-direction: column;

    .Pricing {
        height: 100%;
    }

    // @media (#{$medium-bp}) {
    flex-direction: row;
    // }

    &.slick-initialized {
        flex-direction: column;
    }
}

.Pricing {
    cursor: pointer;

    &:hover {
        .Button--red {
            border-color: $hot-coral;
            background-color: $hot-coral;
            color: $white;
        }
    }
}

.PriceComparison-container {
    width: 100%;

    h3 {
        padding-bottom: $v * 3;
    }

    @media (#{$large-bp}) {
        width: 970px;
    }

    @media (#{$xlarge-bp}) {
        width: 1170px;
    }

    .slick-list {
        overflow: visible;
    }
    .slick-slide {
        overflow: visible;
        padding: $v * 2;
    }
}

.PriceComparison-table {
    background: $white;
    width: 100%;
    display: flex;
    align-items: center;
    padding: $v * 2 0;

    .fa-check {
        color: $blue-1;
    }
}

.PriceComparison-table--slide {
    background: transparent;
    th,
    td {
        border: none;
    }
    td:first-child {
        border-right: 5px solid $dusk-4;
    }

    tr:nth-child(even) {
        background: transparent;
    }

    tr:nth-child(odd) {
        background: $white;
    }
}

.PriceAccordion-body-row {
    .col-md-4 {
        margin-right: auto;
        @media (#{$large-bp}) {
            margin-right: initial;
        }
    }
}

// .PriceAccordion-body-row-PremiumPhoneSupport,
// .PriceComparison-table-PremiumPhoneSupport {
//   display: none;
// }

// .PriceList[data-country=GB],
// .PriceList[data-country=NZ],
// .PriceList[data-country=AU] {
//   .PriceComparison-table-PremiumPhoneSupport {
//     display: table-row;
//   }
// }

// .PriceSMS {
//     padding: 0;
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     left: 0;
//     right: 0;
// }

// .PriceSMS-accordion {
//     margin-top: $v * 3;
//     background: $white;
//     box-shadow: $card-box-shadow;
//     border-radius: $card-border-radius;
//     // border: 1px solid $charcoal-4;
//     // padding: $v;
// }

// .PriceSMS-accordion-title {
//     @extend %h4;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: $v * 3 $v * 2;
//     cursor: pointer;
// }

// .PriceSMS-accordion-body {
//     transition: max-height 0.5s ease-in-out;
//     overflow: hidden;

//     .row {
//         padding: $v;
//         margin-left: 0;
//         margin-right: 0;
//         border-top: 1px solid $dusk-2;

//         h6 {
//             margin: 0;
//         }
//     }
// }

// .closed {
//     .PriceSMS-icon-vertical {
//         // transition: all 0.5s ease-in-out;
//         transform: rotate(0deg);
//         opacity: 1;
//     }

//     .PriceSMS-icon-horizontal {
//         // transition: all 0.5s ease-in-out;
//         transform: rotate(0deg);
//         opacity: 1;
//     }
//     .PriceSMS-accordion-body {
//         max-height: 0;
//     }
// }

// .opened {
//     opacity: 1;

//     .PriceSMS-icon-vertical {
//         // transition: all 0.5s ease-in-out;
//         transform: rotate(90deg);
//         opacity: 0;
//     }

//     .PriceSMS-icon-horizontal {
//         // transition: all 0.5s ease-in-out;
//         transform: rotate(0deg);
//         opacity: 1;
//     }
//     .PriceSMS-accordion-body {
//         max-height: 900px;
//     }
// }

// .PriceSMS-icon {
//     height: 4rem;
//     width: 4rem;
//     font-size: 1em;
//     // opacity: .7;
// }

// .PriceSMS-icon .PriceSMS-icon-circle {
//     position: relative;
//     width: 2.55em;
//     height: 2.5em;
//     border-radius: 100%;
//     // border: solid .5em #DFDAD7;
// }

// .PriceSMS-icon .PriceSMS-icon-circle .PriceSMS-icon-horizontal {
//     position: absolute;
//     background-color: $hot-coral;
//     width: 16px;
//     height: 2px;
//     left: 50%;
//     margin-left: -8px;
//     top: 50%;
//     margin-top: -1px;
// }

// .PriceSMS-icon .PriceSMS-icon-circle .PriceSMS-icon-vertical {
//     position: absolute;
//     background-color: $hot-coral;
//     width: 2px;
//     height: 16px;
//     left: 50%;
//     margin-left: -1px;
//     top: 50%;
//     margin-top: -8px;
// }

.Pricing-country-list {
    text-align: center;
    max-height: 0;
    transition: max-height 0.5s ease-in-out;
    overflow: hidden;
    margin: 0;

    a {
        padding: math.div($v, 4) 0;
        display: block;
    }

    &.is-open {
        max-height: 1000px;
    }
}

.PriceComparison-header-mobile {
    position: fixed;
    top: 0;
    z-index: 2500015;
    width: 100%;
    background: $white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: all 0.4s ease-in-out;
    transform: translateY(-105%);

    @media (#{$medium-bp}) {
        .PriceComparison-container {
            display: flex;
            align-items: inherit;
            justify-content: space-evenly;
        }
    }

    @media (#{$large-bp}) {
        display: none;
    }

    &.is-down {
        transform: translateY(0);
    }

    button {
        @extend %h5;
        background: none;
        border: none;
        padding: $v;
        text-align: center;
        outline: none;
        line-height: 2rem;
        color: $dusk-0;
        // opacity: .7;

        @media (#{$medium-bp}) {
            color: $hot-coral;
            opacity: 1;
            border-left: 1px solid $charcoal-4;
            width: 100%;
            padding-top: $v * 2;
            padding-bottom: $v * 2;

            &:first-child {
                border-left: 0;
            }
        }
    }
    .slick-current,
    .is-active {
        button {
            color: $hot-coral;
            // opacity: 1;
        }
        @media (#{$medium-bp}) {
            background-color: $charcoal-5;
        }
    }
}

.PriceComparison-header {
    display: none;
    position: fixed;
    top: 0;
    z-index: 2500015;
    width: 100%;
    background: $white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: all 0.4s ease-in-out;
    transform: translateY(-105%);
    @media (#{$large-bp}) {
        display: block;
    }

    &.is-down {
        transform: translateY(0);
    }
}

.PriceComparison-table-header {
    h6 {
        margin-bottom: math.div($v, 2);
    }
}

.PricingHeader-hidden{
    display: none;
}

.PricingHeader-price {
    margin-bottom: $v;
}

.PriceComparison-container-large {
    display: none;
    @media (#{$large-bp}) {
        display: block;
    }
}
.PriceComparison-container-mobile {
    @media (#{$large-bp}) {
        display: none;
    }
}

.PriceComparison-title,
.PriceComparison-List {
    margin-left: $v * 2;
    margin-right: $v * 2;
    padding-bottom: 0;
    @media (#{$large-bp}) {
        margin-left: 0;
        margin-right: 0;
    }
}

.PriceComparison-title {
    padding-bottom: $v * 3;

    h3 {
        padding-top: $v * 6;
        text-align: center;
    }

    @media (#{$medium-bp}) {
        display: flex;
        align-items: center;

        h3 {
            text-align: left;
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

// .PriceComparison-key {
//   display: flex;
//   align-items: center;
//   margin-left: auto;
//   color: $charcoal-2;

//   @media (#{$small-bp}) {
//     justify-content: center;
//     font-size: $v * 1.2;
//     line-height: $v * 1.2;
//   }
// }

.PriceComparison-List {
    display: block;

    .slick-track {
        align-items: flex-start;
    }

    @media (#{$large-bp}) {
        display: none;
    }
}

.Pricing-toggle {
    display: flex;
    padding: 0 0 $v * 5;
    align-items: center;
    justify-content: center;

    > span {
        display: inline-block;
        max-width: 33%;
        margin: 0 math.div($v, 2);
        line-height: 1.8rem !important;
        text-align: left;
    }
    label {
        margin: 0;
    }
}
// .Pricing-chevron {
// }

.u-widerContainer {
    width: 100%;

    @media (#{$large-bp}) {
        width: 970px;
    }

    @media (#{$xlarge-bp}) {
        width: 1170px;
    }
}

// .slick-current {
//   .Button--red {
//     background-color: $hot-coral;
//     border-color: $hot-coral;
//     color: $white;
//     &:hover {
//       color: $white;
//       background-color: $coral-hover;
//       border-color: $coral-hover;
//     }
//   }
// }

.Pricing-tick {
    margin-left: auto;
    margin-right: auto;
}

.tick-small {
    width: 12px;
}

.PricingComparison-detailsPlan {
    display: none;
    border-left: 1px solid $charcoal-3;
    color: $charcoal-3;
    font-weight: bold;
    font-size: $v * 1.8;

    @media (#{$large-bp}) {
        display: block;
    }
}

.PricingComparison-detailsPlan--new {
    display: none;
    border-left: 1px solid $charcoal-0;
    color: $charcoal-0;
    // font-weight: bold;
    // text-transform: uppercase;
    // font-size: $v * 1.8;

    line-height: 3.5rem;
    @media (#{$large-bp}) {
        display: block;
    }
}

.PriceList-columns {
    display: flex;
    // @media (#{$large-bp}) {
    //   width: 970px;
    // }

    @media (#{$large-bp-below}) {
        flex-direction: column-reverse;
        > div {
            width: 100%;
        }
    }

    @media (#{$xlarge-bp}) {
        display: block;
    }
}

.Pricing-popup {
    .Tooltip-trigger {
        padding-top: 0;
    }
}

.Pricing-wrap {
    padding-left: $v;
    padding-right: $v;
}

.Pricing-chevron {
    z-index: 2;
    color: $hot-coral;
    background: none;
}

.Pricing-smsQuota {
    margin: $v * 3 0 0;
}

.Pricing-smsQuota-text {
    border-radius: 4px;
    padding: $v;
    background-color: $charcoal-5;
    font-size: $v * 1.6;
}

.Pricing-MajorFeatures {
    list-style: none;
    padding: $v * 3 0 0 0;
    border-top: 1px solid $charcoal-4;

    li {
        line-height: $v * 3;
        text-align: left !important;
        padding-bottom: $v;
        display: flex;
    }
    .Pricing-MajorFeature--tick::before {
        content: url('/images/svg/tick-midnight.svg');
        margin-right: $v;
        position: relative;
        bottom: 3px;
    }

    .Pricing-MajorFeature--dash {
        color: $charcoal-3;
        &::before {
            border-radius: 3px;
            content: '';
            height: 3px;
            width: 11px;
            background-color: $charcoal-3;
            margin-right: $v;
            margin-top: 14px;
        }
    }
}

.PricingFeatures--new {
    .PriceAccordion-body-title {
        color: $charcoal-0;
    }
}

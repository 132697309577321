$pathways__bp: $large-bp;
$pathways__bp--large: $xlarge-bp;

.pathways {}

.pathways__layout {
	@media (#{$pathways__bp}) {
		// Only change to grid view this early if there are fewer than four items
		&:not(:has(.pathways__item:nth-child(4))) {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
		}
	}

	@media (#{$pathways__bp--large}) {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
	}
}

.pathways__item {
	display: flex;
	justify-content: center;
	@media (#{$pathways__bp}) {
		// If there are only two items, align them with the rest of the grid
		&:first-child:nth-last-child(2) {
			justify-content: flex-start;
		}
		&:nth-child(2):last-child {
			justify-content: flex-end;
		}
	}
	@media (#{$pathways__bp--large}) {
		// Extra specificity needed to override styles for lower breakpoint
		&:first-child:nth-last-child(2),
		&:nth-child(2):last-child {
			justify-content: center;
		}
	}

	background-repeat: no-repeat;
	background-size: cover;

	&,
	&.pathways__item--blush {
		background-color: $coral-3;
		background-image: url("/images/background-images/bg-colour-blush.jpg");
		color: $charcoal-base;
	}

	&.pathways__item--aubergine {
		background-color: $aubergine;
		background-image: url("/images/background-images/bg-colour-aubergine.jpg");
		color: $white;
	}

	&.pathways__item--midnight {
		background-color: $midnight;
		background-image: url("/images/background-images/bg-colour-midnight.jpg");
		color: $white;
	}

	&.pathways__item--rose {
		background-color: $dusk-3;
		background-image: url("/images/background-images/bg-colour-rose.jpg");
		color: $charcoal-base;
	}
}

.pathways__item__body {
	display: flex;
	flex-direction: column;
	align-items: center;

	// Set the max width to the width used by the image crop
	box-sizing: content-box;
	max-width: 768px;
	padding: (8 * $v) (1.5 * $h);

	@media (#{$pathways__bp}) {
		padding: (12 * $v) (8 * $h);
		// Reduce inline padding if there are four or more items
		.pathways:has(.pathways__item:nth-child(4)) & {
			padding-inline: (4 * $h);
		}

		// Special rules for when there are just two items
		.pathways__item:nth-child(2):last-child &,
		.pathways__item:first-child:nth-last-child(2) & {
			max-width: min(100%, 720px);
			max-width: min(100%, calc(var(--container-width) / 2));
		}

		.pathways__item:nth-child(2):last-child & {
			padding-right: 0;
			padding-right: var(--container-gutter);
		}
		.pathways__item:first-child:nth-last-child(2) & {
			padding-left: 0;
			padding-left: var(--container-gutter);
		}
	}
	@media (#{$pathways__bp--large}) {
		max-width: none;
		width: 100%;

		// Extra specificity needed to override styles for lower breakpoint
		.pathways__item:nth-child(2):last-child &,
		.pathways__item:first-child:nth-last-child(2) & {
			padding-left: 0;
			padding-right: 0;
			// Further restrict width if there are just two items
			width: 60%;
		}
	}
}

.pathways__item__content {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (#{$pathways__bp--large}) {
		max-width: 490px;
	}
}

.pathways__item__tagline {
	@extend %h4;

	.pathways--heading-intro & {
		display: none;
	}
}

.pathways__item__title {
	@extend %h2;
	text-align: center;
	display: block;

	.pathways--heading-intro & {
		margin: 0;
	}
	.pathways--tagline-heading & {
		flex-grow: 1;
		margin: (2 * $v) 0 0;
	}

	.pathways--show-all & {
		flex-grow: 1;
		margin: (2 * $v) 0 0;
	}
}

.pathways__item__description {
	@extend %p;
	text-align: center;
	display: block;
	margin: (2 * $v) 0 0;
	flex-grow: 1;

	.pathways--tagline-heading & {
		display: none;
	}
}

.pathways__item__image {
	display: block;
	object-fit: cover;
	object-position: center;
	margin: (4 * $v) 0 0;

	img {
		transition: 1s transform ease-in-out;
	}
}
a.pathways__item__image {
	overflow: hidden;
	&:hover,
	&:focus {
		img {
			transform: scale(1.05);
		}
	}
	// Separate selector so it won't break the main one on older browsers
	.pathways__item:has(.pathways__item__link:where(:hover, :focus)) & {
		img {
			transform: scale(1.05);
		}
	}
}

.pathways__item__link {
	@include button("primary");
	margin: (4 * $v) 0 0;
}

.u-sm-only {
    display: none !important;

    @media (#{$small-bp}) {
        display: block !important;
    }
}

.u-md-only {
    display: none !important;

    @media (#{$medium-bp}) {
        display: block !important;
    }

    @media (#{$large-bp}) {
        display: none !important;
    }
}
.u-md-visible {
    display: none !important;

    @media (#{$medium-bp}) {
        display: initial !important;
    }

    @media (#{$large-bp}) {
        display: none !important;
    }
}

.u-lg-only {
    display: none !important;

    @media (#{$large-bp}) {
        display: block !important;
    }
}

.u-sm-hide {
    display: none !important;

    @media (#{$medium-bp}) {
        display: block !important;
    }
}

.u-md-hide {
    display: block !important;

    @media (#{$medium-bp}) {
        display: none !important;
    }

    @media (#{$large-bp}) {
        display: block !important;
    }
}
.u-lg-hide {
    display: block !important;

    @media (#{$large-bp}) {
        display: none !important;
    }
}

.u-visibilityHidden {
    visibility: hidden;
}

.u-sm-hidden {
    display: initial;
    @media (#{$small-bp}) {
        display: none;
    }
}

.u-md-hidden {
    display: initial;
    @media (#{$medium-bp}) {
        display: none;
    }
    @media (#{$large-bp}) {
        display: initial;
    }
}

.invisible {
    display: none;
    visibility: hidden;
    transition: opacity 0.2s ease-in;
    opacity: 0;
    height: 0;
}

.is-visible {
    visibility: visible;
    opacity: 1;
    height: initial;
}

* .u-displayNone {
    display: none !important;
}

.home-stars {
  background-color: $charcoal-5;
  padding: $v * 9 0;
  text-align:center;

  .row {
    text-align: center;
    @media (#{$medium-bp}) {
      display: flex;
      .col-sm-auto {
        width: auto;
      }
    }
    justify-content: center;
  }

  .ga {
    transform: scale(0.9);
  }

  img {
    height: 24px;
    margin-top: 50px;
  }

  .star-logo {
    margin-bottom: $v;
    // filter: grayscale(100%);
  }

  .star-wrap {
    margin: -5px 2rem;
    overflow: hidden;

    @media (max-width: 991px) {
      margin: 0 0 2rem 0;
    }

    .fa-star {
      display: inline-block;
      color: $gold-base;
      font-size: 24px;
      transition: all 0.5s cubic-bezier(0.29, 0.21, 0.58, 1.39);
      transform: translateY(100%);
    }
  }

  &.up {
    .star-wrap {
      .fa-star {
        transform: translateY(0);
      }
    }
  }

  @media (max-width: 991px) {
    img {
      margin: 0;
    }
  }
}
@use "sass:math";

$video-course-pillar__bp: $medium-bp;

.video-course-pillar {}

.video-course-pillar__layout {
	padding: (3 * $v) 0 (10 * $v);
	border-top: 1px solid $border-grey;
}

.video-course-pillar__title {
	@extend %h2;

	margin-top: 0;
}

.video-course-pillar__items {
	list-style: none;
	margin: 0;
	padding: 0;

	display: grid;
	column-gap: 2 * $h;
	row-gap: 6 * $v;

	@media ($video-course-pillar__bp) {
		grid-template-columns: repeat(3, 1fr);
	}
}

.video-course-pillar__item {}

.video-course-pillar__item__link {
	display: block;

	text-decoration: none;
	color: $charcoal-0;

	&:hover,
	&:focus {
		color: $charcoal-0;

		.video-course-pillar__item__thumbnail::before {
			background-color: $coral-2;
		}
	}
}

.video-course-pillar__item__thumbnail {
	margin: 0;

	position: relative;
	font-size: 6px;

	&::before,
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&::before {
		width: 12em;
		height: 8em;
		background: rgba($black, 0.6);
		border-radius: 1em;

		transition: 0.2s background-color ease-in-out;
	}

	&::after {
		width: 0;
		height: 0;
		border-left: 3em solid $white;
		border-top: 2em solid transparent;
		border-bottom: 2em solid transparent;
		margin-left: 0.4em;
	}
}

.video-course-pillar__item__thumbnail-image {
	display: block;
	max-width: 100%;
	aspect-ratio: 16 / 9;
}

.video-course-pillar__item__duration {
	display: block;
	margin: $v 0 0;

	@extend %h5;

	color: $charcoal-2;
}

.video-course-pillar__item__name {
	@extend %h3;
	margin: 0;
}

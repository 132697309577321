// ----- General -----
.pop-up__container {
    border-radius: 7px;
    padding: 3.5rem 0;
}

.pop-up__left-col {
    padding: 1.5rem 0 1.5rem 5rem;
    text-align: left;
}

.pop-up__right-col {
    padding: 1.5rem 5rem 1.5rem 0;
}

.pop-up__content_vert {
    display: grid;
    align-content: center;
}

.pop-up__heading {
    text-wrap: balance;
}

.pop-up__copy {
    text-wrap: balance;
}

.pop-up__button-holder {
    display: inline-block;
}

.pop-up__button {
    height: auto;
    width: auto;
}

.pop-up__button {
    &::after {
        content: '+';
        padding-left: 6px;
    }
}

.pop-up__button2 {
    height: auto;
    width: auto;
}

.pop-up__mb-5 {
    margin-bottom: 2.5rem;
}

.pop-up__image {
    width: 100%;
    max-width: 100%;
    height: auto;
}

.pop-up__image-link {
    width: 140px;
    height: auto;
}

// ------ Pop up overlay -----
.pop-up__image-overlay {
    width: auto;
    max-width: 100%;
    height: auto;
}

@keyframes pop-up__slide-up {
    from {
        transform: translateY(150%);
        opacity: 0;
    }
}

.pop-up__animation-active {
    animation: pop-up__slide-up 0.5s ease-in-out;
}

.pop-up__overlay_outer,
.VideoOverlay-wrap {
    top: 40%;
    padding-top: 2.5rem;
}

.pop-up__overlay_outer {
    text-align: center;
    background-color: white;
    width: 100%;
    max-width: 1100px;
    height: auto;
    padding: 5rem;
    margin-left: auto;
    margin-right: auto;
}

.pop-up__margin-spacer{
    margin-right: 1.5rem;
}

// ----- media queries ------
/* Small devices (tablets) */
@media ($small-bp) {

    .pop-up__margin-spacer{
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }

    .pop-up__overlay_outer {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .pop-up__left-col {
        padding: 1.5rem 3rem 3rem 3rem;
        text-align: center;
    }

    .pop-up__right-col {
        padding: 1.5rem 3rem 1.5rem 3rem;
    }

    .pop-up__heading {
        text-wrap: none;
    }

    .pop-up__copy {
        text-wrap: none;
    }
}

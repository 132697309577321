$video-course-breadcrumb__bp: $medium-bp;

.video-course-breadcrumb {
	margin: (5 * $v) 0 (3 * $v);
}

.video-course-breadcrumb__link {
	text-decoration: none;

	font-family: $text-font;
	font-size: 1.6rem;
	line-height: 1.2;
	font-weight: 700;

	color: $charcoal-base;
	transition: 0.2s color ease-in-out;

	&:hover,
	&:focus {
		color: $coral-hover
	}
}

.video-course-breadcrumb__icon {
	font-size: 1.2em;

	// Account for FontAwesome's extra padding within the icon itself
	margin-left: -0.3em;
	&::before {
		// Remove FontAwesome's margin
		margin-left: 0;
	}
}

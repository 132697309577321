.timely-buttons {
	margin: 2 * $v 0;
}

.timely-buttons__layout {
	display: flex;
	flex-wrap: wrap;

	gap: 2 * $h;
}

.timely-buttons__layout--left {
	justify-content: flex-start;
}
.timely-buttons__layout--centre {
	justify-content: center;
}
.timely-buttons__layout--right {
	justify-content: flex-end;
}

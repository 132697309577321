.leadcap-revamp {
  background: #36434a;
  color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
}

.leadcap-revamp.form-footer {
  padding: 10em 0;
}

.leadcap-field-col {
  padding-right: 0;
}

.tmly-form .check label,
form.tmly-lcf .check label {
  color: #fff;
}
.u-dark {
  .tmly-form .check label,
  form.tmly-lcf .check label {
    color: #444;
  }
}

form.tmly-lcf .leadcap-field-col input.field {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.leadcap-submit-col {
  padding-left: 0;
}

form.tmly-lcf .leadcap-submit-col input.btn {
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -1px;
}

form.tmly-lcf .check .field {
  position: absolute;
}

.crate .leadcap-revamp .row {
  margin-left: -15px;
  margin-right: -15px;
}

@media (max-width: 990px) {
  .leadcap-revamp {
    text-align: center;
  }

  .spruce .leadcap-revamp {
    text-align: left;
  }

  form.tmly-lcf .leadcap-submit-col input.btn {
    max-width: none;
  }
}


@media (max-width: 767px) {

  .spruce .leadcap-revamp {
    text-align: center;
  }

}

@media (min-width: 767px) and (max-width: 1200px) {

  form.tmly-lcf .leadcap-field-col,
  form.tmly-lcf .leadcap-submit-col {
    margin-top: 0;
  }

  .spruce .form-footer .row {
    align-items: top;
    -webkit-box-align: top;
  }
}

.leadcap-revamp--switch {
  padding-top: $v * 7.5 !important;
  .Button--primary {
    float: left;
  }
}

.guten-leadcap {
  background-color: $dusk-4;
  text-align: center;
  padding-top: $v * 6;
  padding-bottom: $v * 6;
  padding-left: 0;
  padding-right: 0;
  width: 100vw;

  .check label {
    @extend .u-midGrey;
  }

  @media (#{$small-bp}) {
    margin-left: -$v * 1.5;

    .leadcap-field-col {
      padding-right: 15px;
      .field {
        border-bottom-right-radius: 4px !important;
        border-top-right-radius: 4px !important;
      }
    }
    .leadcap-submit-col {
      padding-left: 15px;
      .Button {
        width: 100%;
        max-width: 100% !important;
      }
    }
  }

  @media (#{$medium-bp}) {
    width: calc(100% - 3rem);
    border-radius: 10px;
    padding-left: $v * 4;
    padding-right: $v * 4;
    margin-right: $v * 1.5;
    margin-left: $v * 1.5;
    .leadcap-submit-col {
      .Button {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
        max-width: 100% !important;
      }
    }
  }

  @media (#{$large-bp}) {
    width: 100%;
    text-align: left;
    margin-right: 0;
    margin-left: 0;

    .row {
      align-items: center;
      display: flex;
    }
  }
}

.guten-leadcap-description  {
  margin-bottom: $v * 3;
  @media (#{$large-bp}) {
    padding-left: $v * 2.5;
    margin-bottom: 0;
  }
}
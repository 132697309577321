.Brochure {
    @extend .u-vSpacingXL-top;
    margin-top: $v * 9;
    padding-bottom: $v * 9;
    color: $white;
    background-size: cover;
    background-position: center center;

    @media (#{$medium-bp}) {
        margin-top: $v * 21;
        padding-bottom: $v * 21;
    }

    &.is-dark {
        &,
        h3,
        label {
            color: $charcoal-0;
        }
    }
}

.Brochure-container {
    @extend %container;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    a:not(.Button) {
        &,
        &:visited {
            transition: all 0.2s ease-out;
            color: $light-coral;
        }
        &:hover {
            color: $hot-coral;
        }
    }

    @media (#{$large-bp}) {
        flex-direction: row;
        padding: 0;
        > div {
            padding: 0;
        }
    }
}

.Brochure-image {
    width: 100%;
    padding-bottom: $v * 3;

    @media (#{$medium-bp}) {
        padding-bottom: $v * 4;
    }

    @media (#{$large-bp}) {
        padding-bottom: 0;
    }
}

.Brochure-col1,
.Brochure-col2 {
    h3 {
        margin-bottom: $v * 4;
    }
}

.Brochure-col2 {
    form {
        margin-top: $v * 4;
        p {
            margin: 0;
        }
    }
}

.Brochure-actions {
    text-align: center;
    margin-top: $v * 4;
}

.Brochure-tnc {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: normal;
    margin-bottom: $v;
    text-align: center;
}

.Brochure-checkbox,
.Brochure-privacy {
    font-size: 1.4rem;
    font-weight: normal;
    text-align: left;
    a {
        border-bottom: 1px solid rgba(0, 0, 0, 0);
    }
    a:hover {
        padding-bottom: 0;
        border-bottom: 1px solid $hot-coral;
    }
}

.nogdpr .Brochure-privacy {
    text-align: center;
}

.nogdpr .Privacy-checkbox {
    padding-left: 0;
    .checkmark {
        display: none;
    }
}
.nogdpr .Brochure-tnc {
    display: none;
}

.nogdpr #formmodal-1-newsoffers,
.nogdpr #hplc-newsoffers,
.nogdpr label[for='hplc-newsoffers'],
.nogdpr label[for='formmodal-1-newsoffers'] {
    display: none;
}

.Brochure-container--column {
    flex-direction: column !important;

    h3 {
        margin-top: $v;
        margin-bottom: $v * 4;
    }

    .Brochure-tnc,
    .Brochure-privacy {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        font-weight: normal;
    }
}

.input.input--flex {
    margin-bottom: $v * 2;

    @media (#{$medium-bp}) {
        display: flex;
        input {
            margin-bottom: 0 !important;
            border-radius: 0 !important;
            border-color: $white !important;
        }
    }
}

.Brochure-select {
    width: 100%;
    height: 56px;
    display: flex;
    flex-direction: column;
    // margin-bottom: $v * 2;
    .mdc-select__native-control,
    .mdc-select__selected-text,
    .mdc-select--outlined .mdc-select__native-control,
    .mdc-select--outlined .mdc-select__selected-text {
        border: 1px solid #aaa;
        background-color: $white;
        &:hover {
            border-bottom-color: #aaa;
        }
    }

    .mdc-select__dropdown-icon {
        color: $charcoal;
    }

    .mdc-notched-outline label.mdc-floating-label {
        opacity: 1;
        z-index: 2;
        position: absolute;
        top: 4px;
        left: 15px;
        // font-weight: 700;
        // text-transform: uppercase;
        font-size: 17px;
        height: 50px;
        line-height: 50px;
        transition: all 0.3s ease;
        color: $charcoal-0;
        cursor: text;
        pointer-events: none;

        &.mdc-floating-label--float-above {
            transform: translateY(-23%) scale(0.75);
        }
    }

    &.mdc-select--focused .mdc-select__dropdown-icon {
        transform: translateY(50%);
    }
}

.Brochure.Brochure--condensed {
    padding-top: $v * 6;
    padding-bottom: $v * 5;
    margin-top: 0;

    @media (#{$medium-bp}) {
        padding-top: $v * 10;
    }
}

//Default
.Brochure-loading {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}
.Brochure-loading div {
    position: absolute;
    width: 5px;
    height: 5px;
    background: #cef;
    border-radius: 50%;
    animation: Brochure-loading 1.2s linear infinite;
}
.Brochure-loading div:nth-child(1) {
    animation-delay: 0s;
    top: 29px;
    left: 53px;
}
.Brochure-loading div:nth-child(2) {
    animation-delay: -0.1s;
    top: 18px;
    left: 50px;
}
.Brochure-loading div:nth-child(3) {
    animation-delay: -0.2s;
    top: 9px;
    left: 41px;
}
.Brochure-loading div:nth-child(4) {
    animation-delay: -0.3s;
    top: 6px;
    left: 29px;
}
.Brochure-loading div:nth-child(5) {
    animation-delay: -0.4s;
    top: 9px;
    left: 18px;
}
.Brochure-loading div:nth-child(6) {
    animation-delay: -0.5s;
    top: 18px;
    left: 9px;
}
.Brochure-loading div:nth-child(7) {
    animation-delay: -0.6s;
    top: 29px;
    left: 6px;
}
.Brochure-loading div:nth-child(8) {
    animation-delay: -0.7s;
    top: 41px;
    left: 9px;
}
.Brochure-loading div:nth-child(9) {
    animation-delay: -0.8s;
    top: 50px;
    left: 18px;
}
.Brochure-loading div:nth-child(10) {
    animation-delay: -0.9s;
    top: 53px;
    left: 29px;
}
.Brochure-loading div:nth-child(11) {
    animation-delay: -1s;
    top: 50px;
    left: 41px;
}
.Brochure-loading div:nth-child(12) {
    animation-delay: -1.1s;
    top: 41px;
    left: 50px;
}
@keyframes Brochure-loading {
    0%,
    20%,
    80%,
    100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
}

.Brochure-col2--divider {
    border-top: 2px solid $charcoal-2;
    padding-top: 6 * $v;
    margin-top: 4 * $v;
    @media (#{$medium-bp}) {
        padding-top: 0;
        margin-top: 0;
        border-top: 0;
        border-left: 2px solid $charcoal-3;
        padding-left: 8.333% !important;
    }
}

.Brochure-select.mdc-select.mdc-select--outlined .mdc-floating-label {
    background-color: transparent;
}

.TeamSizeChooser {
    flex-wrap: wrap;

    @media (#{$medium-bp}) {
        display: flex;
    }
}

.TeamSizeChooser--main-title {
    @extend .h3;

    padding-top: $v * 3;

    @media (#{$medium-bp}) {
        padding-top: $v * 5;
        padding-bottom: $v * 3;
    }
    @media (#{$large-bp}) {
        padding-bottom: 0;
    }
}

.TeamSizeChooser-item {
    cursor: pointer;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    text-align: center;
    border: 1px solid $hot-coral;
    padding: $v * 1.5;
    border-radius: 3px;
    width: 100%;
    margin-top: $v * 3;
    margin-bottom: $v * 3;
    box-shadow: $card-box-shadow;

    .TeamSizeChooser-item-img {
        position: relative;
        width: 40%;
        height: 60px;
    }

    @media (#{$medium-bp}) {
        box-shadow: none;
        border-color: $charcoal-4;
        width: calc(50% - #{$v} * 3);
        flex-direction: column;
        flex-basis: auto;
        // flex-grow: 0;
        margin-left: $v * 1.5;
        margin-right: $v * 1.5;
        margin-bottom: 0;
        padding: $v * 3 $v * 1.5 $v * 1.5;

        .TeamSizeChooser-item-img {
            width: 70%;
            height: 169px;
        }
    }
    @media (#{$large-bp}) {
        .TeamSizeChooser-item-img {
            position: relative;
            height: 96px;
        }
    }

    @media (#{$xlarge-bp}) {
        margin-top: $v * 6;
        margin-bottom: $v * 6;

        // flex-basis: auto;
        width: calc(25% - #{$v} * 3);

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .TeamSizeChooser-action,
    .Pricing-pitch-desc {
        display: none;

        @media (#{$medium-bp}) {
            width: 100%;
            display: block;
        }
    }

    .Pricing-pitch-desc {
        @extend .h6;
        color: $charcoal-2;
        margin-bottom: $v * 3;
    }

    &:hover {
        .Button--red {
            color: $white;
            background-color: $hot-coral;
        }
    }
}

.TeamSizeChooser-title {
    // @extend %h2;
    color: $hot-coral;
    width: 100%;
    @media (#{$medium-bp}) {
        color: $charcoal-0;
        margin-top: $v * 3;
        margin-bottom: $v * 3;
    }
}

.TeamSizeChooser-action {
    margin-top: auto;
    width: 100%;
}

.TeamSizeChooser--footer {
    color: $charcoal-1;
}

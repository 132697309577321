.TalkToUs-box {
    background: $dusk-4;
    color: $charcoal-base;
    padding-top: $v * 6;
    padding-bottom: $v * 6;
    padding-left: $v * 3;
    padding-right: $v * 3;
    margin-left: -15px;
    margin-right: -15px;
    text-align: center;
    border-radius: 0;

    @media (#{$medium-bp}) {
        border-radius: 10px;
        text-align: left;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        .row {
            display: flex;
            align-items: center;
        }
    }
}

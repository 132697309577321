/* Carousel Slider */

// .Carousel {
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    clear: both;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
    &:focus {
        outline: none;
    }
    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    &:before,
    &:after {
        content: '';
        display: table;
    }
    &:after {
        clear: both;
    }
    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir='rtl'] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }
    display: none;
    &.dragging img {
        pointer-events: none;
    }
    .slick-initialized & {
        display: block;
    }
    .slick-loading & {
        visibility: hidden;
    }
    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}
// }

.slick-slide {
    position: relative;
    transition-delay: 0 !important;

    &.slick-active {
        transition-delay: 400ms !important;
    }
}

ul.slick-dots {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    margin-top: $v;
    display: flex !important;
    justify-content: center;
    @media (#{$medium-bp}) {
        margin-top: $v * 4;
    }

    li {
        line-height: 0;
    }
    button {
        background: transparent;
        outline: none;
        font-size: 0;
        height: 6px;
        width: 30px;
        margin: 0 math.div($v, 2);
        border-radius: 3px;
        border: 1px solid #b0b5b8;
    }
    .slick-active button {
        background-color: #b0b5b8;
    }
}

.slick-arrow {
    -webkit-appearance: none;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    color: rgba(0, 0, 0, 0);
    border: solid 2px $charcoal-4;
    border-right: none;
    border-bottom: none;
    border-radius: 1px;
    outline: none;
    background: transparent;
    width: 15px;
    height: 15px;
    padding: 0;
    bottom: 50%;
    position: absolute;
    z-index: 9;
    display: none !important;
    cursor: pointer;

    &::before {
        transition: all 0.2s ease-in-out;
        content: ' ';
        border: solid 2px $charcoal-3;
        border-right: none;
        border-bottom: none;
        border-radius: 1px;
        outline: none;
        background: transparent;
        width: 15px;
        height: 15px;
        position: absolute;
    }

    @media (#{$medium-bp}) {
        display: block !important;
    }

    &::after {
        transition: all 0.2s ease-in-out;
        border-radius: 50%;
        content: ' ';
        background: $charcoal-5;
        border-radius: 50%;
        display: block;
        height: 50px;
        width: 50px;
        position: absolute;
        top: -20px;
        left: -20px;
        z-index: -2;
    }

    &:hover {
        &::after {
            background: $charcoal-4;
        }
        &::before {
            border-color: $charcoal-2;
        }
    }
}

.slick-prev {
    transform: rotateZ(-45deg);
    left: 20px;
    overflow: visible;
    @media (#{$xlarge-bp}) {
        left: -35px;
    }
}

.slick-next {
    transform: rotateZ(135deg);
    right: 20px;
    overflow: visible;
    @media (#{$xlarge-bp}) {
        right: -35px;
    }
}

@mixin button__theme($theme: null) {
	@if ($theme == "primary") {
		border-color: $hot-coral;
		background: $hot-coral;
		color: $white;

		&:hover {
			background-color: $coral-hover;
			border-color: $coral-hover;
			color: $white;
		}
		&:focus {
			color: $white;
		}
	} @else if ($theme == "plain") {
		opacity: 0.85;
		position: relative;
		top: 0;
		color: $hot-coral;
		border-radius: 0;
		padding: 0;
		transition: all 0.2s ease-in;
		background: transparent;
		cursor: pointer;

		border-left: 0;
		border-right: 0;
		border-top: 0;
		border-bottom: 0;
		padding: 0.6em 0 0;
		margin-left: $v;
		margin-right: $v;
		margin-bottom: 0;
		font-weight: 400;

		img {
			width: 142px;
		}

		&.nopad {
			padding-top: 0;
		}
		.Clipboard {
			padding: 0;
			line-height: inherit;
			font-size: $v * 2.2;
		}

		&:hover {
			opacity: 1;
		}

		&:hover,
		&:active,
		&:focus {
			border-color: $coral-hover;
			color: $coral-hover;
		}
		@media (#{$medium-bp}) {
			// top: -2px;
			img {
				width: 146px;
			}
		}
	} @else if ($theme == "link") {
		background-color: transparent;
		border: none;
		color: $hot-coral;
		border-radius: 0;
		padding: 0.5rem 0 0.4rem;

		font-weight: 400;
		white-space: normal;
		background-size: 1px 1em;
		box-shadow: inset 0 -0.1rem $hot-coral;
		display: inline !important;

		&:hover,
		&:active,
		&:focus {
			border-color: $coral-hover;
			color: $coral-hover;
			box-shadow: inset 0 -0.1rem $hot-coral;
		}
	} @else if ($theme == "link-white") {
		background-color: transparent;
		border: none;
		color: $white;
		border-radius: 0;
		padding: 0.5rem 0 0.4rem;

		font-weight: 400;
		white-space: normal;
		background-size: 1px 1em;
		box-shadow: inset 0 -0.1rem $white;
		display: inline !important;

		&:hover,
		&:active,
		&:focus {
			border-color: transparent;
			color: $dusk-4;
			box-shadow: inset 0 -0.1rem $dusk-4;
		}
	} @else if ($theme == "link-charcoal") {
		background-color: transparent;
		border: none;
		color: $charcoal-0;
		border-radius: 0;
		padding: 0.5rem 0 0.4rem;

		font-weight: 400;
		white-space: normal;
		background-size: 1px 1em;
		box-shadow: none;
		display: inline !important;

		&:hover,
		&:active,
		&:focus {
			border-color: transparent;
			color: $coral-hover;
			box-shadow: none;
		}
	} @else if ($theme == "white") {
		border-color: $white;
		background-color: transparent;
		color: $white;

		&:hover,
		&:active,
		&:focus {
			border-color: $white;
			background-color: $white;
			color: $charcoal;
		}
	} @else if ($theme == "white-alt") {
		border-color: $white;
		background-color: $white;
		color: $charcoal;

		&:hover,
		&:active {
			border-color: $dusk-2;
			background-color: $dusk-2;
			color: $charcoal;
		}
	} @else if ($theme == "charcoal") {
		border-color: $charcoal;
		background-color: $charcoal;
		color: $white;

		&:hover,
		&:active,
		&:focus {
			border-color: $black;
			background-color: $black;
			color: $white;
		}
	} @else if ($theme == "secondary" or $theme == "charcoal-alt") {
		border-color: $charcoal;
		background-color: transparent;
		color: $charcoal;

		&:hover,
		&:active,
		&:focus {
			border-color: $charcoal;
			background-color: $charcoal;
			color: $white;
		}
	} @else if ($theme == "red") {
		border-color: $hot-coral;
		background-color: transparent;
		color: $hot-coral;

		&:hover,
		&:active,
		&:focus {
			border-color: $hot-coral;
			background-color: $hot-coral;
			color: $white;
		}
	} @else if ($theme == "light-to-dark") {
		border-color: $charcoal;
		background-color: transparent;
		color: $charcoal;

		&:hover,
		&:active,
		&:focus {
			border-color: $charcoal;
			background-color: $charcoal;
			color: $white;
		}
		@media (#{$large-bp}) {
			border-color: $white;
			background-color: transparent;
			color: $white;

			&:hover,
			&:active,
			&:focus {
				border-color: $white;
				background-color: $white;
				color: $charcoal;
			}
		}
	} @else if ($theme == "midnight") {
		background-color: $midnight;
		border-color: $midnight;
		color: $white;
		&:hover {
			color: $white;
			background-color: $charcoal-0;
			border-color: $charcoal-0;
		}
	} @else if ($theme == "link-coral") {
		margin: 0 0 $v 0;
		padding: 0;
		left: 5px;
		top: -2px;
		border: 0;
		color: $hot-coral;
		font-weight: 600;
		display: inline-block !important;
		&::after {
			display: inline-block;
			content: '\F105';
			font-family: fa;
			transition: transform 0.3s ease;
			position: relative;
			left: 4px;
		}
		&:hover {
			color: $hot-coral;
		}
	} @else if ($theme == "light-grey") {
		background-color: $charcoal-4;
		color: $charcoal-0;

		&:hover,
		&:focus-visible {
			background-color: $charcoal-0;
			color: $charcoal-4;
		}

		&:disabled {
			background-color: $charcoal-5;
			color: $charcoal-4;
		}
	} @else {
		&:hover {
			color: darken($button-color, 5);
		}
	}
}

@mixin button__size($size: "medium") {
	@if ($size == "medium") {
		border-radius: 2px;
		padding: 0.4em 1em;

		line-height: $v * 2.8;
		font-size: 1.6 * $v;
	} @else if ($size == "large") {
		border-radius: 2px;
		padding: 1em 1.2em;

		line-height: $v * 2.2;
		font-size: 2.0 * $v;
	}
}

@mixin button($theme: null, $size: "medium") {
	-webkit-appearance: none;
	background-image: none;

	border-color: $button-color;
	border-width: 1px;
	border-style: solid;

	color: $button-color;

	cursor: pointer;
	&:disabled {
		cursor: not-allowed;
	}

	text-decoration: none;
	text-shadow: none;
	white-space: nowrap;
	vertical-align: middle;
	text-align: center;
	display: inline-block;
	font-weight: 700;

	transition: all 0.2s ease-out;

	@include button__theme($theme);
	@include button__size($size);
}

@mixin button__icon($icon) {
	&::after {
		display: inline-block;

		content: $icon;
		font-family: "fa";

		transition: margin-left 0.3s ease;

		margin-left: 0.5 * $h;
	}

	&:hover,
	&:focus {
		&::after {
			margin-left: $h;
		}
	}
}

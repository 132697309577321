.benefit-section {
  padding-top: $v * 6;
  margin-top: $v * 9;
  margin-bottom: $v * 9;
  background-color: $charcoal-5;
  // border-top: 1px solid $charcoal-4;
  // border-bottom: 1px solid $charcoal-4;

  .benefit-block {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    .benefit-slide {
      overflow: hidden;
      text-align: left;
      margin: 0 30px -80px 0;
      flex: 1;
      background-color: white;
      border: 1px solid $charcoal-4;
      border-radius: 2px;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        //cursor: pointer;
        border: 1px solid $charcoal-2;
      }

      img {
        width: 100%;
      }

      .slide-inner {
        padding: $v * 2;

        a {
          white-space: nowrap;

          .fa {
            font-size: 0.8em;
          }
        }
      }

      @media (max-width: 991px) and (min-width: 668px) {
        margin: 0 auto;
        display: flex;
        flex: none;
        align-items: center;
        margin: 10px auto;
        width: 90%;
        max-width: 700px;

        &:nth-child(odd) {
          flex-direction: row-reverse;
        }

        &:last-child {
          margin-right: auto;
        }

        img {
          width: 50%;
        }

        .slide-inner {
          display: inline-block;
          flex: 1;
        }
      }

      @media (max-width: 667px) {
        width: 100%;
        margin: 20px 0;
        border: none;
        background-color: transparent;
        text-align: center;
        flex: none;

        &:hover {
          border: none;
          cursor: default;
        }

        img {
          width: 200px;
          margin: 0 auto;
        }
      }
    }
  }
}
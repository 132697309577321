.AskQuestion {
    padding-bottom: $v * 9;
    overflow: hidden;

    @media (#{$medium-bp}) {
        padding-bottom: 0;
    }
}
.AskQuestion-card {
    background-color: $white;

    @media (#{$medium-bp}) {
        box-shadow: $card-box-shadow;
        border-radius: $card-border-radius;
        overflow: hidden;
        padding: $v * 4;
    }
}

.AskQuestion-title {
    margin-bottom: $v * 2;
}

.AskQuestion-container {
    @media (#{$small-bp}) {
        padding: 0;
    }
}

.AskQuestion-background {
    background-size: cover;
    @media (#{$small-bp}) {
        background: none !important;
    }
}

.AskQuestion-success {
    min-height: 500px;
}

.Overlay {
    .AskQuestion-card {
        @media (#{$small-bp}) {
            border-radius: 7px;
            padding-left: 2rem;
            padding-right: 2rem;
            padding-top: 0rem;
            padding-bottom: 3rem;
        }
    }
}
.AskQuestion-cardImage {
    margin-left: -2rem;
    margin-right: -2rem;
    @media (#{$medium-bp}) {
        margin-left: -4rem;
        margin-right: -4rem;
        margin-top: -4rem;
    }
}

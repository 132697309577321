@import 'animations';
@import 'backgrounds';
@import 'borders';
@import 'colours';
@import 'flex';
@import 'layout';
@import 'margins';
@import 'spacing';
@import 'text';
@import 'visibility';

.u-cf {
  clear: both;
}
.img-responsive {
  width: 100%;
  display: block;
  height: auto;
}

.image-thumb {
  line-height: $v;
}

.img-offset {
  width: 100%;
  @media (#{$large-bp}) {
    width: auto;
  }

}


$video-course-hero-landing__bp: $medium-bp;

.video-course-hero-landing {}

.video-course-hero-landing__bg {
	padding: (5 * $v) 0;
	@media ($video-course-hero-landing__bp) {
		padding: (10 * $v) 0;
	}

	background-color: $charcoal-0;
	background-size: cover;
	background-position: center;
	background-image: url("/images/mental-health-masterclass/hero-mobile.jpg");
	@media ($video-course-hero-landing__bp) {
		background-image: url("/images/mental-health-masterclass/hero-desktop.jpg");
	}
	color: $white;
}

.video-course-hero-landing__layout {
	text-align: center;
	max-width: 600px;
	margin: 0 auto;
}

.video-course-hero-landing__tagline {
	@extend %h5;
}

.video-course-hero-landing__title {
	@extend %h1;
}

.video-course-hero-landing__action {}
@use 'sass:math';

$weight-regular: 300;
$weight-semibold: 400;
$weight-medium: 500;
$weight-bold: 600;

@font-face {
    font-family: 'fa';
    src: url('/fonts/fa.woff');
    src: url('/fonts/fa.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DomaineDisplay';
    src: url('/fonts/DomaineDisplayWeb-Regular.woff');
    src: url('/fonts/DomaineDisplayWeb-Regular.woff') format('woff');
    font-weight: $weight-regular;
    font-display: swap;
}

@font-face {
    font-family: 'DomaineDisplay';
    src: url('/fonts/DomaineDisplayWeb-Semibold.woff');
    src: url('/fonts/DomaineDisplayWeb-Semibold.woff') format('woff');
    font-weight: $weight-semibold;
    font-display: swap;
}

@font-face {
    font-family: 'DomaineDisplay';
    src: url('/fonts/DomaineDisplayWeb-Bold.woff');
    src: url('/fonts/DomaineDisplayWeb-Bold.woff') format('woff');
    font-weight: $weight-bold;
    font-display: swap;
}

@font-face {
    font-family: 'DomaineText';
    src: url('/fonts/DomaineTextWeb-Regular.woff');
    src: url('/fonts/DomaineTextWeb-Regular.woff') format('woff');
    font-weight: $weight-regular;
    font-display: swap;
}

@font-face {
    font-family: 'DomaineDisplay';
    src: url('/fonts/DomaineDisplayWeb-Medium.woff');
    src: url('/fonts/DomaineDisplayWeb-Medium.woff') format('woff');
    font-weight: $weight-medium;
    font-display: swap;
}

$domaindisplay: 'DomaineDisplay', 'Lato', 'Sans-serif';
$domaintext: 'DomaineText', 'Lato', 'Sans-serif';
$text-font: 'Lato', 'Sans-serif';

// // 1rem = 10px

// // Oh no here we go adding shit

// // @import '../../css/timely.css';
// // @import '../../css/timely.v2.css';

html {
    -webkit-font-smoothing: antialiased;
    font-size: 100%;
    font-size: 10px;
}

// @import "old/index";
// .edit-post-layout__content,
@import 'settings/index';
// Tools – globally used mixins and functions. It’s important not to output any CSS in the first 2 layers.
@import 'tools/index';
// // Generic – reset and/or normalize styles, box-sizing definition, etc. This is the first layer which generates actual CSS.
@import 'generic/index';
// // Elements – styling for bare HTML elements (like H1, A, etc.). These come with default styling from the browser so we can redefine them here.
@import 'elements/index';
// // Objects – class-based selectors which define undecorated design patterns, for example media object known from OOCSS
@import 'objects/index';
// // Layouts - seets up some overrides based on page layout modifiers
@import 'layouts/index';
// Components – specific UI components. This is where majority of our work takes place and our UI components are often composed of Objects and Components
@import 'components/index';
// Utilities – utilities and helper classes with ability to override anything which goes before in the triangle, eg. hide helper class
@import 'utilities/index';
// Trumps - just like the president, steamrolls over all the things
@import 'trumps/index';

.page-wrapper {
    &::after {
        transition: all 0.3 ease-out;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        content: ' ';
        background-color: $dusk-4;
        z-index: 999998;
        animation: pageTransition 1s 1;
        animation-timing-function: ease-out; /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
        animation-fill-mode: forwards;
    }
}

@keyframes pageTransition {
    0% {
        opacity: 1;
    }
    70% {
        opacity: 1;
        background-color: $white;
    }
    90% {
        opacity: 1;
    }
    99% {
        background-color: rgba(255, 255, 255, 0);
        opacity: 0;
        width: 100%;
        height: 100vh;
    }
    100% {
        opacity: 0;
        bottom: unset;
        right: unset;
        // width: 0;
        height: 0;
    }
}

.u-textCenter {
    text-align: center !important;
}
.u-textLeft {
    text-align: left !important;
    .mdc-form-field {
        justify-content: flex-start;
    }
}

.u-bold {
    font-weight: 700;
}

.u-grey {
    color: $charcoal-2;
}

.u-linkStyle {
    &,
    &:visited {
        color: $hot-coral;
        transition: color 0.2s ease-in;
    }

    .svg {
        vertical-align: baseline;
    }

    &:hover,
    &:focus {
        color: $coral-hover;

        .svg g {
            fill: $coral-hover;
        }
    }
}

.u-headerTitle {
    @extend .container;
    @media (#{$large-bp}) {
        max-width: 780px !important;
        margin-left: auto;
        margin-right: auto;
    }
}

.sign-up-paged {
	display: grid;
	grid-gap: 10rem;
	// align-items: center;
	margin-top: 10rem;
	margin-bottom: 5rem;
  
	// Media query for mobile devices
	@media ($small-bp) {
	  grid-template-columns: 1fr;
	  margin: 0;
	  grid-gap: 4rem;
	}
  
	// Media query for larger screens
	@media ($medium-bp) {
	  grid-template-columns: repeat(2, 1fr);
	}
  }


///////////
// Aside //
///////////

.sign-up-paged__aside{
	margin-top: 9rem;
}

.sign-up-paged__spacer{
	margin-top: 50px
}

.sign-up-paged__aside__title {
	@extend %h1;
	font-size: 4.7rem;
	text-align: left;
}

.sign-up-paged__aside__selling-points-list {
	list-style-image: url('/images/svg/tick-red.svg');
	list-style-position: inside;
	margin: ($v * 2) 0 0;
	padding: 0;
}

.sign-up-paged__aside__selling-points-item {
	margin-bottom: $v;
	font-size: 18px;
}

.sign-up-paged__col-helper{
	display: flex;
	align-items: center ;
	padding-top: 1rem;
}

.sign-up-paged__aside__selling-points-item::marker {
	margin-bottom: $v;
	margin-right: 20px;
}

.sign-up-paged__navbar{
	background-color: $white;
	position: fixed;
	top: 0;
	left: 0;
	min-height: 50px;
	width: 100%;
	z-index: 10;
	border-bottom: 1px solid #cdd2d5;
}

.sign-up-paged__logo{
	max-width: 160px;
	height: auto;
}


//////////
// Form //
//////////
.sign-up-paged__form__card {
	padding: (4.5 * $v) (3 * $h);
	border-radius: 7px;
	box-shadow: 0 0 1.5rem rgba($black, 0.15);
}

.sign-up-paged__form__step-indicator {
	list-style: none;
	display: flex;
	gap: 1.1rem;
	margin: 0 auto (4 * $v);
	padding: 0;
	max-width: 180px;
}

.sign-up-paged__button-charcoal-5{
	background-color: $charcoal-5;
	border-color: $charcoal-5;
	color: $charcoal-2;
}

.sign-up-paged__button-charcoal-5:hover{
	background-color: $charcoal-4;
	border-color: $charcoal-4;
	color: $charcoal-2;
}

.sign-up-paged__location-field{
	text-align: center;
}

.sign-up-paged__location-field-inner{
	color: $charcoal-0;
	border: none;
	background-color: transparent;
}

.sign-up-paged__promo-code-field-inner{
	color: $coral-2;
	border: none;
	background-color: transparent;
}

.sign-up-paged__location-field-arrow {
	border: solid $charcoal-0;
	margin-bottom: 3px;
	margin-left: 5px;
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 3px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
}

.sign-up-paged__form__step-indicator-item {
	flex-grow: 1;
	height: 0.6rem;
	border-radius: 0.7rem;

	// Double size linear gradient lets us animate background colour as a translation
	background-image: linear-gradient(90deg, $coral-1 0%, $coral-1 50%, $charcoal-4 50%, $charcoal-4 100%);
	background-size: 200%;
	background-position: 0 0;
	transition: 0.3s background-position ease-in-out;
	[aria-current="true"] ~ & {
		background-position: 100% 0;
	}
}

.sign-up-paged__alert{
	width: 98%;
    margin-left: 1%;
}

.sign-up-paged__form__step {
	border: 0;
	margin: 0;
	padding: 0;
	min-height: 420px;
}

.sign-up-paged__form__step__head {
	margin-bottom: 3 * $v;
	text-align: center;
}

.sign-up-paged__form__step__title {
	@extend %h3;
}

.sign-up-paged__form__step__subtitle {
	margin-top: $v;
	@extend %p;
}

// Password requirements
.password-requirements{
	color: $charcoal-0;
	font-size: 1.6rem;
	margin-top: 1.5rem;
}

.password-requirements__list{
margin-top: 5px;
}

.password-requirement__line{
	font-size: 1.2rem;
	line-height: 1.6rem;
	color: $charcoal-0;
}

.password-requirement__line-status{
	height: 22px;
	margin-right: 6px;
}

// Bootstrap forms additional styling
.form-control.sign-up-paged__form-field{
	height: 54px;
    padding: 14px 20px;
	border-radius: 3px;
    font-size: 18px;
    line-height: 1.33;
	border: 1px solid $charcoal-4;
}

.form-floating>label{
	top: 3px;
}

.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
	top: 0;
    transform: scale(.75) translateY(-0.7rem) translateX(0.15rem);
}

.form-check-label{
	display: initial;
}

.form-control.sign-up-paged__b-left{
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.form-control.sign-up-paged__b-right{
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.sign-up-paged__checkbox.form-check{
	padding-left: 0;
}

#check_box_terms{
	margin-top: 4px;
}

.sign-up-paged__tooltip.tooltip .tooltip-inner{
	padding: 0.5rem 1rem 0.5rem 1rem;
	font-family: $text-font;
	border-radius: 3px;
	background-color: $dusk-3;
	color: $charcoal-0;
	text-align: left;
	border: 1px solid $dusk-0;
	box-shadow: 0 0 1.5rem rgba($black, 0.15);
}

.tooltip .tooltip-arrow{
	color: $dusk-0;
}

.sign-up-paged__tooltip.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .bs-tooltip-start .tooltip-arrow::before{
	color: $dusk-0;
	border-left-color: $dusk-0;
	border-bottom-color: $dusk-0;
}

.sign-up-paged__tooltip.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow, .bs-tooltip-start .tooltip-arrow{
	right: 1px;
	color: $dusk-0;
	border-left-color: $dusk-0;
	border-bottom-color: $dusk-0;
}

.sign-up-paged__tooltip.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .bs-tooltip-top .tooltip-arrow::before{
	color: $dusk-0;
	border-left-color: $dusk-0;
	border-bottom-color: $dusk-0;
}

.sign-up-paged__checkbox, .form-control.is-valid, .was-validated .form-control:valid{
	border-color: $charcoal-4;
	background-image: none;
}

.sign-up-paged__checkbox, .form-control.is-valid:focus, .was-validated .form-control:valid:focus{
	border-color: $charcoal-4;
	box-shadow: none;
}

.sign-up-paged__checkbox, .was-validated .form-select:valid:not([multiple]):not([size]){
	background-image: none;
}

.sign-up-paged__checkbox, .was-validated .form-check-input:valid~.form-check-label{
	color: $charcoal-0;
}

.sign-up-paged__checkbox, .was-validated .form-check-input:valid:checked{
	// background-color: #0d6efd;
	box-shadow: none;	
}

.sign-up-paged__checkbox, .was-validated .form-check-input:invalid~.form-check-label{
	color: $charcoal-0;
}


// ------ Loading state
.sign-up-paged__loader {
	// Span both columns
	grid-column: 1 / 3;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.sign-up-paged__loader-inner{
	width: 80%;
	height: auto;
	max-width: 1200px;
	padding-top: 10rem;
	color: $charcoal-0;
}

.sign-up-paged__copy{
	color: $charcoal-0;
}

.sign-up-paged__copy--bold{
	font-weight: 700;
}

.sign-up-paged__caution_icon{
	width: 17px;
	height: auto;
	margin-top: -5px;
	margin-right: 5px;
}

.sign-up-paged__loading_icon{
	width: 64px;
	height: auto;
	animation: spin 1.8s linear infinite;
}

@keyframes spin {
	0% {
	  transform: rotate(0deg);
	}
  
	100% {
	  transform: rotate(-360deg);
	}
  }

// Media queires
@media ($medium-bp-only) {
	.sign-up-paged{
		grid-gap: 6rem;
	}

	.sign-up-paged__form__card{
		padding: 2.5rem 2rem
	}
}

  @media ($small-bp) {
	.sign-up-paged__aside__selling-points-list{
		display: none;
	}

	.sign-up-paged__form__card{
		padding: 0;
		border-radius: 0;
		box-shadow: none;
	}

	.sign-up-paged__form__step__head{
		text-align: left;
	}

	.sign-up-paged__aside {
		padding: 3.5rem 15px 3.5rem 15px;
		margin-top: 0;
		margin-left: -15px;
		margin-right: -15px;
		background-color: $dusk-3;
	}

	.sign-up-paged__aside__title {
		text-align: center;
		font-size: 3rem;
		line-height: 4rem;
	}

	.sign-up-paged__tooltip .tooltip .tooltip-inner{
		padding: 0.5rem 0.5rem 0.5rem 0.5rem;
	}

	.sign-up-paged__logo{
		max-width: 120px;
		height: auto;
	}
  }
  
/**
* DEPRECATED
**/

.u-vSpacingLG {
    padding-top: $v * 6;
    padding-bottom: $v * 6;

    @media (#{$medium-bp}) {
        padding-top: $v * 9;
        padding-bottom: $v * 9;
    }
    @media (#{$large-bp}) {
        padding-top: $v * 15;
        padding-bottom: $v * 15;
    }
}

.u-vSpacingMD {
    @extend .u-vSpacingMD-top;
    @extend .u-vSpacingMD-bottom;
}

.u-vSpacingXL {
    padding-top: $v * 21;
    padding-bottom: $v * 21;
}

/****************
* Top Padding
****************/

.u-vSpacingXL-top {
    padding-top: $v * 9;

    @media (#{$medium-bp}) {
        padding-top: $v * 15;
    }

    @media (#{$large-bp}) {
        padding-top: $v * 21;
    }
}

.u-vSpacingLG-top {
    padding-top: $v * 6;

    @media (#{$medium-bp}) {
        padding-top: $v * 9;
    }
    @media (#{$large-bp}) {
        padding-top: $v * 15;
    }
}

.u-vSpacingMD-top {
    padding-top: $v * 6;

    @media (#{$medium-bp}) {
        padding-top: $v * 9;
    }
}

.u-vSpacingSM-top {
    padding-top: $v !important;

    @media (#{$medium-bp}) {
        padding-top: $v * 5 !important;
    }
}

.u-vSpacing0-top {
    padding-top: 0;
}

/****************
* Bottom Padding
****************/

.u-vSpacingXL-bottom {
    padding-bottom: $v * 9;

    @media (#{$medium-bp}) {
        padding-bottom: $v * 15;
    }
    @media (#{$large-bp}) {
        padding-bottom: $v * 21;
    }
}

.u-vSpacingLG-bottom {
    padding-bottom: $v * 6;

    @media (#{$medium-bp}) {
        padding-bottom: $v * 6;
    }
    @media (#{$large-bp}) {
        padding-bottom: $v * 9;
    }
}
.u-vSpacingMD-bottom {
    padding-bottom: $v * 3;

    @media (#{$medium-bp}) {
        padding-bottom: $v * 6;
    }
}

.u-vSpacingSM-bottom {
    padding-bottom: $v;

    @media (#{$medium-bp}) {
        padding-bottom: $v * 3;
    }
}

.u-vSpacing0-bottom {
    padding-bottom: 0;
}

.u-vSpacing {
    padding-top: $v * 3 !important;
    padding-bottom: $v * 3 !important;
}

.u-equal {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
}

.u-vSpacing0-top {
    margin-top: 0 !important;
}

.u-pR-sm {
    padding-right: $v;
}

.u-pR-md {
    padding-right: $v * 2;
}

.u-pT-sm {
    padding-top: $v * 2;
}
.u-pT-md {
    padding-top: $v * 6 !important;
}

.u-marginB0 {
    margin-bottom: 0 !important;
}

.page-switch {
    .FeaturesList-item {
        padding-bottom: 0;
    }
}

.p0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.u-mb25 {
    margin-bottom: $v * 2.5;
}
.u-mb3 {
    margin-bottom: $v * 3;
}
.u-mb4 {
    margin-bottom: $v * 4;
}

.u-mbp5 {
    margin-bottom: $v * 0.5;
}

.u-mb1 {
    margin-bottom: $v;
}
.u-mb2 {
    margin-bottom: $v;
}

.u-mt0 {
    margin-top: 0;
}

.u-mb0 {
    margin-bottom: 0;
}

.u-longSpacing {
    display: block;
    position: relative;
    padding-bottom: 34.3%;
}

.u-fSize0 {
    font-size: 0;
}

.u-pTB2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

$mob-gap: 50px;
/* Icon 3 */
.TimelyNavBar--small {
    .TimelyNavBar {
        height: $nav-height--mobile;
        height: var(--nav-height);

        width: 100%;
        display: flex;
    }

    .TimelyNavBar-logo > svg {
        padding-top: 5px;
        width: 100px;
    }

    .TimelyNavBar-overlap {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        min-height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        overflow: scroll;
    }

    .TimelyNavBar-overlapContainer {
        position: absolute;
        top: 0;
        left: 0;
        background: #fff;
        height: 100vh;
        right: $mob-gap;
        list-style: none;
        transition: all 0.2s ease-in-out;
        overflow-x: hidden;
        transform: translate(-100%, 0);
        padding-bottom: $v * 8;
    }

    .TimelyNavBar-overlapContainerPrimary {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: #fff;
        min-height: 100vh;
        padding: $v * 2 $v * 3;
    }

    .TimelyNavBar-overlapContainerSecondary {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: #fff;
        min-height: 100vh;
        transition: all 0.2s ease-in-out;
        transform: translate(100%, 0);
        padding: 0;
    }

    .TimelyNavBar-overlapContainerSecondary--open {
        transform: translate(0, 0);
    }

    .TimelyNavBar-overlapContainer--open {
        transform: translate(0, 0);
    }
    .TimelyNavBar-PrimaryList {
        list-style: none;
        padding: $v 0 $v * 8;
        margin: 0;
    }

    .TimelyNavBar-searchMenu {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    }

    .TimelyNavBar-PrimaryList-item {
        border-bottom: 1px solid $border-color-nav;
    }

    .TimelyNavBar-SecondaryListItem {
        margin: $v $v * 3 $v * 2;
        border-bottom: 1px solid $border-color-nav;
        > h3 {
            font-weight: normal;
            font-family: $domaindisplay;
            &::after {
                content: '\F105';
                font-family: fa;
                position: relative;
                left: 8px;
                font-weight: bold;
            }
        }

        .Button--link-charcoal {
            color: $hot-coral;
            font-weight: 600;
            display: inline-block !important;
            margin-bottom: $v;
            &::after {
                display: inline-block;
                content: '\F105';
                font-family: fa;
                transition: transform 0.3s ease;
                position: relative;
                left: 4px;
            }
        }

        &:last-child {
            border-bottom: none;
        }
    }

    .TimelyNavBar-SecondaryList--groups-with-labels .TimelyNavBar-SecondaryListItem {
        &:last-child {
            background-color: $cream-0;
            margin: 0;
            padding: ($v * 2) ($v * 3) ($v * 10);
        }
        border-bottom: none;
    }

    .TimelyNavBar-PrimaryList-itemLink {
        padding-top: $v * 2;
        padding-bottom: $v * 2;
        display: block;
        width: 100%;
        color: $charcoal-0;
        font-weight: $weight-regular;
        font-family: $domaindisplay;
        letter-spacing: 0;
        font-size: 2.2rem;
        text-decoration: none;
    }

    .TimelyNavBar-PrimaryList-itemLink--list {
        &::after {
            content: '\F105';
            font-family: fa;
            font-size: 18px;
            float: right;
            color: $charcoal-3;
        }
    }

    .TimelyNavBar-searchButton {
        border: none;
        border-radius: 50%;
        background-color: $charcoal-5;
        width: 34px;
        height: 34px;
        padding: 0;
    }

    .TimelyNavBar-backButton {
        background-color: transparent;
        border: none;
        margin-bottom: $v * 2;
        padding: $v * 2 0 0 $v * 3;
    }
}

.TimelyNavBar-overlapContainer-footer {
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100% - #{$mob-gap});
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $v * 2 $v * 1.5;
    box-shadow: 0px 5px 9px 1px rgba(0, 0, 0, 0.5);
    .Button {
        width: 100%;
        margin-left: $v;
        margin-right: $v;
    }
}

.TimelyNavBar-SearchContainer {
    margin-top: $v * 3;
    padding-left: $v * 3;
    padding-right: $v * 3;

    h4 {
        letter-spacing: 2.7px;
        color: $charcoal-3;
    }

    #searchform {
        position: relative;
    }

    .TimelyNavBar-searchList {
        list-style: none;
        padding-left: 0;

        li {
            @media (#{$large-bp}) {
                display: inline-block;
            }
        }

        .h3 {
            font-size: $v * 2.2;
            line-height: $v * 4.4;
            color: $charcoal;
            font-weight: normal;
            font-family: $domaindisplay;

            &:hover {
                color: $coral-hover;
            }
            &::after {
                content: ', ';
                margin-right: math.div($v, 2);
            }
        }

        li {
            &:last-child {
                a::after {
                    content: '';
                    margin-right: 0;
                }
            }
        }
    }
}

.TimelyNavBar-SecondaryListItem.TimelyNavBar-SecondaryListItem--book {
    background-color: $cream-0;
    margin-top: calc(-1rem - 1px);
    padding-top: $v * 2;
    padding-bottom: $v * 8;

    padding-left: $v * 2;
    padding-right: $v * 2;

    .Button--link-charcoal {
        margin-bottom: 0;
    }
}

// To use these geovisible classes in JavaScript, see `DisplayRegion.ts`

.nogdpr-only,
.gdpr-only,
.gb-only,
.au-only,
.nz-only,
.sg-only {
    display: none !important;
}

.au-hidden,
.nz-hidden,
.gb-hidden {
    display: block !important;
}

.GB .gb-hidden {
    display: none !important;
}

.AU .au-hidden {
    display: none !important;
}
.NZ .nz-hidden {
    display: none !important;
}

.NZ .nz-only {
    display: block !important;
}

.AU .au-only {
    display: block !important;
}

.GB .gb-only {
    display: block !important;
}

.SG .sg-only {
    display: block !important;
}

.gdpr .gdpr-only {
    display: block !important;
}

.nogdpr .nogdpr-only {
    display: block !important;
}

.tp-only {
    display: none !important;
}

.GB,
.NZ,
.AU,
.AT,
.BE,
.BG,
.CY,
.CZ,
.DK,
.EE,
.FI,
.FR,
.DE,
.GR,
.IE,
.IT,
.LV,
.LT,
.LU,
.MT,
.NL,
.PL,
.PT,
.RO,
.SK,
.SI,
.EA,
.IC,
.SE {
    // tp - TimelyPay
    .tp-only {
        display: block !important;
    }

    // row - Rest Of World. Excludes NZ, AU, GB, and EU
    .row-only,
    .tp-hidden {
        display: none !important;
    }
}

// tpeu - TimelyPay European Union
.tpeu-only {
    display: none !important;
}

.tpeu-hidden {
    display: block !important;
}

.AT,
.BE,
.BG,
.CY,
.CZ,
.DK,
.EE,
.FI,
.FR,
.DE,
.GR,
.IE,
.IT,
.LV,
.LT,
.LU,
.MT,
.NL,
.PL,
.PT,
.RO,
.SK,
.SI,
.EA,
.IC,
.SE {
    .tpeu-hidden {
        display: none !important;
    }

    .tpeu-only {
        display: block !important;
    }
}


.tp-terminals-only {
    display: none !important;
}

.tp-terminals-hidden {
    display: block !important;
}

.NZ,
.AU,
.GB {
    .tp-terminals-hidden {
        display: none !important;
    }

    .tp-terminals-only {
        display: block !important;
    }
}

.custom-search {
	text-align: center;
	#searchform {
		display: inline;
		position: relative;
		>div {
			display: inline;
			position: relative;
		}
	}

	#s {
		color: $charcoal-0;
		padding-left: 0;
		border-radius: 0;
	}

  .SearchOverlay-container {
    border-bottom: 2px solid $charcoal-2;
  }

	label.screen-reader-text {
		display: none;
	}
	input[type=text] {
    width: 100%;
    box-shadow: none;
    @extend %h1;
    background: none;
    border: 0;
    outline: none;
    color: $charcoal-2;

    &::placeholder {
      color: $charcoal-2;
    }
	}
	.searchsubmit {
		display: inline;
		padding: .05em .4em;
		background-position: 8px 8px;
		background-repeat: no-repeat;
		background-size: 28px;
		border: 0;
		border-radius: 10em;
		&:focus {
			outline: none;
		}
	}
}
.search-buckets {
	margin-top: $v * 5;
	margin-bottom: $v * 2;
}

.in-menu-search {
	.in-nav-search-input {
		@media (#{$small-bp}) {
			padding-left: $v * 2 !important;
			font-size: 2.4rem !important;
		}
	}
}

.search-listing .image-rect.bgtightblue {
	background-color: $dusk-4;
}
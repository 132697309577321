*,
::before,
::after {
  box-sizing: border-box;
}

.u-flex {
  display: flex;
}

.u-flexJustifyCenter {
  justify-content: center;
}

.u-flexAlignItemsCenter {
  align-items: center;
}
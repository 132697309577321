.PortraitCards {
    h3 {
        font-size: 28px;
        line-height: 34px;
        @media (#{$medium-bp}) {
            font-size: 35px;
            line-height: 4.6rem;
        }
        margin: 0;
    }
}

.PortraitCard {
    margin-top: 7.5rem;

    p:last-child {
        margin-bottom: 0;
    }
    @media (#{$medium-bp}) {
        min-height: 590px;
    }
}

.PortraitCard-image {
    width: 100%;
}
.PortraitCard-title {
    margin-top: $v * 2;
    margin-bottom: 0;
    text-transform: uppercase;
    letter-spacing: 3px;

    font-size: 16px;
    line-height: 25px;
    @media (#{$medium-bp}) {
        font-size: 1.6rem;
        line-height: 2.5rem;
    }
}

.PortraitCard-subTitle {
    font-weight: 700;
    text-transform: uppercase;
    color: $mid-grey;
    letter-spacing: 3px;
    margin-bottom: $v * 2;
    font-size: 16px;
    line-height: 25px;

    @media (#{$medium-bp}) {
        font-size: 1.6rem;
        line-height: 2.5rem;
    }
}

.ProfileCards {
    h3 {
        font-size: 28px;
        line-height: 34px;
        @media (#{$medium-bp}) {
            font-size: 35px;
            line-height: 4.6rem;
        }
        margin: 0;
    }
}

.ProfileCard {
    margin-top: 5.5rem;
    p:last-child {
        margin-bottom: 0;
    }
}

.ProfileCard-image {
    width: 100%;
}
.ProfileCard-title {
    margin-top: $v * 2;
    margin-bottom: 0;
    text-transform: uppercase;
    letter-spacing: 3px;

    font-size: 16px;
    line-height: 25px;
    @media (#{$medium-bp}) {
        font-size: 1.6rem;
        line-height: 2.5rem;
    }
}

.ProfileCard-subTitle {
    font-weight: 700;
    text-transform: uppercase;
    color: $mid-grey;
    letter-spacing: 3px;
    margin-bottom: $v * 2;
    font-size: 16px;
    line-height: 25px;

    @media (#{$medium-bp}) {
        font-size: 1.6rem;
        line-height: 2.5rem;
    }
}

.PortraitCards--tight {
    h3 {
        font-size: $v * 2.8;
    }
    .PortraitCard {
        margin-top: $v * 5;
        @media (#{$medium-bp}) {
            min-height: 540px;
        }
    }
}

.ProfileCard--brochure {
    text-align: center;
    .Actions {
        justify-content: center;
    }

    .ProfileCard-title {
        font-size: $v * 1.4;
    }

    .ProfileCard-subTitle {
        font-size: $v * 2.8;
        font-weight: 300;
        text-transform: none;
        color: $charcoal-0;
        font-family: $domaintext;
        line-height: 3.5rem;
        letter-spacing: -0.5px;
    }
}

.LeadingText {
  @extend .u-textCenter;
  @extend .u-vSpacingLG-top;

  h2 {
    margin-bottom: 0;
  }

  p {
    margin-top: $v * 2;
  }
}
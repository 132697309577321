.NotificationBar {
    overflow: hidden;
    display: flex;
    height: 57px;
    width: 100%;
    position: reltaive;
    justify-content: space-between;
    background-color: $charcoal-0;
    color: $white;
    z-index: 2;
    transition: height 0.1s ease-out;
}

.NotificationBar-title,
.NotificationBar-description {
    margin: 0 $v * 3 0 0;
}

.NotificationBar-title {
    img {
        width: 100px;
    }
}

.NotificationBar-description {
    display: none;
    @media (#{$medium-bp}) {
        display: block;
    }
}

.NotificationBar-button {
    @extend .fineprint;
}

.NotificationBar-container {
    display: flex;
    align-items: center;
}

.NotificationBar-close {
    background: none;
    border: 0;
    display: table-cell;
    padding-right: $v * 3;
    outline: none;
    svg {
        width: 15px;
        height: 15px;
        vertical-align: middle;
    }
}

#stickynav.navbar--hasNotification {
    height: auto;
    padding-bottom: $v;

    // .orient-backing {
    // display: none;
    // }
}

.navbar--hasNotification {
    .page-wrapper {
        margin-top: 114px;
    }
    // .headroom--not-top.headroom--pinned {
    //     transform: translateY(-50%);
    // }
}

.headroom {
    will-change: transform;
    transition: transform 200ms linear;
    position: fixed;
    top: 0;
    width: 100%;
}

.headroom--pinned {
    transform: translateY(0%);
}
.gdpr .headroom--unpinned {
    transform: translateY(calc(-100% + 92px));
    @media (#{$medium-bp}) {
        transform: translateY(calc(-100% + 88px));
    }
}
.no-gdpr .headroom--unpinned {
    transform: translateY(calc(-100% + 60px));
}

.Personalisation.NotificationBar {
    display: none;
}

.page-wrapper {
    transition: all 0.1s ease-out;
}

.NotificationBar.NotificationBar--expo {
    height: auto;
    padding: math.div($v, 2);

    .NotificationBar-description {
        display: block;
        padding: $v;
    }
}

.Notification {
    background-color: $charcoal-4;
    color: $black;
    width: 200px;
    border-radius: 4px;
    padding: 1rem 2rem;
    width: 200px;
    position: fixed;
    z-index: 13;
    left: calc(50% - 100px);
    top: 0;
    display: none;
}

.Notification.Notification--active {
    display: block;
    animation: fadeOut 2s linear forwards;
}

.Actions {
    padding: 0 $v;
    > div {
        margin-bottom: $v * 2;
        margin-right: $v;
        margin-left: $v;
        // min-width: calc(50% - #{$v});
    }

    @media (#{$medium-bp}) {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0;

        .Button:first-child {
            margin-left: 0;
        }

        > div {
            margin-right: $v * 3;
            margin-left: 0;
            margin-bottom: 0;
            min-width: auto;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.Actions--center {
    justify-content: center;
    > div {
        text-align: center;
    }
}

.HeroBlock {
  padding-top: $v * 4;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (#{$medium-bp}) {
    padding-top: $v * 9;
  }
}

.HeroBlock-title {
  margin-bottom: $v * 4;
}

.HeroBlock-parallax {
  width: 60%;
  display: block;
  margin: -40% auto 4rem;
  position: relative;

  @media (#{$medium-bp}) {
    width: 35%;
    margin: -40% auto 0;
  }
  @media (#{$large-bp}) {
    width: 35%;
    margin: -55% 0 0 10rem;
  }
}
.HeroBlock-image {
  position: relative;
  width: calc(100% + 8rem);
  // max-width: 100%;
  margin-top: $v * 4;

  margin-left: -4rem;
  margin-right: 0;

  @media (#{$large-bp}) {
    margin-right: -4rem;
    margin-top: $v * 0;
    width: 120%;
    max-width: 120%;
    margin-left: 8%;
  }
}

.HeroBlock-logo {
  margin-bottom: $v * 4;

  @media (#{$medium-bp}) {
    margin-bottom: $v * 6;
  }

  @media (#{$xxlarge-bp}) {
    width: 80%;
  }
}

.HeroBlock-description {
  margin-top: $v * 4;
  margin-bottom: $v * 4;
}

.HeroBlock-container {
  @extend %container;
  @media (#{$medium-bp}) {
    & {
      padding: 0;
    }
  }
}

.HeroBlock--onDark {
  color: $white;
}

.HeroBlock-actions {
  text-align: center;
  padding: 0;
  .Button {
    margin-bottom: $v;
    margin-top: $v;
  }
  @media (#{$medium-bp}) {
    text-align: left;
    .Button {
      margin-bottom: 0;
      margin-right: $v * 3;
      &:last-child {
        margin-right: 0
      }
    }
  }
}

.HeroBlock.HeroBlock--consult {
  padding-top: 0;

  max-height: 960px;

  @media (#{$large-bp}) {
    max-height: initial;
  }


  .HeroBlock-container {
    @media (#{$xlarge-bp}) {
      display: flex;
      align-items: center;
    }
  }

  // min-height: 100vh;
  .HeroBlock-logo {
    max-width: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  }
  .HeroBlock-title,
  .HeroBlock-description {
    text-align: center;
  }

  .row > div {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  .HeroBlock-content {
    margin-top: $v * 9;
    @media (#{$medium-bp}) {
      margin-top: $v * 10;
    }
    @media (#{$large-bp}) {
      margin-top: $v * 15;
    }

    @media (#{$xlarge-bp}) {
      margin-top: 0;
    }
  }

  .HeroBlock-static {
    padding-top: $v *9;
    padding-bottom: $v *9;
    width: 100%;
    max-width: 100%;

    @media (#{$medium-bp}) {
      padding-top: $v * 10;
      padding-bottom: $v * 10;
    }
    @media (#{$large-bp}) {
      padding-top: $v * 9;
      padding-bottom: $v * 9;
      width: initial;
    }
  }
  .HeroBlock-actions {
    align-items: start;
  }
}

.HeroBlock--buyersGuide {
  position: relative;
  overflow: visible;

  .HeroBlock-container {
    display: flex;
    flex-direction: column-reverse;
  }

  .HeroBlock-content {
    position: relative;
    z-index: 1;
  }

  .HeroBlock-static {
    position: relative;
    z-index: 1;
    // max-width: 50%;
    width: 80%;

    @media (#{$large-bp}) {
      max-width: 70%;
    }
  }

  .HeroBlock-container {
    overflow: visible;
    text-align: center;

    @media (#{$large-bp}) {
      position: relative;
      overflow: visible;
      align-items: center;
      flex-direction: row;
      text-align: left;
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      height: 110vw;
      width: 110vw;
      top: -70vw;
      right: -5vw;
      border-radius: 50%;
      background-color: $dusk-4;

      @media (#{$medium-bp}) {
        right: -5vw;
      }
      @media (#{$large-bp}) {
        height: 900px;
        width: 900px;
        top: -400px;
        right: -250px;
      }
    }

  }

  .HeroBlock-actions {
    @media (#{$medium-bp}) {
      justify-content: center;
    }

    @media (#{$large-bp}) {
      justify-content: initial;
    }
  }
}

.page-appointment-booking-software-buyers-guide {
  .buyers-guide-image,
  .HeroBlock-container {
    &::after {
      background-color: $charcoal-5;
    }
  }
}


.HeroBlock-image-play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  svg {
    overflow: visible;
  }
}
$video-course-intro-video__bp: $large-bp;

.video-course-intro-video {
	margin: (8 * $v) 0;
	@media (#{$video-course-intro-video__bp}) {
		margin: (12 * $v) 0;
	}
}

.video-course-intro-video__layout {
	display: grid;

	gap: 2 * $v;
	grid-template-columns: 1fr;

	@media ($video-course-intro-video__bp) {
		gap: 10 * $h;
		grid-template-columns: repeat(2, 1fr);
		align-items: center;
	}
}

.video-course-intro-video__thumbnail {
	display: block;

	position: relative;
	font-size: 6px;
	@media ($video-course-intro-video__bp) {
		font-size: 10px;
	}

	&::before,
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&::before {
		width: 12em;
		height: 8em;
		background: rgba($black, 0.6);
		border-radius: 1em;

		transition: 0.2s background-color ease-in-out;
	}

	&::after {
		width: 0;
		height: 0;
		border-left: 3em solid $white;
		border-top: 2em solid transparent;
		border-bottom: 2em solid transparent;
		margin-left: 0.4em;
	}

	&:hover,
	&:focus {
		&::before {
			background-color: $coral-2;
		}
	}
}

.video-course-intro-video__thumbnail-image {
	display: block;
	max-width: 100%;
	aspect-ratio: 16 / 9;
}

.video-course-intro-video__body {}

.video-course-intro-video__title {
	@extend %h2;

	margin-top: 0;
}

.video-course-intro-video__description {
	@extend %p;
}

.video-course-intro-video__actions {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: $h * 2;
}

.BookACall-wrap {
    display: flex;
    background-color: $white;
    min-height: 100%;
}

.BookACall-form {
    flex-basis: 100%;
    flex-shrink: 0;
    // transform: translateY(25%);
    @media (#{$medium-bp}) {
        flex-basis: 70%;
        flex-shrink: 0;
    }
}
.BookACall-quotes {
    flex-basis: 30%;
    flex-grow: 0;
    width: 30%;
    color: $white;
    position: relative;
    display: none;
    background-size: cover;

    @media (#{$medium-bp}) {
        display: block;
    }
    &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        height: 50%;
        width: 100%;
        background-color: transparent;
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0) 100%
        );
    }
}

.BookACall-quotes-wrap {
    position: absolute;
    width: 100%;
    bottom: $v;
    padding-left: $v * 2.5;
    padding-right: $v * 2.5;

    .slick-dots {
        margin-top: $v * 2.5;
        justify-content: flex-start;
    }
}

.BookACall-mt-3{
    margin-top: 4rem;

    @media (max-width: 576px) {
        margin-top: 1rem;
    }
}

.BookACall-form-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: $v * 3;
    max-width: 700px;

    h2 {
        margin-bottom: $v * 2.5;
    }

    @media (#{$medium-bp}) {
        width: 60%;
        padding-left: 0;
        padding-right: 0;
    }

    @media (min-height: 700px) {
        padding-top: 10vh;
    }
}

.page-wrapper .BookACall-form-container {
    @media (min-height: 700px) {
        // padding-top: $v * 3;
        min-height: 70vh;
        padding-top: 10vh;
    }
}

.BookACall-carousel {
    padding-left: $v * 2;
    padding-right: $v * 2;
    margin-bottom: 35px;

    .slick-dots li {
        display: inline-block;
    }
}

.BookACall-quote-business {
    margin-top: $v * 2.5;
    margin-bottom: 0;
}

.TwoColumn {
  @extend .u-vSpacingXL-top;
}

.TwoColumn-container {
  text-align: center;
  > div {
    &:first-child {
      padding-bottom: 4rem;
    }

    .StandardContent-actions {
      .Button--link {
        margin-left: 0;
      }
    }
  }

  @media (#{$medium-bp}) {
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    > div {
      &:first-child {
        padding-bottom: 0;
      }
    }
  }
  @media (#{$large-bp}) {
    align-items: center;
    padding: 0;
    > div {
      padding: 0;
      &:first-child {
        padding: 0;
      }
    }
  }

  @media (#{$small-bp}) {
    .svg-scroll-in {
    max-width: 100%;
    }
  }
}

.TwoColumn-container--reverse {
  display: flex;
  flex-direction: column-reverse;
  > div {
    &:last-child {
      padding-bottom: 4rem;
    }
  }
  @media (#{$medium-bp}) {
    > div {
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

// .TwoColumn--parallax {
//   @media (#{$small-bp}) {
//     .TwoColumn-container > div {
//       padding-left: 0;
//       padding-right: 0;
//     }
//   }
// }

.buyers-guide-image {
  text-align: center;

  @media (#{$medium-bp}) {
    margin-bottom: $v * 9;
  }

  img {
    position: relative;
    z-index: 1;
    max-width: 50%;
    margin-bottom: $v * 4;

    @media (#{$large-bp}) {
      margin-bottom: 0;
      max-width: 100%;
    }
  }

  &::after {
    content: '';
    display: block;
    top: 100px;
    left: 50%;
    height: 130vw;
    width: 130vw;
    transform: translateX(-50%);
    position: absolute;
    border-radius: 50%;
    background-color: $dusk-4;

    @media (#{$medium-bp}) {
      top: 200px;
      height: 110vw;
      width: 110vw;
    }
    @media (#{$large-bp}) {
      top: -140px;
      left: -550px;
      height: 850px;
      width: 850px;
      transform: none;
    }
  }
}

.page-appointment-booking-software-buyers-guide {
  .buyers-guide-image::after {
    width: 135vw;
    height: 135vw;
    @media (#{$medium-bp}) {
      top: 200px;
      height: 100vw;
      width: 100vw;
    }
    @media (#{$large-bp}) {
      top: -140px;
      left: -550px;
      height: 850px;
      width: 850px;
      transform: none;
    }
  }
}

.TwoColumn--buyersGuide {
  position: relative;

  .TwoColumn-container {
    position: relative;
    z-index: 1;
    @media (#{$medium-bp}) {
      flex-direction: column;
      align-items: center;
    }
    @media (#{$large-bp}) {
      flex-direction: row;
    }
  }

  .h2,
  h5 {
    text-align: center;
    @media (#{$large-bp}) {
      text-align: left;
    }
  }
}

.TwoColumn-container--alignTop {
  align-items: flex-start !important;
}
$search-transition: all 0.3s ease-in;

.SearchOverlay {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-55%, -50%);
    // border-bottom: 2px solid $charcoal-2;
    transition: all 0.3s ease-in-out;
    width: 60%;
    max-width: 768px;
    &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 0;
        height: 2px;
        background-color: $charcoal-2;
        transition: width 0.2s ease-in;
        transition-delay: 0.1s;
    }
    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 0;
        height: 2px;
        background-color: $coral-2;
        transition: width 0.2s ease-in-out;
    }
}

.SearchOverlay-field {
    @extend %h1;
    background: none;
    border: 0;
    outline: none;
    color: $white;
    font-weight: 300;

    &::placeholder {
        color: $charcoal-2;
    }
}

.SearchOverlay-button {
    opacity: 0;
    margin-bottom: math.div($v, 2);
    transition: $search-transition;
    transition-delay: 0.15s;
    outline: none;
    font-size: 1.6 * $v;
}

.SearchOverlay-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: math.div($v, 2);
}

.SearchOverlay--focus {
    // border-bottom-color: $coral-2;
    &::after {
        width: 100%;
    }

    .SearchOverlay-button {
        opacity: 1;
    }
}

.Overlay-blur {
    .SearchOverlay {
        transform: translate(-50%, -50%);
        &::before {
            width: 100%;
        }
    }
}

// .Search-Overlay {
//   transition: opacity .3s ease-in;
//   transition-delay: .2s;
//   opacity: 1;
//   .hidden {
//     opacity: 0;
//   }
// }

.tick-list-block__background {
	padding: (5 * $v) 0;

	color: $charcoal-base;
}

.tick-list-block__background--coral {
	background-color: $coral-3;
}

.tick-list-block__background--blush {
	background-color: $dusk-4;
}

.tick-list-block__background--midnight-pale {
	background-color: $midnight-pale;
}

.tick-list-block__background--spa-pale {
	background-color: $spa-pale;
}

.tick-list-block__title {
	@extend %h2;
	text-align: center;

	margin: 0 0 (2 * $v);
}

.tick-list-block__list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	column-gap: 2 * $h;
	row-gap: $v;

	list-style-image: url('/images/svg/tick-red.svg');
	list-style-position: inside;
	margin: 0;
	padding: 0;
}

.tick-list-block__item {
	@extend %p;
	text-align: center;
}

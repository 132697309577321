.ArticleCard {
    margin-bottom: $v * 6;

    a {
        .Flag-title,
        .ArticleCard-excerpt {
            color: $charcoal;
        }
        h4.ArticleCard-title,
        h3.ArticleCard-title {
            color: $black;
            text-decoration: none;
            margin-top: 0;
        }

        &:hover {
            h4.ArticleCard-title,
            h3.ArticleCard-title {
                text-decoration: underline;
                border-width: thin;
            }
        }
    }
}

.ArticleCard-excerpt {
    &,
    p {
        font-family: $domaintext;
        font-size: $v * 1.4;
        line-height: $v * 2.2;
    }
}

.ArticleCard-image {
    a {
        display: block;
        position: relative;
        padding-bottom: 56.2%;
        img {
            position: absolute;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}

.ArticleCard .ArticleCard-preText {
    margin-bottom: $v;
    color: $charcoal-2;
    font-size: $v * 1.4;
}

.ArticleCard-excerpt {
    margin-top: $v * 2;
    margin-bottom: $v * 3;
}

.ArticleCard--landscape {
    @media (#{$large-bp}) {
        margin-left: -$v * 1.5;
        margin-right: -$v * 1.5;

        > a {
            display: flex;
        }

        .ArticleCard-image,
        .ArticleCard-content {
            width: 50%;
            padding-left: $v * 1.5;
            padding-right: $v * 1.5;
        }
    }

    .Flag--author {
        display: none;
        @media (#{$large-bp}) {
            display: flex;
        }
    }
    .ArticleCard-excerpt {
        display: none;
        @media (#{$large-bp}) {
            display: block;
        }
    }
}

.col-md-6 {
    .ArticleCard--landscape {
        // .ArticleCard-title {
        // font-size: $v * 2;
        // line-height: $v * 3;
        // }

        .ArticleCard-image {
            display: none;
        }

        .ArticleCard-content {
            width: 100%;
        }
    }

    .ArticleCard-excerpt {
        display: none;
    }
}

.ArticleCard-preText {
    margin-bottom: 0;
}

.ArticleCard-image {
    margin-bottom: $v;
}

.ArticleCard-image-overlay {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    width: 45px;
    height: 45px;
    transform: translate(-50%, -50%);
}

.ArticleCard--plain {
    .ArticleCard-preText {
        margin-bottom: 0;
    }
}

.Article-widget {
    .ArticleCard {
        margin-bottom: $v * 3;
    }
}

.ArticleCard--text {
    .ArticleCard-title {
        font-size: $v * 2;
        line-height: $v * 2.4;
    }
}

.Article-card.Article-fancy {
    cursor: pointer;

    @media (#{$small-bp}) {
        margin-left: -$v * 1.5;
        margin-right: -$v * 1.5;
    }
}

.ArticleCard.ArticleCard--sans {
    @media (#{$small-bp}) {
        padding-left: $v * 1.5;
        padding-right: $v * 1.5;

        .ArticleCard-title {
            font-size: $v * 2.8;
            letter-spacing: 0.15px;
            line-height: $v * 3.6;
            color: #36434a;
        }
    }

    .ArticleCard-excerpt {
        display: block;
        p {
            font-size: $v * 1.6;
            font-family: $text-font;
        }
    }

    .Flag.Flag--author {
        display: flex;
    }

    a:hover {
        color: $coral-hover;
    }
}

$marketo-form-block__bp: $medium-bp;

.marketo-form-block__background {
	color: $charcoal-base;
}

.marketo-form-block__background--coral,
.marketo-form-block__background--blush,
.marketo-form-block__background--midnight-pale,
.marketo-form-block__background--spa-pale,
.marketo-form-block__background--rose-light,
.marketo-form-block__background--cream {
	margin: 0;

	padding: (8 * $v) 0;
	@media (#{$marketo-form-block__bp}) {
		padding: (12 * $v) 0;
	}

	// Excessive specificity and `!important` is necessary to override `!important` elsewhere in CSS 😬
	form.mktoForm input[type=date],
	form.mktoForm input[type=email],
	form.mktoForm input[type=number],
	form.mktoForm input[type=tel],
	form.mktoForm input[type=text],
	form.mktoForm input[type=url],

	form.mktoForm select.mktoField,
	form.mktoForm textarea.mktoField,

	form.mktoForm .mktoCheckboxList > label::before,
	form.mktoForm .mktoCheckboxList > label:hover::before,
	form.mktoForm input[type="checkbox"].mktoField:checked + label::before,

	form.mktoForm .mktoRadioList > label::before,
	form.mktoForm input[type="radio"].mktoField:checked + label::before {
		background-color: $white !important;
	}
}

.marketo-form-block__background--coral {
	background-color: $coral-3;
}

.marketo-form-block__background--blush {
	background-color: $dusk-4;
}

.marketo-form-block__background--midnight-pale {
	background-color: $midnight-pale;
}

.marketo-form-block__background--spa-pale {
	background-color: $spa-pale;
}

.marketo-form-block__background--rose-light {
	background-color: $rose-light;
}

.marketo-form-block__background--cream {
	background-color: $cream-0;
}

.marketo-form-block__inner-container {
	margin: 0 auto;
	max-width: 700px;
}

.marketo-form-block__title {
	&.marketo-form-block__title--h1 {
		@extend %h1;
	}
	&.marketo-form-block__title--h2 {
		@extend %h2;
	}
}

.marketo-form-block__description {
	@extend %p;
}

.marketo-form-block__form:not(:has(.mktoForm)) {
	// Hold some space until the form loads
	min-height: 400px;
	background: $cream-0;
}

.Input {
    border: 1px solid #aaa;
    color: #444;
    height: 40px;
    width: 100%;
    padding-bottom: 0;
    padding-top: 0;
    padding-left: 12px;
    position: relative;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border-radius: 2px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-bottom: $v * 2;
}

// .Checkbox {
//   &[type="checkbox"] {
//       margin-top: 0;
//       margin-right: 7px;
//   }
// }
.Checkbox {
    display: block;
    position: relative;
    padding-left: 20px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 22px;
    transition: all 0.2s;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        &:checked ~ .checkmark {
            border: 1px solid $hot-coral;
            background: $hot-coral;
            &:after {
                display: block;
            }
        }
        &[disabled] ~ .checkmark {
            background: #c8c8c8;
            border: none;
            cursor: not-allowed;
        }
    }
    .checkmark {
        position: absolute;
        left: 0;
        top: 5px;
        height: 15px;
        width: 15px;
        border: 1px solid #c8c8c8;
        border-radius: 4px;
        background-color: #fff;
        transition: all 0.2s;
        &:after {
            content: '';
            position: absolute;
            display: none;
            left: 4px;
            top: 0;
            width: 5px;
            height: 10px;
            border: solid #fff;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
        }
    }
    &:hover input ~ .checkmark {
        border-color: $hot-coral;
    }
}

.mdc-text-field-helper-text.mdc-text-field-helper-text--persistent {
    color: $charcoal-2;
}

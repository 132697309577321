.SocialIcons {
  position: absolute;
  bottom: $v * 1.5;
  left: $v * 2;
  z-index: 1;

  p {
    margin-bottom: 0;
    text-align: left;
    height: 30px;
  }
  strong {
    opacity: .7;
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    filter: drop-shadow(0px 0px 6px rgba(0,0,0,.4))
  }

  ul,
  li {
    padding: 0;
    margin: 0;
  }

  li {
    display: inline-block;
    margin-right: $v * 1.5;
  }

  img,
  svg {
    filter: drop-shadow(0px 0px 6px rgba(0,0,0,.4))
  }
}

.SocialIcons-item {
  opacity: .7;
  &:hover {
    opacity: 1;
  }
}

.SocialIcons--components {
  position: static;
  bottom: initial;
  left: initial;

  a {
    img,
    svg {
      filter: none;
      path,
      g {
        transition: all .2s ease-in-out;
        fill: $charcoal-2;
      }
    }
  }
  a:hover {
    img,
    svg {
      path,
      g {
        fill: $charcoal-0;
      }
    }
  }
}
@import 'fa';

.homecard {
    h3 {
        padding: $v * 2 $v * 2 0;
    }
    p {
        padding: 0 $v * 2 0;
    }
}

.u-grad-blue,
.tsnav {
    background: $dusk-2;
    color: $charcoal-base;
}

.tsnav .list span {
    color: $charcoal-base;
}

.triplecopy02 .nolink img {
    filter: grayscale(100%);
}

.u-col-timely-lite {
    color: $charcoal-4;
}

.quote01 {
    @extend .u-vSpacingLG;
    // padding-top: $v * 9;
    // padding-bottom: $v * 15;
}

.footer-strip {
    background-color: $dusk-2;

    padding: $v * 3 0 $v * 4 0;
    a,
    .ctwhite {
        color: $charcoal;
    }
}

.footer-strip-content {
    margin-bottom: 0;
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        @media (#{$medium-bp}) {
            align-items: baseline;
        }
    }
}

.contentlist02 h3 {
    color: $charcoal;
}

.triplecopy01 {
    @extend .u-vSpacingLG;
    border-color: $charcoal-5;
    background-color: $charcoal-5;

    .titlea {
        color: $charcoal;
        margin-bottom: $v * 3.5;
        padding-bottom: math.div($v, 2);

        &:hover {
            border-color: $charcoal;
        }
    }
}

.contentlist01 h4 {
    margin-top: $v * 3;
    color: $charcoal;
}

.u-bg-grey {
    background-color: $charcoal-5;
}
.u-bg-duskbg,
.u-bg-tightblue {
    background-color: $dusk-3;
}

.IndexTable {
    background-color: $charcoal-5;
    border: 1px solid $charcoal-4;
}

.card {
    border-color: $charcoal-4;
    h3 {
        color: $charcoal;
    }
    &:hover {
        border-color: $charcoal-3 !important;
        h3 {
            color: $charcoal;
        }
    }
}
.ctmidgray {
    color: $charcoal-2;
}

.resources-index ol li a.active {
    color: $hot-coral;
}

.pagination {
    > li > a,
    > li > .dots {
        color: $charcoal-2;
        &:hover {
            background-color: $hot-coral;
        }
    }
    .current {
        &,
        &:hover {
            background-color: $hot-coral;
        }
    }
}

// RESET JUMBO
.page-template {
    h1.jumbo {
        @extend %h1;
    }

    h2.jumbo {
        @extend %h2;
    }

    h3.jumbo {
        @extend %h3;
    }
}

h1.jumbo {
    @extend %h1;
}

h2.jumbo {
    @extend %h2;
}

h3.jumbo {
    @extend %h3;
}

.list-tick-blue li {
    background: none;
    border-color: $charcoal-4;
    padding-left: 0;
    list-style: inside;
}

.panel-title {
    h4 {
        margin-bottom: 0;
    }
    .open-close {
        color: $charcoal;
    }
    &:hover {
        .open-close {
            color: $coral-hover;
        }
    }
}

.panel-title h4 {
    margin-bottom: 0;
}

address {
    font-size: 1.4rem;
    font-weight: normal;
}

.hbspt-form input[type='submit'],
.frm_form_fields input[type='submit'] {
    @extend .Button;
    @extend .Button--charcoal;
}

.product-icons .tag {
    color: $charcoal;
}

.bb,
.bt {
    border-color: $charcoal-4;
}

// .PriceList {
//   .billing-period.ctblue {
//     color: $charcoal;
//   }
// }

input:checked + .slider,
.slider {
    background-color: $charcoal;
}

.table-dot {
    background-color: $charcoal-4;
    border-radius: 50%;
    display: inline-block;
    height: 12px;
    width: 12px;
}

form.tmly-lcf .check .field:checked + label:before,
.tmly-form .check .field:checked + label:before {
    background-color: $hot-coral;
    border-color: $hot-coral;
}

.tsnav .browse {
    border-color: $charcoal;
    color: $charcoal;
}

html.tsnav .tsnav .browse {
    border-color: $charcoal;
    color: $charcoal;
}

// .tsnav .list li.active a {
//   color: $hot-coral;
//   border-color: $hot-coral;
// }
.heading01 .jumbo {
    @extend %h1;
}

.tsnav .list li.active {
    border-color: $charcoal;
    padding-bottom: $v;
}
.tsnav .list li.active a {
    color: $charcoal;
    border-color: $charcoal;
}
.tsnav .list li.active a .mainfill {
    fill: $charcoal;
}

.leadcap02 .action-link a {
    margin-bottom: $v;
    @media (#{$large-bp}) {
        margin-bottom: 0;
    }
}

.check label:before {
    margin-right: $v !important;
}

.Afterpay-intro {
    text-align: center;
    align-items: center;
}

.Afterpay-intro img {
    width: 175px;
    margin: $v auto 2rem;
}

.Afterpay-intro h3 {
    margin-bottom: 1rem;
    margin-top: 0;
}

@media (min-width: 600px) {
    .Afterpay-intro {
        text-align: left;
    }

    .Afterpay-intro img {
        width: 220px;
    }
}

.footer-copyright {
    text-transform: uppercase;
    letter-spacing: 3.5px;
    font-size: 1.4rem;
}

.blog-nav > li {
    > a {
        color: $charcoal;
        &:hover {
            color: $hot-coral;
        }
    }

    &.active > a {
        background-color: $hot-coral;
        &::after {
            border-color: $hot-coral transparent;
        }

        &:hover {
            background-color: $hot-coral;
            &::after {
                border-color: $hot-coral transparent;
            }
        }
    }
}

.newsletter-footer {
    background-color: $charcoal-5;
    padding: $v * 9 0;
}

#related-content {
    padding-top: $v * 3;
    padding-bottom: $v * 3;
    &,
    .post-tags.h5 {
        background-color: $charcoal-5;
    }
}

.bgtightblue.post-type-page {
    background-color: $dusk-2;
}

.read-more .img-responsive.addon-icon {
    width: auto;
    margin-bottom: $v * 2;
}

.post-type-page .h4.ctmidgray {
    @extend h6;
}

._tease-add-ons h3 {
    margin-bottom: $v;
}

.panel-sash {
    filter: grayscale(1);
}

.subnav-new {
    background-color: $charcoal;
    .active a {
        color: $hot-coral !important;
        border-color: $hot-coral !important;
    }
}

.card-video {
    .play-button {
        background-color: $charcoal;
    }
    &:hover {
        .play-button {
            background-color: $black;
            color: $white;
        }
    }
}

.related-links li:before {
    color: $charcoal;
}

.videos-template-default .related {
    background-color: $charcoal-5;
}

.divider.u-d,
.u-d {
    color: $charcoal;
}

.page-id-54803 {
    .seven.centered,
    .eight.centered {
        h4 {
            font-size: 1.6rem !important;
            text-transform: initial;
            letter-spacing: 0;
        }
    }

    .product_title {
        margin-bottom: $v * 2;
    }
}

.carousel01 .slide.header {
    color: $charcoal !important;
    h3 {
        font-family: $text-font !important;
    }
    &.indicator {
        background-color: $charcoal !important;
    }
}
.carousel01 .slide.header.mobile {
    border-top-color: $charcoal-4;
}

.at0.carousel01 .carousel-dot:nth-child(1),
.at1.carousel01 .carousel-dot:nth-child(2) {
    background-color: $hot-coral;
}

.alt-rows li:nth-child(2n + 1) {
    background: $charcoal-5;
}

.bgtightblue {
    background-color: $dusk-2;
}

.page-id-2625 {
    .bgtightblue {
        img.ibk.m0 {
            filter: grayscale(1);
        }
    }
}

.box-row-right,
.box-row-left {
    border-color: $charcoal-4;
}

.careers-promo > div {
    display: table-row;
}

.list-lined li {
    border-color: $charcoal-4;
}

.floaty-block h3 {
    margin-bottom: $v * 2;
}

ul.tour-nav-list li:hover a {
    background-color: $charcoal-5;
}

.custom-search .search-buckets ul .active li {
    color: $coral-hover;
    border-color: $coral-hover;
}

.page-template-campaign-template,
.page-influencer-marketing-guide,
.page-silly-season-guide,
.page-salon-trends-2019,
.page-salon-trends {
    .page-wrapper {
        margin-top: 0 !important;
    }

    #stickynav {
        display: none;
    }
}

.button.btn-blue {
    &:focus {
        outline: none;
        background: $hot-coral;
    }
}

.page-call-us {
    .btn-nav-call.btn-talk-to-us-mobile,
    #timely-nav .btn-talk-to-us.btn-nav-call {
        display: none;
    }
}

.bgim {
    .u-bg-none.u-dark {
        color: #fff;
    }
}

#homepage-top-submit.double {
    @extend .Button;
    @extend .Button--white;
    border-radius: 0;
    box-shadow: none;

    &:not(.btn-outline) {
        @extend .Button--primary;
    }
}

.page-id-53448 .page-wrapper {
    overflow: unset;
}

.nav-search-button {
    line-height: 22px !important;
    bottom: 1px;
    display: none !important;
    transition: color 0.2 ease-in-out;

    @media (#{$medium-bp}) {
        display: block !important;
    }

    &:hover {
        svg g {
            stroke: $coral-base;
        }
    }
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
    background-color: $coral-base;
}

.in-menu-search {
    @media (#{$medium-bp}) {
        display: none !important;
    }
}

.guide {
    margin-top: $v * 5;
    text-align: left;

    h5 {
        margin-bottom: $v;
    }
}

.QuoteBG {
    margin-top: $v * 12;
    padding-bottom: $v * 21;
    background-repeat: no-repeat;
    background-size: cover;
}

.page-inverted {
    .page-wrapper {
        background-color: $dusk-2;
        &,
        .YesBlock-quote,
        .YesBlock-quote-author,
        .u-grey,
        .HeroSection-timelyCrew-name,
        .YesBlock-quote-business {
            color: $charcoal-0;
        }
    }
}

.hero-bg {
    padding-top: 77px;
    background: none;
    box-shadow: none;
    border: none;
    border-radius: 0;
    position: absolute;
    // padding-top: 30px;
    left: 0;
    right: 0;
    top: 50%;
    padding-bottom: 53.72%;
    transform: translateY(-50%);
    height: 0;
    overflow: hidden;
    z-index: 10;
}

// Loading react
.fade-board {
    opacity: 1;
    width: 100%;
    height: 100vh;
    transition: all 0.2s ease;
    position: fixed;
    background-color: white;
    top: 0;
    left: 0;
    z-index: 99999;
}

.fade-board--active {
    opacity: 0;
    z-index: -1;
}

.search-box {
    clear: both;
}

.img-negTop {
    margin: -$v * 7 auto $v * 4;
    position: relative;
    z-index: 3;
    display: block;
    max-width: 80%;
}

.btn-blue:focus {
    background: linear-gradient(to bottom, $coral-base 0, $coral-base 100%);
}

.page-wrapper {
    &.allow-sticky,
    .page-covid-19 &,
    .page-hardware &,
    .feature-add-ons &,
    .page-world-class-hardware &,
    .page-template-sidebar-template &,
    .feature-google-tag-manager &,
    .feature-izettle &,
    .feature-square &,
    .feature-shopify &,
    .feature-zapier &,
    .feature-xero &,
    .feature-quickbooks &,
    .feature-afterpay & {
        overflow: initial !important;
        @media (#{$small-bp}) {
            overflow: hidden;
        }
    }
}

.nav-top {
    display: none;
}

.gdpr {
    .page-wrapper {
        margin-top: 76px;
    }

    .nav-top {
        display: block;
        background-color: $charcoal-0;
        color: $charcoal-3;
        font-size: $v * 1.2;
        padding-top: $v * 0.4;
        padding-bottom: $v * 0.4;
        text-align: center;

        @media (#{$medium-bp}) {
            text-align: left;
            padding-left: 8px;
            padding-right: 8px;
        }

        // @media (#{$large-bp}) {
        //   padding-left: 0;
        //   padding-right: 0;
        // }

        a {
            color: $white;
            font-weight: bold;
            padding-left: 5px;
            font-size: $v * 1.2;
        }
    }
    #stickynav.navbar-uk:not(.navbar--hasNotification) {
        height: 86px;
    }

    // .navbar.headroom--not-top.headroom--pinned {
    //     transform: translateY(0);
    // }
}

.nav-top-flag {
    position: relative;
    bottom: -1px;
    margin-right: math.div($v, 4);
}

.PostSearch {
    display: none;

    @media (#{$large-bp}) {
        display: flex;
        justify-content: flex-end;
    }
}

.PostSearch-button {
    line-height: 100%;
    padding-top: 0;
    padding: 0 $v * 2;
}

p.text-center:not(.blog-post-intro),
.text-center h1 {
    display: flex;
    align-items: center;
    justify-content: center;
}

// .Loader {
// width: 100%;
// height: 300px;
// background-color: $dusk-2;
// }

.Subscribe-wrap {
    background-color: $cream-0;
    padding: $v * 6 0;
    position: absolute;
    text-align: center;

    @media (min-height: 500px) {
        top: 50%;
        transform: translateY(-50%);
    }
}

.Subscribe-title {
    margin-bottom: $v * 3;
}

.Subscribe {
    margin-top: $v * 2;

    .Button--charcoal-alt {
        font-weight: 500;
        border-radius: 4px;
    }
}

.Subscribe.Subscribe--header {
    margin-top: 0;
    margin-right: $v * 1.5;
}

@keyframes fadeOut {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
        top: 2rem;
    }
    90% {
        opacity: 1;
        top: 2rem;
    }
    99% {
        opacity: 0;
        top: 0;
    }
    100% {
        opacity: 0;
    }
}

.navbar-brand svg {
    @media (#{$small-bp}) {
        width: 125px;
        margin-bottom: 5px;
    }

    width: 145px;
}

.CustomerStoryCard-image {
    max-width: 170px;
}
.CustomerStoryCard-title {
    color: $charcoal-3;
    font-size: $v * 1.4;
    letter-spacing: 2.8px;
    line-height: $v * 2.1;
    margin-top: $v * 3;
}

.Hero5050 {
    @media (#{$medium-bp}) {
        display: flex;
        min-height: 700px;
        height: 100vh;
        background-color: #ff0d10;
    }
}

.Hero5050-half {
    width: 100%;
    padding-top: $v * 4;
    padding-left: $v * 1.5;
    padding-right: $v * 1.5;
    position: relative;

    @media (#{$medium-bp}) {
        padding-top: 0;
        width: 50%;
    }
}
.Hero5050--5050ImageOnLeft {
    @media (#{$medium-bp}) {
        flex-direction: row-reverse;
    }
}

.Hero5050-imageHalf {
    background-size: cover;
    background-position: center;
    height: 100vw;
    padding-top: $v * 4;
    margin-top: $v * 6;

    @media (#{$medium-bp}) {
        padding-top: 0;
        margin-top: 0;
        height: auto;
    }
}

.Hero5050-content {
    width: 100%;
    padding-top: $v * 7;

    @media (#{$medium-bp}) {
        padding-top: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(750px * 0.41666666667);
    }

    @media (#{$large-bp}) {
        width: calc(970px * 0.41666666667);
    }

    @media (#{$xlarge-bp}) {
        width: calc(1170px * 0.41666666667);
    }

    @media (#{$xxlarge-bp}) {
        width: calc(1800px * 0.41666666667);
    }
}

.newsletter-footer {
    .large.crate {
        width: 100%;
    }
}

html.intercom-hideaway #intercom-container {
    position: fixed;
}

.Hardware {
    margin-bottom: $v * 6;

    div.Hardware-description p,
    h3.Hardware-title {
        padding-bottom: 0 !important;
        margin-bottom: $v * 2;
    }

    h3.Hardware-title {
        margin-top: $v * 2;
    }
}

.Hardware--landscape {
    h3.Hardware-title {
        margin-top: $v * 2;

        @media (#{$medium-bp}) {
            margin-top: 0;
        }
    }
}

.HardwareCountrySelector {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: $v * 3;
    padding-bottom: $v * 3;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    .mdc-select__native-control {
        line-height: 1.75rem;
    }
}

.Hardware--portrait {
    .Hardware-title {
        margin-top: $v * 1.5;
    }
}

.HardwareCountrySelector-label {
    display: none;
    @media (#{$medium-bp}) {
        display: inline;
    }
}

.lv-wrap {
    background-position: center;
    background-size: cover;
    height: 100vh;
}

.lv-panel {
    position: absolute;
    left: 50%;
    text-align: center;
    top: 70%;
    bottom: 0;
    width: 480px;
    max-width: 100%;
    transform: translate(-50%, 0);
}

.onDark {
    color: $white;
}

.navbar-default {
    z-index: 190000;
}

.navbar-default .navbar-header {
    z-index: 195000;
}

.Article-cat-covid {
    white-space: nowrap;
    display: inline-flex;
    align-items: baseline;
    svg {
        margin-left: 5px;
    }
}
h6.media-title.ptp5.ibk {
    min-height: 55px;
}

.page-call-us .BookACall-form {
    border-bottom: 1px solid #e5eaec;
}
.nowrap {
    white-space: nowrap;
}

.ibk {
    display: inline-block;
}

svg {
    vertical-align: bottom;
}

.m0 {
    margin: 0;
}

.mt1 {
    margin-top: 1rem;
}
.mt2 {
    margin-top: 2rem;
}
.mb2 {
    margin-bottom: 2rem;
}
.mb1 {
    margin-bottom: 1rem;
}

.mb15 {
    margin-bottom: 1.5rem;
}

.mtb5 {
    margin-bottom: 0.5rem;
}
.mtp5 {
    margin-top: 0.5rem;
}
.pt3 {
    padding-top: 3rem;
}
.pr2 {
    padding-right: 2rem;
}
.pbp5 {
    padding-bottom: 1rem;
}

.embed-responsive iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
// .ReferAFriend-img {
//     top: 0;
//     left: 0;
//     right: 0;
// }

.ReferAFriend {
    position: relative;
    @media (#{$medium-bp}) {
        .AskQuestion-card {
            padding: $v * 7;
        }
    }
}

.ReferAFriend-container {
    padding-top: $v * 6;
    padding-bottom: $v * 6;
    width: 100%;
    @media (#{$medium-bp}) {
        margin-top: -40%;
    }
}

.ReferAFriend-container--condensed {
    margin-top: -185%;
    @media (#{$medium-bp}) {
        padding-top: 0;
        margin-top: -52%;
        .ReferAFriend-card {
            padding: $v * 3 $v * 2;
        }
    }
    padding-bottom: 0;

    .ReferAFriend-card {
        padding-top: $v * 2;
    }
}

.clearfix:before,
.clearfix:after {
    content: '\0020';
    display: block;
    height: 0;
    overflow: hidden;
}
.clearfix:after {
    clear: both;
}
.clearfix {
    zoom: 1;
}

.Brochure .Message--error {
    background-color: rgba(0, 0, 0, 0.5);
    color: $coral-3;
    border: 1px solid $coral-3;
    border-radius: 5px;
    padding: $v math.div($v, 2);
    font-size: 1.4rem;
}

.Column--mobi-reverse {
    .ColumnLayout {
        display: flex;
        flex-direction: column-reverse;
        @media (#{$large-bp}) {
            flex-direction: initial;
        }
    }
}
.wp-block-cgb-block-columns-block {
    overflow: hidden;
    max-width: 100%;
    position: relative;
}

.SupportImage img {
    max-width: 100%;
    width: 100%;
    height: auto;
    @media (#{$large-bp}) {
        max-width: 700px;
        width: calc(55% - 1.5rem);
        position: absolute;
    }
}

.Column--mobi-reverse.d {
    h2.u-vSpacingMD-top {
        @media (#{$small-bp}) {
            padding-top: $v;
        }
    }
}

.wp-block-image img {
    height: auto;
}

.Calculator-input {
    width: 70px;
    background-color: $dusk-2;
    color: $coral-base;
    border-bottom: 1px solid $hot-coral;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    text-align: center;
}

#expression-of-interest {
    @media (#{$medium-bp}) {
        background-color: transparent !important;
    }
}

.tick-list {
    list-style-image: url('/images/svg/tick-red.svg');

    li {
        line-height: $v * 2;
        text-align: left !important;
        padding-bottom: $v;
    }
}
.star-list {
    list-style-image: url('/images/svg-icons/star-list.svg');

    li {
        line-height: $v * 2;
        text-align: left !important;
        padding-bottom: $v;
    }
    li::marker {
        position: relative;
        top: 1px;
    }
}

.tick-list-inline {
    list-style-image: url('/images/svg/tick-red.svg');
    text-align: left !important;
    // max-width: 65%;
    margin-left: auto;
    margin-right: auto;
    @media (#{$medium-bp}) {
        display: flex;
        justify-content: center;
    }
    li {
        @media (#{$medium-bp}) {
            margin-left: 35px;
        }
    }
}

.btn-nav-call {
    cursor: pointer;
}

.Button--consult-modal,
.consult-block,
.consult-modal {
    display: none !important;
}

.NZ,
.GB,
.IE,
.AU,
.CA,
.US {
    .Button--consult-modal,
    .consult-modal {
        display: inline !important;
    }
    .consult-block {
        display: block !important;
    }
}

#wpadminbar {
    z-index: 999999999;
}

.List-condensed {
    li {
        margin-bottom: 0;
        padding-left: 0;
    }
}

.ColumnLayout-column {
    max-width: 100%;
}
.inline-list {
    list-style: none;
    text-align: center;
    li {
        padding-left: 0;
        display: inline-block;
        margin-right: $v * 4.5;
    }
    a {
        color: $charcoal-0;
        display: flex;
        align-items: center;
        img {
            margin-right: math.div($v, 2);
        }

        &:hover {
            color: $hot-coral;
        }
    }
}

.TitleIcons {
    display: flex;
    align-items: center;
    color: $charcoal-0;
    text-decoration: none;

    .TitleIcons-icon {
        margin-right: $v;
    }

    &:hover {
        color: $hot-coral;
        filter: invert(38%) sepia(99%) saturate(1143%) hue-rotate(327deg)
            brightness(85%) contrast(113%);
    }
}

.TitleIcons--arrow {
    &::after {
        display: inline-block;
        content: '\F105';
        font-family: fa;
        transition: transform 0.3s ease;
        position: relative;
        left: 18px;
        bottom: 1px;
    }

    &:hover {
        &::after {
            color: $hot-coral;
        }
    }
}

.Card-title.TitleIcons {
    font-size: 2.6rem;
}

.ColumnLayout-full-col {
    .ColumnLayout-column:first-child {
        margin-left: 0;
    }
}

.SectionHeading {
    @extend %h4;
    @extend %container;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    &:before,
    &:after {
        content: '';
        border-top: 2px solid #ffd4cc;
        margin: 0 1.5rem 0 0;
        flex: 1 0 20px;
    }

    &:after {
        margin: 0 0 0 1.5rem;
    }
    @media (#{$medium_bp}) {
        &:before,
        &:after {
            margin: 0 2rem 0 0;
        }

        &:after {
            margin: 0 0 0 2rem;
        }
    }
}

.AccordionPanel {
    width: 100%;
}

.bgImage-contact {
    background-image: url(/images/background-images/contact-page.jpeg);
    background-size: 100%;
    background-repeat: no-repeat;
}

.bgImage-fill {
    position: fixed;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    z-index: -1;
}

.forceShow {
    display: block !important;
}

.card-image {
    position: relative;
}

.panel {
    margin-bottom: 20px;
    background-color: #ffffff;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.panel-body {
    padding: 15px;
}
.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
.panel-heading > .dropdown .dropdown-toggle {
    color: inherit;
}
.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;
}
.panel-title > a,
.panel-title > small,
.panel-title > .small,
.panel-title > small > a,
.panel-title > .small > a {
    color: inherit;
}
.panel-footer {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #dddddd;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
.panel > .list-group,
.panel > .panel-collapse > .list-group {
    margin-bottom: 0;
}
.panel > .list-group .list-group-item,
.panel > .panel-collapse > .list-group .list-group-item {
    border-width: 1px 0;
    border-radius: 0;
}
.panel > .list-group:first-child .list-group-item:first-child,
.panel
    > .panel-collapse
    > .list-group:first-child
    .list-group-item:first-child {
    border-top: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
.panel > .list-group:last-child .list-group-item:last-child,
.panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
    border-bottom: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
.panel
    > .panel-heading
    + .panel-collapse
    > .list-group
    .list-group-item:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}
.panel-heading {
    color: #323e45;
    padding: 0;
    border-radius: 0;
    border-top: 1px solid #ddd;
}
.panel-heading + .list-group .list-group-item:first-child {
    border-top-width: 0;
}
.list-group + .panel-footer {
    border-top-width: 0;
}
.panel > .table,
.panel > .table-responsive > .table,
.panel > .panel-collapse > .table {
    margin-bottom: 0;
}
.panel > .table caption,
.panel > .table-responsive > .table caption,
.panel > .panel-collapse > .table caption {
    padding-left: 15px;
    padding-right: 15px;
}
.panel > .table:first-child,
.panel > .table-responsive:first-child > .table:first-child {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
.panel > .table:first-child > thead:first-child > tr:first-child,
.panel
    > .table-responsive:first-child
    > .table:first-child
    > thead:first-child
    > tr:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child,
.panel
    > .table-responsive:first-child
    > .table:first-child
    > tbody:first-child
    > tr:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel
    > .table-responsive:first-child
    > .table:first-child
    > thead:first-child
    > tr:first-child
    td:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel
    > .table-responsive:first-child
    > .table:first-child
    > tbody:first-child
    > tr:first-child
    td:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel
    > .table-responsive:first-child
    > .table:first-child
    > thead:first-child
    > tr:first-child
    th:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.panel
    > .table-responsive:first-child
    > .table:first-child
    > tbody:first-child
    > tr:first-child
    th:first-child {
    border-top-left-radius: 3px;
}
.panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel
    > .table-responsive:first-child
    > .table:first-child
    > thead:first-child
    > tr:first-child
    td:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel
    > .table-responsive:first-child
    > .table:first-child
    > tbody:first-child
    > tr:first-child
    td:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel
    > .table-responsive:first-child
    > .table:first-child
    > thead:first-child
    > tr:first-child
    th:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel
    > .table-responsive:first-child
    > .table:first-child
    > tbody:first-child
    > tr:first-child
    th:last-child {
    border-top-right-radius: 3px;
}
.panel > .table:last-child,
.panel > .table-responsive:last-child > .table:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
.panel > .table:last-child > tbody:last-child > tr:last-child,
.panel
    > .table-responsive:last-child
    > .table:last-child
    > tbody:last-child
    > tr:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child,
.panel
    > .table-responsive:last-child
    > .table:last-child
    > tfoot:last-child
    > tr:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}
.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel
    > .table-responsive:last-child
    > .table:last-child
    > tbody:last-child
    > tr:last-child
    td:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel
    > .table-responsive:last-child
    > .table:last-child
    > tfoot:last-child
    > tr:last-child
    td:first-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel
    > .table-responsive:last-child
    > .table:last-child
    > tbody:last-child
    > tr:last-child
    th:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
.panel
    > .table-responsive:last-child
    > .table:last-child
    > tfoot:last-child
    > tr:last-child
    th:first-child {
    border-bottom-left-radius: 3px;
}
.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel
    > .table-responsive:last-child
    > .table:last-child
    > tbody:last-child
    > tr:last-child
    td:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel
    > .table-responsive:last-child
    > .table:last-child
    > tfoot:last-child
    > tr:last-child
    td:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel
    > .table-responsive:last-child
    > .table:last-child
    > tbody:last-child
    > tr:last-child
    th:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
.panel
    > .table-responsive:last-child
    > .table:last-child
    > tfoot:last-child
    > tr:last-child
    th:last-child {
    border-bottom-right-radius: 3px;
}
.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive,
.panel > .table + .panel-body,
.panel > .table-responsive + .panel-body {
    border-top: 1px solid #dddddd;
}
.panel > .table > tbody:first-child > tr:first-child th,
.panel > .table > tbody:first-child > tr:first-child td {
    border-top: 0;
}
.panel > .table-bordered,
.panel > .table-responsive > .table-bordered {
    border: 0;
}
.panel > .table-bordered > thead > tr > th:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:first-child,
.panel > .table-bordered > tbody > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
.panel > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-bordered > thead > tr > td:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
.panel > .table-bordered > tbody > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
.panel > .table-bordered > tfoot > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
}
.panel > .table-bordered > thead > tr > th:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:last-child,
.panel > .table-bordered > tbody > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
.panel > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-bordered > thead > tr > td:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
.panel > .table-bordered > tbody > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
.panel > .table-bordered > tfoot > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
}
.panel > .table-bordered > thead > tr:first-child > td,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > td,
.panel > .table-bordered > tbody > tr:first-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > td,
.panel > .table-bordered > thead > tr:first-child > th,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > th,
.panel > .table-bordered > tbody > tr:first-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > th {
    border-bottom: 0;
}
.panel > .table-bordered > tbody > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
.panel > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-bordered > tbody > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
.panel > .table-bordered > tfoot > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
    border-bottom: 0;
}
.panel > .table-responsive {
    border: 0;
    margin-bottom: 0;
}
.panel-group {
    margin-bottom: 20px;
}
.panel-group .panel {
    margin-bottom: 0;
    border-radius: 4px;
}
.panel-group .panel + .panel {
    margin-top: 5px;
}
.panel-group .panel-heading {
    border-bottom: 0;
}
.panel-group .panel-heading + .panel-collapse > .panel-body,
.panel-group .panel-heading + .panel-collapse > .list-group {
    border-top: 1px solid #dddddd;
}
.panel-group .panel-footer {
    border-top: 0;
}
.panel-group .panel-footer + .panel-collapse .panel-body {
    border-bottom: 1px solid #dddddd;
}
.panel-default {
    border-color: #dddddd;
}
.panel-default > .panel-heading {
    color: #333333;
    background-color: #f5f5f5;
    border-color: #dddddd;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #dddddd;
}
.panel-default > .panel-heading .badge {
    color: #f5f5f5;
    background-color: #333333;
}
.panel-default > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #dddddd;
}
.panel-primary {
    border-color: #00b3f0;
}
.panel-primary > .panel-heading {
    color: #ffffff;
    background-color: #00b3f0;
    border-color: #00b3f0;
}
.panel-primary > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #00b3f0;
}
.panel-primary > .panel-heading .badge {
    color: #00b3f0;
    background-color: #ffffff;
}
.panel-primary > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #00b3f0;
}
.panel-success {
    border-color: #d6e9c6;
}
.panel-success > .panel-heading {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}
.panel-success > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #d6e9c6;
}
.panel-success > .panel-heading .badge {
    color: #dff0d8;
    background-color: #3c763d;
}
.panel-success > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #d6e9c6;
}
.panel-info {
    border-color: #bce8f1;
}
.panel-info > .panel-heading {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}
.panel-info > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #bce8f1;
}
.panel-info > .panel-heading .badge {
    color: #d9edf7;
    background-color: #31708f;
}
.panel-info > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #bce8f1;
}
.panel-warning {
    border-color: #faebcc;
}
.panel-warning > .panel-heading {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
}
.panel-warning > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #faebcc;
}
.panel-warning > .panel-heading .badge {
    color: #fcf8e3;
    background-color: #8a6d3b;
}
.panel-warning > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #faebcc;
}
.panel-danger {
    border-color: #ebccd1;
}
.panel-danger > .panel-heading {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}
.panel-danger > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #ebccd1;
}
.panel-danger > .panel-heading .badge {
    color: #f2dede;
    background-color: #a94442;
}
.panel-danger > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #ebccd1;
}

.panel-title a {
    background-color: #ffffff;
    display: block;
    color: #323e45;
    padding: 1em;
    padding-right: 4em;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    transition: background-color 0.25s ease-out;
}
.panel-title .open-close {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: normal;
    line-height: 1.5;
    font-size: 24px;
    position: absolute;
    right: 1em;
    top: 0.4em;
    margin-top: 0;
    transform: rotate(45deg);
    transition: 0.25s all ease-out;
}
.panel-title a.collapsed .open-close {
    transform: rotate(0deg);
}
.fade.in {
    opacity: 1;
}
.collapse {
    display: none;
}
.collapse.in {
    display: block;
}
.panel-heading:first-child {
    border-top: none;
}
.ABTest-a {
    display: none;
}

@keyframes fadein-ab {
    from {
        opacity: 0;
    }
}

.registration-page.public {
    animation: fadein-ab 0.2s ease-out 0.8s 1 backwards;
}

.nav-pills > li {
    float: left;
}
.nav-justified > li,
.nav-stacked > li {
    float: none;
}
.nav-pills > li > a {
    border-radius: 4px;
}
.nav-pills > li + li {
    margin-left: 2px;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
    color: #fff;
}

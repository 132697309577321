.RestartTrial {
  @extend .u-vSpacingXL-top;
  margin-top: $v * 9;
  padding-bottom: $v * 9;
  color: $white;
  background-size: cover;
  background-position: center center;

  @media (#{$medium-bp}) {
    margin-top: $v * 21;
    padding-bottom: $v * 21;
  }
}

.RestartTrial-container {
  @extend %container;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  a:not(.Button) {
    &, &:visited {
      transition: all .2s ease-out;
      color: $light-coral;
    }
    &:hover {
      color: $hot-coral;
    }
  }

  @media (#{$large-bp}) {
    text-align: left;
    flex-direction: row;
    padding: 0;
    > div {
      padding: 0;
    }
  }
}

.RestartTrial-image {
  width: 100%;
  padding-bottom: $v * 3;

  @media (#{$medium-bp}) {
    padding-bottom: $v * 4;
  }

  @media (#{$large-bp}) {
    padding-bottom: 0;
  }
}

.RestartTrial-col2 {
  h3 {
    margin-bottom: $v * 4;
  }

  form {
    margin-top: $v * 4;
    p {
      margin: 0;
    }
  }
}

.RestartTrial-actions {
  text-align: center;
  margin-top: $v * 4;

  @media (#{$medium-bp}) {
    text-align: left;
  }
}

.RestartTrial-tnc {
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 1.4rem;
  margin-bottom: $v;
}

.RestartTrial-privacy {
  font-size: 1.4rem;
  text-align: left;
  a {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
  a:hover {
    padding-bottom: 0;
    border-bottom: 1px solid $hot-coral;
  }
}
.CategoryHeader {
    min-height: 40rem;
    position: relative;
    background-size: cover;
    margin-bottom: $v * 4.5;
    margin-left: -$v * 1.5;
    margin-right: -$v * 1.5;
    @media (#{$medium-bp}) {
        margin-left: -$v * 3;
        margin-right: -$v * 3;
    }
    @media (#{$large-bp}) {
        margin-left: 8.333333333%;
    }
}

.CategoryHeader-content {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.CategoryHeader-title,
.CategoryHeader-subTitle {
    padding-left: $v * 1.5;
    @media (#{$medium-bp}) {
        padding-left: $v * 4;
    }
}

.CategoryHeader-subTitle {
    margin-bottom: 0;
}

.CategoryHeader-title {
    margin-bottom: $v * 3;
    margin-top: 0;
}

.CategoryHeader-tabs {
    list-style: none;
    background-color: rgba(0, 0, 0, 0.65);
    width: 100%;
    margin: 0;
    padding: 0;
    li {
        display: inline-block;
        margin: 0;
        padding: 0;
    }

    @media (#{$small-bp}) {
        display: flex;
        justify-content: center;
    }
    @media (#{$medium-bp}) {
        text-transform: uppercase;
    }
}

.CategoryHeader-tab {
    display: inline-block;
    color: $white;
    font-size: $v * 1.6;
    line-height: 25px;
    text-shadow: 0 0 2px 0 rgba(255, 255, 255, 0.7);
    padding: $v * 2;
    text-decoration: none;
    font-weight: 400;

    @media (#{$medium-bp}) {
        letter-spacing: 3px;
        font-weight: 700;
        &:first-child {
            padding-left: $v * 4;
        }
    }
}
.CategoryHeader-tab.CategoryHeader-tab-active {
    color: $coral-2;
    position: relative;
    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        height: 3px;
        width: 100%;
        background-color: $coral-2;
        left: 0;
    }
}

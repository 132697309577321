@font-face {
    font-family: 'fa';
    src: url('/fonts/fa.woff');
    src: url('/fonts/fa.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[class^='fa-']:before,
[class*=' fa-']:before {
    font-family: fa;
    font-style: normal;
    font-weight: 400;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: 0.2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fa-bookmark:before {
    content: '\e800';
}

.fa-caret-down:before {
    content: '\e801';
}

.fa-caret-left:before {
    content: '\e802';
}

.fa-bolt:before {
    content: '\e803';
}

.fa-caret-right:before {
    content: '\e804';
}

.fa-check:before {
    content: '\e805';
}

.fa-check-cirle:before {
    content: '\e806';
}

.fa-check-circle-o:before {
    content: '\e807';
}

.fa-cog:before {
    content: '\e808';
}

.fa-cogs:before {
    content: '\e809';
}

.fa-comment:before {
    content: '\e80a';
}

.fa-envelope:before {
    content: '\e80b';
}

.fa-fw:before {
    content: '\e80c';
}

.fa-heart:before {
    content: '\e80d';
}

.fa-info-circle:before {
    content: '\e80e';
}

.fa-large:before {
    content: '\e80f';
}

.fa-lock:before {
    content: '\e810';
}

.fa-pencil:before {
    content: '\e811';
}

.fa-phone:before {
    content: '\e812';
}

.fa-play:before {
    content: '\e813';
}

.fa-search:before {
    content: '\e814';
}

.fa-star:before {
    content: '\e815';
}

.fa-tour-attract:before {
    content: '\e816';
}

.fa-tour-calendar:before {
    content: '\e817';
}

.fa-tour-cash-management:before {
    content: '\e818';
}

.fa-tour-customers:before {
    content: '\e819';
}

.fa-tour-dashboard:before {
    content: '\e81a';
}

.fa-tour-messages:before {
    content: '\e81b';
}

.fa-tour-mobile:before {
    content: '\e81c';
}

.fa-tour-online-booking:before {
    content: '\e81d';
}

.fa-tour-point-of-sale:before {
    content: '\e81e';
}

.fa-tour-stock:before {
    content: '\e81f';
}

.fa-tour-support:before {
    content: '\e820';
}

.fa-heart-o:before {
    content: '\e821';
}

.fa-down-open:before {
    content: '\f004';
}

.fa-twitter:before {
    content: '\f099';
}

.fa-facebook:before {
    content: '\f09a';
}

.fa-certificate:before {
    content: '\f0a3';
}

.fa-list-ul:before {
    content: '\f0ca';
}

.fa-linkedin:before {
    content: '\f0e1';
}

.fa-medkit:before {
    content: '\f0fa';
}

.fa-angle-left:before {
    content: '\f104';
}

.fa-angle-right:before {
    content: '\f105';
}

.fa-angle-up:before {
    content: '\f106';
}

.fa-angle-down:before {
    content: '\f107';
}

.fa-mobile:before {
    content: '\f10b';
}

.fa-rocket:before {
    content: '\f135';
}

.fa-youtube-play:before {
    content: '\f16a';
}

.fa-instagram:before {
    content: '\f16d';
}

.fa-facebook-square:before {
    content: '\f308';
}


.fa-tiktok:before {
    content: '\e07b';
}

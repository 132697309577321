.AddOnCards {
    margin-top: $v * 15;

    @media (#{$medium-bp}) {
        margin-top: $v * 21;
    }
}

.AddOnCard-Container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    > div {
        margin-bottom: $v * 2;
    }
}

.AddOnCard-hover {
    transition: all 0.2s ease-in-out;
    position: absolute;
    left: calc(50%);
    transform: translateX(-50%);
    opacity: 0;
    display: block;
}

.AddOnCard-image {
    transition: all 0.2s ease-in-out;
    transition-delay: 0;
    position: absolute;
    left: calc(50%);
    transform: translateX(-50%);
    display: block;
}

.AddOnCard {
    display: flex;
    align-items: center;
    position: relative;
    height: 120px;

    &:hover {
        box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.15);
        .AddOnCard-hover {
            opacity: 1;
        }
        .AddOnCard-image {
            transition-delay: 0.15s;
            opacity: 0;
        }
    }
}

.AddOnCards--fiveCol {
    .AddOnCards-wrap {
        @media (#{$large-bp}) {
            .col-md-2 {
                width: 20%;
            }

            .AddOnCard-Container {
                width: 85%;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

.Card {
    border: 1px solid $charcoal-4;
    padding: $v * 2 $v * 4;
    border-radius: 3px;
    transition: all 0.2s ease-in-out;
    width: 100%;
}

.Card.Card--customerStoryCard {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    border: none;
    padding: $v * 3;
    margin-top: $v * 6;
    @media (#{$medium-bp}) {
        margin-top: 0;
    }
}

.Card.Card--signUpPaged {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    border: none;
    padding: $v * 3 0;
    background-color: $white;
    @media (#{$small-bp}) {
        margin-left: -$v * 3;
        margin-right: -$v * 3;
        width: 100vw;
    }
}
.ColumnLayout-two-equal-col {
    .Card.Card--signUpPaged {
        @media (#{$small-bp}) {
            margin-left: -$v * 1.5;
            margin-right: -$v * 1.5;
        }
    }
}
.CardsCarousel .slick-track {
    display: flex !important;
    padding-bottom: 1.5rem;
}

.CardsCarousel .slick-slide {
    height: inherit !important;
    > div {
        height: 100%;
    }
    .CardCarousel-card {
        height: 100%;
    }
}

.CustomerStoryCard {
    @media (#{$medium-bp}) {
        display: flex;
    }
    @media (#{$large-bp}) {
        align-items: center;
    }
}

.CardCarousel-card {
    background-color: $white;
    border: 1px solid $charcoal-0;
    border-radius: 3px;
    transition: all 0.2s ease-in-out;
    margin-left: $v;
    margin-right: $v;

    .Card-image {
        padding: 2rem 2rem 0 2rem;
    }

    .Card-title {
        margin-bottom: $v * 1.5;
    }

    .Button--link {
        font-weight: 600;
        box-shadow: none;
    }

    .Actions {
        display: block;
        padding-left: 0;
        padding-right: 0;
        > div {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.CardCarousel-card:hover{
    box-shadow: 8px 8px $light-midnight;
}

.CardCarousel--full {
    margin-left: 0;
    margin-right: 0;
}

.CardCarousel-card--quote {
    &::before {
        font-family: $domaindisplay;
        font-weight: 400;
        content: '\201C';
        font-size: $v * 8;
        position: absolute;
        left: $v * 3;
        color: $coral-3;
    }
    .Card-content {
        padding-top: $v * 9;
    }

    .Flag {
        margin-bottom: $v;
    }
    .Flag-title {
        font-weight: 700;
    }
}

.Card-content {
    padding: $v * 2;
}

.Card-label {
    font-family: $text-font;
    font-weight: normal;
    font-size: $v * 1.6;
    color: #616b71;
    background: #e5e9eb;
    padding: 0.5rem;
    border-radius: 2px;
    position: relative;
    bottom: 2px;
    left: 8px;
}

.CardCarousel-card--blogPost {
    display: block;
    text-decoration: none;

    // Prevent over-zealous colour styling rules for `a` tags from applying
    &,
    &:hover,
    &:focus {
        color: inherit;
    }

    height: 426px;
    overflow: hidden;
    position: relative;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 2rem;
}
.CardCarousel-card__image-link {
    display: block;
}
.bottom-fade {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 35px 0;
    background-image: linear-gradient(rgba(255, 255, 255, 0), white);
}

.CardCarousel-card__link-after::after{
    content: url('/images/svg-icons/Arrow hover state.svg');
    margin-left: 6px;
    vertical-align: middle;
}

.cards-carousel ul.slick-dots button{
    border: 1px solid $charcoal-0;
}

.cards-carousel ul.slick-dots .slick-active button{
    background-color: $charcoal-0;
}

.cards-carousel .slick-arrow::before{
    border: solid 2px $charcoal-0;
    border-right: none;
    border-bottom: none;
}

.cards-carousel .slick-arrow::after{
    background-color: $cream-0;
}

.WebinarSignup {
  h4 {
    margin-bottom: $v;
  }
}

.single-webinar {
  h2 {
    margin-bottom: $v;
  }

  .page-wrapper > .has-2-columns {
    padding-top: $v * 6;
    flex-direction: column-reverse;

    .ProfileCard {
      margin-top: $v * 2;
    }
    > .wp-block-column:first-child {
      section {
        margin-bottom: $v * 9;
      }
    }
    .wp-block-column {
      width: 100%;

      @media (#{$medium-bp}) {
        width: calc(100% - #{$v} * 3);
      }
    }


    .has-2-columns {
      padding-left: 0;
      padding-right: 0;

      .wp-block-column {
        flex-basis: 100%;

        margin-left: 0;
        margin-right: 0;

        @media (#{$medium-bp}) {
          flex-wrap: nowrap;
          flex-basis: calc(50% - 1.5rem);

          &:first-child {
            margin-left: 0;
            margin-right:  $v * 1.5;
          }

          &:last-child {
            margin-left:  $v * 1.5;
            margin-right: 0;
          }

        }
      }
    }
    @media (#{$large-bp}) {
      padding-top: $v * 15;
      flex-direction: row;

      > .wp-block-column:first-child {
        flex-basis: 33.333333333%;
        padding-right: 8.333333333%;

      }

      > .wp-block-column:last-child {
        flex-basis: 66.66%;
      }
    }
  }
  .wp-block-column,
  .wp-block-column:first-child,
  .wp-block-column:last-child {
    margin-left: 0;
    margin-right: 0;

    @media (#{$medium-bp}) {
      margin-left: $v * 1.5;
      margin-right: $v * 1.5;
    }
  }
}

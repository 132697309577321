.YesBlock {
  padding-top: $v * 15;

  @media (#{$medium-bp}) {
    padding-top: $v * 21;
    padding-bottom: $v * 15;
  }
  @media (#{$large-bp}) {
    padding-bottom: 0;
  }

  .container {
    position: relative;
  }
}

.YesBlock--kylie {
  .YesBlock-heroImage {
    left: 0;

    @media (#{$medium-bp-only}) {
      width: 66.66%;
      position: absolute;
      left: -$v * 3;
    }
  }
}
.YesBlock-heroImage {
  width: 100%;

  @media (#{$medium-bp}) {
    width: 58.3333%;
    position: absolute;
    top: 0;
  }

  @media (#{$large-bp}) {
    width: 50%;
  }
}

.YesBlock-quote-author,
.YesBlock-quote-business,
.YesBlock-quote {
  position: relative;
  z-index: 1;
  color: $charcoal-2;

  @media (#{$small-bp}) {
    text-align: center;
  }
}

.YesBlock-parallax {
  max-width: 100%;
  padding-top: $v * 7.5;
  display: none;

  @media (#{$large-bp}) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.YesBlock-quote {
  text-indent: -15px; // hanging indentation;

  padding-top: $v * 4;

  @media (#{$large-bp}) {
    padding-top: $v * 10;
  }
}

.YesBlock-quote-author {
  padding-top: $v * 3;
  font-weight: 700;
}

.YesBlock--dario {
  position: relative;
  padding-bottom: $v * 6;

  @media (#{$small-bp}) {
    padding-top: $v * 7.5;
  }

  @media (#{$large-bp}) {
    padding-bottom: $v * 21;
  }


  .YesBlock-quote {
    @media (#{$large-bp}) {
      padding-top: $v * 4;
    }
  }
  .YesBlock-heroImage {
    left: 55%;
    width: 100%;
    @media (#{$large-bp}) {
      position: static;
    }
  }

  .col-md-8 {
    clear: left;
  }

  .YesBlock-parallax {
    position: absolute;
    top: $v * 12;
    right: 0;
    padding: 0;
  }
}


@use 'sass:math';

// .blog-post blockquote,
// .blog-post blockquote p,
// .blog-post blockquote b,
// .blog-post blockquote strong,
// .blog-post blockquote i:not(.fa-twitter),
// .blog-post blockquote em,
// .blog-post blockquote span {
//     .h2;
//     font-style: normal !important;
//     font-weight: 300 !important;
// }
.nav-pills.blog-nav {
    list-style: none;
    padding: 0;
    > li {
        position: relative;
        @media (#{$small-bp}) {
            float: none;
        }
    }
    > li.active {
        a {
            color: $white !important;
            border-radius: 50px;
        }
        &:after {
            content: '';
            position: absolute;
            border-style: solid;
            border-width: 20px 15px 0;
            border-color: $hot-coral transparent;
            display: block;
            width: 0;
            z-index: 1;
            bottom: -10px;
            right: 20px;
        }
        @media (#{$small-bp}) {
            &:after {
                right: 45px;
            }
        }
    }
}
.blog-nav > li > a {
    font-family: $text-font;
    font-size: $v * 1.8;
    text-align: center;
    line-height: $v * 3.4;
    letter-spacing: 0;
    text-decoration: none !important;
    font-family: Lato, 'Sans-serif';
    display: inline-block;
    border-radius: 50px;
    padding: 10px 20px;
    &:hover {
        text-decoration: underline !important;
    }
}

.blog-post blockquote {
    border-left: 5px solid $hot-coral;
    // p,
    // h3 {
    //     font-family: $domaintext;
    // }
    * {
        &:first-child {
            text-indent: -10px;
        }
    }
}

.blog-post blockquote,
.blog-post h5,
.blog-post p {
    margin: 30px 0;
    text-indent: 0;
}

.blog-post img {
    max-width: 100%;
}
.blog-post__content li {
    font-size: $v * 1.8;
    line-height: $v * 3.2;
}

.blog-post p {
    font-size: $v * 1.8;
    line-height: $v * 3.1;
}

.blog-post blockquote {
    margin: 50px 0;
}

.blog-post blockquote > p {
    // text-indent: -.3em;
}

.blog-post h2,
.blog-post h2 b,
.blog-post h2 span {
    margin: 30px 0 30px;
    font-weight: 300 !important;
}

.blog-post h3,
.blog-post h3 b,
.blog-post h3 span {
    @extend %h2;
    margin: 30px 0 30px;
    font-weight: 300 !important;
}

.blog-post h4,
.blog-post h4 b,
.blog-post h4 span {
    font-weight: 700 !important;
}

.blog-post img.size-full,
.blog-post [id^="attachment_"] { // WordPress can set an explicit inline width on attachment elements wrapping full size images
    display: block;
    height: auto;
    max-width: 100%;
    width: 100%;
    //margin: 0 auto;
}

.blog-post img.alignright {
    display: inline-block;
    float: right;
    margin: 0 0 3em 3em;
}

.blog-post img.alignleft {
    display: inline-block;
    float: left;
    margin: 0 3em 3em 0;
}

.blogPostIntro {
    margin-top: $v * 3;
    font-family: $text-font;

    &, & p {
        font-size: $v * 1.8;
        line-height: $v * 2.8;
        @media (#{$medium-bp}) {
            font-size: $v * 2;
            line-height: $v * 3.4;
        }
    }
}
.blog-post p > img,
.blog-post figure > img {
    margin-top: $v;
}

.blog-author {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.blog-post p.blog-author {
    margin-bottom: 0;
}

.PostsSidebar {
    @media (#{$small-bp}) {
        display: none;
    }
    position: sticky;
    top: 140px;
    list-style: none;
    padding: 0;
    margin: 0;
    h5 {
        margin-bottom: $v;
        color: $charcoal-1;
    }
    ul {
        list-style: none;
        padding: 0;
    }

    .PostsSidebar-title {
        font-size: $v * 1.4;
        color: #384752;
        line-height: 2rem;
        font-size: 1.4rem;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
    .col-md-8 {
        @media (#{$medium-bp}) {
            padding-left: 0;
        }
    }
}

.article-body {
    @media (#{$medium-bp}) {
        display: flex;
        width: 100%;
    }
}

.PostsSidebar-shareList {
    li {
        display: inline-block;
        line-height: $v * 2;
    }
}
.share-linkedin a,
.share-email a,
.share-twitter a,
.share-facebook-square a {
    background-color: $charcoal-0;
    &:hover {
        background-color: $charcoal-1;
    }
}

.PostsSidebar-shareList--relatedMeta {
    .share-linkedin a,
    .share-email a,
    .share-twitter a,
    .share-facebook-square a {
        background-color: $charcoal-4;
        span {
            color: $charcoal-0;
        }
        &:hover {
            background-color: $charcoal-0;
            span {
                color: $white;
            }
        }
    }
}

.blog-post .related-meta-title {
    color: $charcoal-3;
    margin-bottom: math.div($v, 2);
    margin-top: 0;
}

.sharebutton a {
    width: 30px;
    height: 30px;
    display: inline-block;
    border-radius: 50%;
    padding: math.div($v, 2);
    margin-right: math.div($v, 2);
}
.sharebutton span {
    position: relative;
    bottom: 1px;
    font-size: 16px;
}

.single-videos .related-links,
.related-video-links {
    padding-left: 0;
    list-style: none;
    li:before {
        content: url('/assets/images/svg-icons/ic-down.svg');
        transform: rotate(-90deg);
        display: inline-block;
        margin-left: -5px;
    }
    a {
        padding-left: $v;
        color: $charcoal-0;
        &:hover {
            color: $coral-hover;
        }
    }
}

.single-videos .post-tags {
    color: $charcoal-1;
}

#RelatedVideos {
    padding-bottom: $v * 6;
    .slick-next,
    .slick-prev {
        bottom: 65%;
    }
    .slick-prev {
        left: -24px;
    }
    .slick-next {
        right: -24px;
    }
}

.single-videos .page-wrapper {
    @media (#{$small-bp}) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.video-count {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-family: Lato, 'Sans-serif';
    text-transform: none;
    letter-spacing: 0;
    font-weight: 400;
    color: $charcoal-0;
}

.text-center.blog-author {
    justify-content: center;
}

.ReadMoreFromCategory-container {
    padding: $v * 3 $v * 1.5;
    overflow: hidden;
    border-radius: 7px;
    @media (#{$medium-bp}) {
        display: flex;
        align-items: center;
        > div {
            width: 50%;
            flex-shrink: 0;
            flex-grow: 0;
            padding: 0 $v * 1.5;
        }
    }
}

.ReadMoreFromCategory-content {
    h3 {
        margin-top: 0;
        margin-bottom: 0;
        color: $charcoal;
    }

    p {
        font-size: $v * 1.6;
        line-height: $v * 2.4;
        color: $charcoal;
        margin: $v 0 $v * 2;
    }
}

.ReadMoreFromCategory-image {
    @media (#{$small-bp}) {
        margin-top: $v * 3;
    }
}
.ReadMoreFromCategory.isDark {
    .ReadMoreFromCategory-content {
        h3,
        p {
            color: $white;
        }
    }
}

.FeaturedImage {
    position: absolute;
    top: 0;
}

img.size-full {
    max-width: 100%;
    margin-top: $v * 2;
    margin-bottom: $v * 2;
    height: auto;
}
.blogHeader {
    @media (#{$small-bp}) {
        background-image: url('/images/background-images/blog-banner.jpeg') !important;
    }
}

.blog-author-avatar img {
    border-radius: 50%;
}
.blog-author-details {
    padding-left: $v;
}
.wp-video-shortcode,
.wp-video {
    max-width: 100%;
    height: auto;
}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
}
.pagination > li {
    display: inline;
}
.pagination > li > a,
.pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #00b3f0;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    margin-left: -1px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
    margin-left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}
.pagination > li > a:hover,
.pagination > li > span:hover {
    z-index: 2;
    color: $white;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
    color: #777777;
    background-color: #ffffff;
    border-color: #dddddd;
    cursor: not-allowed;
}
.pagination-lg > li > a,
.pagination-lg > li > span {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
}
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
}
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
}
.pagination-sm > li > a,
.pagination-sm > li > span {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
}
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}

.page-number {
    &:hover,
    &.current {
        color: $white;
    }
}
.video-wrapper {
    width: 100%;
    height: 0px;
    position: relative;
    padding-bottom: 56.2493%;
    overflow: hidden;
    margin-bottom: 2em;
}
.video-wrapper iframe,
.video-wrapper embed {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.blog__related-posts {
    margin-top: $v * 6;
    border-top: 1px solid $border-grey;
    padding-top: $v * 3;

    @media (#{$medium-bp}) {
        margin-top: $v * 9;
    }
}

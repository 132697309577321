.quotes-animated__container-fluid {
    width: 100%;
    height: 210px;
    overflow: hidden;
    position: relative;
    padding: 0;
    margin: 0;
}

.quotes-animated__container-fluid .row{
    justify-content: center;
}

// Quote box
.quotes-animated__quote-box {
    box-shadow: 8px 8px $spa;
    border: 1px solid $charcoal;
    background-color: $white;
    border-radius: 3px;
    padding: 1.6rem;
    margin: 0 2rem;
    height: auto;
    max-width: 450px;
    min-height: 150px;
}

.quotes-animated__quote-box-upper {
    display: flex;
    margin-bottom: 1.6rem;
}

.quotes-animated__img-holder {
    margin-right: 1.4rem;
}

.quotes-animated__img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 40px;
}

.quotes-animated__name-holder {
    display: grid;
    align-items: center;
}

.quotes-animated__quote {
    @extend %p;
    font-size: 1.7rem;
    line-height: 2.5rem;
}

.quotes-animated__left,
.quotes-animated__right {
    width: 120%;
    height: auto;
    overflow: hidden;
}

.quotes-animated__left {
    position: absolute;
    left: -50px;
    margin-left: -50px;
}

.quotes-animated__right {
    position: absolute;
    right: -50px;
    margin-right: -50px;
}

// Spacer left for testing
.quotes-animated__big_spacer {
    position: relative;
    width: auto;
    height: 600px;
}

// Media queires
// extra wide - 1920 wide
@media ($xxlarge-bp) {
    .quotes-animated__quote-box {
        width: 450px;
    }

    .quotes-animated__left {
        left: -100px;
        margin-left: -100px;
    }

    .quotes-animated__right {
        right: -100px;
        margin-right: -100px;
    }
}

// desktop
@media (min-width: 1200px) and (max-width: 1919px) {
    .quotes-animated__container-fluid {
        height: 230px;
    }

    .quotes-animated__quote-box {
        width: 400px;
    }

    .quotes-animated__left {
        left: -125px;
        margin-left: -125px;
    }

    .quotes-animated__right {
        right: -125px;
        margin-right: -125px;
    }
}

// hardly used
@media (min-width: 768px) and (max-width: 1199.98px) {
    .quotes-animated__container-fluid {
        height: 214px;
    }

    .quotes-animated__quote-box {
        width: auto;
        min-width: 200px;
    }

    .quotes-animated__quote {
        font-size: 1.5rem;
        line-height: 2.3rem;
    }

    .quotes-animated__left, .quotes-animated__right {
        width: 150%;
    }

    .quotes-animated__left {
        left: -100px;
        margin-left: -100px;
    }

    .quotes-animated__right {
        right: -100px;
        margin-right: -100px;
    }
}

// Mobile
@media ($small-bp) {
    .quotes-animated__container-fluid {
        height: 230px;
    }

    .quotes-animated__left, .quotes-animated__right {
        width: 190%;
    }

    .quotes-animated__left {
        left: -130px;
        margin-left: -130px;
    }

    .quotes-animated__right {
        right: -130px;
        margin-right: -130px;
    }

    .quotes-animated__quote-box {
        width: 270px;
        margin: 0 1.5rem;
    }

    .quotes-animated__quote {
        font-size: 1.5rem;
        line-height: 2.3rem;
    }

}
// Mobile small
@media (max-width: 576px) {
    .quotes-animated__left, .quotes-animated__right {
        width: 300%;
    }

    .quotes-animated__container-fluid {
        height: 280px;
    }

    .quotes-animated__quote-box {
        width: auto;
        min-width: 200px;
    }
}

.page-the-source,
.ResourceHub {
  background-color: $cream-0;
  h1 {
    font-family: $domaindisplay;
    font-size: $v * 4;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: $v * 4.8;
    margin-top: 0;
    margin-bottom: $v * 6;
    @media (#{$medium-bp}) {
      font-size: $v * 7;
      letter-spacing: -1.75px;
      line-height: $v * 7.5;
    }
  }

  h2 {
    font-family: $domaindisplay;
    font-size: $v * 3;
    font-weight: 400;
    letter-spacing: -0.75px;
    line-height: $v * 4;
    margin-top: $v * 6;
    margin-bottom: $v * 2;
    @media (#{$medium-bp}) {
      margin-top: $v * 9;
      font-size: $v * 4;
      letter-spacing: -1px;
      line-height: $v * 4.8;
    }
  }

  h3 {
    font-family: $domaindisplay;
    font-size: $v * 2;
    font-weight: 400;
    letter-spacing: -0.5px;
    line-height: $v * 2.5;
    margin-top: $v * 4;
    margin-bottom: $v * 2;

    @media (#{$medium-bp}) {
      font-size: $v * 3;
      letter-spacing: -0.75px;
      line-height: $v * 3.2;
    }
  }

  h4 {
    font-family: $domaindisplay;
    font-size: $v * 2;
    font-weight: 400;
    letter-spacing: -0.5px;
    line-height: $v * 2.5;
    text-transform: none;
    margin-top: $v * 3;

    @media (#{$medium-bp}) {
      font-size: $v * 2;
      letter-spacing: -0.5px;
      line-height: $v * 2.5;
    }
  }

  h5 {
    font-family: Lato;
    font-size: $v * 1.4;
    font-weight: bold;
    letter-spacing: 2.2px;
    line-height: $v * 2.1;
  }

  p,
  h1,
  h2,
  h3,
  h4 {
    a {
      color: inherit;
      text-decoration: underline;
      text-underline-position: under;
      text-decoration-color: $charcoal-3;
      transition: all .2s ease-in-out;
    }
  }

  h5 {
    a {
      color: $coral-2;
    }
  }

  p {
    font-size: $v * 1.8;
    margin-bottom: $v * 2;

    a:hover {
      color: $hot-coral;
      text-decoration-color: $hot-coral;
    }
  }

  .alignwide {
    margin-right: 0;
    margin-left: -$v * 1.5;
    width: 100vw;
    max-width: 100vw;

    @media (#{$medium-bp}) {
      margin-left: -$v * 3;
    }

    @media (#{$large-bp}) {
      margin-right: 0;
      margin-left: -13%;
      width: 126%;
    }
  }

  figcaption {
    text-align: right;
  }

  .is-dark {
    p,
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: $white;
      text-shadow: 0 1px 2px rgba(0,0,0,0.6);
    }
    p {
      a {
        color: $dusk-0;
        transition: all .2s ease-in-out;

        &:hover {
          color: $dusk-2;
        }
      }

    }
  }

  .Article-fancy-title {
    margin-top: $v;
    margin-bottom: 0;
  }
}


.category {
  .icon-bar {
    background-color: $white;
  }
  .is-open .icon-bar {
    background-color: $charcoal;
  }
}

.HubFooter {
  clear: both;

  @media (#{$small-bp}) {
    padding-bottom: $v * 7;
  }
}

.HubFooter-col {
  @media (#{$small-bp}) {
    text-align: center;
    padding-bottom: $v * 3;
    .Article-socialIcon {
      justify-content: center;
    }
  }
  .Article-widget {
    margin-bottom: $v * 2;

  }
}

.wp-block-gallery {
  width: 100%;
  display: block;
  list-style: none;
  margin-top: $v * 6;
  margin-bottom: $v * 6;

  .blocks-gallery-item {
    margin-bottom: 0;
    padding: 0;
    width: calc((100% - 3rem) / 2);
    margin-right: 3rem;

    &::before {
      display: none;
    }

    @media (#{$small-bp}) {
      width: 100%;
      padding-left: 0;
    }
  }

  @media (#{$medium-bp}) {
    display: flex;
    float: right;
  }

  @media (#{$large-bp}) {
    margin-left: $v * 4;
    margin-top: $v * 2.5;
    margin-bottom: $v * 2.5;
    margin-right: -55%;
  }

  @media (#{$xlarge-bp}) {
    width: calc(1200px * .5 - 4rem);
  }

  @media (#{$xxlarge-bp}) {
    width: calc(1800px * .5 - 7.5rem);
  }
}

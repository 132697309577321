/// Hide an element from view, while still allowing it to be seen be screen readers
@mixin visually-hidden {
	position: absolute;
	clip: rect(0 0 0 0);
	overflow: hidden;
	width: 1px;
	height: 1px;
	padding: 0;
	border: 0;
	margin: -1px;
}

.ConsultSignoff {
  margin-top: $v * 12;
  text-align: center;

  @media (#{$medium-bp}) {
    margin-top: $v * 15;
  }

  span {
    display: block;
    margin: $v auto;
    font-weight: 400;

    @media (#{$medium-bp}) {
      display: inline-block;
    }
  }
  svg {
    margin-left: auto;
    margin-right: auto;
    @media (#{$medium-bp}) {
      margin-left: $v * 1.5;
      margin-right: $v * 1.5;
    }
  }
}
.gated-content__title {
    color: $charcoal-0;
}

.gated-content__CharcoalLink {
  @extend .CharcoalLink;
   transition: transform 0.3s ease;
}

.gated-content__back_icon {
  @extend .CharcoalLink--back;
   margin-left: -8px;
   transition: transform 0.3s ease;
}

.gated-content__back_icon::before{
  top: 0;
  transition: transform 0.3s ease;
}

.gated-content__popup_container {
    position: absolute;
    top: 6rem;
    left: 50%;
    transform: translate(-50%, 0%);
    margin-bottom: 3rem;
    height: auto;
    width: 60%;
    max-width: 768px;
    background-color: $white;
    border-radius: 9px;
    padding: 20px 5rem 10px 5rem;
    transition: all 0.3s ease-in-out;
    margin-bottom: 6rem;

    @media (#{$small-bp}) {
        width: 96%;
        padding: 15px 2rem 10px 2rem;
    }
}

.gated-content__popup_container_inner {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.gated-content__align_center {
    text-align: center;
    padding: 5rem 0 5rem 0;
}

.gated-content__pb {
    padding-bottom: 2rem;
}

.gated-content__popup_heading {
    text-align: center;
    padding-top: 20px;
}

.gated-content__popup_h2 {
    margin-bottom: 10px;
}

.gated-content__image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
}

.gated-content__imageLink {
    display: block;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: none;
    padding: 0;
}

.gated-content__video_holder {
    padding-top: 2rem;
}

.gated-content__py-4 {
    padding: 3rem 0 3rem 0;
}

// Vimeo iframe embed 
.gated-content__figure {
	margin: 0;
	aspect-ratio: 16 / 9;
	position: relative;
}

.gated-content__embed {
	position: absolute;
	width: 100%;
	height: 100%;
}
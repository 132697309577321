
.ImageCloud {
  position: relative;
  display: none;
  @media (#{$large-bp}) {
    display: block;
  }
}

.ImageCloud-firstRow {
  display: flex;
  align-items: flex-end;
}

.ImageCloud-secondRow {
  margin-top: $v * 3;
}

.ImageCloud-image {
  padding: 0 $v * 1.5;
  float: left;
}

.ImageCloud-container {
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  max-width: 970px;
  width: 100%;

  @media (#{$medium-bp}) {
    width: 100%;
  }

  @media (#{$large-bp}) {
    width: 110%;
    max-width: 110%;
    margin-left: -5%;
  }

  // @media (#{$xlarge-bp}) {
  //   width: 1170px;
  //   max-width: 1170px;
  // }
  @media (#{$xxlarge-bp}) {
    max-width: 1800px;
    width: 1800px;
    margin-left: auto;
    margin-right: auto;
    padding-left: $v * 1.5;
    padding-right: $v * 1.5;
  }
}
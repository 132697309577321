.QuoteBlock {
  @extend .u-vSpacingXL-top;
}

.QuoteBlock-container {
  position: relative;
}

.QuoteBlock-content {
  text-align: center;
  margin-top: $v * 4.5;

  @media (#{$large-bp}) {
    margin-top: 0;
    text-align: left;
    position: absolute;
    right: 0;
    top: 20%;
    z-index: 1;
  }
}

.QuoteBlock-parallax {
  max-width: 100%;
  @media (#{$medium-bp-only}) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.QuoteBlock-author {
  @extend %h5;
  margin-top: $v * 3;
  @media (#{$large-bp}) {
    margin-left: 25%;
  }
}

.QuoteBlock--right {
  .QuoteBlock-author {
    margin-left: 0;
  }
    .QuoteBlock-content {
      right: initial;
      left: 0;
    }

    .QuoteBlock-parallax {
      @media (#{$large-bp}) {
        float: right;
      }
    }
}


@import '_blocks';
@import '_grid';
@import '_article';
@import '_resource-hub';
// @import '_navbar';
@import '_webinar';
// @import '_registration';

.page-template-gutenberg-template {
    .page-wrapper > p {
        @extend .container;
        @media (#{$small-bp}) {
            margin-left: auto;
            margin-right: auto;
        }
        @media (#{$medium-bp-only}) {
            margin-left: $v * 1.5;
            margin-right: $v * 1.5;
        }
        @media (#{$large-bp}) {
            padding-left: $v * 3;
            padding-right: $v * 3;
        }
    }
}

.u-16x9 {
    display: block;
    position: relative;
    padding-bottom: 56.2%;
    iframe,
    img {
        position: absolute;
        // object-fit: cover;
        width: 100%;
        height: 100%;
        border: 0;
    }
}
.u-16x10 {
    display: block;
    position: relative;
    padding-bottom: 59.7%;
    iframe,
    img {
        position: absolute;
        // object-fit: cover;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

.wp-block-image {
    margin-top: $v * 6;
    margin-bottom: $v * 6;
}

// $enable-flex: true;
// $enable-grid-classes: true;
// @import "bootstrap/scss/variables";
// @import "bootstrap/scss/mixins";

// @import "bootstrap/scss/grid";
// @import "bootstrap/scss/bootstrap-grid";

// professional beauty speakers pages mobile lead capture fix
.AskQuestion.u-pTB2 {
    @media (#{$small-bp}) {
        background-color: #fff;
    }
}

// Stolen from https://csswizardry.com/2013/05/the-Flag-object/
// Usage
// <div class="Flag">
//     <div class="Flag-image">
//         <img src="" alt="">
//     </div>
//     <div class="Flag-body">
//         <p></p>
//     </div>
// </div>
.Flag {
    display: flex;
    width: 100%;
    .avatar {
        height: auto;
    }
}

.Flag-image,
.Flag-body {
    vertical-align: middle;
}

.Flag-image {
    padding-right: 1.5rem;
    > img {
        display: block;
        width: 60px;
        height: auto !important;
    }
    .Flag--rev & {
        padding-right: 0;
        padding-left: 10px;
    }
}

.Flag-body {
    width: 100%;
    p {
        margin: 0;
        line-height: 1.8rem;
    }
}

.Endorsement .Flag,
.new-quote .Flag,
.quote01 .Flag {
    justify-content: center;
    align-items: center;
    .Flag-title {
        margin-bottom: math.div($v, 4);
        font-weight: 700;
        text-transform: none;
    }
    .Flag-body {
        width: auto;
        text-align: left;
    }
}

.Flag.Flag--author {
    align-items: center;
    font-family: $domaindisplay;
    font-size: $v * 1.8;
    font-weight: 600;
    letter-spacing: -0.45px;
    line-height: $v * 2;
    margin-bottom: $v * 3;

    .Flag-description {
        color: $charcoal-2;
        margin-top: $v * 0.25;
    }
}

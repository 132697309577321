.InlineImages {
    padding-top: $v * 1.5;
    padding-bottom: $v * 1.5;
    @media (#{$xxlarge-bp}) {
        padding-top: $v * 3;
    }
}

.InlineImage {
    text-align: center;
    position: relative;
    height: 60px;

    img {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 70%;
        left: 50%;
    }

    @media (#{$medium-bp}) {
        height: 100px;
    }

    @media (#{$large-bp}) {
        img {
            max-width: 90%;
        }
    }
    @media (#{$xxlarge-bp}) {
        img {
            max-width: 80%;
        }
    }
}

.InlineImages-container {
    @extend %container;
    padding-left: 0;
    padding-right: 0;

    // @media (#{$medium-bp}) {
    //   width: 100%;
    // }

    @media (#{$large-bp}) {
        display: flex;
        justify-content: center;
    }
}

.InlineImages-title {
    margin-top: $v;
    margin-bottom: $v * 2;
    text-align: center;
}

.InlineImages--center {
    .InlineImage {
        float: none;
        margin-left: auto;
        margin-right: auto;
    }
}

.wp-block-table {
	$table-inline-padding: 2 * $h;

	margin: 0;

	table {
		border: 1px solid $border-grey;
		border-radius: 5px;
		overflow: hidden;

		border-collapse: separate;
		border-spacing: 0;
		width: 100%;
	}

	thead {
		background: $dusk-4;
		font-weight: bold;
		text-align: left;
	}

	tbody {
		tr:nth-child(n+2) td {
			position: relative;
			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 0;
				border-top: 1px solid $border-grey;
			}
			&:first-child::before {
				left: $table-inline-padding;
			}
			&:last-child::before {
				right: $table-inline-padding;
			}
		}
	}

	th, td {
		padding: (2 * $v) (1 * $h);

		&:first-child {
			padding-left: $table-inline-padding;
		}
		&:last-child {
			padding-right: $table-inline-padding;
		}
	}
}

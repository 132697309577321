.mdc-menu-surface--anchor {
    position: relative;
    overflow: visible;
}
.mdc-menu-surface--fixed {
    position: fixed;
}
.mdc-menu {
    min-width: 112px;
}
.mdc-menu .mdc-list,
.mdc-menu .mdc-list-item__graphic,
.mdc-menu .mdc-list-item__meta {
    color: rgba(0, 0, 0, 0.87);
}
.mdc-menu .mdc-list-divider {
    margin: 8px 0;
}
.mdc-menu .mdc-list-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.mdc-menu .mdc-list-item--disabled {
    cursor: auto;
}
.mdc-menu a.mdc-list-item .mdc-list-item__graphic,
.mdc-menu a.mdc-list-item .mdc-list-item__text {
    pointer-events: none;
}
.mdc-menu__selection-group {
    padding: 0;
    fill: currentColor;
}
.mdc-menu__selection-group .mdc-list-item {
    padding-left: 56px;
    padding-right: 16px;
}
.mdc-menu__selection-group .mdc-list-item[dir='rtl'],
[dir='rtl'] .mdc-menu__selection-group .mdc-list-item {
    padding-left: 16px;
    padding-right: 56px;
}
.mdc-menu__selection-group .mdc-menu__selection-group-icon {
    left: 16px;
    right: auto;
    display: none;
    position: absolute;
}
.mdc-menu__selection-group .mdc-menu__selection-group-icon[dir='rtl'],
[dir='rtl'] .mdc-menu__selection-group .mdc-menu__selection-group-icon {
    left: auto;
    right: 16px;
}
.mdc-menu-item--selected .mdc-menu__selection-group-icon {
    display: inline;
}
@-webkit-keyframes mdc-select-float-native-control {
    0% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
        opacity: 0;
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes mdc-select-float-native-control {
    0% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
        opacity: 0;
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}
.mdc-select--with-leading-icon:not(.mdc-select--disabled) .mdc-select__icon {
    color: #000;
    color: var(--mdc-theme-on-surface, #000);
}
.mdc-select--with-leading-icon .mdc-select__icon {
    display: inline-block;
    position: absolute;
    bottom: 16px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    border: none;
    background-color: transparent;
    fill: currentColor;
    opacity: 0.54;
    text-decoration: none;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.mdc-select__icon:not([tabindex]),
.mdc-select__icon[tabindex='-1'] {
    cursor: default;
    pointer-events: none;
}
.mdc-select-helper-text {
    font-family: Lato, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.03333em;
    text-decoration: inherit;
    text-transform: inherit;
    display: block;
    line-height: normal;
    margin: 0;
    -webkit-transition: opacity 0.18s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 0.18s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    will-change: opacity;
}
.mdc-select-helper-text:before {
    display: inline-block;
    width: 0;
    height: 16px;
    content: '';
    vertical-align: 0;
}
.mdc-select-helper-text--persistent {
    -webkit-transition: none;
    transition: none;
    opacity: 1;
    will-change: auto;
}
.mdc-select {
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 56px;
    overflow: hidden;
    will-change: opacity, transform, color;
}
.mdc-select:not(.mdc-select--disabled) {
    background-color: #f5f5f5;
}
.mdc-select:after,
.mdc-select:before {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: '';
}
.mdc-select:before {
    -webkit-transition: opacity 15ms linear, background-color 15ms linear;
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1;
}
.mdc-select.mdc-ripple-upgraded:before {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
    transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-select.mdc-ripple-upgraded:after {
    top: 0;
    left: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: center center;
    transform-origin: center center;
}
.mdc-select.mdc-ripple-upgraded--unbounded:after {
    top: 0;
    top: var(--mdc-ripple-top, 0);
    left: 0;
    left: var(--mdc-ripple-left, 0);
}
.mdc-select.mdc-ripple-upgraded--foreground-activation:after {
    -webkit-animation: mdc-ripple-fg-radius-in 225ms forwards,
        mdc-ripple-fg-opacity-in 75ms forwards;
    animation: mdc-ripple-fg-radius-in 225ms forwards,
        mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-select.mdc-ripple-upgraded--foreground-deactivation:after {
    -webkit-animation: mdc-ripple-fg-opacity-out 0.15s;
    animation: mdc-ripple-fg-opacity-out 0.15s;
    -webkit-transform: translate(0) scale(1);
    transform: translate(0) scale(1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0))
        scale(var(--mdc-ripple-fg-scale, 1));
    transform: translate(var(--mdc-ripple-fg-translate-end, 0))
        scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-select:after,
.mdc-select:before {
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
}
.mdc-select.mdc-ripple-upgraded:after {
    width: 100%;
    width: var(--mdc-ripple-fg-size, 100%);
    height: 100%;
    height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-select:after,
.mdc-select:before {
    background-color: rgba(0, 0, 0, 0.87);
}
.mdc-select:hover:before {
    opacity: 0.04;
}
.mdc-select.mdc-ripple-upgraded--background-focused:before,
.mdc-select:not(.mdc-ripple-upgraded):focus:before {
    -webkit-transition-duration: 75ms;
    transition-duration: 75ms;
    opacity: 0.12;
}
.mdc-select:not(.mdc-select--disabled) .mdc-select__native-control,
.mdc-select:not(.mdc-select--disabled) .mdc-select__selected-text {
    color: rgba(0, 0, 0, 0.87);
}
.mdc-select:not(.mdc-select--disabled) .mdc-floating-label {
    color: rgba(0, 0, 0, 0.6);
}
.mdc-select:not(.mdc-select--disabled) .mdc-select__native-control,
.mdc-select:not(.mdc-select--disabled) .mdc-select__selected-text {
    border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-select:not(.mdc-select--disabled) + .mdc-select-helper-text {
    color: rgba(0, 0, 0, 0.6);
}
.mdc-select,
.mdc-select__native-control {
    border-radius: 4px 4px 0 0;
}
.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-line-ripple {
    background-color: #889095;
    background-color: var(--mdc-theme-primary, #889095);
}
.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
    color: rgba(136, 144, 149, 0.87);
}
.mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:hover {
    border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-select .mdc-floating-label--float-above {
    -webkit-transform: translateY(-70%) scale(0.75);
    transform: translateY(-70%) scale(0.75);
}
.mdc-select .mdc-floating-label {
    left: 16px;
    right: auto;
    top: 21px;
    pointer-events: none;
}
.mdc-select .mdc-floating-label[dir='rtl'],
[dir='rtl'] .mdc-select .mdc-floating-label {
    left: auto;
    right: 16px;
}
.mdc-select.mdc-select--with-leading-icon .mdc-floating-label {
    left: 48px;
    right: auto;
}
.mdc-select.mdc-select--with-leading-icon .mdc-floating-label[dir='rtl'],
[dir='rtl'] .mdc-select.mdc-select--with-leading-icon .mdc-floating-label {
    left: auto;
    right: 48px;
}
.mdc-select.mdc-select--outlined .mdc-floating-label {
    left: 4px;
    right: auto;
    top: 17px;
}
.mdc-select.mdc-select--outlined .mdc-floating-label[dir='rtl'],
[dir='rtl'] .mdc-select.mdc-select--outlined .mdc-floating-label {
    left: auto;
    right: 4px;
}
.mdc-select.mdc-select--outlined.mdc-select--with-leading-icon
    .mdc-floating-label {
    left: 36px;
    right: auto;
}
.mdc-select.mdc-select--outlined.mdc-select--with-leading-icon
    .mdc-floating-label[dir='rtl'],
[dir='rtl']
    .mdc-select.mdc-select--outlined.mdc-select--with-leading-icon
    .mdc-floating-label {
    left: auto;
    right: 36px;
}
.mdc-select.mdc-select--outlined.mdc-select--with-leading-icon
    .mdc-floating-label--float-above {
    left: 36px;
    right: auto;
}
.mdc-select.mdc-select--outlined.mdc-select--with-leading-icon
    .mdc-floating-label--float-above[dir='rtl'],
[dir='rtl']
    .mdc-select.mdc-select--outlined.mdc-select--with-leading-icon
    .mdc-floating-label--float-above {
    left: auto;
    right: 36px;
}
.mdc-select__dropdown-icon {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='5' viewBox='7 10 10 5' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' opacity='.54' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E")
        no-repeat 50%;
    left: auto;
    right: 8px;
    position: absolute;
    bottom: 16px;
    width: 24px;
    height: 24px;
    -webkit-transition: -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    transition: -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1),
        -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    pointer-events: none;
}
.mdc-select__dropdown-icon[dir='rtl'],
[dir='rtl'] .mdc-select__dropdown-icon {
    left: 8px;
    right: auto;
}
.mdc-select--focused .mdc-select__dropdown-icon {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='5' viewBox='7 10 10 5' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23889095' fill-rule='evenodd' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E")
        no-repeat 50%;
    -webkit-transform: rotate(180deg) translateY(-5px);
    transform: rotate(180deg) translateY(-5px);
    -webkit-transition: -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    transition: -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1),
        -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-select__native-control {
    padding-top: 20px;
}
.mdc-select.mdc-select--focused .mdc-line-ripple:after {
    -webkit-transform: scaleY(2);
    transform: scaleY(2);
    opacity: 1;
}
.mdc-select + .mdc-select-helper-text {
    margin-right: 12px;
    margin-left: 12px;
}
.mdc-select--outlined + .mdc-select-helper-text {
    margin-right: 16px;
    margin-left: 16px;
}
.mdc-select--focused
    + .mdc-select-helper-text:not(.mdc-select-helper-text--validation-msg) {
    opacity: 1;
}
.mdc-select__selected-text {
    min-width: 200px;
    padding-top: 22px;
}
.mdc-select__native-control,
.mdc-select__selected-text {
    font-family: Lato, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: 0.00937em;
    text-decoration: inherit;
    text-transform: inherit;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 56px;
    padding: 20px 52px 4px 16px;
    border: none;
    border-bottom: 1px solid;
    outline: none;
    background-color: transparent;
    color: inherit;
    white-space: nowrap;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.mdc-select__native-control[dir='rtl'],
.mdc-select__selected-text[dir='rtl'],
[dir='rtl'] .mdc-select__native-control,
[dir='rtl'] .mdc-select__selected-text {
    padding-left: 52px;
    padding-right: 16px;
}
.mdc-select__native-control::-ms-expand,
.mdc-select__selected-text::-ms-expand {
    display: none;
}
.mdc-select__native-control::-ms-value,
.mdc-select__selected-text::-ms-value {
    background-color: transparent;
    color: inherit;
}
@-moz-document url-prefix("") {
    .mdc-select__native-control,
    .mdc-select__selected-text {
        text-indent: -2px;
    }
}
.mdc-select--outlined {
    border: none;
    overflow: visible;
}
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled)
    .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.24);
}
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused)
    .mdc-select__native-control:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused)
    .mdc-select__native-control:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused)
    .mdc-select__native-control:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__trailing,
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused)
    .mdc-select__selected-text:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused)
    .mdc-select__selected-text:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused)
    .mdc-select__selected-text:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.87);
}
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused
    .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused
    .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-width: 2px;
}
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused
    .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused
    .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-color: #889095;
    border-color: var(--mdc-theme-primary, #889095);
}
.mdc-select--outlined .mdc-floating-label--shake {
    -webkit-animation: mdc-floating-label-shake-float-above-text-field-outlined
        0.25s 1;
    animation: mdc-floating-label-shake-float-above-text-field-outlined 0.25s 1;
}
.mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border-radius: 4px 0 0 4px;
}
.mdc-select--outlined
    .mdc-notched-outline
    .mdc-notched-outline__leading[dir='rtl'],
[dir='rtl']
    .mdc-select--outlined
    .mdc-notched-outline
    .mdc-notched-outline__leading {
    border-radius: 0 4px 4px 0;
}
.mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
    border-radius: 0 4px 4px 0;
}
.mdc-select--outlined
    .mdc-notched-outline
    .mdc-notched-outline__trailing[dir='rtl'],
[dir='rtl']
    .mdc-select--outlined
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-radius: 4px 0 0 4px;
}
.mdc-select--outlined .mdc-select__native-control {
    border-radius: 4px;
}
.mdc-select--outlined:after,
.mdc-select--outlined:before {
    content: none;
}
.mdc-select--outlined:not(.mdc-select--disabled) {
    background-color: transparent;
}
.mdc-select--outlined .mdc-floating-label--float-above {
    -webkit-transform: translateY(-144%) scale(1);
    transform: translateY(-144%) scale(1);
    font-size: 0.75rem;
}
.mdc-select--outlined.mdc-notched-outline--upgraded
    .mdc-floating-label--float-above,
.mdc-select--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    -webkit-transform: translateY(-130%) scale(0.75);
    transform: translateY(-130%) scale(0.75);
    font-size: 1rem;
}
.mdc-select--outlined .mdc-select__native-control,
.mdc-select--outlined .mdc-select__selected-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 12px 52px 12px 16px;
    border: none;
    background-color: transparent;
    z-index: 1;
}
.mdc-select--outlined .mdc-select__native-control[dir='rtl'],
.mdc-select--outlined .mdc-select__selected-text[dir='rtl'],
[dir='rtl'] .mdc-select--outlined .mdc-select__native-control,
[dir='rtl'] .mdc-select--outlined .mdc-select__selected-text {
    padding-left: 52px;
    padding-right: 16px;
}
.mdc-select--outlined .mdc-select__selected-text {
    padding-top: 14px;
}
.mdc-select--outlined .mdc-select__icon {
    z-index: 2;
}
.mdc-select--outlined .mdc-floating-label {
    line-height: 1.15rem;
    pointer-events: auto;
}
.mdc-select--invalid:not(.mdc-select--disabled) .mdc-floating-label {
    color: #b00020;
    color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid:not(.mdc-select--disabled) .mdc-select__native-control,
.mdc-select--invalid:not(.mdc-select--disabled) .mdc-select__selected-text {
    border-bottom-color: #b00020;
    border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused
    .mdc-line-ripple {
    background-color: #b00020;
    background-color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused
    .mdc-floating-label {
    color: #b00020;
}
.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--invalid
    + .mdc-select-helper-text--validation-msg {
    color: #b00020;
    color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid:not(.mdc-select--disabled)
    .mdc-select__native-control:hover {
    border-bottom-color: #b00020;
    border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled)
    .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled)
    .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled)
    .mdc-notched-outline__trailing {
    border-color: #b00020;
    border-color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused)
    .mdc-select__native-control:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused)
    .mdc-select__native-control:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused)
    .mdc-select__native-control:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__trailing,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused)
    .mdc-select__selected-text:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused)
    .mdc-select__selected-text:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused)
    .mdc-select__selected-text:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-color: #b00020;
    border-color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused
    .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused
    .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-width: 2px;
}
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused
    .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused
    .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-color: #b00020;
    border-color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid .mdc-select__dropdown-icon {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='5' viewBox='7 10 10 5' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23b00020' fill-rule='evenodd' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E")
        no-repeat 50%;
}
.mdc-select--invalid + .mdc-select-helper-text--validation-msg {
    opacity: 1;
}
.mdc-select--required .mdc-floating-label:after {
    content: '*';
}
.mdc-select--disabled {
    background-color: #fafafa;
    cursor: default;
    pointer-events: none;
}
.mdc-select--disabled .mdc-floating-label {
    color: rgba(0, 0, 0, 0.37);
}
.mdc-select--disabled .mdc-select__dropdown-icon {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='5' viewBox='7 10 10 5' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' opacity='.37' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E")
        no-repeat 50%;
}
.mdc-select--disabled .mdc-line-ripple {
    display: none;
}
.mdc-select--disabled .mdc-select__icon {
    color: rgba(0, 0, 0, 0.37);
}
.mdc-select--disabled .mdc-select__native-control,
.mdc-select--disabled .mdc-select__selected-text {
    color: rgba(0, 0, 0, 0.37);
    border-bottom-style: dotted;
}
.mdc-select--disabled .mdc-select__selected-text {
    pointer-events: none;
}
.mdc-select--disabled.mdc-select--outlined {
    background-color: transparent;
}
.mdc-select--disabled.mdc-select--outlined .mdc-select__native-control,
.mdc-select--disabled.mdc-select--outlined .mdc-select__selected-text {
    border-bottom-style: none;
}
.mdc-select--disabled.mdc-select--outlined .mdc-notched-outline__leading,
.mdc-select--disabled.mdc-select--outlined .mdc-notched-outline__notch,
.mdc-select--disabled.mdc-select--outlined .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.16);
}
.mdc-select--with-leading-icon .mdc-select__icon {
    left: 16px;
    right: auto;
}
.mdc-select--with-leading-icon .mdc-select__icon[dir='rtl'],
[dir='rtl'] .mdc-select--with-leading-icon .mdc-select__icon {
    left: auto;
    right: 16px;
}
.mdc-select--with-leading-icon .mdc-select__native-control,
.mdc-select--with-leading-icon .mdc-select__selected-text {
    padding-left: 48px;
    padding-right: 32px;
}
.mdc-select--with-leading-icon .mdc-select__native-control[dir='rtl'],
.mdc-select--with-leading-icon .mdc-select__selected-text[dir='rtl'],
[dir='rtl'] .mdc-select--with-leading-icon .mdc-select__native-control,
[dir='rtl'] .mdc-select--with-leading-icon .mdc-select__selected-text {
    padding-left: 32px;
    padding-right: 48px;
}
.mdc-select--with-leading-icon.mdc-select--outlined
    .mdc-floating-label--float-above {
    -webkit-transform: translateY(-144%) translateX(-32px) scale(1);
    transform: translateY(-144%) translateX(-32px) scale(1);
}
.mdc-select--with-leading-icon.mdc-select--outlined
    .mdc-floating-label--float-above[dir='rtl'],
[dir='rtl']
    .mdc-select--with-leading-icon.mdc-select--outlined
    .mdc-floating-label--float-above {
    -webkit-transform: translateY(-144%) translateX(32px) scale(1);
    transform: translateY(-144%) translateX(32px) scale(1);
}
.mdc-select--with-leading-icon.mdc-select--outlined
    .mdc-floating-label--float-above {
    font-size: 0.75rem;
}
.mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded
    .mdc-floating-label--float-above,
.mdc-select--with-leading-icon.mdc-select--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    -webkit-transform: translateY(-130%) translateX(-32px) scale(0.75);
    transform: translateY(-130%) translateX(-32px) scale(0.75);
}
.mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded
    .mdc-floating-label--float-above[dir='rtl'],
.mdc-select--with-leading-icon.mdc-select--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above[dir='rtl'],
[dir='rtl']
    .mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded
    .mdc-floating-label--float-above,
[dir='rtl']
    .mdc-select--with-leading-icon.mdc-select--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    -webkit-transform: translateY(-130%) translateX(32px) scale(0.75);
    transform: translateY(-130%) translateX(32px) scale(0.75);
}
.mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded
    .mdc-floating-label--float-above,
.mdc-select--with-leading-icon.mdc-select--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    font-size: 1rem;
}
.mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--shake {
    -webkit-animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon
        0.25s 1;
    animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon
        0.25s 1;
}
.mdc-select--with-leading-icon.mdc-select--outlined[dir='rtl']
    .mdc-floating-label--shake,
[dir='rtl']
    .mdc-select--with-leading-icon.mdc-select--outlined
    .mdc-floating-label--shake {
    -webkit-animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon-rtl
        0.25s 1;
    animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon-rtl
        0.25s 1;
}
.mdc-select--with-leading-icon.mdc-select__menu .mdc-list-item__text {
    padding-left: 32px;
    padding-right: 32px;
}
.mdc-select--with-leading-icon.mdc-select__menu .mdc-list-item__text[dir='rtl'],
[dir='rtl']
    .mdc-select--with-leading-icon.mdc-select__menu
    .mdc-list-item__text {
    padding-left: 32px;
    padding-right: 32px;
}
.mdc-select__menu .mdc-list .mdc-list-item--selected {
    color: #000;
    color: var(--mdc-theme-on-surface, #000);
}
.mdc-select__menu .mdc-list .mdc-list-item--selected:after,
.mdc-select__menu .mdc-list .mdc-list-item--selected:before {
    background-color: #000;
}
@supports not (-ms-ime-align: auto) {
    .mdc-select__menu .mdc-list .mdc-list-item--selected:after,
    .mdc-select__menu .mdc-list .mdc-list-item--selected:before {
        background-color: #000;
        background-color: var(--mdc-theme-on-surface, #000);
    }
}
.mdc-select__menu .mdc-list .mdc-list-item--selected:hover:before {
    opacity: 0.04;
}
.mdc-select__menu
    .mdc-list
    .mdc-list-item--selected.mdc-ripple-upgraded--background-focused:before,
.mdc-select__menu
    .mdc-list
    .mdc-list-item--selected:not(.mdc-ripple-upgraded):focus:before {
    -webkit-transition-duration: 75ms;
    transition-duration: 75ms;
    opacity: 0.12;
}
.mdc-select__menu
    .mdc-list
    .mdc-list-item--selected:not(.mdc-ripple-upgraded):after {
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}
.mdc-select__menu
    .mdc-list
    .mdc-list-item--selected:not(.mdc-ripple-upgraded):active:after {
    -webkit-transition-duration: 75ms;
    transition-duration: 75ms;
    opacity: 0.12;
}
.mdc-select__menu .mdc-list .mdc-list-item--selected.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.12;
}
@-webkit-keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon {
    0% {
        -webkit-transform: translateX(-32px) translateY(-130%) scale(0.75);
        transform: translateX(-32px) translateY(-130%) scale(0.75);
    }
    33% {
        -webkit-animation-timing-function: cubic-bezier(
            0.5,
            0,
            0.70173,
            0.49582
        );
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        -webkit-transform: translateX(calc(4% - 32px)) translateY(-130%)
            scale(0.75);
        transform: translateX(calc(4% - 32px)) translateY(-130%) scale(0.75);
    }
    66% {
        -webkit-animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        -webkit-transform: translateX(calc(-4% - 32px)) translateY(-130%)
            scale(0.75);
        transform: translateX(calc(-4% - 32px)) translateY(-130%) scale(0.75);
    }
    to {
        -webkit-transform: translateX(-32px) translateY(-130%) scale(0.75);
        transform: translateX(-32px) translateY(-130%) scale(0.75);
    }
}
@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon {
    0% {
        -webkit-transform: translateX(-32px) translateY(-130%) scale(0.75);
        transform: translateX(-32px) translateY(-130%) scale(0.75);
    }
    33% {
        -webkit-animation-timing-function: cubic-bezier(
            0.5,
            0,
            0.70173,
            0.49582
        );
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        -webkit-transform: translateX(calc(4% - 32px)) translateY(-130%)
            scale(0.75);
        transform: translateX(calc(4% - 32px)) translateY(-130%) scale(0.75);
    }
    66% {
        -webkit-animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        -webkit-transform: translateX(calc(-4% - 32px)) translateY(-130%)
            scale(0.75);
        transform: translateX(calc(-4% - 32px)) translateY(-130%) scale(0.75);
    }
    to {
        -webkit-transform: translateX(-32px) translateY(-130%) scale(0.75);
        transform: translateX(-32px) translateY(-130%) scale(0.75);
    }
}
@-webkit-keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon-rtl {
    0% {
        -webkit-transform: translateX(32px) translateY(-130%) scale(0.75);
        transform: translateX(32px) translateY(-130%) scale(0.75);
    }
    33% {
        -webkit-animation-timing-function: cubic-bezier(
            0.5,
            0,
            0.70173,
            0.49582
        );
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        -webkit-transform: translateX(calc(4% + 32px)) translateY(-130%)
            scale(0.75);
        transform: translateX(calc(4% + 32px)) translateY(-130%) scale(0.75);
    }
    66% {
        -webkit-animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        -webkit-transform: translateX(calc(-4% + 32px)) translateY(-130%)
            scale(0.75);
        transform: translateX(calc(-4% + 32px)) translateY(-130%) scale(0.75);
    }
    to {
        -webkit-transform: translateX(32px) translateY(-130%) scale(0.75);
        transform: translateX(32px) translateY(-130%) scale(0.75);
    }
}
@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon-rtl {
    0% {
        -webkit-transform: translateX(32px) translateY(-130%) scale(0.75);
        transform: translateX(32px) translateY(-130%) scale(0.75);
    }
    33% {
        -webkit-animation-timing-function: cubic-bezier(
            0.5,
            0,
            0.70173,
            0.49582
        );
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        -webkit-transform: translateX(calc(4% + 32px)) translateY(-130%)
            scale(0.75);
        transform: translateX(calc(4% + 32px)) translateY(-130%) scale(0.75);
    }
    66% {
        -webkit-animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        -webkit-transform: translateX(calc(-4% + 32px)) translateY(-130%)
            scale(0.75);
        transform: translateX(calc(-4% + 32px)) translateY(-130%) scale(0.75);
    }
    to {
        -webkit-transform: translateX(32px) translateY(-130%) scale(0.75);
        transform: translateX(32px) translateY(-130%) scale(0.75);
    }
}
.mdc-list {
    font-family: Lato, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: 0.00937em;
    text-decoration: inherit;
    text-transform: inherit;
    line-height: 1.5rem;
    margin: 0;
    padding: 8px 0;
    list-style-type: none;
    color: rgba(0, 0, 0, 0.87);
    color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mdc-list-item__secondary-text {
    color: rgba(0, 0, 0, 0.54);
    color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.mdc-list-item__graphic {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.38);
    color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.mdc-list-item__meta {
    color: rgba(0, 0, 0, 0.38);
    color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.mdc-list-group__subheader {
    color: rgba(0, 0, 0, 0.87);
    color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mdc-list--dense {
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 0.812rem;
}
.mdc-list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    height: 48px;
    padding: 0 16px;
    overflow: hidden;
}
.mdc-list-item:focus {
    outline: none;
}
.mdc-list-item--activated,
.mdc-list-item--activated .mdc-list-item__graphic,
.mdc-list-item--selected,
.mdc-list-item--selected .mdc-list-item__graphic {
    color: #889095;
    color: var(--mdc-theme-primary, #889095);
}
.mdc-list-item--disabled {
    color: rgba(0, 0, 0, 0.38);
    color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}
.mdc-list-item__graphic {
    margin-left: 0;
    margin-right: 32px;
    width: 24px;
    height: 24px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    fill: currentColor;
}
.mdc-list-item[dir='rtl'] .mdc-list-item__graphic,
[dir='rtl'] .mdc-list-item .mdc-list-item__graphic {
    margin-left: 32px;
    margin-right: 0;
}
.mdc-list .mdc-list-item__graphic {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}
.mdc-list-item__meta {
    margin-left: auto;
    margin-right: 0;
}
.mdc-list-item[dir='rtl'] .mdc-list-item__meta,
[dir='rtl'] .mdc-list-item .mdc-list-item__meta {
    margin-left: 0;
    margin-right: auto;
}
.mdc-list-item__text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.mdc-list-item__text[for] {
    pointer-events: none;
}
.mdc-list-item__primary-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 0;
    line-height: normal;
    margin-bottom: -20px;
    display: block;
}
.mdc-list-item__primary-text:before {
    display: inline-block;
    width: 0;
    height: 32px;
    content: '';
    vertical-align: 0;
}
.mdc-list-item__primary-text:after {
    display: inline-block;
    width: 0;
    height: 20px;
    content: '';
    vertical-align: -20px;
}
.mdc-list--dense .mdc-list-item__primary-text {
    display: block;
    margin-top: 0;
    line-height: normal;
    margin-bottom: -20px;
}
.mdc-list--dense .mdc-list-item__primary-text:before {
    display: inline-block;
    width: 0;
    height: 24px;
    content: '';
    vertical-align: 0;
}
.mdc-list--dense .mdc-list-item__primary-text:after {
    display: inline-block;
    width: 0;
    height: 20px;
    content: '';
    vertical-align: -20px;
}
.mdc-list-item__secondary-text {
    font-family: Lato, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.01786em;
    text-decoration: inherit;
    text-transform: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 0;
    line-height: normal;
    display: block;
}
.mdc-list-item__secondary-text:before {
    display: inline-block;
    width: 0;
    height: 20px;
    content: '';
    vertical-align: 0;
}
.mdc-list--dense .mdc-list-item__secondary-text {
    display: block;
    margin-top: 0;
    line-height: normal;
    font-size: inherit;
}
.mdc-list--dense .mdc-list-item__secondary-text:before {
    display: inline-block;
    width: 0;
    height: 20px;
    content: '';
    vertical-align: 0;
}
.mdc-list--dense .mdc-list-item {
    height: 40px;
}
.mdc-list--dense .mdc-list-item__graphic {
    margin-left: 0;
    margin-right: 36px;
    width: 20px;
    height: 20px;
}
.mdc-list-item[dir='rtl'] .mdc-list--dense .mdc-list-item__graphic,
[dir='rtl'] .mdc-list-item .mdc-list--dense .mdc-list-item__graphic {
    margin-left: 36px;
    margin-right: 0;
}
.mdc-list--avatar-list .mdc-list-item {
    height: 56px;
}
.mdc-list--avatar-list .mdc-list-item__graphic {
    margin-left: 0;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.mdc-list-item[dir='rtl'] .mdc-list--avatar-list .mdc-list-item__graphic,
[dir='rtl'] .mdc-list-item .mdc-list--avatar-list .mdc-list-item__graphic {
    margin-left: 16px;
    margin-right: 0;
}
.mdc-list--two-line .mdc-list-item__text {
    -ms-flex-item-align: start;
    align-self: flex-start;
}
.mdc-list--two-line .mdc-list-item {
    height: 72px;
}
.mdc-list--avatar-list.mdc-list--dense .mdc-list-item,
.mdc-list--two-line.mdc-list--dense .mdc-list-item {
    height: 60px;
}
.mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
    margin-left: 0;
    margin-right: 20px;
    width: 36px;
    height: 36px;
}
.mdc-list-item[dir='rtl']
    .mdc-list--avatar-list.mdc-list--dense
    .mdc-list-item__graphic,
[dir='rtl']
    .mdc-list-item
    .mdc-list--avatar-list.mdc-list--dense
    .mdc-list-item__graphic {
    margin-left: 20px;
    margin-right: 0;
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item {
    cursor: pointer;
}
a.mdc-list-item {
    color: inherit;
    text-decoration: none;
}
.mdc-list-divider {
    height: 0;
    margin: 0;
    border: none;
    border-bottom: 1px solid;
    border-bottom-color: rgba(0, 0, 0, 0.12);
}
.mdc-list-divider--padded {
    margin: 0 16px;
}
.mdc-list-divider--inset {
    margin-left: 72px;
    margin-right: 0;
    width: calc(100% - 72px);
}
.mdc-list-group[dir='rtl'] .mdc-list-divider--inset,
[dir='rtl'] .mdc-list-group .mdc-list-divider--inset {
    margin-left: 0;
    margin-right: 72px;
}
.mdc-list-divider--inset.mdc-list-divider--padded {
    width: calc(100% - 88px);
}
.mdc-list-group .mdc-list {
    padding: 0;
}
.mdc-list-group__subheader {
    font-family: Lato, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: 0.00937em;
    text-decoration: inherit;
    text-transform: inherit;
    margin: 0.75rem 16px;
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item {
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item:after,
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item:before {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: '';
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item:before {
    -webkit-transition: opacity 15ms linear, background-color 15ms linear;
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1;
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded:before {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
    transform: scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded:after {
    top: 0;
    left: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: center center;
    transform-origin: center center;
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--unbounded:after {
    top: 0;
    top: var(--mdc-ripple-top, 0);
    left: 0;
    left: var(--mdc-ripple-left, 0);
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-activation:after {
    -webkit-animation: mdc-ripple-fg-radius-in 225ms forwards,
        mdc-ripple-fg-opacity-in 75ms forwards;
    animation: mdc-ripple-fg-radius-in 225ms forwards,
        mdc-ripple-fg-opacity-in 75ms forwards;
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-deactivation:after {
    -webkit-animation: mdc-ripple-fg-opacity-out 0.15s;
    animation: mdc-ripple-fg-opacity-out 0.15s;
    -webkit-transform: translate(0) scale(1);
    transform: translate(0) scale(1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0))
        scale(var(--mdc-ripple-fg-scale, 1));
    transform: translate(var(--mdc-ripple-fg-translate-end, 0))
        scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item:after,
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item:before {
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded:after {
    width: 100%;
    width: var(--mdc-ripple-fg-size, 100%);
    height: 100%;
    height: var(--mdc-ripple-fg-size, 100%);
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item:after,
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item:before {
    background-color: #000;
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item:hover:before {
    opacity: 0.04;
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--background-focused:before,
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):focus:before {
    -webkit-transition-duration: 75ms;
    transition-duration: 75ms;
    opacity: 0.12;
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):after {
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):active:after {
    -webkit-transition-duration: 75ms;
    transition-duration: 75ms;
    opacity: 0.12;
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.12;
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item--activated:before {
    opacity: 0.12;
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item--activated:after,
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item--activated:before {
    background-color: #889095;
}
@supports not (-ms-ime-align: auto) {
    :not(.mdc-list--non-interactive)
        > :not(.mdc-list-item--disabled).mdc-list-item--activated:after,
    :not(.mdc-list--non-interactive)
        > :not(.mdc-list-item--disabled).mdc-list-item--activated:before {
        background-color: #889095;
        background-color: var(--mdc-theme-primary, #889095);
    }
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item--activated:hover:before {
    opacity: 0.16;
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded--background-focused:before,
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):focus:before {
    -webkit-transition-duration: 75ms;
    transition-duration: 75ms;
    opacity: 0.24;
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):after {
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):active:after {
    -webkit-transition-duration: 75ms;
    transition-duration: 75ms;
    opacity: 0.24;
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.24;
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item--selected:before {
    opacity: 0.08;
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item--selected:after,
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item--selected:before {
    background-color: #889095;
}
@supports not (-ms-ime-align: auto) {
    :not(.mdc-list--non-interactive)
        > :not(.mdc-list-item--disabled).mdc-list-item--selected:after,
    :not(.mdc-list--non-interactive)
        > :not(.mdc-list-item--disabled).mdc-list-item--selected:before {
        background-color: #889095;
        background-color: var(--mdc-theme-primary, #889095);
    }
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item--selected:hover:before {
    opacity: 0.12;
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded--background-focused:before,
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):focus:before {
    -webkit-transition-duration: 75ms;
    transition-duration: 75ms;
    opacity: 0.2;
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):after {
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):active:after {
    -webkit-transition-duration: 75ms;
    transition-duration: 75ms;
    opacity: 0.2;
}
:not(.mdc-list--non-interactive)
    > :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.2;
}
.mdc-select:not(.mdc-select--invalid):not(.mdc-select--disabled)
    .mdc-floating-label {
    color: #39464e;
}
.mdc-select:not(.mdc-select--invalid):not(.mdc-select--disabled)
    .mdc-notched-outline__leading,
.mdc-select:not(.mdc-select--invalid):not(.mdc-select--disabled)
    .mdc-notched-outline__notch,
.mdc-select:not(.mdc-select--invalid):not(.mdc-select--disabled)
    .mdc-notched-outline__trailing {
    border-color: #b0b5b8;
}
.mdc-select:not(.mdc-select--invalid):not(.mdc-select--disabled):not(.mdc-select--focused)
    .mdc-select__native-control:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-select:not(.mdc-select--invalid):not(.mdc-select--disabled):not(.mdc-select--focused)
    .mdc-select__native-control:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-select:not(.mdc-select--invalid):not(.mdc-select--disabled):not(.mdc-select--focused)
    .mdc-select__native-control:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__trailing,
.mdc-select:not(.mdc-select--invalid):not(.mdc-select--disabled):not(.mdc-select--focused)
    .mdc-select__selected-text:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-select:not(.mdc-select--invalid):not(.mdc-select--disabled):not(.mdc-select--focused)
    .mdc-select__selected-text:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-select:not(.mdc-select--invalid):not(.mdc-select--disabled):not(.mdc-select--focused)
    .mdc-select__selected-text:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-color: #39464e;
}
.mdc-select:not(.mdc-select--invalid):not(.mdc-select--disabled).mdc-select--focused
    .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-select:not(.mdc-select--invalid):not(.mdc-select--disabled).mdc-select--focused
    .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-select:not(.mdc-select--invalid):not(.mdc-select--disabled).mdc-select--focused
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-width: 2px;
}
.mdc-select:not(.mdc-select--invalid):not(.mdc-select--disabled).mdc-select--focused
    .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-select:not(.mdc-select--invalid):not(.mdc-select--disabled).mdc-select--focused
    .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-select:not(.mdc-select--invalid):not(.mdc-select--disabled).mdc-select--focused
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-color: #39464e;
}
.mdc-select__native-control {
    font-size: 1.7rem;
    line-height: 4.2rem;
}
.mdc-select__selected-text {
    font-size: 1.7rem;
    line-height: 2.7rem;
}
.mdc-select__dropdown-icon {
    bottom: 50%;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    right: 2rem;
    height: auto;
    background: none;
    z-index: 2;
}
.mdc-select__dropdown-icon:before {
    font-size: 24px;
    line-height: 30px;
}
.mdc-select--invalid .mdc-select__dropdown-icon {
    background: none;
}
.mdc-select--invalid .mdc-select__dropdown-icon:before {
    color: #b00020;
}
.mdc-select--focused .mdc-select__dropdown-icon {
    background: none;
    -webkit-transform: rotate(180deg) translateY(-15px);
    transform: rotate(180deg) translateY(-15px);
}
#select-state-input {
    padding: 1rem;
    color: #fc5f64;
    font-size: 1.6rem;
    margin: 0 1.5rem;
    max-width: 80px;
}
#select-state-input:not(.mdc-select--disabled) {
    background-color: #feedeb;
}
#select-hardware-input {
    padding: 1rem;
    color: #fc5f64;
    font-size: 1.6rem;
    margin: 0 1.5rem;
    width: 140px;
}
#select-hardware-input:not(.mdc-select--disabled) {
    background-color: #feedeb;
}
.mdc-select.mdc-select--focused:not(.mdc-select-disabled) {
    color: #39464e;
}
.crane-field {
    font-size: 1.8rem;
}
.crane-field .mdc-select,
.crane-field .mdc-select__native-control {
    height: 40px;
}
.crane-field .mdc-select__dropdown-icon {
    bottom: 50%;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    right: 2rem;
    height: auto;
    background: none;
}
.mdc-select--outlined.mdc-notched-outline--upgraded
    .mdc-floating-label--float-above,
.mdc-select--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    font-size: 1.7rem;
    -webkit-transform: translateY(-120%) scale(0.75);
    transform: translateY(-120%) scale(0.75);
}
.mdc-text-field--outlined.mdc-notched-outline--upgraded
    .mdc-floating-label--float-above,
.mdc-text-field--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    font-size: 1.7rem;
    -webkit-transform: translateY(-107%) scale(0.75);
    transform: translateY(-107%) scale(0.75);
}
.mdc-select.mdc-select--outlined .mdc-floating-label {
    line-height: 2rem;
}
.mdc-select + .mdc-select-helper-line,
.mdc-text-field + .mdc-text-field-helper-line {
    overflow: hidden;
    height: 0;
    padding-left: 0;
    padding-right: 0;
    color: #b00020;
}
.Brochure .mdc-text-field--invalid:not(.mdc-text-field--disabled) {
    border: 2px solid #b00020;
}
.Brochure
    .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid
    + .mdc-text-field-helper-line
    .mdc-text-field-helper-text--validation-msg {
    color: #fbd1cc;
}
.Brochure
    .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea)
    .mdc-text-field__input,
.Brochure
    .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea)
    .mdc-text-field__input:hover {
    border: 0;
}
.mdc-select--invalid.mdc-select + .mdc-select-helper-line,
.mdc-text-field--invalid.mdc-text-field + .mdc-text-field-helper-line {
    overflow: visible;
    height: auto;
    opacity: 1;
}
.mdc-select-helper-text,
.mdc-text-field-helper-text {
    font-size: 1.2rem;
    opacity: 1;
}
.mdc-select {
    position: relative;
}
.mdc-select__menu {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    width: 33%;
}
.mdc-select--fullWidth {
    width: 100%;
}
// $mdc-select-height : 50px;
// @import '../node_modules/@material/menu-surface/dist/mdc.menu-surface';
// @import '../node_modules/@material/menu/dist/mdc.menu';
// @import '../node_modules/@material/select/dist/mdc.select';
// @import '../node_modules/@material/list/dist/mdc.list';

// .mdc-select:not(.mdc-select--invalid) {
//     @include mdc-select-label-color($charcoal-0);
//     @include mdc-select-outline-color($charcoal-3);
//     @include mdc-select-hover-outline-color($charcoal-0);
//     @include mdc-select-focused-outline-color($charcoal-0);
// }

.mdc-select__native-control {
    font-size: $v * 1.7;
    line-height: $v * 2;
}

.mdc-select__selected-text {
    font-size: $v * 1.7;
    line-height: $v * 2.7;
}

.mdc-select__dropdown-icon {
    bottom: 50%;
    transform: translateY(50%);
    right: $v * 2;
    height: auto;
    background: none;
    z-index: 2;
    &:before {
        font-size: 24px;
        line-height: 30px;
    }
}

.mdc-select--invalid .mdc-select__dropdown-icon {
    background: none;
    &:before {
        color: $error-red;
    }
}

.mdc-select--focused .mdc-select__dropdown-icon {
    background: none;
    transform: rotate(180deg) translateY(-15px);
}

#select-state-input {
    // @include mdc-select-container-fill-color($dusk-3);
    padding: $v;
    color: $hot-coral;
    font-size: 1.6rem;
    margin: 0 $v * 1.5;
    max-width: 80px;
    background-color: $dusk-3;
    border-color: $hot-coral;

    @media (#{$large-bp}) {
        font-size: 1.4rem;
    }
}

#select-hardware-input {
    // @include mdc-select-container-fill-color($dusk-3);
    padding: $v;
    color: $hot-coral;
    font-size: 1.6rem;
    margin: 0 $v * 1.5;
    width: 140px;

    @media (#{$large-bp}) {
        font-size: 1.4rem;
    }
}

.mdc-select.mdc-select--focused:not(.mdc-select-disabled) {
    color: $charcoal-0;
}
.crane-field {
    font-size: 1.8rem;

    .mdc-select,
    .mdc-select__native-control {
        height: 40px;
    }

    .mdc-select__dropdown-icon {
        bottom: 50%;
        transform: translateY(50%);
        right: $v * 2;
        height: auto;
        background: none;
    }
}
.mdc-select--outlined.mdc-notched-outline--upgraded
    .mdc-floating-label--float-above,
.mdc-select--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    font-size: $v * 1.7;
    transform: translateY(-120%) scale(0.75);
}
.mdc-text-field--outlined.mdc-notched-outline--upgraded
    .mdc-floating-label--float-above,
.mdc-text-field--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    font-size: $v * 1.7;
    transform: translateY(-107%) scale(0.75);
}

.mdc-select.mdc-select--outlined .mdc-floating-label {
    line-height: $v * 2;
    background-color: $white;
}

.mdc-select + .mdc-select-helper-line,
.mdc-text-field + .mdc-text-field-helper-line {
    overflow: hidden;
    height: 0px;
    padding-left: 0;
    padding-right: 0;
    color: $error-red;
}

.mdc-select--invalid.mdc-select + .mdc-select-helper-line,
.mdc-text-field--invalid.mdc-text-field + .mdc-text-field-helper-line {
    overflow: visible;
    height: initial;
    opacity: 1;
}

.mdc-select-helper-text,
.mdc-text-field-helper-text {
    font-size: $v * 1.2;
    opacity: 1;
}
.mdc-select {
    position: relative;
}
.mdc-select__menu {
    transform: translateY(-40px);
    width: 33%;
}
.mdc-select--fullWidth {
    width: 100%;
}

.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label,
.mdc-select .mdc-floating-label {
    font-size: $v * 1.4;
    color: $charcoal-0;
}
.mdc-select--outlined:not(.mdc-select--invalid) .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--invalid) .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--invalid) .mdc-notched-outline__trailing {
    border-color: $error-red;
}
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused
    .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused
    .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-color: $charcoal-3;
}

.mdc-select--outlined:not(.mdc-select--invalid) .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--invalid) .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--invalid) .mdc-notched-outline__trailing {
    border-color: $charcoal-3;
}

.mdc-select-helper-text--validation-msg {
    text-align: left !important;
}

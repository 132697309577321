$bottom-offset: $v * 15;

.ParallaxContent {
    @media (#{$large-bp}) {
        padding-bottom: $bottom-offset;
    }

    @media (#{$xxlarge-bp}) {
        &.u-vSpacingXL-top {
            padding-top: $v * 36;
        }
    }
}

.ParallaxContent-wrap {
    @media (#{$medium-bp}) {
        display: flex;
        flex-direction: column;
    }

    @media (#{$large-bp}) {
        align-items: center;
        flex-direction: row;
    }
}

.ParallaxContent-image {
    position: relative;
    margin-bottom: $v * 3;

    @media (#{$medium-bp}) {
        margin-bottom: 0;
    }
}

.ParallaxContent-image-bg,
.ParallaxContent-image-top,
.ParallaxContent-image-bottom {
    padding: 0;
}

.ParallaxContent-image-top,
.ParallaxContent-image-bottom {
    img {
        width: 90%;
        max-width: 90%;
    }
}

.ParallaxContent-image-top {
    display: none;
    position: absolute;
    top: -$v * 12;
    left: $v;
    z-index: 2;
    @media (#{$large-bp}) {
        display: block;
    }
    @media (#{$xxlarge-bp}) {
        top: -$v * 15;
    }
}

.ParallaxContent-image-bottom {
    display: none;
    position: absolute;
    right: $v;
    z-index: 2;
    bottom: -$v * 12;
    @media (#{$large-bp}) {
        display: block;
    }

    @media (#{$xxlarge-bp}) {
        bottom: -$bottom-offset;
    }
}

.ParallaxContent-details {
    text-align: center;
    margin-top: $v * 3;

    @media (#{$small-bp}) {
        clear: both;
        text-align: left;
        width: 100%;
    }
    @media (#{$large-bp}) {
        text-align: left;
        margin-top: 0;
    }
}

.ParallaxContent-title,
.ParallaxContent-description {
    margin-bottom: $v * 3;
}

.ParallaxContent-description {
    clear: both;
    display: none;
    @media (#{$medium-bp}) {
        display: block;
    }
}

.ParallaxContent-actions {
    justify-content: center;
    @media (#{$large-bp}) {
        justify-content: flex-start;
    }
}

.ParallaxContent-isReverse {
    @media (#{$large-bp}) {
        .ParallaxContent-wrap {
            flex-direction: row-reverse;
        }

        .ParallaxContent-image-bg {
            float: right;
        }

        .ParallaxContent-image-top {
            left: initial;
            right: $v;
        }

        .ParallaxContent-image-bottom {
            left: 0;
            right: initial;
        }
    }
}

.ParallaxContent--showMore {
    .ParallaxContent-image-top,
    .ParallaxContent-description {
        display: block;
    }

    .ParallaxContent-image-top {
        top: initial;
        bottom: -2rem;
        left: 25%;
        width: 50%;
        img {
            width: 100%;
            max-width: 100%;
        }
    }

    @media (#{$large-bp}) {
        .ParallaxContent-image-top {
            left: $v;
            top: -$v * 12;
            display: block;
            width: 41.66666667%;

            img {
                width: 90%;
                max-width: 90%;
            }
        }
        &.ParallaxContent-isReverse {
            .ParallaxContent-image-top {
                left: initial;
                right: $v;
            }
        }
    }
    @media (#{$xxlarge-bp}) {
        top: -$v * 15;
    }

    h6 {
        margin-top: $v * 6;
    }
}

.ParallaxContent--mobile-text {
    @media (#{$large-bp-below}) {
        .ParallaxContent-image,
        .ParallaxContent-image-top,
        .ParallaxContent-image-bottom {
            display: none;
        }

        .ParallaxContent-title,
        .ParallaxContent-description {
            display: block;
        }
    }
}

.ParallaxContent--video {
    .ParallaxContent-image-bg {
        font-size: 0;
    }

    .ParallaxContent-image {
        @media (#{$small-bp}) {
            margin-left: -1.5rem;
            margin-right: -1.5rem;
        }
    }
}

.ParallaxContent--top {
    .ParallaxContent-image {
        @media (#{$small-bp}) {
            width: 100vw;
            margin-left: 0;
            padding: 0;
        }
        @media (#{$medium-bp-only}) {
            width: 100%;
            margin-left: 0;
            padding: 0;
        }
    }

    .ParallaxContent-wrap {
        align-items: flex-start;
    }

    h6 {
        margin-top: $v * 6;
    }

    .ParallaxContent-description > h6:first-of-type {
        @media (#{$large-bp}) {
            margin-top: 0;
        }
    }

    h3 {
        margin-top: $v * 6;
        @media (#{$large-bp}) {
            margin-top: 0;
        }
    }
}

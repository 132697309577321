.StandardContent p {
    margin: 0;
}

.StandardContent-title {
    margin-top: 0;
    line-height: 3.2rem;
    margin-bottom: $v * 4;

    font-size: 2.8rem;
    margin-bottom: $v * 3;
}

h2.StandardContent-title {
    @extend %h2;
}

.StandardContent--title-h5 {
    .StandardContent-title {
        @extend %h5;
    }
}

.StandardContent--title-h2 {
    .StandardContent-title {
        @extend %h2;
    }
}

.StandardContent-title--withIcon {
    display: flex;
    align-items: center;
    letter-spacing: 3.4px;
    line-height: 2.2rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.4rem;
    margin-bottom: $v * 2;

    @media (#{$large-bp}) {
        font-size: 1.6rem;
    }
    .svg g {
        fill: $charcoal;
    }
}
.StandardContent-icon {
    margin-right: $v * 1.5;
}

.StandardContent-actions {
    display: flex;
    margin-top: $v * 2.5;
    flex-direction: column;
    align-items: flex-start;

    > div:first-child {
        margin-bottom: $v;
    }

    @media (#{$medium-bp}) {
        align-items: baseline;
        flex-direction: row;
        > div {
            margin-right: $v * 3;
            &:first-child {
                margin-bottom: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.StandardContent-uppercase {
    .StandardContent-title {
        text-transform: uppercase;
    }
}

.u-textCenter {
    .StandardContent-image {
        max-width: 80%;
    }
    .StandardContent-actions {
        justify-content: center;
    }
}
.StandardContent-image-wrap {
    margin-top: $v * 6;
    margin-bottom: $v * 2;
}

.StandardContent-image {
    width: auto;
    max-width: 100%;
    // height: 90px;
    margin: $v auto;
}

.StandardContent--condensed {
    .StandardContent-title {
        font-family: $text-font;
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: $v;
    }
    .StandardContent-actions {
        margin-top: 0;
    }
}

.StandardContent--heroTitle {
    .StandardContent-title {
        @extend %h1;
        font-weight: 700;
        margin-bottom: 0;
    }
    p {
        font-weight: 700;
    }
}

.StandardContent-Link {
    cursor: pointer;
    &:hover {
        .play-button {
            opacity: 1;
            background-color: $coral-hover;
            &::before {
                color: $white;
            }
        }
    }
}

.StandardContent--topMargin0 {
    .StandardContent-image-wrap {
        margin-top: $v;
    }
}

.Carousel {
  @extend .u-vSpacingXL-top;

  .Carousel-item {
    display: inline-block;
  }

  .Carousel-heading {
    @extend %container;

    text-align: center;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 3px;
    > div {
      margin-bottom: $v * 5;
    }
    font-size: 1.4rem;

    @media (#{medium-bp}) {
      font-size: 1.6rem;
    }
  }

  .Carousel-center .Endorsement {
    width: 100%;
  }

  .Carousel-list {
    visibility: hidden;
    &.slick-initialized {
      visibility: visible;
    }
  }

  .Carousel-item img {
    width: 100%;
  }

  .slick-slide {
    outline: none;
    margin: $v * 1.5;
    transition: width 0.2s ease-out;
  }

  .Carousel-item {
    position: relative;
    transition: all 0.2s ease-out;
  }

  .slick-track {
    display: flex;
    align-items: center;

    @media (#{$large-bp}) {
      min-height: calc(30vw + #{$v} * 3);
    }
  }

  .Carousel-caption {
    margin-top: $v * 6;
    min-height: 116px;
    transition: opacity 0.2s ease-out;

    h3 {
      text-align: center;
      font-size: 2.5rem;
      line-height: 3.4rem;
      @media (#{$medium-bp}) {
        font-size: 3.5rem;
        line-height: 4.6rem;
      }
    }

    .Flag {
      justify-content: center;
      align-items: center;
      // margin: 0 auto;
      margin-top: $v * 4;

      @media (#{medium-bp}) {
        margin-top: $v * 6;
      }
    }

    .Flag-body {
      width: auto;
    }
  }

  .Carousel-item-name {
    color: $white;
    text-align: center;
    padding: $v;
  }

  .Carousel-item-hover {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
    bottom: 0;
    background-color: rgba(57, 70, 78, 0.8);
    opacity: 0;
    transition: opacity 0.25s ease-in;

    &:hover {
      opacity: 1;
    }
  }

  .slick-current {
    z-index: 1;

    @media (#{$large-bp}) {
      width: 30vw !important;
    }
  }
}

.Carousel-center {
  margin-left: auto;
  margin-right: auto;
  float: none;
}

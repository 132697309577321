.hero-block-latest__block-height {
    display: flex;
    position: relative;
    align-items: center;
    min-height: 670px;
}

.hero-block-latest__block-inner {
    position: relative;
}

.hero-block-latest__h1 {
    text-wrap: balance;
}

.hero-block-latest__h6 {
    text-wrap: balance;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.8rem;
}

.hero-block-latest__bg-image-desktop {
    display: block;
    position: absolute;
    inset: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
}

.hero-block-latest__bg-image-mobile {
    display: none;
    position: absolute;
    inset: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
}

// right column image.
.hero-block-latest__img-wrapper {
    position: relative;
    width: 100%;
    height: 250px;
    overflow: hidden;
}

.hero-block-latest__img-inner {
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}

.hero-block-latest__img-inner-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.hero-block-latest__img-icon {
    width: 205px;
    height: auto;
}

// H1 text
.hero-block-latest__h1-smaller {
    font-size: 4rem;
    line-height: 5rem;
}

.hero-block-latest__h1-jumbo {
    font-size: 5.5rem;
    line-height: 6.4rem;
}

// Text colour
.hero-block-latest__text-white {
    color: $white;
}

.hero-block-latest__text-charcoal {
    color: $charcoal;
}

.hero-block-latest__btn-layout{
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
}

.hero-block-latest__center-content{
    display: grid;
    align-content: center;
}

.hero-block-latest__button-holder {
    padding-left: 15px;
    padding-right: 15px;
}

.hero-block-latest__button{
    height: auto;
}

.hero-block-latest__col {
    display: flex;
    justify-content: center;
    height: 100%;
}

.hero-block-latest__col-l {
    display: flex;
    justify-content: flex-end;
    padding-left: 0;
    padding-right: 0;
    height: 100%;
}

.hero-block-latest__col-r {
    display: flex;
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 0;
    height: 100%;
}

.hero-block-latest__video-overlay.testimonial-carousel__testimonial__video-button::before {
    opacity: 0.8;
    background-color: $charcoal-0;
}

.hero-block-latest__video-overlay.testimonial-carousel__testimonial__video-button::after {
    opacity: 0.8;
}

.hero-block-latest__video-overlay.testimonial-carousel__testimonial__video-button::before:hover {
    opacity: 1;
    background-color: $charcoal-0;
}

.hero-block-latest__video-overlay.testimonial-carousel__testimonial__video-button::after {
    opacity: 1;
}

.hero-block-latest__video-overlay.testimonial-carousel__testimonial__video-button:hover::before {
    opacity: 1;
    background-color: $charcoal-0;
}

.hero-block-latest__video-overlay.testimonial-carousel__testimonial__video-button:hover::after {
    opacity: 1;
}

.hero-block-latest__video-overlay {
    width: 100%;
}

/* Extra large devices (large desktops) */
@media ($xlarge-bp) {
    .hero-block-latest__img-wrapper {
        height: 480px;
    }
}

/* Large devices (desktops) */
@media ($large-bp) and (max-width: 1199.98px) {
    .hero-block-latest__img-wrapper {
        height: 420px;
    }
}

/* Medium devices (laptops) */
@media (min-width: 768px) and ($large-bp-below) {
    .hero-block-latest__img-wrapper {
        height: 350px;
    }

    .hero-block-latest__block-height {
        min-height: 600px;
    }
}

/* Small devices (tablets) */
@media ($small-bp) {
    .hero-block-latest__img-wrapper {
        height: 390px;
    }
    .hero-block-latest__block-height {
        min-height: 600px;
    }

    .hero-block-latest__bg-image-desktop {
        display: none;
    }

    .hero-block-latest__bg-image-mobile {
        display: block;
    }

    .hero-block-latest__col-l,
    .hero-block-latest__col-r {
        justify-content: center;
    }

    .hero-block-latest__col-r {
        padding-top: 15px;
    }

    .hero-block-latest__button-holder {
        padding: 0;
    }

    .hero-block-latest__h6 {
        text-wrap: balance;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.4rem;
    }

    // H1 text
.hero-block-latest__h1-smaller {
    font-size: 2.9rem;
    line-height: 3.6rem;
}

.hero-block-latest__h1{
    font-size: 3.2rem;
    line-height: 3.9rem;
}

.hero-block-latest__h1-jumbo {
    font-size: 4rem;
    line-height: 4.6rem;
}

.hero-block-latest__btn-layout{
    justify-content: center;
    gap: 1.5rem;
}

    // If the class contains .Button--link make the width auto otherwise 100% This is for the button styling
    .hero-block-latest__button .Button--link {
        width: auto;
    }

    .hero-block-latest__button:not(.Button--link) {
        width: 100%;
    }
}

/* Extra small devices (phones) */
@media (max-width: 575.98px) {
    .hero-block-latest__img-wrapper {
        height: 310px;
    }
    .hero-block-latest__block-height {
        min-height: 400px;
    }
}

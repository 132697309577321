$button-color: $charcoal;

.wp-block-button__link,
.Button {
    @include button;
}

.wp-block-button .wp-block-button__link {
    border: 0;
}

.has-coral-1-background-color,
.Button--primary {
    @include button__theme("primary");
}

.Button--plain {
    @include button__theme("plain");
}

.Button--link {
    @include button__theme("link");
}

.Button--link-white {
    @include button__theme("link-white");
}

.Button--link-charcoal {
    @include button__theme("link-charcoal");
}

.Button--white {
    @include button__theme("white");
}

.Button--white-alt {
    @include button__theme("white-alt");
}

.Button--charcoal {
    @include button__theme("charcoal");
}

.Button--secondary,
.Button--charcoal-alt {
    @include button__theme("secondary");
}

.Button--red {
    @include button__theme("red");
}

.Button--full {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.Button--full-sm {
    @media (#{$small-bp}) {
        width: 100%;
    }
}

#nav-get-started {
    background: $hot-coral;
    transition: all 0.2s ease-in;
    &:hover,
    &:active,
    &:focus {
        background: $coral-hover;
        border-color: $coral-hover;
        color: $white;
    }
}

.Button--external {
    padding-top: 0;
    margin-left: 0;
    position: relative;
    border: 0;
    &::after {
        bottom: 50%;
        transform: translateY(60%);
        right: -22px;
        position: absolute;
        display: inline-block;
        content: '';
        width: 16px;
        height: 18px;
        background-color: $hot-coral;
        mask-image: url('/statics/images/svg/external-link.svg');
        mask-repeat: no-repeat;
        mask-size: 12px 12px;
    }
    &:hover {
        &::after {
            background-color: $coral-hover;
        }
    }
    &.Button--external-grey {
        padding: 0;
        color: $charcoal-0;

        &::after {
            background-color: $charcoal-0;
        }

        &:hover {
            color: $charcoal-2;

            &::after {
                background-color: $charcoal-2;
            }
        }
    }
}

.Button--video,
.Button--watchVideo {
    border-bottom: none;
    box-shadow: none;
    display: inline-flex;
    align-items: center;
    color: $white;
}

.Button--video,
.ArticleCard a,
.HeroBlock-image-action,
.Button--watchVideo {
    svg {
        margin-right: $v;

        #PlayButton-hover {
            transition: opacity 0.2s ease-in-out;
            opacity: 0;
        }
    }

    &:hover {
        color: $white;
        svg {
            #PlayButton-hover {
                opacity: 1;
            }
        }
    }
}

.Button.Button--red.show {
    display: inline-block !important;
}

.Button--watchWebinar {
    box-shadow: none;
    border-bottom: none;
    display: inline-flex;
    align-items: center;
    color: $hot-coral;

    @media (#{$medium-bp}) {
        margin-top: $v * 2;
    }

    svg {
        margin-right: $v;

        #PlayButton-hover {
            transition: opacity 0.2s ease-in-out;
            opacity: 0;
        }
    }

    &:hover {
        color: $coral-hover;
        box-shadow: none;
        svg {
            #PlayButton-hover {
                opacity: 1;
            }
        }
    }
}

.Button--video {
    color: $charcoal-0;
    &:focus,
    &:hover {
        color: $black;
        box-shadow: none;
        svg {
            #PlayButton-icon {
                fill: $black;
                color: $black;
            }
            #PlayButton-hover {
                opacity: 1;
            }
        }
    }
}
.HeroBanner--onDark .Button--video.Button--link {
    color: $white;
    box-shadow: 0;

    #PlayButton-icon {
        fill: $white;
        color: $white;
    }

    &:focus,
    &:hover {
        color: $dusk-0;
        box-shadow: none;
        svg {
            #PlayButton-icon {
                fill: $dusk-0;
                color: $dusk-0;
                // opacity: 0;
            }
            #PlayButton-hover {
                fill: $dusk-0;
                color: $dusk-0;
                opacity: 0;
            }
            // #Triangle {
            //     color: $white;
            // }
        }
    }
}

.Button--lightToDark {
    @include button__theme("light-to-dark");
}
.Button--midnight {
    @include button__theme("midnight");
}

.Button--disabled,
.Button:disabled {
    color: $charcoal-2;
    background-color: $charcoal-4;
    border-color: $charcoal-4;
    pointer-events: none;
    cursor: default;
}
.Button--image {
    line-height: 0.8;
}

.Button--clipboard {
    top: 1px;
    right: 8px;
}

.btn-crush.btn.btn-outline {
    @extend .Button;
    @extend .Button--red;
}

.btn-success {
    @extend .Button;
    @extend .Button--charcoal;
    &:hover {
        border-color: $black;
        background-color: $black;
        color: $white;
    }
}

.btn-blue {
    @extend .Button;
    @extend .Button--red;
    &:hover {
        border-color: $coral-hover;
        background-color: $coral-hover;
        background-image: none;
        color: $white;
    }
}

.btn-register {
    letter-spacing: 0 !important;
    font-size: 1.4rem;
}

.Button--clean {
    background-color: transparent;
    border: none;
    padding: 0;
}

.Button--radius {
    border-radius: 4px;
}

.play-button {
    background-color: $charcoal;
    opacity: 0.79;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 74px;
    color: $white;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 2px;
    transition: all 0.1s ease-out;
    &::before {
        color: $white;
        font-size: $v * 2;
    }
}
.play-button--round {
    align-items: center;
    background-color: $hot-coral;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    display: flex;
    height: 110px;
    width: 110px;
    justify-content: center;
    left: 50%;
    opacity: 1;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.32);

    transition: all 0.1s ease-out;
    &::before {
        font-size: 50px;
        width: 100% !important;
        display: block;
    }
}

a:hover {
    .play-button--round {
        opacity: 1;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.52);
    }
}

.Button--nudge {
    @media (#{$medium-bp}) {
        position: relative;
        &::after {
            content: url('/images/svg/get-started.svg');
            width: 129px;
            height: 39px;
            display: block;
            position: absolute;
            top: 10px;
            transform: translate(115%);
        }
    }
}

.Button--link.Button--plain {
    margin: 0;
    padding: 0;
    left: 5px;
    top: -2px;
}

.Button--link-coral {
    @include button__theme("link-coral");
}

.Buttton--charcoalUnderline {
    &,
    a {
        color: $charcoal-0;
    }
}

.Button--icon-arrow-right-chevron {
    @include button__icon($icon-arrow-right-chevron);
}

$hero-lead-capture__bp--small: $small-bp;
$hero-lead-capture__bp: $medium-bp;

.hero-lead-capture__content-container {
	@media (#{$hero-lead-capture__bp}) {
		display: flex;
		align-items: center;
		min-height: 80vh;
		padding-bottom: 0;
	}

	position: relative;

	// Create a new stacking context
	isolation: isolate;
}

.hero-lead-capture__content {
	padding-bottom: 4 * $v;

	@media (#{$hero-lead-capture__bp}) {
		flex-grow: 1;
		position: relative;
		z-index: 1;
	}

	a {
		transition: color 0.2s ease-in;
		text-decoration: none;
	}
}

.hero-lead-capture__content--light {
	color: $white;
	a {
		color: $dusk-base;
		&:hover,
		&:focus {
			color: $dusk-hover;
		}
	}
}

.hero-lead-capture__content--dark {
	color: $charcoal-0;
	a {
		color: $hot-coral;
		&:hover,
		&:focus {
			color: $coral-hover;
		}
	}
}

.hero-lead-capture__container {
	position: relative;

	@media (#{$hero-lead-capture__bp}) {
		width: 50%;
	}
}

.hero-lead-capture__title {
	display: block;
	margin: (5 * $v) 0 $v;

	@media (#{$hero-lead-capture__bp}) {
		margin: 0.5em 0;
	}
}

.hero-lead-capture__title--standard {
	@extend %h1;
}

.hero-lead-capture__title--jumbo {
	@extend %h1--jumbo;
}

.hero-lead-capture__text {
	@extend %p;
}

.hero-lead-capture__form__row {
	display: flex;
	@media ($hero-lead-capture__bp--small) {
		flex-wrap: wrap;
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
	}
	align-items: stretch;
	margin-bottom: 2 * $v;

	.hero-lead-capture__form__input,
	.hero-lead-capture__form__submit {
		@media ($hero-lead-capture__bp--small) {
			flex-grow: 1;
		}
	}

	.hero-lead-capture__form__input {
		margin-bottom: 0.5em;
		@media ($hero-lead-capture__bp) {
			height: auto;
			max-width: 350px;
			margin-bottom: 0;
		}
	}
}

.hero-lead-capture__form__privacy {
	margin: 0;
	font-size: 1.6rem;
	line-height: 1.5;
	min-height: (1.6rem * 1.5);

	input[type="checkbox"] {
		display: inline-block;
		vertical-align: middle;
		margin-right: 1ch;
	}
}

.hero-lead-capture__background-mobile {
	display: block;
	@media (#{$hero-lead-capture__bp}) {
		display: none;
	}

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
}

.hero-lead-capture__image-desktop {
	display: none;
	@media (#{$hero-lead-capture__bp}) {
		display: block;
	}

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	object-position: center;
	object-fit: cover;
}

.hero-lead-capture__image-mobile {
	display: block;
	width: 100%;
	@media (#{$hero-lead-capture__bp}) {
		display: none;
	}

	position: relative;
}

.icon-links {
	margin: 2 * $v 0;
}

.icon-links__layout {
	display: flex;
	flex-wrap: wrap;

	gap: $h;
}

.icon-links__layout--left {
	justify-content: flex-start;
}
.icon-links__layout--centre {
	justify-content: center;
}
.icon-links__layout--right {
	justify-content: flex-end;
}

.icon-links__size--small {
	font-size: 2.5rem;
}
.icon-links__size--large {
	font-size: 4.5rem;
}

.icon-links__colour--charcoal {
	.icon-links__link {
		color: $charcoal-base;
	}
}
.icon-links__colour--coral {
	.icon-links__link {
		color: $coral-base;
	}
}

.icon-links__link {
	&:hover,
	&:focus {
		color: $coral-hover;
	}
	transition: 0.2s color ease-in-out;
}

.icon-links__icon {}

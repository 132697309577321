@use "sass:math";

$industry-tiles__bp: $medium-bp;
$industry-tiles__button-size: rem(56px);

.industry-tiles {
	margin: (8 * $v) 0;
	@media (#{$industry-tiles__bp}) {
		margin: (12 * $v) 0;

		--tiles-visible: 4;
		@media (#{$large-bp}) {
			--tiles-visible: 6;
		}
	}
}

.industry-tiles__title {
	display: block;
	margin: 0 0 4 * $v;

	@extend %h2;

	text-align: center;
}

.industry-tiles__carousel {
	position: relative;
	display: flex;
	align-items: stretch;
}

.industry-tiles__items {
	flex-grow: 1;

	list-style: none;
	margin: 0;
	padding: 0;

	display: grid;
	@include grid-ram(rem(120px));
	gap: 2 * $h;

	@supports(scroll-snap-type: x mandatory) {
		@media (#{$industry-tiles__bp}) {
			display: flex;
			overflow: auto;
			// For preventing focus outlines being cut off by overflow rule
			padding: 2px;

			margin: 0 ($industry-tiles__button-size + 2 * $v);

			scroll-snap-type: x mandatory;
			scroll-behavior: smooth;

			-ms-overflow-style: none;
			scrollbar-width: none;
			&::-webkit-scrollbar {
				display: none;
			}
		}
	}
}

.industry-tiles__controls {
	display: none;
	@media (#{$industry-tiles__bp}) {
		@supports(scroll-snap-type: x mandatory) {
			display: flex;
			align-items: center;
		}
	}
}

.industry-tiles__control {
	display: flex;
	align-items: center;
	justify-content: center;

	width: $industry-tiles__button-size;
	height: $industry-tiles__button-size;

	-webkit-appearance: none;
	background-image: none;
	border-radius: 100%;
	border: none;

	cursor: pointer;
	&:disabled {
		cursor: not-allowed;
	}

	@include button__theme("light-grey");
	transition: all 0.2s ease-out;

	position: absolute;

	&:first-child {
		left: 0;
		.industry-tiles__control__icon {
			right: rem(1px);
			&::before {
				content: "\F104";
			}
		}
	}
	&:last-child {
		right: 0;
		.industry-tiles__control__icon {
			left: rem(1px);
			&::before {
				content: "\F105";
			}
		}
	}
}

.industry-tiles__control__icon {
	display: block;

	// Offset to compensate for FontAwesome's icons not being centred
	position: relative;
	bottom: rem(1px);

	&::before {
		display: block;
		font-family: fa;
		font-size: math.div($industry-tiles__button-size, 2);
	}
}

.industry-tiles__item {
	@supports(scroll-snap-type: x mandatory) {
		@media (#{$industry-tiles__bp}) {
			flex-shrink: 0;
			// This calc handles the offset of the gap
			width: calc((calc(100% / 6) - #{2 * $h} * calc(calc(6 - 1) / 6)));
			width: calc((calc(100% / var(--tiles-visible)) - #{2 * $h} * calc(calc(var(--tiles-visible) - 1) / var(--tiles-visible))));

			scroll-snap-align: start;
		}
	}
}

.industry-tiles__item__link {
	display: block;

	text-decoration: none;
	color: $charcoal;

	&:hover,
	&:focus {
		color: $coral-hover;
		.industry-tiles__item__image {
			transform: scale(1.1);
		}
	}
	transition: all 0.2s ease-out;
}

.industry-tiles__item__figure {
	display: block;
	border-radius: rem(7px);
	overflow: hidden;
}

.industry-tiles__item__image {
	display: block;
	object-fit: cover;
	object-position: center;

	transition: transform 0.2s ease-out;
}

.industry-tiles__item__title {
	display: block;
	margin-top: $v;

	@extend %h6;

	text-align: center;
	@media (#{$industry-tiles__bp}) {
		text-align: initial;
	}
}

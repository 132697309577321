@use "sass:math";

$video-course-next__gap: 2 * $h;

.video-course-next {
	margin: (5 * $v) 0 (10 * $v);
}

.video-course-next__layout {
	border-top: 1px solid $border-grey;
}

.video-course-next__title {
	display: block;
	margin: (2 * $v) 0;

	@extend %h5;

	color: $charcoal-2;
}

.video-course-next__items {
	list-style: none;
	margin: 0;
	padding: 0;

	display: flex;
	gap: $video-course-next__gap;

	overflow: auto;
	scroll-snap-type: x mandatory;
}

.video-course-next__item {
	flex: 0 0 85%;
	scroll-snap-align: start;
	@media ($medium-bp-only) {
		flex: 0 0 45%;
	}
	@media ($large-bp) {
		flex: 0 0 calc(percentage(math.div(1, 3)) - ($video-course-next__gap * 2/3));
	}
}

.video-course-next__link {
	display: block;

	overflow: hidden;
	border-radius: 5px;

	position: relative;
	font-size: 6px;

	&::before,
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&::before {
		width: 12em;
		height: 8em;
		background: rgba($black, 0.6);
		border-radius: 1em;

		transition: 0.2s background-color ease-in-out;
	}

	&::after {
		width: 0;
		height: 0;
		border-left: 3em solid $white;
		border-top: 2em solid transparent;
		border-bottom: 2em solid transparent;
		margin-left: 0.4em;
	}

	&:hover,
	&:focus {
		&::before {
			background-color: $coral-2;
		}
	}
}

.video-course-next__image {
	display: block;
	max-width: 100%;
}

$hero-rotating-text__bp: $large-bp;
$hero-rotating-text__bp--large: $xlarge-bp;

$hero-rotating-text__transition-speed: 0.6s;

.hero-rotating-text {
	background-repeat: no-repeat;
	background-size: cover;

	background-color: $charcoal-0;
	background-image: url("/images/background-images/bg-colour-charcoal.jpg");
	color: $white;

	display: flex;
	align-items: center;

	min-height: calc(100vh - var(--nav-height));
	@supports (min-height: 100dvh) {
		min-height: calc(100dvh - var(--nav-height));
	}

	padding: (8 * $v) 0;

	--hero-rotating-text__underline-width: 0%;
}

.hero-rotating-text__container {
	@extend %container;

	@media (#{$hero-rotating-text__bp--large}) {
		width: 80%;
		padding-left: 0;
		padding-right: 0;
	}
}

.hero-rotating-text__layout {
	display: flex;
	flex-direction: column;
	row-gap: 4 * $v;
	@media (#{$hero-rotating-text__bp}) {
		flex-direction: row;
		justify-content: space-between;
		column-gap: 4 * $h;
	}
}

.hero-rotating-text__body {
	flex-grow: 1;

	display: flex;
	flex-direction: column;
	align-items: center;
	@media (#{$hero-rotating-text__bp}) {
		align-items: flex-start;
		padding: (4 * $v) 0;
	}
	@media (#{$hero-rotating-text__bp--large}) {
		padding: (8 * $v) 0;
	}
}

.hero-rotating-text__tagline {
	@extend %h5--plus;

	display: block;
	margin-bottom: 2 * $v;
}

.hero-rotating-text__page-title {
	@include visually-hidden;
}

.hero-rotating-text__title {
	@extend %h1--jumbo;
	text-align: center;

	display: block;
	margin: 0 0 (2 * $v);
	@media (#{$hero-rotating-text__bp}) {
		text-align: initial;
		margin: 0 0 (4 * $v);
	}
}

.hero-rotating-text__title__title-text {}

.hero-rotating-text__title__rotating-text {
	display: inline-grid;
	isolation: isolate;

	overflow: hidden;

	background-image: linear-gradient(90deg, $white 0%, $white 100%);
	background-size: var(--hero-rotating-text__underline-width) 0.05em;
	background-position: 0% 100%;
	background-repeat: no-repeat;

	transition: background-size $hero-rotating-text__transition-speed ease-in-out;

	@media (#{$small-bp}) {
		display: grid;
		background-position: 50% 100%;
	}
}

.hero-rotating-text__title__rotating-text__item {
	// Stack all items in the same space
	grid-area: 1 / 1;
	text-align: left;
	@media (#{$small-bp}) {
		text-align: center;
	}
}

.hero-rotating-text__title__rotating-text__text {
	// Appropriate blend mode for cross-fading. Relies on parent having `isolation: isolate;`
	mix-blend-mode: plus-lighter;

	visibility: hidden;
	animation: $hero-rotating-text__transition-speed stayVisible;
	position: relative;

	top: 100%;
	@media (prefers-reduced-motion: reduce) {
		opacity: 0;
		top: 0;
	}

	transition:
		top $hero-rotating-text__transition-speed ease-in-out,
		opacity $hero-rotating-text__transition-speed ease-in-out;

	.hero-rotating-text__title__rotating-text__item[aria-hidden="false"] & {
		// Reset animation so it can be started again when leaving this state
		animation: none;
		visibility: visible;

		opacity: 1;
		top: 0;
	}
	.hero-rotating-text__title__rotating-text__item--previous & {
		top: -100%;
		@media (prefers-reduced-motion: reduce) {
			opacity: 0;
			top: 0;
		}
	}
}

.hero-rotating-text__action {
	@include button__theme("primary");
	@include button__size("large");
}

.hero-rotating-text__action--mobile {
	align-self: center;
	@media (#{$hero-rotating-text__bp}) {
		display: none;
	}
}

.hero-rotating-text__action--desktop {
	display: none;
	@media (#{$hero-rotating-text__bp}) {
		display: inline-block;
	}
}

.hero-rotating-text__media {
	display: grid;
	flex-shrink: 0;
	overflow: hidden;

	width: 100%;
	@media (#{$hero-rotating-text__bp}) {
		width: 50%;
	}
}

.hero-rotating-text__figure {
	// Stack in the same space;
	grid-area: 1 / 1;

	display: block;
	margin: 0 auto;

	transform: translateY(100%);
	@media (prefers-reduced-motion: reduce) {
		opacity: 0;
		transform: none;
	}
	transition:
		transform $hero-rotating-text__transition-speed ease-in-out,
		opacity $hero-rotating-text__transition-speed ease-in-out;

	visibility: hidden;
	animation: stayVisible $hero-rotating-text__transition-speed;
	&[aria-hidden="false"] {
		visibility: visible;
		animation: none;

		opacity: 1;
		transform: none;
		transition:
			transform $hero-rotating-text__transition-speed ease-in-out,
			opacity $hero-rotating-text__transition-speed ease-in-out;
	}
	&.hero-rotating-text__figure--previous {
		transform: translateY(-100%);
		@media (prefers-reduced-motion: reduce) {
			opacity: 0;
			transform: none;
		}
	}
}

.hero-rotating-text__image {
	display: block;
	object-fit: cover;
	object-position: center;
}

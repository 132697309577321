$price-icon-size: 22px;
$price-stem-size: 16px;
$price-stem-width: 2px;

.PriceAccordion {
    &:last-child {
        .PriceAccordion-body {
            &:last-child {
                border-bottom: none;
            }
        }
    }
}
.PriceAccordion-wrap {
    background-color: $dusk-4;
    padding-bottom: $v * 6;
    .tooltip.bottom {
        margin-left: $v * 1.5;
    }
}

.PriceAccordion-card {
    box-shadow: $card-box-shadow;
    border-radius: $card-border-radius;
    overflow: hidden;
}

.PriceAccordion-title {
    background-color: $white;
    padding: $v * 3 0;
    cursor: pointer;

    &,
    .PriceAccordion-icon {
        transition: all 0.2s ease-in-out;
    }

    // .col-xs-12 {
    //   padding-left: 0;
    //   padding-right: 0;
    // }

    @media (#{$medium-bp}) {
        &:hover {
            color: $midnight;

            .PriceAccordion-icon-minus path,
            .PriceAccordion-icon-plus path {
                fill: $midnight;
            }
            // .PriceAccordion-icon-stem {
            //   background-color: $midnight;
            // }
        }
    }
}

.PriceAccordion-icon {
    width: $price-icon-size;
    height: $price-icon-size;
    display: flex;
    align-items: center;
    position: relative;
    margin-left: $v * 1.5;
    @media (#{$medium-bp}) {
        margin-left: math.div($v, 2);
    }
}

.PriceAccordion-icon-minus,
.PriceAccordion-icon-plus {
    position: absolute;
    transition: all 0.2s ease-in-out;
    width: 14px;
    height: 14px;
}

//   height: $price-stem-size;
//   width: $price-stem-width;
//   background-color: $charcoal;
//   display: block;
//   position: absolute;
//   top: 3px;
//   left: 10px;
//   transform: rotate(-180deg);
//   transition: all .2s ease-in-out;
// }

// .PriceAccordion-icon-branch {
//   width: $price-stem-size;
//   height: $price-stem-width;
//   background-color: $charcoal;
//   display: block;
//   position: absolute;
//   left: 3px;
//   top: 10px;
// }

.PriceAccordion-body {
    transition: all 0.2s ease-out;
    overflow: hidden;
    max-height: 0;
    border-bottom: 1px solid $charcoal-0;
}

.is-open {
    // .PriceAccordion-body {
    //   max-height: initial;
    // }

    // .PriceAccordion-icon-stem,
    // .PriceAccordion-icon-branch {
    //   background-color: $charcoal-3;
    // }

    .PriceAccordion-icon-plus {
        transform: rotate(-45deg);
        opacity: 0;
    }

    .PriceAccordion-title {
        border-bottom: 1px solid $charcoal-0;
    }
}

.PriceAccordion-label {
    margin-left: $v * 2;
    margin-bottom: 0;
}

.PriceAccordion-body-row {
    display: flex;
    // background-color: rgba(57, 70, 78, 0.03);
    background-color: $charcoal-5;

    .col-md-4 {
        padding-bottom: $v * 1.3;
    }

    &:nth-of-type(even) {
        background-color: $white;
        // background-color: greenyellow;
    }

    > div:last-child {
        margin-right: $v * 2;
    }

    @media (#{$large-bp}) {
        margin-right: 0;
    }
    @media (#{$small-bp}) {
        > div:last-child {
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    }

    .PriceAccordion-label {
        text-align: left;
        color: $charcoal;
        // @media (#{$small-bp}) {
        //   margin-left: 0;
        // }
    }

    a {
        color: $blue-1;
        &.Button--link {
            border-color: transparent;
            margin-bottom: 0.1rem;
        }

        &.Button--external::after {
            background-color: $blue-1;
        }

        &:hover {
            color: $blue-0;
            &.Button--link {
                border-color: transparent;
            }

            &.Button--external::after {
                background-color: $blue-0;
            }
        }
    }

    > div {
        padding: $v;
        // border-style: solid;
        // border-color: $dusk-2;
        border-left-width: 0;
        border-right-width: 1px;
        // border-top-width: 1px;
        // border-bottom-width: 0;

        &:last-child {
            // border-right-width: 0;
        }

        &.col-md-4 {
            // padding: $v * .8 $v $v * 1.2;
        }
    }
    &:first-child {
        > div {
            border-top-width: 1px;
        }
    }
    &:last-child {
        > div {
            border-bottom-width: 0;
        }
    }
}

// .PriceAccordion-body-row-EFTPOSPayments {
//   display: none;
// }

// .PriceList[data-country=NZ],
// .PriceList[data-country=AU] {
//   .PriceAccordion-body-row-EFTPOSPayments {
//     display: flex;
//   }
// }

// .PriceList[data-country=GB],
// .PriceList[data-country=NZ],
// .PriceList[data-country=AU] {
//   .PriceAccordion-body-row-PremiumPhoneSupport {
//     display: flex;
//   }
// }

.tick-small {
    margin-bottom: 3px;
}
.tick-small--disabled {
    filter: grayscale(1);
    opacity: 0.5;
}

.PriceAccordion-dash {
    color: $charcoal-3;
}

.PriceAccordion--footer {
    padding: $v;
    background-color: white;
}

.PriceAccordion-countrySelector {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $v * 3;
    .mdc-select__native-control {
        line-height: 1.75rem;
    }
}

.PriceAccordion-body-title {
    @extend .h5;
    border-top: 1px solid $charcoal-2;
    border-bottom: 1px solid $charcoal-2;
    padding: $v * 2 $v * 2 $v * 2 $v * 3;
    color: $midnight;
    background-color: $white;
}

.PriceAccordion.is-open {
    .PriceAccordion-body-title:first-child {
        border-top: none;
    }
}

$charcoal-nav: #384752;
$border-bottom: #e5eaec;
$boxshadow: 0 0 20px 0px rgba(0, 0, 0, 0.15);

$nav-height--mobile: rem(60px);
$nav-height--desktop: rem(75px);
:root {
    --nav-height: #{$nav-height--mobile};

    // This media query needs to duplicate the (undocumented) default `desktop` breakpoint
    // provided by the `react-media-match` package used to control the navigation variant
    @media (min-width: 1023px) {
        --nav-height: #{$nav-height--desktop};
    }
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: var(--nav-height);
}

#NavWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999;

    // @media (#{$medium-bp}) {
    //     position: absolute;
    // }
}

.TimelyNavBar-container {
    position: absolute;
    top: 0;
    background-color: #fff;
    width: 100%;
    border-bottom: 1px solid $border-bottom;
    .container {
        @media (max-width: 1200px) {
            width: 100%;
        }
    }
}
.NavWrapper--hasNotification {
    .TimelyNavBar-container {
        // top: 57px;
    }
}

.TimelyNavBar {
    height: $nav-height--desktop;
    height: var(--nav-height);
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.TimelyNavBar-logo {
    display: flex;
    align-items: center;
    width: 136px;
    > svg {
        width: 100%;
    }
}

.TimelyNavBar-hamburger {
    width: 20px;
    height: 15px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    outline: transparent;
}

.TimelyNavBar-hamburger span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $charcoal-0;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
}

.TimelyNavBar-hamburger {
    background: transparent;
    border: none;
    top: 0px;
}

.TimelyNavBar-hamburger span:nth-child(1) {
    top: 0px;
}

.TimelyNavBar-hamburger span:nth-child(2),
.TimelyNavBar-hamburger span:nth-child(3) {
    top: 6px;
}

.TimelyNavBar-hamburger span:nth-child(4) {
    top: 12px;
}

.TimelyNavBar-hamburger.open span:nth-child(1) {
    top: 5px;
    width: 0%;
    left: 50%;
}

.TimelyNavBar-hamburger.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.TimelyNavBar-hamburger.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.TimelyNavBar-hamburger.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}

.TimelyNavBar-overlay {
    position: absolute;
    left: 0;
    top: 70px;
    padding-top: $v * 3;
    padding-bottom: $v * 3;
    background-color: #fff;
    display: none;

    // transform: translate(0, -100%);
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    // min-height: 50vh;
    width: 100%;
    z-index: -1;

    > .container {
        display: flex;
    }
}

.navbar--hasNotification {
    .TimelyNavBar-overlay {
        top: 127px;
    }
}

.TimelyNavBar-column {
    width: 33.333%;
    float: left;
    padding-left: $v * 3;
    padding-right: $v * 3;
    padding-top: $v * 3;
    padding-bottom: $v * 3;
    border-left: 1px solid $border-color-nav;
    &:first-child {
        padding-left: 0;
        border-left: none;
    }
    &:last-child {
        padding-right: 0;
    }

    .TimelyNavBar-overlay--groups-with-labels &:last-child {
        border-left: none;
        background-color: $cream-0;
        border-radius: 5px;
    }
}

.TimelyNavBar-column--book {
    background-color: $cream-0;
    border-radius: 5px;
    margin-left: -4px;
    padding-right: $v;
    padding: 0;
    .NavBlock {
        padding: $v * 3;
    }

    &:hover {
        .NavBlock-title {
            filter: invert(38%) sepia(99%) saturate(1143%) hue-rotate(327deg)
                brightness(85%) contrast(113%);
        }
        .Button--link-charcoal {
            &::after {
                left: 10px;
            }
        }
    }
}

.TimelyNavBar-column--half {
    width: 50%;
}

.TimelyNavBar-column--quart {
    width: 25%;
}

.TimelyNavBar-main {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0 0 0 $v * 4;
    > li {
        line-height: 2;
    }
}

.TimelyNavBar-secondary {
    display: flex;
    align-items: center;
    .Button--charcoal-alt {
        border: none;
        @media (#{$large-bp}) {
            border: 1px solid $charcoal-0;
        }
    }
}

.TimelyNavBar-main-item {
    display: inline-block;
    color: $charcoal-0;
    font-weight: 700;
    padding: $v * 2.2 $v * 2.5;
    white-space: nowrap;
    text-decoration: none;

    &.isActive {
        color: $hot-coral;
        //here
        filter: invert(38%) sepia(99%) saturate(1143%) hue-rotate(327deg)
            brightness(85%) contrast(113%);
    }
}

.TimelyNavBar__group-title {
    display: block;
    margin-bottom: $v * 2.25;

    color: $charcoal-1;
    font-family: $text-font;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.2;

    // Needed to override unnecessarily broad styles elsewhere
    letter-spacing: 0;
}

.TimelyNavBar-list {
    &::after {
        display: inline-block;
        // content: '\F105';
        content: url('/images/svg-icons/ic-down.svg');
        font-family: fa;
        transition: transform 0.3s ease;
        position: relative;
        left: 8px;
        top: -1px;
        font-size: 18px;
        line-height: $v;
    }

    &:hover {
        &::after {
            filter: invert(38%) sepia(99%) saturate(1143%) hue-rotate(327deg);
        }
    }
}

.TimelyNavBar--active {
    li:not(.isActive) {
        .TimelyNavBar-main-item {
            &::after {
                filter: invert(85%) sepia(4%) saturate(272%) hue-rotate(159deg)
                    brightness(86%) contrast(87%);
            }
            color: $charcoal-2;
        }
    }
}

.NavBlock {
    margin-bottom: $v * 1.5;

    &:hover {
        cursor: pointer;
        .NavBlock-title {
            color: $coral-hover;
        }
    }
}

.NavBlock--standard {
    .NavBlock-title {
        font-family: $domaindisplay;
        font-weight: 500;
        color: $charcoal-0;
        margin-bottom: $v;
        a {
            color: $charcoal-0;
        }
        // &::after {
        //     display: inline-block;
        //     content: '\F105';
        //     font-family: fa;
        //     transition: transform 0.3s ease;
        //     position: relative;
        //     left: 12px;
        //     bottom: 2px;
        //     font-size: 22px;
        //     line-height: $v;
        // }
    }

    @media (#{$large-bp}) {
        display: flex;
    }
}

.TimelyNavBar-column--pricing {
    .NavBlock-title {
        font-family: $domaindisplay;
        font-weight: 500;
        margin-bottom: $v;
    }
    a:hover .NavBlock-title {
        color: $coral-hover;
    }
}

.MenuBlock .NavBlock--standard .NavBlock-title {
    font-family: $domaintext;
    font-weight: 300;
}

.NavBlock--blog {
    @media (#{$medium-bp}) {
        display: inline-block;
        width: calc(50% - #{$v});
        // margin-left : 0;

        &:nth-child(3) {
            float: right;
            margin-left: $v * 2;
        }
    }
    @media (#{$small-bp}) {
        width: calc(50% - #{$v}/ 2);
        float: left;
        margin-right: $v;
        .NavBlock-description {
            display: none;
        }
        &:nth-child(3) {
            margin-right: 0;
        }
    }

    &:hover {
        .NavBlock-description {
            color: $hot-coral;
        }

        .NavBlock-image img {
            transform: scale(1.025);
        }
    }
}

.Button--link-charcoal {
    clear: both;
}

.NavBlock-description {
    color: $charcoal-nav;
    font-size: $v * 1.4;
    line-height: $v * 2;
    margin-top: 1rem;

    p {
        font-size: $v * 1.4;
        margin-top: 8px;
        margin-bottom: 0;
    }
}

.NavBlock--standard {
    .NavBlock-image {
        height: auto;

        @media (#{$medium-bp}) {
            width: 50%;
            min-width: 187px;
            padding-right: $v * 3;
            flex-basis: 100%;
        }
    }

    &:hover {
        .NavBlock-image img {
            transform: scale(1.05);
        }
    }
}

.NavBlock-image {
    border-radius: 4px;
    overflow: hidden;
    img {
        transition: all 1s ease-in;
    }
}

.NavBlock-title--icon {
    display: flex;
    color: $charcoal-nav;
    font-size: $v * 1.4;
    font-weight: bold;
    align-items: center;
    img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }

    &:hover {
        filter: invert(38%) sepia(99%) saturate(1143%) hue-rotate(327deg)
            brightness(85%) contrast(113%);
    }

    @media (#{$medium-bp}) {
        font-size: $v * 1.6;
    }
}
.TimelyNavBar-searchButton {
    border: 0;
    background: transparent;
    line-height: 0;
    margin-right: $v * 2;
    cursor: pointer;
}

.isActive {
    .TimelyNavBar-overlay {
        // transform: translate(0, 0);
        display: block;
        opacity: 1;
        box-shadow: $boxshadow;
    }

    .TimelyNavBar-list {
        &::after {
            top: 2px;
            transform: rotate(-180deg);
        }
    }
}

.TimelyNavBar-column--pricing {
    border-right: 1px solid $border-color-nav;
    text-align: center;
    &:first-child {
        padding-left: $v * 3;
    }
    &:last-child {
        padding-right: $v * 3;
    }

    a {
        color: $charcoal-0;
        &:hover {
            color: $coral-hover;
        }
    }

    h3 {
        margin-bottom: $v;
    }

    > img {
        width: 80%;
    }

    &:nth-child(2) {
        border-left: none;
    }

    &:last-child {
        border-right: none;
    }
}

.TimelyNavBar-searchRecommendationsList {
    list-style: none;
    padding-left: 0;
    li {
        @media (#{$medium-bp}) {
            display: inline-block;
        }
    }

    a {
        color: $charcoal;
        &:hover {
            color: $coral-hover;
        }
        &::after {
            content: ', ';
            margin-right: $v * 1.5;
        }
    }

    li {
        // transform: rotate(90deg);
        &:last-child {
            a::after {
                content: '';
                margin-right: 0;
            }
        }
    }
}

.TimelyNavBar-search {
    transition: transform 0.2s ease-in;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: $boxshadow;
    transform: translateY(-100%);
    form {
        position: relative;
        @media (#{$medium-bp}) {
            width: 70%;
        }
    }

    .container {
        padding-top: $v * 5;
        padding-bottom: $v * 5;
    }
}

.TimelyNavBar-search--open {
    transform: translateY(0);
}

.TimelyNavBar-submit {
    border: 0;
    background: transparent;
    position: absolute;
    right: 10px;
    top: 14px;
}

.TimelyNavBar-searchCancel {
    border: 0;
    background: transparent;
    position: absolute;
    right: $v * 3;
    top: $v * 3;
    cursor: pointer;
}

.TimelyNavBar-searchInput {
    width: 100%;
    padding: $v * 1.5;
    background-color: $charcoal-5;
    margin-bottom: $v * 4;
    border: none;
}

.TimelyNavBar-searchRecommendationsLabel {
    color: $charcoal-3;
    margin-bottom: 0;
}

.TimelyNavBar-menu {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0;

    .TimelyNavBar-hamburger {
        margin-right: $v;
    }

    &:hover {
        .TimelyNavBar-hamburger span:nth-child(2),
        .TimelyNavBar-hamburger span:nth-child(3) {
            width: 20px;
        }
    }
}

.TimelyNavBar-menuBar {
    transform: translateY(-100%);
    padding-left: $v * 2.5;
    padding-right: $v * 1.5;
}

.MenuBlock-titleLink {
    color: $charcoal;
    &::after {
        display: inline-block;
        content: '\F105';
        font-family: fa;
        transition: transform 0.3s ease;
        position: relative;
        left: 18px;
        bottom: 8px;
        font-size: 18px;
        line-height: $v;
    }
}

.NavBlock-title {
    text-decoration: none;
    color: $charcoal-nav;

    a:hover {
        color: $coral-hover;
    }
    &:hover {
        &::after {
            color: $coral-hover;
            left: 22px;
        }
    }
}

.Button--link-charcoal.book-icon {
    &::before {
        content: '\E812';
        font-family: 'fa';
        display: inline-block;
        margin-right: math.div($v, 2);
    }
}

.TimelyNavBar-overlay {
    .Button--link-charcoal {
        color: $hot-coral;
        font-weight: 600;

        &::after {
            display: inline-block;
            content: '\F105';
            font-family: fa;
            transition: all 0.3s ease;
            position: relative;
            left: 4px;
        }

        &:hover {
            &::after {
                left: 10px;
            }
        }
    }
}

@media (#{$small-bp}) {
    .NavBlock--standard {
        .NavBlock-title {
            font-weight: $weight-regular;
            // &::after {
            //     display: none;
            //     content: '\F105';
            //     font-family: fa;
            //     transition: transform 0.3s ease;
            //     position: relative;
            //     left: 18px;
            //     bottom: 8px;
            //     font-size: 18px;
            //     line-height: $v;
            // }
        }
    }

    .NavBlock-title {
        // &::after {
        //     display: inline-block;
        //     content: '\F105';
        //     font-family: fa;
        //     transition: transform 0.3s ease;
        //     position: relative;
        //     left: 10px;
        //     bottom: 0;
        //     font-size: inherit;
        //     line-height: 0;
        // }
    }
}

.TimelyNavBar-PricingDescription {
    line-height: $v * 2;
    color: $charcoal-0;
}

.TimelyNavBar-PricingImage {
    width: 80%;
}

@use "sass:math";

$testimonial-carousel__bp: $medium-bp;

$testimonial-carousel__transition-speed: 0.4s;

.testimonial-carousel {
	background-color: $charcoal-0;
	color: $white;

	padding: (8 * $v) 0;
	@media (#{$testimonial-carousel__bp}) {
		padding: 12 * $v 0;
	}
}

.testimonial-carousel__brands {
	margin-bottom: 8 * $v;
	@media (#{$testimonial-carousel__bp}) {
		margin-bottom: 12 * $v;
	}
}

.testimonial-carousel__brands__tagline {
	@extend %h5;
	text-align: center;

	display: block;
	margin-bottom: 4 * $v;
	@media (#{$testimonial-carousel__bp}) {
		margin-bottom: 8 * $v;
	}
}

$testimonial-carousel__brand-gap: 2 * $h;
@mixin testimonial-carousel__brand-width($itemsPerRow) {
	width: calc(
		#{percentage(math.div(1, $itemsPerRow))} - #{math.div($testimonial-carousel__brand-gap * ($itemsPerRow - 1), ($itemsPerRow))}
	);
}
.testimonial-carousel__brands__list {
	list-style-type: none;
	margin: 0;
	padding: 0;

	display: flex;
	flex-wrap: wrap;
	gap: $testimonial-carousel__brand-gap;
	justify-content: space-around;
}

.testimonial-carousel__brand {
	@include testimonial-carousel__brand-width(3);
	@media (#{$testimonial-carousel__bp}) {
		@include testimonial-carousel__brand-width(6);
	}
}

.testimonial-carousel__brand__figure {
	display: block;
}

.testimonial-carousel__brand__image {
	display: block;

	object-fit: contain;
	object-position: center;
}

.testimonial-carousel__viewport {}

.testimonial-carousel__testimonials {
	list-style: none;
	margin: 0;
	padding: 0;

	display: flex;
	isolation: isolate;
}

.testimonial-carousel__testimonial {
	display: flex;
	flex-direction: column-reverse;
	@media (#{$testimonial-carousel__bp}) {
		flex-direction: row;
		column-gap: 4 * $h;
	}

	width: 100%;
	flex-shrink: 0;
	&:nth-child(n+2) {
		// Stack the slides in place, while still letting them take up height with `visibility: hidden;`
		margin-left: -100%;
	}

	// Appropriate blend mode for cross-fading. Relies on parent having `isolation: isolate;`
	mix-blend-mode: plus-lighter;

	visibility: visible;
	&[aria-hidden="true"] {
		animation: $testimonial-carousel__transition-speed stayVisible;
		visibility: hidden;
	}
}

.testimonial-carousel__testimonial__body {
	flex-grow: 1;
	@media (#{$testimonial-carousel__bp}) {
		padding: (4 * $v) 0;
	}

	opacity: 1;
	transition: opacity math.div($testimonial-carousel__transition-speed, 2) math.div($testimonial-carousel__transition-speed, 2) ease-in-out;
	.testimonial-carousel__testimonial[aria-hidden="true"] & {
		opacity: 0;
		transition: opacity math.div($testimonial-carousel__transition-speed, 2) ease-in-out;
	}
}

.testimonial-carousel__controls {
	margin-top: 2 * $v;
	@media (#{$testimonial-carousel__bp}) {
		width: 50%;
		transform: translateY(calc(-100% - #{3 * $v}));
	}
}

.testimonial-carousel__testimonial__tagline {
	@extend %h5;

	display: block;
	margin-bottom: 2 * $v;
}

.testimonial-carousel__testimonial__quote {
	// It's necessary to override default styles that are applied too broadly
	margin: 0;
	padding: 0;
	border-left: none;
}

.testimonial-carousel__testimonial__quote,
.testimonial-carousel__testimonial__content {
	@extend %h2;
}

.testimonial-carousel__testimonial__quote__text {
	@supports not (hanging-punctuation: first) {
		&:first-letter {
			display: inline-block;
			// This size is based on a measurement of the size of the "“" character in this font.
			margin-left: -0.38em;
		}
	}
	@supports (hanging-punctuation: first) {
		&:first-letter {
			margin-left: none;
		}
		hanging-punctuation: first;
	}

	&::before {
		content: "“";
	}
	&::after {
		content: "”";
	}
}

.testimonial-carousel__testimonial__citation {
	// It's necessary to override default styles that are applied too broadly
	text-transform: none;
	color: inherit;
	margin: 0;
	letter-spacing: initial;
	font-style: normal;

	@extend %p;
	font-weight: $weight-bold;

	display: block;
	margin-top: 2 * $v;
}

.testimonial-carousel__testimonial__video-button {
	appearance: none;
	margin: 0;
	padding: 0;
	border-radius: 0;
	border: none;
	background-color: transparent;
	cursor: pointer;

	color: $white;
	position: relative;
	&::before {
		content: "";

		position: absolute;
		z-index: 1;

		top: 50%;
		right: 50%;
		transform: translate(50%, -50%);

		width: rem(58px);
		height: rem(58px);
		@media (#{$testimonial-carousel__bp}) {
			width: rem(94px);
			height: rem(94px);
		}
		border-radius: 100%;

		background-color: $hot-coral;
		transition: background-color 0.2s ease-in-out;
	}
	&::after {
		content: "";

		position: absolute;
		z-index: 1;

		top: 50%;
		right: 50%;
		transform: translate(67%, -50%);

		border-left: rem(24px) solid currentColor;
		border-top: rem(15px) solid transparent;
		border-bottom: rem(15px) solid transparent;
		@media (#{$testimonial-carousel__bp}) {
			border-left: rem(39px) solid currentColor;
			border-top: rem(24px) solid transparent;
			border-bottom: rem(24px) solid transparent;
		}
	}

	&:hover,
	&:focus-visible {
		&::before {
			background-color: $coral-hover;
		}
	}
}

.testimonial-carousel__testimonial__figure {
	display: block;
	margin: 0 0 (2 * $v);

	@media (#{$testimonial-carousel__bp}) {
		margin: 0;

		flex-shrink: 0;
		width: 50%;

		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	opacity: 1;
	transition: opacity $testimonial-carousel__transition-speed ease-in-out;
	.testimonial-carousel__testimonial[aria-hidden="true"] & {
		opacity: 0;
	}
}

.testimonial-carousel__testimonial__image {
	display: block;
	object-fit: cover;
	object-position: center;
}

.testimonial-carousel__testimonial__video {}

$hero-centred__bp: $medium-bp;

.hero-centred {
	position: relative;
}

.hero-centred__background {
	position: absolute;
	inset: 0;
}

.hero-centred__background__desktop,
.hero-centred__background__mobile {
	position: absolute;
	inset: 0;

	background-size: cover;
}

.hero-centred__background__desktop {
	display: none;
	@media ($hero-centred__bp) {
		display: block;
	}
}

.hero-centred__background__mobile {
	display: block;
	@media ($hero-centred__bp) {
		display: none;
	}
}

.hero-centred__content {
	position: relative;
	z-index: 1;

	margin: 0 auto;
	max-width: 650px;

	text-align: center;
	padding: (4 * $v) 0;
	@media ($hero-centred__bp) {
		padding: (8 * $v) 0;
	}
}

.hero-centred__content--light {
	color: $white;
}

.hero-centred__logo {
	display: block;
	max-width: 50%;
	margin: 0 auto (3 * $v);
}

.hero-centred__tagline {
	@extend %h4;

	display: block;
	margin-bottom: 2 * $v;
}

.hero-centred__title {
	@extend %h1;

	margin: 0 0 (2 * $v)
}

.hero-centred__title--jumbo {
	@extend %h1--jumbo;
}

.hero-centred__text {
	@extend %h6;
	font-weight: normal;

	display: block;
	margin-bottom: 4 * $v;
}

.hero-centred__buttons {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	gap: $v (2 * $h);
}

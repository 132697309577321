.Parallax-container {
  position: relative;
  @media (#{$small-bp}) {
    padding-left: 0;
    padding-right: 0;
    > div {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.Parallax-foreground {
  position: absolute;
  top: $v;
  z-index: 1;
  img {
    max-width: 100%;
  }
}

.Parallax--center {
  .Parallax-foreground {
    top: 50%;
    transform: translateY(-50%);
  }
}

.Parallax--right {
  @media (#{$medium-bp}) {
    .Parallax-foreground {
      right: -25%;
      left: 25%;
    }
  }

  @media (#{$small-bp}) {
    .Parallax-foreground {
      right: -$v;
      left: $v;
    }
    .Parallax-background {
      right:  30px;
      position: relative;
    }
  }
}

.Parallax--left {
  @media (#{$medium-bp}) {
    .Parallax-foreground {
      left: -25%;
      right: 25%;
    }
  }

  @media (#{$small-bp}) {
    .Parallax-foreground {
      right: $v;
      left: -$v;
    }
    .Parallax-background {
      left:  30px;
      position: relative;
      img {
        float: right;
      }
    }
  }
}

// .Parallax-foreground {
//   position: absolute;
//   bottom: 0;
// }

#share-animated {
  width: 50%;
  float: right;
}

@media (#{$small-bp}) {
  .Parallax-background {
    img {
      max-width: 85%;
    }
  }
}

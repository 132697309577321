.container {
    // width: 100%;
    // margin-right: auto;
    // margin-left: auto;
    // padding-left: 15px;
    // padding-right: 15px;

    // @media (#{$medium-bp}) {
    //   max-width: 970px;
    // }

    // @media (#{$large-bp}) {
    //   width: 1170px;
    //   max-width: 100%;
    // }
    // @media (#{$xxlarge-bp}) {
    //   width: 1800px;
    //   max-width: 100%;
    // }
    @extend %container;
}

.wp-block-columns {
    @extend .container;
}

.alignwide {
    margin-left: -80px;
    margin-right: -80px;
}
.wp-block-image.alignfull {
    width: initial;
    max-width: initial;
    min-height: 100vw;

    img {
        height: 100vw;
        width: auto;
        max-width: initial;
        margin-left: -50vw;
    }

    @media (#{$large-bp}) {
        min-height: initial;
        max-width: 100vw;
        img {
            height: auto;
            width: 100vw;
            margin-left: calc(-100vw / 2 + 100% / 2);
            margin-right: calc(-100vw / 2 + 100% / 2);
        }
    }
}

.has-6-columns {
    flex-wrap: wrap;
    .wp-block-column {
        flex-basis: 100%;
        margin-left: $v * 1.5;
        margin-right: $v * 1.5;

        @media (min-width: 600px) {
            &:first-child {
                margin-left: $v * 1.5;
                margin-right: $v * 1.5;
            }
            &:last-child {
                margin-left: $v * 1.5;
                margin-right: $v * 1.5;
            }
        }

        @media (#{$medium-bp}) {
            flex-basis: calc(50% - 3rem);
        }
        @media (#{$large-bp}) {
            width: 1170px;
            flex-basis: calc(33% - 3rem);
        }
    }
}

.has-4-columns.u-vSpacingLG-top {
    .wp-block-column {
        padding-top: $v * 3;
        @media (#{$medium-bp}) {
            padding-top: $v * 9;
        }
    }
}

.has-3-columns.u-vSpacingLG-top {
    padding-top: $v * 3;

    .wp-block-column {
        margin-left: auto;
        margin-right: auto;
        p {
            margin-top: $v;
        }
    }

    .wp-block-column {
        padding-top: $v * 6;
        flex-basis: 100%;
    }

    @media (#{$medium-bp}) {
        padding-top: $v * 9;
        .wp-block-column {
            flex-basis: calc(33%);
            margin-left: $v * 1.5;
            margin-right: $v * 1.5;
        }
    }

    @media (#{$large-bp}) {
        .wp-block-column {
            margin-left: $v * 1.5;
            margin-right: $v * 1.5;
        }
    }
}

.page-about-2,
.page-about {
    .wp-block-column {
        margin-bottom: 0;
    }
}

@media (min-width: 600px) {
    .wp-block-column,
    .wp-block-column:first-child,
    .wp-block-column:last-child {
        padding-left: $v * 1.5 !important;
        padding-right: $v * 1.5 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

.wp-block-column {
    flex-basis: 100%;
}

.wp-block {
    max-width: initial;
}

.components-panel__body.editor-block-inspector__advanced {
    display: block;
}

.has-3-columns {
    .wp-block-column {
        flex-basis: 100%;
        @media (#{$medium-bp}) {
            flex-basis: calc(33.33%);
        }
    }
}
.has-4-columns {
    @media (#{$medium-bp}) {
        flex-wrap: wrap;
    }
    .wp-block-column {
        flex-basis: 100%;
        @media (#{$medium-bp}) {
            flex-basis: calc(50%);
        }
        @media (#{$large-bp}) {
            flex-basis: calc(25%);
        }
    }
}

.single-feature,
.addon-cards-grey {
    .AddOnCards {
        @extend .u-vSpacingLG;
        margin-top: 0;
        // padding-top: $v * 9;
        // padding-bottom: $v * 9;
        background-color: $charcoal-5;
    }

    .AddOnCard {
        background-color: $white;
    }
}

.single-blog .container {
    @media (#{$xxlarge-bp}) {
        width: 1170px;
    }
}

.ColumnLayout {
    @media (#{$medium-bp}) {
        display: flex;
        flex-wrap: wrap;
    }
    .ColumnLayout-column {
        .wp-block-image {
            margin: 0;
        }
    }
}

.ColumnLayout-column {
    flex-grow: 0;
    flex-shrink: 0;
    @media (#{$medium-bp}) {
        padding: 0;
        margin-left: 15px;
        margin-right: 15px;
        // &:first-child {
        //     margin-left: 0;
        // }
        &:last-child {
            margin-right: 0;
        }
    }
}

.ColumnLayout-full-col {
    .ColumnLayout-column {
        flex-basis: 100%;
    }
}
.ColumnLayout-two-equal-col {
    .ColumnLayout-column {
        @media (#{$medium-bp}) {
            flex-basis: calc(50% - 3rem);
            width: calc(50% - 3rem);
        }
    }
}

.ColumnLayout-three-equal-col {
    .ColumnLayout-column {
        @media (#{$medium-bp}) {
            flex-basis: calc(33.33% - 3rem);
        }
    }
}

.ColumnLayout-four-equal-col {
    .ColumnLayout-column {
        @media (#{$medium-bp}) {
            flex-basis: calc(50% - 3rem);
            &:nth-child(2) {
                margin-right: 0;
            }
            &:nth-child(3) {
                margin-left: 0;
            }
        }
        @media (#{$large-bp}) {
            &:nth-child(2) {
                margin-right: 15px;
            }
            &:nth-child(3) {
                margin-left: 15px;
            }
            flex-basis: calc(25% - 3rem);
        }
    }
}

.ColumnLayout-six-equal-col {
    .ColumnLayout-column {
        @media (#{$medium-bp}) {
            &:nth-child(3) {
                margin-right: 0;
            }
            &:nth-child(4) {
                margin-left: 0;
            }
            flex-basis: calc(33.33% - 3rem);
        }
        @media (#{$large-bp}) {
            &:nth-child(3) {
                margin-right: 15px;
            }
            &:nth-child(4) {
                margin-left: 15px;
            }
            flex-basis: calc(16.666666667% - 3rem);
        }
    }
}

.ColumnLayout-two-eight-two-col {
    .ColumnLayout-column {
        &:last-child,
        &:first-child {
            display: none;
        }
        @media (#{$medium-bp}) {
            flex-basis: calc(66.666666667% - 3rem);
            width: calc(66.666666667% - 3rem);
            &:last-child,
            &:first-child {
                flex-basis: calc(16.666666667% - 3rem);
                width: calc(16.666666667% - 3rem);
                display: block;
            }
        }
        @media (#{$large-bp}) {
            flex-basis: calc(66.666666667% - 3rem);
            width: calc(66.666666667% - 3rem);
        }
    }
}

.ColumnLayout-three-six-three-col {
    .ColumnLayout-column {
        &:last-child,
        &:first-child {
            display: none;
        }
        @media (#{$medium-bp}) {
            flex-basis: calc(50% - 3rem);
            &:last-child,
            &:first-child {
                flex-basis: calc(25% - 3rem);
                display: block;
            }
        }
    }
}

.ColumnLayout-six-three-three-col {
    .ColumnLayout-column {
        @media (#{$medium-bp}) {
            flex-basis: calc(50% - 3rem);
            &:nth-child(2) {
                margin-left: 0;
            }
            &:first-child {
                flex-basis: calc(100% - 3rem);
                margin-right: 0;
            }
        }
        @media (#{$large-bp}) {
            flex-basis: calc(25% - 3rem);
            &:nth-child(2) {
                margin-left: 15px;
            }
            &:first-child {
                flex-basis: calc(50% - 3rem);
                padding-right: 15px;
            }
        }
    }
}
.ColumnLayout-three-three-six-col {
    .ColumnLayout-column {
        @media (#{$medium-bp}) {
            flex-basis: calc(50% - 3rem);
            &:nth-child(2) {
                margin-right: 0;
            }
            &:last-child {
                flex-basis: calc(100% - 3rem);
                margin-left: 0;
            }
        }
        @media (#{$large-bp}) {
            flex-basis: calc(25% - 3rem);
            &:nth-child(2) {
                margin-right: 15px;
            }
            &:last-child {
                flex-basis: calc(50% - 3rem);
                margin-left: 15px;
            }
        }
    }
}

.u-sm-textCenter {
    @media (#{$small-bp}) {
        text-align: center;
    }
}

.ColumnLayout-six-one-five-col {
    @media (#{$medium-bp}) {
        > div:nth-child(1) {
            flex-basis: calc(50% - 3rem);
        }
        > div:nth-child(2) {
            flex-basis: calc(8.3% - 3rem);
        }
        > div:nth-child(3) {
            flex-basis: calc(41.6% - 3rem);
        }
    }
}
.ColumnLayout-five-one-six-col {
    @media (#{$small-bp}) {
        display: flex;
        flex-direction: column-reverse;
    }

    @media (#{$medium-bp}) {
        > div:nth-child(1) {
            flex-basis: calc(41.6% - 3rem);
        }
        > div:nth-child(2) {
            flex-basis: calc(8.3% - 3rem);
        }
        > div:nth-child(3) {
            flex-basis: calc(50% - 3rem);
        }
    }
}

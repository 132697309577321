.pathway-cards__col {
    display: flex;
    justify-content: center;
}

.pathway-cards__col-l {
    display: flex;
    justify-content: flex-end;
}

.pathway-cards__col-r {
    display: flex;
    justify-content: flex-start;
}

.pathway-cards__gx-6 {
    --bs-gutter-x: 4.5rem;
}

.pathway-cards__card_outer {
    transition: box-shadow 0.2s ease-out;
    height: 100%;
    width: 100%;
    max-width: 520px;
    border-radius: 3px;
}

.pathway-cards__card_outer:hover {
    box-shadow: 8px 8px;
    border-radius: 3px;
}

.pathway-cards__card_inner {
    width: 100%;
    height: 100%;
    position: relative;
    align-items: stretch;
    border: 1px solid $charcoal-0;
    color: $charcoal-0;
    padding: 2.5rem 2.5rem 4rem 2.5rem;
    border-radius: 3px;
    background-color: $white;
    flex-grow: 1;
    text-align: center;
}

.pathway-cards__image {
    width: 100%;
    max-width: 100%;
    height: auto;
}

.pathway-cards__button{
   margin-top: 2.5rem; 
}

/* Tablets and below */
@media ($large-bp-below) {
    .pathway-cards__col-l,
    .pathway-cards__col-r {
        justify-content: center;
    }
}

/* Mobile and below */
@media ($small-bp) {
    .pathway-cards__card_inner{
        padding: 2rem 2rem 3rem 2rem;
    }

    .pathway-cards__gx-6 {
        --bs-gutter-x: 0;
    }

    .pathway-cards__col-r {
        padding-top: 15px;
    }
}
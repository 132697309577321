// Additional styling for the 'accordion' block
.AccordionPanel .panel-default{
    border-color: $charcoal-0;
}

.AccordionPanel .panel-group .panel{
    border-radius: 0;
}

.AccordionPanel .panel-default > .panel-heading{
    border-color: $charcoal-0;
}

.AccordionPanel .panel-default > .panel-heading + .panel-collapse > .panel-body{
    border-color: $charcoal-0;
}

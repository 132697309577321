%container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100% !important;

    @media (#{$medium-bp}) {
        width: 750px;
    }

    @media (#{$large-bp}) {
        width: 970px;
    }

    @media (#{$xlarge-bp}) {
        width: 1170px;
    }
}

%container-wide {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100% !important;

    @media (#{$medium-bp}) {
        width: 970px;
    }

    @media (#{$large-bp}) {
        width: 1200px;
    }

    @media (#{$xlarge-bp}) {
        width: 1400px;
    }
}

.CookieConsent {
  background-color: rgba(0, 0, 0, .85);
  color: #fff;
  padding-top: $v * 1;
  padding-bottom: $v * 1;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9999999999; // need to make it big for intercom shuts up.
  font-size: $v * 1.4;

  .container {
    display: flex;
    align-items: center;
  }
}

.CookieConsent-description {
  margin-right: $v * 3;

  @media (#{$large-bp}) {
    display: flex;
    align-items: center;
  }
}

.CookieConsent-link {
  a {
    transition: all .25s ease-in-out;
    color: $coral-3;
    box-shadow:
    inset 0 -0.1rem $coral-3;

    &:hover {
      color: $coral-2;
      box-shadow:
      inset 0 -0.1rem $coral-2;
    }
  }

  @media (#{$large-bp}) {
    margin-left : $v * 3;
  }
}

.CookieConsent-button {
  margin-left: auto;
  outline: none;
  button {
    background-color: $charcoal-1;
    transition: background .25s ease-in-out;
    border: 0;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    padding: 0 10px;
    &:hover {
      background-color: $charcoal-2;
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
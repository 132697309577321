

.registerform,
.webinar-form {
  position: relative;

  form{
      position: relative;
      opacity: 1;
      z-index: 2;
      transition: opacity 500ms;
  }

  .thanks-message{
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      padding-top: 120px;
      text-align: center;

      opacity: 0;
      z-index: 1;
      transition: opacity 500ms;
  }

  div.check{
      input{
          position: relative;
          top: 20px;
          left: 11px;
      }
  }

  .privacy-inline{
      text-align: center;
  }

  &.thanks{
      form{
          opacity: 0;
          z-index: 1;
          pointer-events: none;
      }

      .thanks-message{
          opacity: 1;
          z-index: 2;
      }
  }
}

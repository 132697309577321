.u-bgPink {
    background-color: $dusky-pink;
}

.u-dusky {
    background: $dusk-2;
    color: $charcoal-base;
}

.u-dusky4 {
    background: $dusk-4;
    color: $charcoal-base;
}

.u-bgCharcoal {
    background-color: $charcoal;
    color: $white;

    .Button--link,
    .Button--link:visited {
        color: $light-coral;
        box-shadow: inset 0 -0.1rem $light-coral;
    }
    .Button--link:hover,
    .Button--link:focus {
        color: $hot-coral;
        box-shadow: inset 0 -0.1rem $hot-coral;
    }
}

.u-bgCharcoal5 {
    background-color: $charcoal-5;
    color: $charcoal;
}

.u-bgSalonTrends {
    background-color: #60315e;
}

$backgrounds: (
    'pink-blob-left': '/images/background-images/pink-blob-left.jpg',
    'pink-blob-right': '/images/background-images/pink-blob-right.jpg',
    'purple-blob-left': '/images/background-images/purple-blob-left.jpg',
    'purple-blob-right': '/images/background-images/purple-blob-right.jpg',
    'pink-brush': '/images/background-images/pink-brush.jpg',
    'pricing': '/images/background-images/pricing.jpg',
);

@each $name, $value in $backgrounds {
    .bgImage-#{$name} {
        background-image: url($value);
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.bgImage--positionBottom {
    background-position: bottom;
}

.PostSearch-form {
  position: relative;
  margin-right: $v * 1.5;
  width: 220px;

  form {
    height: 100%;
  }
}

.PostSearch-input {
  height: 100%;
  width: 100%;
  padding: $v;
  outline: none;
  min-width: 200px;
  border: 1px solid $charcoal-3;
  background-color: rgba(0,0,0,0.06);
}

.PostSearch-submit {
  position: absolute;
  top: -2px;
  right: $v * .5;
  padding: 0;
  height: 100%;
  outline: none;
}

// .ResourceHub.category {
  // .PostSearch {
  //   padding-right: $v * 3;
  // }
// }

.PostSearch-form--mobile {
  margin-right: auto;
  margin-left: auto;
  background-color: rgba(0,0,0,0.06);

  .PostSearch-input {
    background: transparent;
    border: none;
    width: 100%;

    &:focus {
      outline: none;
      border: 1px solid $charcoal;
    }
  }

}
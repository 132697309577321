.FeaturesList {
    @extend .u-vSpacingXL-top;
    overflow: hidden;

    display: flex;
    position: relative;
    // align-items: center;
    flex-direction: column-reverse;

    @media (#{$large-bp}) {
        flex-direction: row;
    }

    &.u-vSpacingLG-top {
        .FeaturesList-content {
            @media (#{$large-bp}) {
                transform: translateY(-50%);
            }
        }
    }
}

.FeaturesList-content {
    width: 100%;

    @media (#{$large-bp}) {
        position: absolute;
        top: 50%;
        transform: translateY(-35%);
        .container {
            &,
            > div {
                padding: 0;
            }
        }
    }
}
.FeaturesList-item {
    padding-bottom: $v * 4;
    position: relative;
    left: 0;
    transition: left 0.3s ease-in-out;

    @media (#{$large-bp}) {
        padding-bottom: $v * 5;
    }
}

.FeaturesList-item--hasLink {
    &:hover {
        left: 5px;
    }
}

.FeaturesList-description {
    margin-bottom: 0;
}

.FeaturesList-icon {
    margin-right: 1.5rem;
    width: 29px;
    flex-shrink: 0;
    flex-grow: 0;
}

.FeaturesList-image {
    padding-left: $v * 3;
    padding-right: $v * 3;
    padding-bottom: $v * 4;

    img {
        width: 100%;
    }

    @media (#{$medium-bp}) {
        padding-bottom: $v * 6;
    }

    @media (#{$large-bp}) {
        padding-bottom: 0;

        img {
            width: auto;
        }
    }
}

.FeaturesList-item {
    h4 {
        display: flex;
        align-items: center;
        color: $hot-coral;
        letter-spacing: 3.4px;
        line-height: 2.2rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.4rem;
        @media (#{$large-bp}) {
            font-size: 1.6rem;
        }
    }

    .svg g,
    .svg path {
        fill: $hot-coral;
    }

    p {
        color: $charcoal;
    }

    &,
    &:visited {
        h4 {
            color: $hot-coral;
        }
        p {
            color: $charcoal;
        }
    }

    &:last-child {
        @media (#{$medium-bp}) {
            padding-bottom: 0;
        }
    }
}

.FeaturesList-link {
    &:hover {
        h4 {
            color: $coral-hover;
        }
        .svg g,
        .svg path {
            fill: $coral-hover;
        }
    }
}

.AccordionList-actions,
.FeaturesList-actions {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    .Button {
        display: block;

        &:first-child {
            margin-bottom: $v * 1.5;
        }
    }

    @media (#{$medium-bp}) {
        flex-direction: row;
        .Button {
            margin-right: $v * 3;
        }
        align-items: baseline;
        justify-content: center;
    }

    @media (#{$large-bp}) {
        justify-content: flex-start;
        text-align: left;
        .Button:first-child {
            margin-bottom: 0;
        }
    }
}

.FeaturesBucket {
    @extend .u-vSpacingLG-top;

    .container {
        display: flex;
        flex-direction: column;
    }

    @media (#{$large-bp}) {
        display: flex;
        flex-basis: 50%;
        .container {
            flex-direction: row;
            align-items: center;
        }
    }

    .slick-slide {
        outline: none;
        padding: 0 $v * 1.5;
        transition: width 0.2s ease-out;
    }

    h3 {
        padding-bottom: $v * 6;
        color: $charcoal-1;

        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.FeaturesList-gallery {
    position: relative;
    margin-bottom: $v * 8;
    @media (#{$large-bp}) {
        margin-bottom: 0;
    }
}

.FeatureBucket-backgroundImage {
    max-width: 100%;
    @media (#{$large-bp}) {
        width: 82%;
    }
}

.FeatureBucket-foregroundImage {
    position: absolute;
    bottom: -$v * 6;
    width: 80%;
    left: 50%;
    transform: translate(-50%, 0);

    @media (#{$medium-bp}) {
        width: 64%;
        bottom: 50%;
        right: $v;
        left: initial;
        transform: translate(0, 50%);
    }
    @media (#{$xxlarge-bp}) {
        width: 44%;
    }
}

.FeaturesBucket--reverse {
    .container {
        display: flex;
        flex-direction: column-reverse;

        @media (#{$medium-bp-only}) {
            align-items: flex-end;
        }

        @media (#{$large-bp}) {
            flex-direction: row;
        }
    }

    .FeatureBucket-backgroundImage {
        float: right;
    }

    .FeatureBucket-foregroundImage {
        bottom: -$v * 4;
        left: 50%;
        transform: translate(-50%, 0);

        @media (#{$medium-bp}) {
            bottom: 50%;
            left: -$v;
            transform: translate(0, 50%);
        }
    }
}

$form-field-v-space: $v * 2;

.SignUp {
    .Button--primary {
        min-height: 54px;
    }
}

.SignUp-row {
    margin-bottom: $form-field-v-space;
}

.SignUp-row--two-col {
    .col-sm-6:first-child {
        margin-bottom: $form-field-v-space;

        @media (#{$medium-bp}) {
            padding-right: $v;
            margin-bottom: 0;
        }
    }
    .col-sm-6:last-child {
        @media (#{$medium-bp}) {
            padding-left: $v;
        }
    }
}

.SignUp-select {
    width: 100%;
}

.SignUp-error {
    color: $error-red;
    padding: $v * 1.5 $v * 3;
    margin-bottom: $v;
    background-color: $dusk-3;
    border-radius: 5px;

    p {
        font-size: $v * 1.4;
        margin-bottom: 0;
        margin-top: 0;
    }
}

.SignUp-field-error {
    color: $error-red;
    font-size: 1.2rem;
}

.SignUp-select-flex {
    display: flex;
    flex-direction: column;
}

.SignUp-wrap {
    @media (#{$medium-bp}) {
        padding-top: $v * 3;
    }
    @media (#{$xlarge-bp}) {
        width: calc(100% / 3);
        margin-left: auto;
        margin-right: auto;
        float: none;
        min-width: 700px;
    }
}

.SignUp-promo-link {
    box-shadow: none;
    font-size: $v * 1.4;
    &:hover {
        box-shadow: none;
    }
}

.SignUp-title {
    margin-top: $v * 2;
    margin-bottom: $v;
}

.SignUp-subtitle {
    @media (#{$large-bp}) {
        margin-bottom: $v * 3;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .SignUp input[type='password'] {
        -webkit-text-stroke-width: 0.35rem;
        letter-spacing: 0.5rem;
    }
}

.SignUp-dots {
    font-size: 14px;
    color: #889095;
    .slick-dots {
        margin-top: 0;
        margin-left: $v;
    }
    .dot-1 {
        cursor: pointer;
    }
}
.SignUp-sweetTitle {
    // @extend %h2;
    padding-top: $v * 4;
    padding-bottom: 0;
    text-align: center;
    margin: 0;

    @media (#{$medium-bp}) {
        text-align: left;
        padding-top: $v * 9;
    }
}

.SignUp-dropdown {
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin: auto;
    background: none;
    border: none;
}

.SignUp-dropdown--on {
    img {
        transform: rotate(180deg);
    }
}

.password-requirements {
    margin-top: 8px;
}

.password-requirements__label {
    text-align: left;
    font-style: italic;
}

.password-requirements__list {
    margin-top: 8px;
}

.password-requirement__line {
    display: flex;
    margin: 0;

    color: #888;
    align-items: center;
    line-height: 30px;
}

.password-requirement__line-status {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 8px 0 0;
}

.Brochure .password-requirement__line {
    color: #fff;
}

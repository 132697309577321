$quotesBorder: 1px solid $charcoal-0;
.big-quote-slide {
    > * {
        @media (#{$medium-bp}) {
            max-width: 66%;
        }
        margin-left: auto;
        margin-right: auto;
    }

    .h4,
    .h6,
    .ctmidgray {
        color: $charcoal-1;
    }

    text-align: center;
    p {
        &:before,
        &:after {
            display: none;
            font-size: 50px;
            width: 0;
            height: 0;
            vertical-align: text-top;
            position: relative;
        }

        &:before {
            content: '“';
            left: -20px;
            top: -2px;
        }

        &:after {
            content: '”';
            left: 4px;
            top: -4px;
        }
    }
}

.big-quote-carousel {
    .slick-dots {
        padding: 0;
        margin-top: $v * 2;

        li {
            display: inline-block;
            list-style-type: none;
            height: 14px;
            transform: scale(0.8);

            button {
                -webkit-appearance: none;
                text-align: left;
                white-space: nowrap;
                overflow: hidden;
                text-indent: -9999px;
                background-color: $charcoal-4;
                border-radius: 50%;
                width: 14px;
                height: 14px;
                margin: 0 5px;
                padding: 0;
                border: none;
                outline: none;
            }

            &.slick-active {
                button {
                    background-color: $hot-coral;
                }
            }
        }
    }
}

.Carousel-center,
.big-quote-carousel {
    // margin-bottom: $v * 3;
    // @media (#{$medium-bp}) {
    // margin-bottom: $v * 15;
    // margin-top: $v * 6;
    // }
    // width: 66%;
    // margin: 0 auto;
    .slick-arrow {
        -webkit-appearance: none;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        color: rgba(0, 0, 0, 0);
        border: solid 2px $charcoal-3;
        border-right: none;
        border-bottom: none;
        border-radius: 1px;
        outline: none;
        background: none;
        width: 30px;
        height: 30px;
        padding: 0;
        bottom: 45%;
        position: absolute;
        z-index: 9;
        display: none !important;

        @media (#{$medium-bp}) {
            display: block !important;
        }

        &:hover {
            border-color: $charcoal-2;
        }

        &::after {
            border-radius: 50%;
            content: ' ';
            display: block;
            height: 100px;
            width: 100px;
            position: absolute;
            top: -40px;
            left: -40px;
            z-index: -2;
            background: transparent;
        }

        &::before {
            border: none;
        }
    }

    .slick-prev {
        transform: rotateZ(-45deg);
        left: 20px;
        overflow: visible;
        @media (#{$xlarge-bp}) {
            left: 120px;
        }
    }

    .slick-next {
        transform: rotateZ(135deg);
        right: 20px;
        overflow: visible;
        @media (#{$xlarge-bp}) {
            right: 120px;
        }
    }

    .Endorsement {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        padding-left: $v;
        padding-right: $v;
        @media (#{$medium-bp}) {
            width: 60% !important;
            display: block !important;
        }
    }
}

.quote-nav-carousel {
    // width: 330px;
    margin: 0 auto;

    .quote-nav-slide {
        padding: 10px;

        img {
            width: 100%;
            border-radius: 50%;
            overflow: hidden;
        }
    }
}

.QuoteCarousel--small {
    margin-top: $v * 6;

    @media (#{$medium-bp}) {
        margin-top: $v * 3;
    }

    .Carousel-center {
        margin-top: 0;
        width: 100%;

        .Endorsement {
            width: 100% !important;
        }

        @media (#{$medium-bp}) {
            margin-bottom: 0;
        }

        @media (#{$xlarge-bp}) {
            float: left;
            text-align: left;
        }
    }

    .Endorsement-quote {
        @extend %h3;
        color: $charcoal-1;

        @media (#{$large-bp}) {
            text-align: left;
            font-size: $v * 2.2;
            line-height: $v * 2.8;
        }
    }

    .Flag {
        color: $charcoal-1;
        @media (#{$large-bp}) {
            justify-content: flex-start;
        }
    }

    .slick-dots {
        @media (#{$large-bp}) {
            justify-content: flex-start;
        }
    }

    @media (#{$medium-bp}) {
        margin-top: $v * 6;
    }

    @media (#{$large-bp}) {
        margin-top: 0;
    }
}

.QuoteCards-item {
    display: flex !important;
    border: $quotesBorder;
    border-radius: 7px;
    padding: $v * 3 $v * 2;
    height: 100%;
    flex-direction: column;
    background-color: $white;
    > div {
        &:first-child {
            padding-bottom: $v * 1.5;
        }

        &:last-child {
            border-top: $quotesBorder;
            padding-top: $v * 3;
            margin-top: auto;
        }
    }

    @media (#{$medium-bp}) {
        flex-direction: row;
        > div {
            width: 50%;
            flex-shrink: 0;
            height: 100%;
            align-items: center;
            display: flex;
            align-items: center;

            &:first-child {
                padding-bottom: 0;
                padding-right: $v * 1.5;
                border-right: $quotesBorder;
            }

            &:last-child {
                border-top: 0;
                padding-top: 0;
                padding-left: $v * 3;
            }
        }
    }
    .Flag {
        margin-top: $v * 1.5;
    }
    .Flag-image img {
        width: 120px;
    }
    .Flag-title {
        font-weight: 700;
        margin-bottom: math.div($v, 2);
        margin-top: math.div($v, 2);
    }
    .Flag-actions {
        margin-top: $v * 1.5;
    }
}

.QuoteCards-title {
    font-family: $domaindisplay;
    font-size: $v * 2;
    line-height: $v * 2.5;
    font-weight: 300;
    text-transform: none;
    letter-spacing: 0;
    margin-bottom: 0;
    &:before {
        content: '“';
        font-family: $domaindisplay;
        font-size: $v * 5;
        display: block;
        color: $rose;
    }
}

.QuoteCards {
    max-width: calc(100% + #{$v} * 3);
    @media (#{$medium-bp}) {
        max-width: 100%;
    }

    .slick-track {
        display: flex !important;
    }

    .slick-slide {
        height: auto;
        max-width: 100%;
        margin-left: $v;
        margin-right: $v;
        > div {
            height: 100%;
        }
    }

    ul.slick-dots button{
        border: 1px solid $charcoal;
    }

    ul.slick-dots .slick-active button{
        background-color: $charcoal;
    }
}

.Carousel--leftAlign {
    .Carousel-center {
        .Endorsement {
            @media (#{$medium-bp}) {
                width: 100% !important;
            }
            h3 {
                text-align: left;
            }
            .Flag {
                justify-content: flex-start;
            }
        }
    }
}

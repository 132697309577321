.hero-banner {
    &,
    &.HeroBanner--featurePage {
        overflow: hidden;
    }
}

.hero-banner__wrapper {
    // This is mainly just needed to override other problematic styles
    .hero-banner.HeroBanner--featurePage & {
        position: relative;
        top: auto;
        transform: none;
    }
}

.hero-banner__layout {
    @media (#{$large-bp}) {
        display: flex;
        align-items: flex-start;
    }
}

.hero-banner__body {
    // col-sm-10 col-sm-offset-1 col-md-offset-0 col-md-6

    @media (#{$large-bp}) {
        flex: 0 0 50%;
    }
}

.hero-banner__offset-image {
    @media (#{$small-bp}) {
        margin-top: 5 * $v;
    }

    @media (#{$medium-bp}) {
        margin: 10 * $v auto;

        width: percentage(10/12);
    }

    @media (#{$large-bp}) {
        margin-top: 0;
        margin-left: percentage(2/12);
    }

    @media (#{$xxlarge-bp}) {
        width: 65vw;
        margin-bottom: 10 * $v;
    }

    img {
        box-shadow: 0 0 65px 0 rgba(0, 0, 0, 0.12);
    }
}

//////////////////////////////
// Old code from this point //
//////////////////////////////

.HeroBanner {
    position: relative;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    text-align: center;
    color: $charcoal;

    @media (#{$medium-bp}) {
        min-height: 760px;
        height: calc(100vh - 247px);
        text-align: left;
        background-position: center center;
    }
    @media (#{$xxlarge-bp}) {
        min-height: 600px;
        // height: calc(100vh - 240px); // This isn't great for why choose timely page
        height: 100vh;
    }

    .Breadcrumbs {
        @media (#{$medium-bp}) {
            position: absolute;
            top: 0;

            .container {
                padding: 0;
            }
        }
    }
}

.HeroBanner--quote {
    @media (#{$medium-bp}) {
        .HeroBlock-title {
            text-indent: -$v * 2;
        }
    }
}
.HeroBanner--condensed {
    padding: $v * 5 $v * 2;
    @media (#{$medium-bp}) {
        padding: calc(#{$v} * 7 - 5px) 0 $v * 7.5;
        height: auto;
        min-height: auto;
    }

    h1 {
        margin-bottom: 0;
    }
}

.HeroBlock-pretext {
    margin-bottom: 0;
}

.HeroBlock-subtext {
    margin-top: $v * 2;
}

.HeroBanner-actions {
    @extend .Actions;
    padding: 0;
}

.HeroBanner-image {
    width: 100%;
}

.HeroBanner-wrap {
    padding-top: $v * 6;
    padding-bottom: $v * 9;
    position: relative;
    z-index: 12;

    @media (#{$medium-bp}) {
        padding: 0;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
    }
}

.HeroBanner-title {
    margin: 0;
    font-size: 2.8rem;
    line-height: 3.6rem;

    @media (#{$medium-bp}) {
        font-size: 4.5rem;
        line-height: 5.4rem;
    }
}

.HeroBanner--onDark {
    color: $white;

    .Button--clipboard path {
        fill: $white;
    }
}

.HeroBanner-description {
    margin-top: 0;
    margin-bottom: $v * 4;
}

.HeroBanner-container {
    @extend %container;
    position: relative;

    @media (#{$medium-bp}) {
        padding: 0 $v * 2;
        > div {
            padding: 0;
        }
    }
}

.HeroBanner {
    position: relative;
}

.HeroBanner-video {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: calc(100% + 10px);
    z-index: -1;
    // display: none;

    @media (#{$medium-bp}) {
        z-index: 0;
        display: block;
    }

    &::before {
        content: ' ';
        background: rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 11;
    }
}

.HeroBanner--center {
    .HeroBanner-wrap {
        @media (#{$medium-bp}) {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translateY(-50%);
        }
    }

    .HeroBanner-container {
        .col-md-7,
        .col-md-6 {
            margin-left: auto;
            margin-right: auto;
            float: none;
            clear: both;
            text-align: center;

            @media (#{$medium-bp}) {
                width: 66.66666667%;
            }
        }
    }

    .HeroBanner-actions {
        justify-content: center;
        @media (#{$small-bp}) {
            margin-top: $v * 4;
            // .Button {
            //   margin-right: $v;
            // }
        }
        .Button:last-child {
            margin-right: 0;
        }
    }

    @media (#{$medium-bp}) {
        h1 {
            font-size: 5rem;
            line-height: 6rem;
        }
        .HeroBlock-subtext {
            margin-top: $v * 3;
            margin-bottom: $v * 6;
        }
    }
}

.HeroBanner--campaign {
    height: 100vh;
    z-index: 1000000;
    .HeroBanner-wrap {
        padding: 0;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
    }

    .HeroBanner-container {
        .col-md-7,
        .col-md-6 {
            margin-left: auto;
            margin-right: auto;
            float: none;
            clear: both;
            text-align: center;
            @media (#{$medium-bp}) {
                width: 66.66666667%;
            }
        }
    }

    h1 {
        font-size: 3.5rem;
        line-height: 4rem;
        text-transform: uppercase;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        @media (#{$medium-bp}) {
            width: 100%;
            font-size: 7.5rem;
            line-height: 8rem;
        }
    }

    .lato {
        font-family: $text-font !important;
        letter-spacing: -2px;
        font-weight: 300;
        line-height: 3.5rem;
    }
}

.HeroBanner--sillySeason {
    @media (#{$medium-bp}) {
        .col-md-7 {
            width: 75% !important;
        }
    }
}
.HeroBanner--nikki {
    @media (#{$large-bp}) {
        height: 56.25vw !important;
        min-height: auto !important;
    }

    .HeroBlock-title {
        @media (#{$medium-bp}) {
            font-size: 4.5rem;
            line-height: 5rem;
        }
    }
}

.HeroBanner--yes {
    background-color: $dusk-1;
    background: linear-gradient(135deg, $dusk-4 0%, $dusk-1 100%);
    @media (#{$large-bp}) {
        background: url('/assets/images/yes-campaign/Jazz-hero.jpg');
        background-size: cover;
        background-position: bottom;
        background-repeat: no-repeat;
        min-height: 800px;
        // max-height: calc(100%-77px);
        padding-bottom: 0;
    }

    .HeroBlock-subtext {
        @media (#{$medium-bp}) {
            margin-bottom: $v * 6;
        }
    }

    .HeroBanner-wrap {
        padding-bottom: 0;
        padding-top: $v * 4;

        @media (#{$large-bp}) {
            padding-top: 0;
        }
    }

    .HeroBlock-title {
        @media (#{$large-bp}) {
            margin-top: 0;
        }
        img {
            margin-left: auto;
            margin-right: auto;
            max-width: 80%;

            @media (#{$medium-bp}) {
                max-width: 100%;
            }
            @media (#{$large-bp}) {
                margin-left: initial;
                margin-right: initial;
            }
        }
    }

    @media (#{$medium-bp-only}) {
        height: auto;

        .HeroBanner-wrap {
            margin-top: $v * 9;
            position: static;
            width: 100%;
            transform: none;
        }

        .HeroBanner-container {
            .col-md-7,
            .col-md-6 {
                margin-left: auto;
                margin-right: auto;
                float: none;
                clear: both;
                text-align: center;
                width: 66.66666667%;
            }
        }

        .HeroBanner-actions {
            justify-content: center;
            .Button:last-child {
                margin-right: 0;
            }
        }

        h1 {
            font-size: 5rem;
            line-height: 6rem;
        }
        .HeroBlock-subtext {
            margin-top: $v * 3;
            margin-bottom: $v * 6;
        }
    }
}

.HeroBanner-img {
    padding-top: $v * 4;
    text-align: center;
    font-size: 0;
    position: relative;
    z-index: 1;

    @media (#{$large-bp}) {
        display: none;
    }

    img {
        width: auto;
        max-height: 270px;

        @media (#{$medium-bp}) {
            max-height: 450px;
        }
    }
}

.HeroBanner--simple {
    height: auto !important;
}

.HeroBanner.HeroBanner--maxHeight {
    min-height: unset;

    @media (#{$large-bp}) {
        height: $v * 65;
        .HeroBanner-wrap {
            padding: 0;
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translateY(-50%);
        }
    }
}

.HeroBanner-video-cta {
    .Button--primary {
        margin-top: $v;
    }
    @media (#{$medium-bp}) {
        display: flex;
        align-items: center;
        justify-content: center;
        .Button--primary {
            margin-top: 0;
        }
    }
}

.HeroBanner--wide {
    @media (#{$large-bp}) {
        .col-sm-11.col-md-7 {
            width: 100%;
        }
    }
}

.HeroBanner.HeroBanner--GatedVideo {
    min-height: auto;
    height: auto;

    &,
    .Actions {
        text-align: left;
        .Button--watchWebinar {
            margin-left: 0;
        }
    }

    .HeroBlock-container {
        @media (#{$large-bp}) {
            display: flex;
            align-items: center;
            padding: $v * 7 0 $v * 15;
            border-bottom: 1px solid $charcoal-4;
        }
    }

    .HeroBlock-image {
        max-width: 100%;
    }

    .HeroBlock-title {
        margin-bottom: 0;
    }
    .HeroBlock-description {
        margin-top: $v * 2;
        margin-bottom: $v;
    }
}

.HeroBlock-social {
    margin-top: $v * 6;
    display: flex;
    justify-content: space-between;
    @media (#{$large-bp}) {
        display: block;
        margin-top: 0;
    }
}

.HeroBanner--GatedVideo-open {
    .HeroBlock-wrap {
        filter: blur(3px);
    }
}

.HeroBanner--featurePage {
    background-color: $charcoal-5;
    overflow: visible;
    margin-bottom: $v * 15;
    text-align: center !important;

    @media (#{$medium-bp}) {
        margin-bottom: $v * 9;
    }
    @media (#{$large-bp}) {
        .HeroBanner-container {
            width: 1170px;
        }
    }
    @media (#{$xxlarge-bp}) {
        max-height: 650px;

        .HeroBanner-container {
            width: 1820px;
        }
    }

    .HeroBanner-wrap {
        @media (#{$small-bp}) {
            padding-top: $v * 5;
            padding-bottom: $v * 13;
        }

        @media (#{$large-bp}) {
            padding: 0;
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translateY(-50%);
        }
    }

    .HeroBanner-actions {
        @media (#{$medium-bp}) {
            justify-content: center;
        }
        @media (#{$large-bp}) {
            justify-content: start;
        }
    }

    @media (#{$large-bp}) {
        height: 100vw;
        min-height: unset;
        height: $v * 65;
        text-align: left !important;
    }
}

.HeroBanner-offsetImage {
    pointer-events: none;

    margin-top: $v * 5;
    @media (#{$medium-bp}) {
        margin-top: $v * 10;
        margin-bottom: -$v * 20;
    }
    @media (#{$large-bp}) {
        margin-top: 0;
        margin-bottom: 0;
        position: absolute;
    }

    @media (#{$xxlarge-bp}) {
        width: 65%;
    }

    img {
        box-shadow: 0 0 65px 0 rgba(0, 0, 0, 0.12);
    }
}

.HeroBanner--noShadow {
    .HeroBanner-offsetImage {
        img {
            box-shadow: none;
        }
    }
}

.HeroBanner--img {
    position: relative;
}

.HeroBanner--smallBG {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background-size: cover;

    @media (#{$medium-bp}) {
        background-position: center;
    }

    @media (#{$large-bp}) {
        display: none;
    }
}

.HeroBanner--productVis {
    // background-color: #C27BA3;

    .HeroBanner-video {
        &:before {
            display: none;
        }
    }

    @media (#{$small-bp}) {
        background-image: none !important;
        height: auto;

        .HeroBanner-wrap {
            // margin-top: 9rem;
            position: inherit;
            transform: none;
            width: 100%;
            padding-bottom: 0;
        }

        .HeroBanner-img img {
            padding-top: 0;
            width: 100%;
            height: auto;
            max-height: initial;
        }
    }

    @media (#{$medium-bp-only}) {
        background-image: none !important;
        height: auto;
        text-align: center;

        .Actions {
            justify-content: center;
        }

        .HeroBanner-video {
            display: none;
        }

        .HeroBanner-wrap {
            margin-top: 9rem;
            position: inherit;
            transform: none;
            width: 100%;
        }

        .HeroBanner-img {
            padding-top: $v * 4;
            img {
                margin-top: $v * 4;
                width: 100%;
                height: auto;
                max-height: initial;
            }
        }
    }
}

.HeroBanner--productVis-midnight {
    background-color: $midnight;

    @media (#{$small-bp}) {
        background-image: url('/assets/images/homepage/bg-colour-midnight.jpg') !important;
    }

    @media (#{$medium-bp-only}) {
        background-image: url('/assets/images/homepage/bg-colour-midnight.jpg') !important;
    }
}

.HeroBanner.HeroBanner--campaignV2 {
    min-height: auto;
    height: auto;

    @media (#{$small-bp}) {
        background-image: none !important;
    }

    @media (#{$medium-bp-only}) {
        background-image: none !important;
    }

    @media (#{$large-bp}) {
        height: 100vh;
    }

    .HeroBanner-flexImage img {
        width: 100vw;
        max-height: fit-content;

        @media (#{$medium-bp}) {
            left: -$v * 2;
        }

        @media (#{$large-bp}) {
            display: none;
        }
    }
}

.HeroBanner-container.HeroBanner-flex {
    padding-top: $v * 14;

    width: 100%;
    @media (#{$large-bp}) {
        padding-top: 0;
        display: flex;
        align-items: center;
        height: 100%;
    }
}

.HeroBanner-flexContent {
    flex-shrink: 0;
    text-align: center;
    z-index: 1;
    .HeroBanner-actions {
        justify-content: center;
    }

    @media (#{$large-bp}) {
        text-align: left;
        .HeroBanner-actions {
            justify-content: flex-start;
        }
    }
}

.HeroBanner-flexImage {
    padding-top: $v * 6;

    @media (#{$large-bp}) {
        padding-top: 0;
    }

    img {
        position: relative;
        bottom: -$v;
        width: 100%;
        left: -$v * 1.5;

        @media (#{$large-bp}) {
            left: initial;
            width: auto;
            height: 90%;
            position: absolute;
            bottom: 0;
        }
    }
}

.HeroBanner-flexLogo {
    margin-bottom: $v * 4;
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: block;

    @media (#{$large-bp}) {
        max-width: 90%;
        margin-left: 0;
        margin-right: 0;
    }
}

.u-textCenter {
    .HeroBanner-flexLogo {
        @media (#{$medium-bp}) {
            margin-left: auto;
            margin-right: auto;
        }
    }
    .HeroBlock-title,
    .HeroBanner-description {
        text-align: center;
    }
    .HeroBanner-actions {
        justify-content: center !important;
    }
}

.HeroBanner--stdImageOnLeft {
    @media (#{$large-bp}) {
        .HeroBanner-flex {
            flex-direction: row-reverse;
        }

        .HeroBanner-flexContent.col-md-4.col-md-offset-1 {
            margin-left: 0;
            margin-right: 8.3333%;
        }

        .HeroBanner-flexImage {
            img {
                left: 0;
            }
        }
    }
}

.HeroBanner {
    .Button--clipboard {
        opacity: 0.7;
        line-height: 0;
        top: -0.45rem;
        position: relative;
        &:hover {
            opacity: 1;
        }
    }
}

.HeroBanner.HeroBanner--centerText {
    @media (#{$large-bp}) {
        .HeroBanner-flexContent {
            text-align: center;
            .HeroBanner-actions {
                justify-content: center;
            }
        }
    }
}

.HeroBanner.HeroBanner--jumbo {
    .HeroBlock-title {
        font-size: $v * 5;
        line-height: $v * 5.7;
        margin-top: $v * 2;
        @media (#{$medium-bp}) {
            font-size: $v * 6.6;
            line-height: $v * 7;
        }
    }
}

.HeroBanner.HeroBanner--maxHeightSmall {
    min-height: unset;

    @media (#{$large-bp}) {
        height: $v * 45;
        .HeroBanner-wrap {
            padding: 0;
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translateY(-50%);
        }
    }
}

.HeroBanner-PlayButton {
    position: absolute;
    top: 145%;
    right: 45%;
    @media (#{$medium-bp}) {
        top: 45%;
        right: 25%;
    }
}


.Section-heading {
  text-align: center;
  h3 {
    margin-top:0;
    margin-bottom: 3rem;
    line-height: 3.4rem;

    @media (#{$medium-bp}) {
      margin-bottom: 5rem;
      line-height: 4.6rem;
    }
  }
}

.Section-actions {
  margin-top: $v * 3;
  text-align: center;
}

.Section-heading {
  text-align: center;
  h3 {
    margin-top:0;
    margin-bottom: 3rem;
    line-height: 3.4rem;

    @media (#{$medium-bp}) {
      margin-bottom: 5rem;
      line-height: 4.6rem;
    }
  }
}

.Section-actions {
  margin-top: $v * 3;
  text-align: center;
}
@use "sass:math";

$video-course-cta__bp: $medium-bp;

.video-course-cta {
	margin: (5 * $v) 0;
}

.video-course-cta__bg {
	background: $dusk-4;

	padding: (5 * $v) 0;
	@media ($video-course-cta__bp) {
		padding: (10 * $v) 0;
	}
}

.video-course-cta__layout {
	display: grid;
	column-gap: 4 * $h;
	row-gap: 2 * $v;

	@media ($video-course-cta__bp) {
		grid-template-columns: repeat(2, 1fr);
	}
}

.video-course-cta__heading {
	@extend %h2;

	margin: 0;

	text-align: center;
	@media ($video-course-cta__bp) {
		text-align: right;
	}
}

.video-course-cta__actions {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	gap: 2 * $h;

	justify-content: center;
	@media ($video-course-cta__bp) {
		justify-content: flex-start;
	}
}

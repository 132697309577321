.PricingCard {
    height: 100%;
    min-height: 340px;
    padding: $v * 2 $v * 1.5 $v * 3;
    border: 1px solid $charcoal-0;
    border-radius: 4px;
    background-color: $white;
    border-top-width: 10px;
    border-top-style: solid;
    display: flex;
    flex-direction: column;
    .Pricing-currency,
    .Pricing-recurrence {
        font-size: $v * 2;
        font-weight: bold;
    }
    .Pricing-recurrence {
        margin-left: 0.5rem;
        margin-bottom: 1rem;
    }
    .Button {
        margin-top: $v * 2.5;
    }

    @media (#{$medium-bp}) {
        margin-bottom: $v * 3;
    }
    @media (#{$large-bp}) {
        margin-bottom: 0;
    }
    .Tooltip-trigger {
        padding: 0;
    }
}

.PricingCard--basic {
    border-top-color: $charcoal-4;
}

.PricingCard--build {
    border-top-color: $rose;
}

.PricingCard--elevate {
    border-top-color: $coral-2;
}

.PricingCard--innovate {
    border-top-color: $midnight;
}

.PricingCard--disabled {
    background-color: $charcoal-5;
    border-left: 1px solid $charcoal-0;
    border-right: 1px solid $charcoal-0;
    border-bottom: 1px solid $charcoal-0;
    box-shadow: none;

    @media (#{$medium-bp}) {
        min-height: 140px;
        margin-bottom: -2px;
    }
    @media (#{$large-bp}) {
        min-height: 340px;
    }
}

.PricingCard-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 35px;
}

.PricingCard-description {
    margin-top: 0.5rem;
    margin-bottom: auto;
    color: $charcoal-2;
    min-height: $v * 4.8;
}

.PricingCard-description-disabled{
    margin-bottom: auto;
    color: $charcoal-0;
    min-height: $v * 4.8;
    font-weight: 700;
}

.PricingCard-min-height{
    min-height: $v * 4.8;
    margin-bottom: auto;
}

.PricingCard-sub-line{
    @extend %p;
    margin-top: 0.5rem;
    margin-bottom: 0;
}

.PricingCard-sub-line::before {
    content: url(/images/svg-icons/plus-midnight.svg);
    margin-right: 8px;
}

.PricingCard-price {
    display: flex;
    align-items: flex-end;
    padding-bottom: $v * 2;
}

.PricingCard-staffPrice {
    margin-top: $v * 0.5;
}

.PricingCard-tagline {
    @extend %h4;
    display: block;
    text-align: center;
    margin-bottom: 0;
}

.PricingCard-heading {
    text-align: center;
    margin-bottom: $v * 3;
    padding-bottom: 0;
    @media (#{$medium-bp}) {
        padding-bottom: $v * 3;
        margin-bottom: 0;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.PlanCalculator-select {
    @extend %h2;
    line-height: 32px;
    text-align: left;
}

.PricingCard-heading-title{
    @extend %h2;
    line-height: 32px;
}

.PlanCalculator-select .PlanCalculator-select__single-value {
    color: $midnight;
    overflow: visible;
}

.PlanCalculator-select__value-container {
    background-color: $midnight-pale;
    border: 0;
    border-bottom: solid 2px $charcoal-0;
    color: $midnight;
}

.PlanCalculator-select__indicator-separator {
    display: none;
}

.PlanCalculator-select.PlanCalculator-select--small {
    font-size: 1.6rem;
    line-height: 3.4rem;
    font-family: $text-font;
    font-weight: normal;
    background-color: $midnight-pale;

    .PlanCalculator-select__value-container {
        background-color: $midnight-pale;
        border: 0;
        border-bottom: solid 2px $charcoal-0;
        color: $midnight;
    }
}

.PlanCalculator-select__indicators {
    background-color: $midnight-pale;
    border: 0;
    border-bottom: solid 2px $charcoal-0;
}
.PlanCalculator-select .PlanCalculator-select__control:hover,
.PlanCalculator-select .PlanCalculator-select__control {
    border: none;
    box-shadow: none;
}
.PlanCalculator-select__dropdown-indicator svg {
    fill: $midnight;
}

.PlanCalculator-select__menu {
    font-size: $v * 1.8;
    font-family: $text-font;
    font-weight: 400;
}

.PlanCalculator-select .PlanCalculator-select__option--is-selected {
    background-color: $midnight-pale;
    color: $charcoal;
}
.PlanCalculator-select .PlanCalculator-select__option--is-focused {
    background-color: $midnight;
    color: $white;
}

.PricingCard-seeFeature {
    margin-top: auto;
    margin-bottom: 0;
    .Button {
        margin-top: $v;
    }
}

.PlanCalculator-tip {
    max-width: 400px;
    min-width: 400px;
}
.PlanCalculator-wrap {
    .container {
        @media (#{$small-bp}) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@media only screen and (min-width: 991px) and (max-width: 1200px) {
    .PlanCalculator-select{
        font-size: 3rem;
    }

    .PricingCard-heading-title{
        font-size: 3rem;
    }
  }

@media only screen and (min-width: 768px) and (max-width: 990px) {
    .PlanCalculator-select{
        font-size: 2.5rem;
    }

    .PricingCard-heading-title{
        font-size: 2.5rem;
    }
  }

  @media only screen and (max-width: 767px) {
    .PricingCard-title {
        font-size: 3.5rem;
    }
  }


$pricing-border-style: 1px solid #ddd;
$most-popular-height: 37px;

.PricingPlan {
    @extend .u-vSpacingXL-top;
    h3 {
        margin-top: 0;
        margin-bottom: 4rem;
        font-size: 2.5rem;
        line-height: 3.4rem;

        @media (#{$medium-bp}) {
            margin-bottom: 7.5rem;
            font-size: 3.5rem;
            line-height: 4.6rem;
        }
    }

    .slick-slide {
        margin-left: $v;
        margin-right: $v;
    }
}

.PricingPlan-container {
    padding: 0 !important;
    width: 100%;

    @media (#{$large-bp}) {
        padding-left: $v * 1.5 !important;
        padding-right: $v * 1.5 !important;
        width: 970px;
        display: flex;
        justify-content: center;
    }

    @media (#{$xlarge-bp}) {
        width: 1170px;
    }
}

.Pricing {
    background: $white;
    border-radius: $card-border-radius;
    box-shadow: $card-box-shadow;
    margin-top: $most-popular-height;
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.Pricing-title {
    font-family: $domaindisplay;
    padding-top: $v * 0.5;
    padding-bottom: $v * 0.5;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin: 0;
    color: $white;
}

.Pricing--start {
    .Pricing-title {
        background-color: $charcoal-pricing;
    }
}

.Pricing--build {
    .Pricing-title {
        background-color: $midnight;
    }
}

.Pricing--elevate {
    .Pricing-title {
        background-color: $coral-2;
    }
}

.Pricing--innovate {
    .Pricing-title {
        background-color: $charcoal-0;
    }
}

.Pricing--enterprise {
    .Pricing-desc,
    .Pricing-title {
        color: $charcoal-0;
        padding-left: $v * 1.5;
        padding-right: $v * 1.5;
        @media (#{$medium-bp}) {
            padding-left: $v * 9;
            padding-right: $v * 9;
        }
    }

    .Pricing-title {
        padding-top: $v * 6;
        font-family: $domaintext;
        font-weight: normal;
        letter-spacing: 0;
    }

    .Pricing-desc {
        font-size: $v * 1.6;
        font-style: normal;
        padding-top: $v * 4;
    }

    .Pricing-cta {
        margin-top: auto;
    }
}

.Pricing-sms {
    font-size: 1.4rem;
    padding-top: $v * 2;
}

.Pricing-price {
    padding-top: $v * 2.5;
    display: flex;
    justify-content: center;
}

.Pricing-symbol {
    font-size: 2.5rem;
    line-height: 4rem;
}

.Pricing-amount {
    font-size: 6rem;
    line-height: 6rem;
    @media (#{$large-bp}) {
        font-size: 7.6rem;
        line-height: 7.6rem;
    }
}
.PlanCalculator--inline {
    .Pricing-amount {
        font-size: 6rem;
        line-height: 6rem;
    }
}

.Pricing-recurrence {
    line-height: 1rem;
}

.Pricing-desc {
    font-size: $v * 1.4;
    padding: $v * 1.5 $v $v;
    font-style: italic;
    color: $charcoal-2;
    line-height: 1.4;
    margin-bottom: 0;
}

.Pricing-small {
    line-height: 2rem;
    padding-top: $v * 3;
    padding-bottom: $v * 3;
    border-bottom: $pricing-border-style;
}

.Pricing-pitch {
    padding-bottom: $v * 3;
}

.Pricing-cta {
    padding: $v;
}

.Pricing-symbol,
.Pricing-amount {
    font-family: $domaindisplay;
    font-weight: 300;
    letter-spacing: -.4px;
}

.Pricing-disclaimer {
    text-align: center;
    margin-bottom: 0;
    color: $mid-grey;
}
.Pricing-actions {
    text-align: center;
    margin-top: $v * 3;
    margin-bottom: $v;
}

.Pricing-currency {
    // font-variant: small-caps;
    // text-transform: lowercase;
    font-size: 1.5rem;
}

.Pricing-enterprise {
    .Pricing-amount {
        letter-spacing: -3px;
    }
}

.PricingList-toggle-label {
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 40px;
    height: 16px;
    border-radius: 25px;
    background-color: $charcoal-4;
    margin: 0 $v * 1.5;

    .PricingList-toggle-lever {
        position: absolute;
        border-radius: 50%;
        background-color: $charcoal-1;
        transition: 0.1s ease;
        width: 22px;
        height: 22px;
        top: -3px;
        left: -3px;
        box-shadow: 0px 0.5px 0.5px 1.25px rgba(0, 0, 0, 0.25);
    }

    input:checked + .PricingList-toggle-lever {
        left: 50%;
    }

    input {
        display: none;
    }
}

.PricingList-frequency-toggle {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: calc(#{$v} * 3);
    color: $charcoal-2;

    > div {
        @media (#{$small-bp}) {
            width: calc(100% / 3);
        }
    }

    @media (#{$medium-bp}) {
        margin-top: calc(#{$v} * 3 + #{$most-popular-height});
    }

    @media (#{$xlarge-bp}) {
        justify-content: flex-end;
    }

    .isActive {
        color: $charcoal-0;
    }

    .PricingList-toggle {
        width: auto;
    }

    .PricingList-frequency-toggle-month {
        text-align: right;
    }
}

.PricingList-frequency-toggle.PricingList-frequency-toggle--new {
    margin-top: 0;
    justify-content: center;
    @media (#{$xlarge-bp}) {
        justify-content: center;
    }
    > div {
        @media (#{$small-bp}) {
            width: auto !important;
        }
    }
}

.Pricing-popularity {
    display: none;
    text-align: center;
    background-color: $dusk-0;
    padding: math.div($v, 2);
    margin-bottom: -1px;
    font-weight: 600;
}

.Pricing-wrap .Pricing {
    height: 100%;
}

.Pricing-wrap.Pricing-isRecommended {
    .Pricing {
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
        margin-top: 0;
        height: calc(100% + #{$most-popular-height});
    }

    .Pricing-title {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }
    .Pricing {
        border-left: 2px solid $dusk-0;
        border-right: 2px solid $dusk-0;
        border-bottom: 2px solid $dusk-0;
    }

    .Pricing-popularity {
        display: block;
    }
}

.PricingList-toggle--new {
    .PricingList-toggle-label {
        background-color: $hot-coral;
        height: 28px;
        width: 50px;
    }
    .PricingList-toggle-lever {
        top: 3px;
        left: 3px;
        background-color: $white;
    }

    input:checked + .PricingList-toggle-lever {
        left: 50%;
    }
}

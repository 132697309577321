// ----- header open -----
.sign-up-refined__navbar{
	background-color: $white;
    position: fixed;
	top: 0;
	left: 0;
    height: auto;
	width: 100%;
	z-index: 10;
    // Page transition
    // view-transition-name: sign_up_refined__navbar;
    // contain: paint;
}

.sign-up-refined__navbar-inner{
    height: 80px;
}

.sign-up-refined__left{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}

.sign-up-refined__right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}

.sign-up-refined__logo-outer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.sign-up-refined__logo{
	max-width: 120px;
	height: auto;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
}
// ----- Header closed -----

// ----- homepage -----
.sign-up-refined__container-outer{
    width: 100%;
}

.sign-up-refined__container-inner{
    height: 100vh;
}

.sign-up-refined__container-inner-top-margin{
    margin-top: 80px;
    height: calc(100vh - 80px);
}

.sign-up-refined__coral-logo{
    width: 159px;
    height: auto;
}

.sign-up-refined__exit{
    position: absolute;
    top: 3%;
    right: 2%;
}

.sign-up-refined__exit-image{
    width: 26px;
    height: auto;
}

.sign-up-refined__form-holder-outer{
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sign-up-refined__form-holder-inner{
    width: 80%;
    max-width: 580px;
    // Page transition
    // view-transition-name: sign_up_refined__form_area;
    // contain: paint;
}

.sign-up-refined__form-holder-inner-page2{
    border-radius: 7px;
    padding: 3rem;
}

.sign-up-refined__form-holder-inner-page2-border{
    border: 1px solid $charcoal-4;
}

.sign-up-refined__main-bg-image {
    background-color: $coral-0;
    background-image: url('/images/two-plus-sign-up/sign-up-bg.jpg');
    display: block;
    inset: 0;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    // Page transition
    // view-transition-name: sign_up_refined__left_side;
    // contain: paint;
}

.sign-up-refined__cover-holder{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-bottom: 8%;
}

.sign-up-refined__cover-text{
    color: $white;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    width: 100%;
    justify-content: center;
}

.sign-up-refined__cover-image{
    width: auto;
    height: auto;
    display: flex;
    width: 100%;
    max-width: 1000px;
    padding-left: 10%;
    padding-right: 10%;
    justify-content: center;
}

.sign-up-refined__form-holder-header{
    width: 100%;
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 1rem;
}

.sign-up-refined__form-holder-footer{
    text-align: left;
    margin-left: 28px;
}

.sign-up-refined__form-holder-footer a{
    color: $midnight;
    text-decoration: underline;
}

.sign-up-refined__loader-inner{
	width: 80%;
	height: auto;
	max-width: 1200px;
	padding-top: 0;
	color: $charcoal-0;
}

// ----- Animation transition -----

// @keyframes sign_up_refined__fade-in {
//     from { opacity: 0; }
//   }
// @keyframes sign_up_refined__fade-out {
//     to { opacity: 0; }
//   }

// @keyframes sign_up_refined__slide-from-right {
//     from { transform: translateX(100%); }
//   }
// @keyframes sign_up_refined__slide-to-left {
//     to { transform: translateX(-100%); }
//   }


//   ::view-transition-old(sign_up_refined__form_area) {
//     // animation-delay: 600ms;
//     animation: 200ms ease-in-out sign_up_refined__fade-out;
//   }


//   ::view-transition-new(sign_up_refined__form_area) {
//     animation-delay: 1000ms;
//     animation: 200ms ease-in-out sign_up_refined__fade-in;
//   }

//   ::view-transition-new(sign_up_refined__navbar) {
//     animation-delay: 1000ms;
//     animation: 200ms ease-in-out sign_up_refined__fade-in;
//   }


//   ::view-transition-old(sign_up_refined__left_side) {
//     animation-delay: 200ms;
//     animation: 400ms ease-in-out sign_up_refined__slide-to-left;
//   }

  

// ::view-transition-old(root),
// ::view-transition-new(root) {
//   animation-duration: 1s;
// }

// ----- Form -----
.sign-up-refined__field-tweak{
    position: relative;
    left: -1px;
}

.sign-up-refined__form__card {
    padding-top: 1rem;
    padding-bottom: 1rem;
	border-radius: 7px;
}

.sign-up-refined__form__card-step1 {
    border: 1px solid $charcoal-4;
}

// ----- Progress bar  -----
.sign-up-refined__form-step-indicator {
	list-style: none;
	display: flex;
	margin: 0;
	padding: 0;
	width: 100%;
    background-color: $white;
    border-top: 1px solid $charcoal-4;
}

.sign-up-refined__form-step-indicator-item {
	flex-grow: 1;
	height: 0.5rem;
    background-color: $white;
	// Double size linear gradient lets us animate background colour as a translation
	background-image: linear-gradient(90deg, $midnight 0%, $midnight 50%, $white 50%, $white 100%);
	background-size: 210%;
    border: none;
	background-position: 0 0;
	transition: 0.6s background-position ease-in-out;
	[aria-current="true"] ~ & {
		background-position: 100% 0;
	}
}

// ----- form fields -----
// Radio buttons
.sign-up-refined__form-field-radio{
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid $charcoal-4;
    border-radius: 4px;
    margin-bottom: 1.5rem;
    padding-left: 2rem;
}

.sign-up-refined__form-field-radio:hover{
background-color: $charcoal-5;
}


.sign-up-refined__form-field-terms-btn{
    cursor: pointer;
    margin-left: 2rem;
    padding: 1rem;
    border: 2px solid $charcoal-4;
}

// Terms checkbox
.sign-up-refined__form-field-radio-btn{
    cursor: pointer;
    margin-left: 2rem;
    padding: 0.75rem;
    border: 2px solid $charcoal-4;
}

.sign-up-refined__form-field-radio-btn .form-check-input:checked{
    background-color: $midnight;
    border-color: $midnight;
}

.sign-up-refined__form-field-radio-btn.was-validated .form-check-input:checked{
    background-color: $midnight;
    border-color: $midnight;
}

.sign-up-refined__form-field-radio-btn,.form-check-input:focus{
    border-color: $charcoal-4;
}

.sign-up-refined__form-field-radio-btn.form-check-input:checked[type=checkbox]{
    background-color: $midnight;
    border-color: $midnight;
}

.sign-up-refined__radio-input-helper{
    position: absolute;
    left: 0;
    margin-top: 30px;
    pointer-events: none;
    clip: rect(0,0,0,0);
}

.sign-up-refined__radio-input-holder{
    position: relative;
    display: inline-block;
}

.sign-up-refined__form-field-radio-btn .was-validated .form-check-input:valid:checked{
    background-color: $midnight;
}

.sign-up-refined__form-field-radio .form-check-input:checked{
    background-image: url('/images/svg/tick-white.svg');
    background-size: 70%;
    background-color: $midnight;
    border-color: $midnight;
}

.sign-up-refined__form-field-radio .form-check-input:checked[type=radio]{
    background-image: url('/images/svg/tick-white.svg');
    background-size: 70%;
    background-color: $midnight;
    border-color: $midnight;
}

.sign-up-refined__form-field-radio .form-check-input:focus{
    border-color: $charcoal-4;
    box-shadow: none;
}

.sign-up-refined__form-field-radio-label{
    cursor: pointer;
    padding: 2rem 1.8rem;
    width: 100%;
    height: 100%;
}

.sign-up-refined__form-field-radio-select{
    border: 1px solid $charcoal-4;
    border-radius: 40px;
    font-size: 1.5rem;
    padding: 1.1rem 2.1rem;
    margin: 1rem 1rem;
    background-color: $white;
    color: $charcoal-0;
}

.sign-up-refined__form-field-radio-btn, .was-validated .form-check-input:valid{
    border-color: $charcoal-4;
}

.sign-up-refined__form-field-radio-btn, .was-validated .form-check-input:invalid{
    border-color: $charcoal-4;
}

.sign-up-refined__form-field-radio-btn, .was-validated .form-check-input:invalid ~ .form-check-label{
   color: $charcoal-0;
}

.sign-up-refined__form-field-radio-select:checked{
    background-color: $midnight;
}

.sign-up-refined__form-field-category-outer .btn-outline-success:hover{
    background-color: $charcoal-5;
    border: 1px solid $charcoal-4;
    color: $charcoal-0;
}

.sign-up-refined__form-field-category-outer .btn-check:active+.btn-outline-success, .btn-check:checked+.btn-outline-success, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show, .btn-outline-success:active{
    background-color: $midnight;
    border-color: $midnight;
    color: $white;
    box-shadow: none;
}

.sign-up-refined__form-field-category-outer .btn-check:checked+.btn-outline-success:focus{
    box-shadow: none;
}

.sign-up-refined__form-field-category-outer .btn-outline-success:focus{
    box-shadow: none;
}

.sign-up-refined__form-field-radio-hold-1{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.sign-up-refined__form-field-radio-hold-2{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.sign-up-refined__form-field-radio-hold-3{
    width: 100%;
    padding: 0 2rem 2rem 0;
}

.form-check .form-check-input, .sign-up-refined__form-field-radio-btn{
    margin-left: 0;
}

.form-control.sign-up-refined__form-field-special{
	height: 54px;
    padding: 14px 20px;
	border-radius: 3px;
    font-size: 16px;
    line-height: 1.3;
    background-color: $midnight-pale;
    border: none;
}

.sign-up-refined__promo-code-field-inner{
	color: $midnight;
	border: none;
	background-color: transparent;
}

.sign-up-refined__business-category-icon{
    padding-right: 10px;
    min-width: 35px;
}

.btn-check:checked + .btn-outline-success .sign-up-refined__svg-colour{
    stroke: white;
    color: white;
}

// ----- Tooltips -----
.sign-up-refined__tooltip-hidden.tooltip{
    display: none;
}

.sign-up-refined__tooltip.tooltip{
    opacity: 1;
}

.sign-up-refined__tooltip.tooltip .tooltip-inner{
	padding: 1rem 1rem 1rem 1rem;
	font-family: $text-font;
	border-radius: 3px;
	background-color: $spa-pale;
	color: $charcoal-0;
	text-align: left;
    max-width: 250px;
    box-shadow: none;
}

.sign-up-refined__tooltip.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .bs-tooltip-start .tooltip-arrow::before{display: none;}
.sign-up-refined__tooltip.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow, .bs-tooltip-start .tooltip-arrow{display: none;}

.sign-up-refined__tooltip.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .bs-tooltip-start .tooltip-arrow::before{display: none;}
.sign-up-refined__tooltip.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow, .bs-tooltip-start .tooltip-arrow{display: none;}

.sign-up-refined__tooltip.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .bs-tooltip-start .tooltip-arrow::before{display: none;}
.sign-up-refined__tooltip.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow, .bs-tooltip-start .tooltip-arrow{display: none;}

.sign-up-refined__tooltip.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .bs-tooltip-start .tooltip-arrow::before{display: none;}
.sign-up-refined__tooltip.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow, .bs-tooltip-start .tooltip-arrow{display: none;}

// Tooltip serious info
.sign-up-refined__tooltip-serious.tooltip .tooltip-inner{
	padding: 1rem 1rem 1rem 1rem;
	font-family: $text-font;
	border-radius: 5px;
	background-color: $charcoal-0;
	color: $white;
	text-align: center;
    max-width: 280px;
}

.sign-up-refined__form{
    position: relative;
}

.sign-up-refined__tooltip-serious.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .bs-tooltip-start .tooltip-arrow::before{display: none;}
.sign-up-refined__tooltip-serious.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow, .bs-tooltip-start .tooltip-arrow{display: none;}

.sign-up-refined__tooltip-serious.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .bs-tooltip-start .tooltip-arrow::before{display: none;}
.sign-up-refined__tooltip-serious.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow, .bs-tooltip-start .tooltip-arrow{display: none;}

.sign-up-refined__tooltip-serious.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .bs-tooltip-start .tooltip-arrow::before{display: none;}
.sign-up-refined__tooltip-serious.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow, .bs-tooltip-start .tooltip-arrow{display: none;}

.sign-up-refined__tooltip-serious.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .bs-tooltip-start .tooltip-arrow::before{display: none;}
.sign-up-refined__tooltip-serious.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow, .bs-tooltip-start .tooltip-arrow{display: none;}

.sign-up-refined__tooltip-link-midnight{
    color: $midnight;
    text-decoration: underline;
}

.sign-up-refined__tooltip-link-midnight:hover{
    color: $midnight;
    text-decoration: underline;
}

.sign-up-refined__form__step__head {
	margin-bottom: 3 * $v;
	text-align: center;
}

.sign-up-refined__form__step__title {
	@extend %h2;
}

.sign-up-refined__form__step__subtitle {
	@extend %p;
}

// ----- re-named paged SCSS classes -----
.sign-up-refined__alert{
	width: 98%;
    margin-left: 1%;
}

.sign-up-refined__not-valid{
    background-color: $charcoal-5;
    border-color: $charcoal-5;
    color: $charcoal-2;
    cursor: not-allowed;
}

.sign-up-refined__not-valid:hover{
    background-color: $charcoal-5;
    border-color: $charcoal-5;
    color: $charcoal-2;
    cursor: not-allowed;
}

.sign-up-refined__valid{
    background-color: $midnight;
    border-color: $midnight;
    color: $white;
}

.sign-up-refined__valid:hover{
    background-color: $midnight-3;
    border-color: $midnight-3;
    color: $white;
}

.sign-up-refined__button-charcoal-5{
	background-color: $charcoal-5;
	border-color: $charcoal-5;
	color: $charcoal-2;
}

.sign-up-refined__button-charcoal-5:hover{
	background-color: $charcoal-4;
	border-color: $charcoal-4;
	color: $charcoal-2;
}

.sign-up-refined__back-button{
	background-color: $white;
	border-color: $white;
	color: $charcoal-0;
}

.sign-up-refined__back-button::before{
    content: url(/images/svg-icons/chevron-left.svg);
    padding-right: 8px;
}

.sign-up-refined__copy--bold{
	font-weight: 700;
}

.sign-up-refined__copy{
	color: $charcoal-0;
}

// ------ re-named paged SCSS - field page
.form-control.sign-up-refined__form-field.sign-up-refined__b-left{
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
    clip-path: inset(-15px 0px -15px -15px);
}

.form-control.sign-up-refined__form-field.sign-up-refined__b-right{
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
    clip-path: inset(-15px -15px -15px 0px);
}

// Bootstrap forms additional styling
.form-control.sign-up-refined__form-field{
	height: 54px;
    padding: 14px 20px;
	border-radius: 3px;
    font-size: 18px;
    line-height: 1.33;
	border: 1px solid $charcoal-4;
}

.sign-up-refined__location-field{
	text-align: center;
}

.sign-up-refined__location-field-inner{
	color: $charcoal-0;
	border: none;
	background-color: transparent;
}

.sign-up-refined__location-field-arrow {
	border: solid $charcoal-0;
	margin-bottom: 3px;
	margin-left: 5px;
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 3px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
}

.sign-up-refined__checkbox.form-check{
	padding-left: 0;
}

.sign-up-refined__checkbox, .form-control.is-valid, .was-validated .form-control:valid{
	border-color: $charcoal-4;
	background-image: none;
}

.sign-up-refined__checkbox, .form-control.is-valid:focus, .was-validated .form-control:valid:focus{
	border-color: $charcoal-4;
	box-shadow: none;
}

.sign-up-refined__checkbox, .was-validated .form-select:valid:not([multiple]):not([size]){
	background-image: none;
}

.sign-up-refined__checkbox, .was-validated .form-check-input:valid~.form-check-label{
	color: $charcoal-0;
}

.sign-up-refined__checkbox, .was-validated .form-check-input:valid:checked{
	box-shadow: none;	
}

.sign-up-refined__caution_icon{
	width: 17px;
	height: auto;
	margin-top: -5px;
	margin-right: 5px;
}

.sign-up-refined-captcha{
    min-height: 78px;
}

// ------ Loading state
.sign-up-refined__loader {
	grid-column: 1 / 3;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.sign-up-refined__loading_icon{
	width: 64px;
	height: auto;
	animation: spin 1.8s linear infinite;
}

// ----- Media queires -----
@media only screen and (min-width: 768px) and (max-width: 1100px) {
    .sign-up-refined__form-holder-inner{
        width: 70%;
        max-width: 550px;
    }
}

// 768px - 991px
@media ($medium-bp-only) {
	.sign-up-refined__form__card{
		padding: 2.5rem 2rem
	}

    .sign-up-refined__form__card {
        padding: 1rem 0 1rem 0;
    }

    .sign-up-refined__cover-image{
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .sign-up-refined__radio-input-holder{
        display: inline;
    }
}

// Max 767px Mobile breakpoint
@media ($small-bp) {
	.sign-up-refined__form__card{
		padding: 0;
		border-radius: 0;
		box-shadow: none;
	}

    .sign-up-refined__cover-holder{
        padding-bottom: 15%;
    }

    .sign-up-refined__main-bg-image{
        height: 80vh;
    }

    .sign-up-refined__form-holder-inner{
        padding-left: var(--container-gutter);
        padding-right: var(--container-gutter);
        width: 100%;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    
    .sign-up-refined__navbar-inner{
        height: 52px;
    }

    .sign-up-refined__form-field-radio-label{
        padding: 1.5rem 1.5rem;
    }

    .sign-up-refined__form-field-radio{
        padding-left: 1.5rem;
    }

    .sign-up-refined__form-holder-header{
        margin-top: 8rem;
        margin-bottom: 2rem;
    }

    .sign-up-refined__container-inner-top-margin{
        margin-top: 50px;
        height: auto;
    }

    .sign-up-refined__form-holder-footer p{
        font-size: 1.4rem;
        line-height: 2.2rem;
        margin-bottom: 0;
    }

    .sign-up-refined__form__step__head{
		text-align: center;
	}

    .sign-up-refined__radio-input-helper{
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .sign-up-refined__tooltip.tooltip .tooltip-inner{
        padding: 1rem;
        max-width: 220px;
    }

    .sign-up-refined__tooltip-serious.tooltip .tooltip-inner{
        padding: 1rem;
        max-width: 220px;
    }

    .form-control.sign-up-refined__form-field-special{
        height: auto;
        padding: 1.5rem;
    }

    .sign-up-refined__form-step-indicator-item {
        height: 0.4rem;
    }

    .sign-up-refined__form-holder-outer-page-2{
        align-items: flex-start;
        margin-top: 10%;
    }

    .sign-up-refined__form__step__title {
        @extend %h2;
        font-size: 2.8rem;
        line-height: 3.5rem;
    }

    .sign-up-refined__coral-logo{
        width: 110px;
        height: auto;
    }

    .sign-up-refined__container-inner{
        margin-top: 0;
    }

    .sign-up-refined__radio-input-holder{
        display: inline;
    }

    .sign-up-refined__loader-inner{
        width: 80%;
        height: auto;
        max-width: 1200px;
        padding-top: 10rem;
        color: $charcoal-0;
    }
}

// Mobile
@media (max-width: 576px) {
    .sign-up-refined__cover-image{
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .sign-up-refined__form-holder-header{
        margin-top: 6rem;
    }

    .sign-up-refined__form-holder-footer-spacer{
        margin-top: 6rem;
    }

    .sign-up-refined__form-holder-inner-page2{
        padding: 0;
        border: none;
    }

    .sign-up-refined__form-field-radio-select{
        font-size: 1.4rem;
        padding: 0.9rem 1.8rem;
        margin: 0.8rem 0.5rem;
    }

    .sign-up-refined__exit{
        top: 2%;
        right: 4%;
    }
    
    .sign-up-refined__exit-image{
        width: 20px;
    }
}

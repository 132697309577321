.HeroSection {
  text-align: center;

  @media (#{$medium-bp}) {
    text-align: left;
  }

  h2 {
    margin-bottom: 0;
    @media (#{$large-bp}) {
      margin-bottom: $v * 3;
    }
  }

  h4 {
    margin: 0;
  }

  p {
    margin-top: $v;
  }

  .Actions {
    padding: 0;
    .Button:first-child {
      margin-right: $v * 2;
    }
    .Button:last-child {
      margin-right: 0;
    }
  }
}

.HeroSection-image {
  width: 100%;
  @media (#{$large-bp}) {
    width: auto;
  }
}

.HeroSection-feature {
  padding-bottom: $v * 4;
  @media (#{$small-bp}) {
    &:first-child {
      padding-top: $v * 4;
    }
  }
}

.HeroSection-features {

  .Actions {
    padding: 0;
  }

  @media (#{$small-bp}) {
    text-align: center;
    .FeaturesList-title {
      display: block;
      padding-top: $v * 6;
    }
    .FeaturesList-title,
    .FeaturesList-description {
      text-align: center;
    }
  }

  @media (#{$medium-bp}) {
    padding-top: $v * 6;
  }

  @media (#{$large-bp}) {
    padding-top: $v * 10;
  }

}

.HeroSection-row {
  .Actions .Button {
    margin-top: $v * 2;
  }

  > div {
    flex-shrink: 0;
  }

  .u-grey.u-bold {
    margin-top: 0;
  }
}

.HeroSection-timelyCrew {
  max-width: 100%;
}

.HeroSection-timelyCrew-name {
  text-align: center;
  color: $charcoal-2;
  margin: $v * 1.5 0 $v * 4;
  font-weight: 700;
}

.HeroSection--timely {
  text-align: center;
  padding-top: $v * 15;

  @media (#{$medium-bp}) {
    text-align: left;
    padding-top: $v * 21;
  }
}

.HeroSection--bottomAligned {
  @media (#{$small-bp}) {
    padding-top: $v * 10;
    text-align: center;
    .HeroSection-bg {
      padding-top: $v * 4;
      margin-left: -$v * 1.5;
      margin-right: -$v * 1.5;
    }
  }

  .HeroSection-row {
    @media (#{$medium-bp}) {
      display: flex;
      align-items: flex-end;
    }
  }
}

.ColoredCard {
  cursor: pointer;
  margin-bottom: $v * 3;
  text-align: center;
  position:relative;
  border-radius: 4px;

  &.u-dark {
    .ColoredCard-title,
    .ColoredCard-description {
      color: $charcoal;
    }
  }
}

.ColoredCard::after {
  content: '';
  display: block;
  padding-bottom: 65%;

  @media (#{$large-bp}) {
    padding-bottom: 70%;
  }

  @media (#{$large-bp}) {
    padding-bottom: 100%;
  }

  @media (#{$xxlarge-bp}) {
    padding-bottom: 70%;
  }
}

.ColoredCard-title {
  margin-top: $v * 3;
  margin-bottom: $v * 2;

  img {
    margin-left: auto;
    margin-right: auto;
  }
}

.ColoredCard-content {
  position: relative;
  z-index: 2;
}

.ColoredCard-wrap {
  padding: $v * 3 $v * 3 0;
  width: 100%;
  color: $white;
  @media (#{$medium-bp}) {
    overflow: hidden;
    position: absolute;
    height: 100%;
    padding: $v * 3;
  }
}

.ColoredCard-image {
  img {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (#{$medium-bp}) {
    img {
      width: 100%;
      left: 0;
      transform: none;
    }

    position: absolute;
    width: 55%;
    height: 40%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    // img {
    //   width: 100%;
    // }
  }
}

.ColoredCard--fullImage {
  .ColoredCard-image {
    width: 130%;
    max-width: 130%;
    @media (#{$xxlarge-bp}) {
      width: 80%;
      max-width: 80%;
    }
  }
}

.ColoredCards .slick-slide {
  .ColoredCard,
  > div > div,
  > div {
    height: 100%;
  }
}

.ColoredCards-container.slick-initialized {
  padding-left: 0;
  padding-right: 0;
}
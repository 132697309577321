body {
    font-family: $text-font;
    font-size: 16px;
    color: $charcoal-0;
}

// h1,
// .h1 {
//   font-family: $domaindisplay;
// }

// a {
//     font-size: 1.6 * $v;
// }

h2,
.h2,
h3,
.h3 {
    font-family: $domaindisplay;
}

.h1,
h1 {
    @extend %h1;
}

.h2,
h2 {
    @extend %h2;
}

.h3,
h3 {
    @extend %h3;
}

.h4,
h4 {
    @extend %h4;
}

.h5,
h5 {
    @extend %h5;
}

.h6,
h6 {
    @extend %h6
}

.u-p,
.p,
p {
    @extend %p;
}

.bold {
    font-weight: 700;
}

.fineprint {
    font-size: 1.4rem;
    line-height: 2.1rem;
}

.u-midGrey,
.disclaimer {
    color: $mid-grey !important;
}

%h1 {
    font-size: 3.5rem;
    line-height: 4.2rem;
    font-family: $domaindisplay;
    font-weight: $weight-regular;
    letter-spacing: -0.4px;

    @media (#{$medium-bp}) {
        font-size: 4.5rem;
        line-height: 5.4rem;
    }
}

/// Same as %h1, but with two extra steps up at larger sizes
%h1--jumbo {
    @extend %h1;

    @media (#{$large-bp}) {
        font-size: 5.0rem;
        line-height: 1.2;
    }

    @media (#{$xlarge-bp}) {
        font-size: 6.6rem;
    }
}

%h2 {
    font-family: $domaindisplay;
    font-size: 2.8rem;
    line-height: 3.5rem;
    font-weight: $weight-regular;
    letter-spacing: -0.4px;
    @media (#{$medium-bp}) {
        font-size: 3.5rem;
        line-height: 4.4rem;
    }
}

%h3 {
    font-family: $domaindisplay;
    font-size: 2.2rem;
    line-height: 2.8rem;
    margin: 0;
    font-weight: $weight-regular;
    letter-spacing: -0.4px;
    @media (#{$medium-bp}) {
        font-size: 2.8rem;
        line-height: 3.5rem;
    }
}

%h4 {
    font-size: 1.4rem;
    margin-top: 0;
    text-transform: uppercase;
    font-family: $text-font;
    font-weight: 700;
    letter-spacing: 2.5px;
    line-height: 2.1rem;

    @media (#{$medium-bp}) {
        font-size: 1.5rem;
        line-height: 2.5rem;
    }
}

%h5 {
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin-top: 0;
    letter-spacing: 2.2px;
    text-transform: uppercase;
    font-family: $text-font;
    font-weight: 700;

    @media (#{$medium-bp}) {
        font-size: 1.4rem;
        line-height: 2.1rem;
        letter-spacing: 2.5px;
    }
}

/// Same as `%h5`, but with an extra step up
%h5--plus {
    @extend %h5;

    @media (#{$large-bp}) {
        font-size: 1.6rem;
        line-height: 1.5;
    }
}

%h6 {
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-family: $text-font;
    font-weight: 700;
    margin-top: 0;
    text-transform: initial;
    letter-spacing: 0;
    margin-bottom: $v;
}

%p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-family: $text-font;
}

.italics {
    font-style: italic;
}

%smallLabel {
    font-size: 1.2rem;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.u-displayFontMedium {
    font-family: $domaindisplay;
    font-weight: $weight-medium;
}

.h3-small {
    font-family: $domaindisplay;
    font-size: 2.2rem;
    line-height: 2.8rem;
    margin: 0;
    font-weight: $weight-regular;
    letter-spacing: -0.4px;
}

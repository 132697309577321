$scroll-accordion__bp: $medium-bp;

.scroll-accordion {
    --scroll-accordion-progress-position: 0%;
    --scroll-accordion-progress-size: 100%;
}

.scroll-accordion__layout {
    @media ($scroll-accordion__bp) {
        display: grid;
        align-items: stretch;
		grid-template-columns: repeat(24, 1fr);
		grid-gap: 10px;

        // Image, slides, progress bar
        // grid-template-columns: 1fr 1fr 5px;
        // column-gap: 2 * $h;
    }
}

.scroll-accordion__figure {
    display: none;
    @media ($scroll-accordion__bp) {
        display: block;
		grid-column: span 12;
		display: flex;
		align-items: center;
    }
}

.scroll-accordion__spacer{
	grid-column: span 1
}

.scroll-accordion__image {
    display: block;
    max-width: 100%;
}

.scroll-accordion__slides {
	grid-column: span 10;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2.5 * $v;
}

.scroll-accordion__inner-p:last-child {
    margin-bottom: 0;
}

.scroll-accordion__slide__figure {
    @media ($scroll-accordion__bp) {
        display: none;
    }
}

.scroll-accordion__slide__image {
    display: block;
    max-width: 100%;
}

.scroll-accordion__slide__title {
    @extend %h3;
    color: $charcoal-2;
    @media ($scroll-accordion__bp) {
        color: $charcoal-2;
        .scroll-accordion__slide--current & {
            color: $charcoal;
        }
        transition: color 0.1s ease-in-out;
    }
}

.scroll-accordion__slide__title--trigger:hover {
    color: $charcoal-0;
}

.scroll-accordion__slide--current .scroll-accordion__slide__title {
    color: $charcoal-0;
}

.scroll-accordion__slide__title--trigger {
    // Don't apply default button styles
    all: inherit;
    width: 100%;
    display: none;
    @media ($scroll-accordion__bp) {
        display: block;
    }
}

.scroll-accordion__slide__title--inert {
    display: block;
    @media ($scroll-accordion__bp) {
        display: none;
    }
}

@media ($scroll-accordion__bp) {
    .scroll-accordion__slide__collapsable {
        // This trick utilises grid to be able to animate between height 0 and auto
        // See https://chriscoyier.net/2022/12/21/things-css-could-still-use-heading-into-2023/#animate-to-auto
        display: grid;
        grid-template-rows: 0fr;
        .scroll-accordion__slide--current & {
            grid-template-rows: 1fr;
        }
        @media (prefers-reduced-motion: no-preference) {
            transition: grid-template-rows 0.3s ease-in-out;
        }
        overflow: hidden;
    }

    .scroll-accordion__slide__collapsable__content {
        min-height: 0;
    }
}

.scroll-accordion__slide__text {
    text-wrap: balance;
    p {
        @extend %p;
    }
}

.scroll-accordion__slide__link {
    display: block;
    margin-top: 2 * $v;
}

.scroll-accordion__progress {
	grid-column: span 1;
    width: 2px;
    // Setting background position lets us take advantage of the way this property handles
    // percentage values differently compared with setting top or translateY properties
    // https://css-tricks.com/i-like-how-percentage-background-position-works/
    // It's necessary to double the vertical size of the gradient to let it slide vertically
    background-size: 100% 200%;
    background-color: $charcoal-0;

    // These custom properties are updated from the component
    background-image: linear-gradient(
        180deg,
        $coral-2 0%,
        $coral-2 calc(var(--scroll-accordion-progress-size) / 2),
        transparent calc(var(--scroll-accordion-progress-size) / 2),
        transparent 100%
    );
    background-position: 0 calc(-1 * var(--scroll-accordion-progress-position));

    @media (prefers-reduced-motion: no-preference) {
        transition: all 0.3s ease-in-out;
    }
}

// Image holder
.scroll-accordion__img-wrapper {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    isolation: isolate;
}

.scroll-accordion__img-inner {
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    // Tell the browser to mix opacities for a proper cross-fade
    mix-blend-mode: plus-lighter;
}

.scroll-accordion__img-inner--current {
    z-index: 1;
    opacity: 1;
}

/* Large devices (desktops) */
@media ($medium-bp) and (max-width: 1199.98px) {
    .scroll-accordion__img-wrapper {
        height: 350px;
    }
}

/* Small devices (tablets) */
@media ($small-bp) {
    .scroll-accordion__img-wrapper {
        height: 350px;
    }

    .scroll-accordion__slides {
        gap: 5 * $v;
    }

    .scroll-accordion__slide__title {
        color: $charcoal-0;
    }
}

@use "sass:math";

$icon-list-dynamic__bp: $medium-bp;

.icon-list-dynamic {}

.icon-list-dynamic__container {
	margin-top: 5 * $v;
	@media ($icon-list-dynamic__bp) {
		margin-top: 10 * $v;
	}
}

.icon-list-dynamic__title {
	@extend %h2;
	margin-top: 0;
	margin-bottom: 4 * $v;
}

.icon-list-dynamic__title--has-image {
	text-align: center;
}

.icon-list-dynamic__layout {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	column-gap: percentage(math.div(1, 12));
	row-gap: 2 * $v;

	@media ($icon-list-dynamic__bp) {
		flex-wrap: nowrap;
	}

	margin-bottom: 5 * $v;
	@media ($icon-list-dynamic__bp) {
		margin-bottom: 10 * $v;
	}
	&.icon-list-dynamic__layout--flush {
		margin-bottom: 0;
	}
}

.icon-list-dynamic__figure {
	margin: 0;

	flex: 1 1 100%;
	@media ($icon-list-dynamic__bp) {
		width: percentage(math.div(6, 12));
	}
}

.icon-list-dynamic__image {
	display: block;
	max-width: 100%;
}

.icon-list-dynamic__list {
	flex: 1 0 percentage(math.div(5, 12));

	list-style: none;
	margin: 0;
	padding: 0;
}

.icon-list-dynamic__item {
	display: flex;
	align-items: flex-start;
	gap: $h;
}

.icon-list-dynamic__item__icon {
	flex: 0 0 3rem;
	aspect-ratio: 1 / 1;
}

.icon-list-dynamic__item__text {
	@extend %p;
	margin-bottom: 2 * $v;

	> :first-child {
		margin-top: 0.3 * $v; // Align with icon
	}
	> :last-child {
		margin-bottom: 0;
	}
}

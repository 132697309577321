.ImageCards {
  padding-left: 4px;
  padding-right: 4px;
  overflow: hidden;
}

.ImageCard {
  margin-top: 8px;
  width: 100%;
  padding-left: 4px;
  padding-right: 4px;
  float: left;
  position: relative;
  overflow: hidden;
  font-size: 0;

  img {
    transition: all .5s ease-in-out;
    width: 100%;
    transform: scale(1.005);
  }

  @media (#{$medium-bp}) {
    width: 50%;
  }

  @media (#{$large-bp}) {
    width: 25%;
  }

  &:hover {
    img {
      transform-origin: center;
      transform: scale(1.01);
    }

    .ImageCard-content {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 4px;
        right: 4px;
        bottom: 0;
        background-color: rgba(0,0,0,.2);
        z-index: 1;
      }
    }
  }

}

.ImageCard-wrap {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  padding: $v $v * 3;
  transform: translateY(-50%);
  z-index: 10 !important;
}

.ImageCard-title {
  color: $white;
  font-size: 2.8rem;
  line-height: 3.4rem;
  font-family: $domaindisplay;
  font-weight: $weight-regular;
  text-align: center;
}

.ImageCard-content {
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 4px;
    right: 4px;
    bottom: 0;
    transition: background .5s ease-in-out;
    background-color: rgba(0,0,0,0);
    z-index: 9;
  }
}
.FeaturedPostsCarousel {
    list-style: none;
    padding: 0;
    margin: 0;
}
.FeaturedPostsCarousel-item {
    background-color: $white;
    border: 1px solid $charcoal-4;
    border-radius: 3px;

    @media (#{$medium-bp}) {
        display: flex;

        > * {
            width: 50%;
            flex-basis: 50%;
            flex-grow: 0;
            flex-shrink: 0;
        }
    }
}
.FeaturedPostsCarousel-image {
    position: relative;
}
.FeaturedPostsCarousel-content {
    padding: $v * 3 $v * 1.5;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    color: $charcoal-0;
    @media (#{$medium-bp}) {
        padding: $v * 3 $v * 4;
    }
}

.PostContent-meta {
    display: flex;
    align-items: center;
}
.PostContent-metaDate {
    font-size: $v * 1.2;
    color: #889095;
    text-transform: uppercase;
    letter-spacing: 0.75px;
    &.u-sm-only {
        display: block;
        flex-basis: 100%;
        width: 100%;
        padding-top: $v;
    }
}
.PostContent-metaDate--single {
    .PostContent-metaDate {
        color: $charcoal;
        text-transform: none;
        font-size: $v * 1.4;
        letter-spacing: 0;
        line-height: 20px;
    }
}
.PackageButton {
    border: none;
    color: $white;
    cursor: pointer;
    border-radius: 14px;
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1.27px;
    line-height: 24px;
    text-transform: uppercase;
    padding: 0 $v * 1.5;
    margin-right: $v;
}

.PackageButton--elevate {
    background-color: #f386a1;
}

.PackageButton--innovate {
    background-color: $midnight-2;
}

.FeaturedPostsCarousel-share {
    margin-left: auto;
}

.FeaturedPostsCarousel-title {
    margin: $v 0 $v * 1.5;
    color: $charcoal-0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
        text-decoration: underline;
    }
}

.ShareButton {
    background-color: $charcoal-5;
    border-radius: 50%;
    border: 0;
    width: 34px;
    height: 34px;
    flex-grow: 0;
    flex-shrink: 0;
    transition: all 0.2s ease-in-out;
    padding: 0;
    cursor: pointer;
    > svg {
        position: relative;
        top: 0px;
        left: -1px;
        width: 34px;
        display: inline-block;
        @media (#{$small-bp}) {
            top: -2px;
        }
    }
    &:hover {
        background-color: $charcoal-3;

        #Group-6 {
            stroke: $white;
        }
    }
}

.ShareButton-link {
    display: inline-block;

    a {
        color: $white;
        background-color: $charcoal-0;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin-right: math.div($v, 2);
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            background-color: $charcoal-1;
        }
    }

    span {
        position: relative;
        top: -1px;
    }
}

div.ShareButton-content.react-hint__content {
    background-color: $white;
    display: flex;
    box-shadow: 1px 2px 8px 0px rgb(0 0 0 / 5%);
}

.ShareButton-hint {
    &.react-hint--bottom {
        &::after {
            border-bottom-color: $white !important;
        }
    }
    &.react-hint--left {
        &::after {
            border-left-color: $white !important;
        }
    }
}

.PackageButton-tip {
    width: 313px;
    div.react-hint__content {
        background-color: $white;
        color: $charcoal;
        box-shadow: 1px 2px 8px 0px rgb(0 0 0 / 5%);
    }
    &::after {
        border-top-color: $dusk-4 !important;
    }
    &.react-hint--right {
        &::after {
            border-right-color: $dusk-4 !important;
        }
    }
}

.PackageButton-content {
    display: flex;
    align-items: baseline;
    .fa-info-circle {
        color: $hot-coral;
        font-size: $v * 2;
        margin-right: $v * 0.5;
    }
}

.MobileDropDown {
    background-color: $hot-coral;
    color: $white;
    width: 100%;
    padding: 0;
    text-align-last: center;
    display: flex;
    justify-content: center;
    align-items: center;
    select {
        appearance: none;
        background-color: $hot-coral;
        border: none;
        padding: $v * 2;
    }
    &::after {
        display: inline-block;
        // content: '\F105';
        content: url('/images/svg-icons/ic-down.svg');
        font-family: fa;
        transition: transform 0.3s ease;
        position: relative;
        left: 8px;
        top: -1px;
        font-size: 18px;
        line-height: $v;
        filter: invert(99%) sepia(100%) saturate(85%) hue-rotate(279deg)
            brightness(113%) contrast(100%);
    }
}

.posts-in-sticky-list {
    .StickyGroup-group {
        @extend .u-vSpacingMD-top;
        &:first-child {
            padding-top: 0;
            .StickyGroup-group-heading {
                margin-top: 0;
            }
        }

        .StickyListItem {
            &:first-child {
                margin-top: $v * 3;
            }
        }
    }
}

a:not(.page-number, .has-white-color, .TitleIcons--arrow, .register-button, .btn, .TimelyNavBar-PrimaryList-itemLink, .Button, .NavBlock-title, .TimelyNavBar-main-item, .Footer-item, .CharcoalLink, .ShareLink-button, .CoralLink, .no-color-override) {
    &,
    &:focus,
    &:visited {
        color: $hot-coral;
        transition: color 0.2s ease-in;
        text-decoration: none;
    }

    .svg {
        vertical-align: baseline;
    }
}
.no-color-override {
    // This class should have no styles. It exists only to exclude an element from the colour override above
}
.nav-pills a {
    color: $charcoal !important;
}

.Buttton--charcoalUnderline {
    a {
        color: $charcoal-0 !important;
        box-shadow: inset 0 -0.1rem $charcoal-0;
        transition: all;
        &:hover {
            color: $hot-coral !important;
            box-shadow: inset 0 -0.1rem $hot-coral;
        }
    }
}

a:focus {
    color: $hot-coral;
}
a:hover {
    color: $coral-hover;

    .svg g {
        fill: $coral-hover;
    }
}

.PhoneNumber {
    color: $charcoal;
}

.leadcap-revamp {
    a:not(.Button) {
        &,
        &:visited {
            transition: all 0.2s ease-out;
            color: $light-coral;
        }

        &:hover {
            color: $hot-coral;
        }
    }
}

.CharcoalLink {
    text-decoration: none;
    color: $charcoal-0;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
}

.CoralLink {
    cursor: pointer;
    text-decoration: none;
    color: $hot-coral;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
}

.CharcoalLink--back {
    &::before {
        transition: all 0.2s ease-in-out;
        margin-right: $v;
        display: inline-block;
        content: url(/images/svg-icons/ic-down.svg);
        transform: rotate(90deg);
        font-family: fa;
        transition: transform 0.3s ease;
        position: relative;
        left: 8px;
        top: -1px;
        font-size: 18px;
        line-height: 1rem;
    }

    &:hover {
        &::before {
            filter: invert(38%) sepia(99%) saturate(1143%) hue-rotate(327deg);
        }
    }
}
.sharebutton a {
    color: $white !important;
}

.CommonQuestions-row {
  clear: both
}

.CommonQuestions-item {
  margin-bottom: $v * 5;
}

.CommonQuestions-title {
  text-align: center;
  padding-top: $v * 6;
  padding-bottom: $v * 6;
  margin: 0;

  @media (#{$large-bp}) {
    padding-top: $v * 9;
    padding-bottom: $v * 9;
  }
}
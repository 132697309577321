.FourColumn {
  padding-top: $v * 9;
  padding-bottom: $v * 9;
  @media (#{$medium-bp}) {
    padding-top: $v * 15;
    padding-bottom: $v * 15;
  }
}
.FourColumn-title {
  font-size: 2.5rem;
  line-height: 3rem;
}

.FourColumn-container {
  .col-sm-3 {
    margin-top: $v * 6;
    @media (#{$medium-bp}) {
      margin-top: 0;
    }
  }
}

.FourColumn--withBorders {

  .col-sm-3 {
    padding-bottom: 0;
    padding-top: $v * 3;
    border-top: 1px solid $charcoal-4;
    margin-left: $v * 1.5;
    margin-right: $v * 1.5;
    margin-top: $v * 3;

    &:first-child {
      border: none;
    }

    @media (#{$medium-bp}) {
      margin-left: 0;
      margin-right: 0;
      border-left: 1px solid $charcoal-4;
      border-top: 0;
      padding-bottom: $v * 9;
      padding-top: $v * 9;
    }
  }

  h3 {
    font-size: 60px !important;
    font-weight: 300 !important;
    line-height: 1.2 !important;
  }

  p {
    color: $charcoal-2;
    font-size: 13px;
    font-weight: normal;
    letter-spacing: 1px;
    line-height: 1.5;
    text-transform: uppercase;
  }

}
@use "sass:math";

$accordion-list__bp: $large-bp;

.AccordionList {
    display: flex;
    flex-wrap: wrap;
    column-gap: percentage(math.div(1, 12));
    row-gap: $v * 2;

    align-items: center;
    @media ($accordion-list__bp) {
        flex-wrap: nowrap;
    }
}

.AccordionList__column {
    flex: 1 0 100%;
    width: 100%;
    @media ($accordion-list__bp) {
        flex: 1 1 percentage(math.div(6, 12));
        width: auto;
        &.AccordionList__column--content {
            flex: 1 1 percentage(math.div(5, 12));
        }
    }
}

.AccordionList-item {
    margin-top: $v;
    margin-bottom: $v;
    padding: $v;
    display: flex;
    align-items: baseline;
    overflow: hidden;
    cursor: pointer;
    transition: background-color 0.25s ease-in;

    > img {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 5%;
        position: relative;
        top: math.div($v, 2);
        margin-right: $v;
    }

    @media (#{$large-bp}) {
        padding: $v * 1.5;
    }

    &:hover {
        background-color: $charcoal-5;
    }
}

.AccordionList-item--isOpen {
    background-color: $charcoal-5;
    border-radius: 4px;
}

.AccordionList.AccordionList--noBg {
    .AccordionList-item--isOpen {
        background-color: transparent;
    }
    .AccordionList-item {
        &:hover {
            background-color: transparent;
        }
    }
}

.AccordionList-description {
    // padding-top: $v;
    letter-spacing: 0;
    line-height: $v * 2.4;
    p {
        margin-bottom: 0;
    }
}

.AccodionList-image {
    display: block;
    max-width: 100%;

    margin-bottom: $v * 3;
    @media (#{$large-bp}) {
        margin-bottom: 0;
    }
}

.AccordionList--reverse {
    flex-direction: column-reverse;
    @media (#{$large-bp}) {
        flex-direction: row;
    }
}

.AccordionList-actions {
    margin-top: $v * 2;
}

.AccordionList-heading {
    margin-bottom: $v * 2;
    @media ($accordion-list__bp) {
        margin-bottom: $v * 4;
    }
}

.AccordionList--noPoint {
    .AccordionList-item {
        cursor: initial;
        padding-left: 0;
        > img {
            flex-basis: 5%;
        }
    }
}

.AccordionList--animate {
    .AccordionList-item {
        &::after {
            content: ' ';
            position: absolute;
            transition: width 9s ease-in;
            width: 0;
        }
    }
    .AccordionList-item--isOpen {
        position: relative;
        &::before {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            height: 2px;
            right: 0;
            background-color: #f1f0f0;
        }
        &::after {
            top: 0;
            left: 0;
            height: 2px;
            width: 100%;
            background-color: $hot-coral;
        }
    }
}

.AccordionList-title {
    h6 {
        font-family: $text-font;
    }
}

.Accordion--bugs {
    background-color: $charcoal-5;
    padding: $v * 2;
    margin-top: $v * 5;

    .Accordion-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: math.div($v, 2);
        cursor: pointer;

        h3 {
            padding: 0;
        }
        &::after {
            display: inline-block;
            // content: '\F105';
            content: url('/images/svg-icons/ic-down.svg');
            font-family: fa;
            transition: transform 0.3s ease;
            position: relative;
            left: 0;
            top: -1px;
            font-size: 18px;
            line-height: $v;
        }
        &:hover {
            h3 {
                color: $coral-hover;
            }
            &::after {
                filter: invert(38%) sepia(99%) saturate(1143%)
                    hue-rotate(327deg);
            }
        }
    }
}
.Accordion--open {
    .Accordion-title {
        &::after {
            transform: rotate(180deg);
        }
    }
}

.AccordionBug-item {
    margin-top: $v * 2;
    margin-bottom: $v * 2;
    p {
        margin-top: math.div($v, 2);
        margin-bottom: math.div($v, 2);
    }
}

.AccordionList--h2Titles {
    h3.AccordionList-heading {
        @extend %h2;
    }
}

.CardFeature {
    margin-bottom: $v * 4;

    background-color: $white;
    border: 1px solid $dusk-0;

    > a {
        color: $charcoal;
        transition: box-shadow 0.5 ease-in-out;
    }

    &:hover {
        border-color: $hot-coral;
        h4.CardFeature-title,
        h3.CardFeature-title {
            text-decoration: underline;
        }
    }

    .CardFeature-preText {
        margin-bottom: 0;
        color: $charcoal-2;
        font-size: $v * 1.4;
        line-height: $v;
    }
    .CardFeature-content {
        padding: $v * 1.5;
    }

    h3.CardFeature-title {
        font-size: $v * 1.8;
        line-height: $v * 2.3;
        margin: 0.3rem 0 0;
        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .CardFeature-image {
        img {
            object-fit: cover;
        }
    }
}

@media (#{$medium-bp}) {
    .CardFeature--landscape {
        > a {
            display: flex;
            align-items: center;
            width: 100%;
            min-height: 90px;
            max-height: 220px;

            .CardFeature-logo {
                width: 65%;
            }

            .CardFeature-image {
                width: 33%;
                display: flex;
                align-self: stretch;
            }
            .CardFeature-content {
                width: 67%;
            }
        }
    }
}

@media (#{$large-bp}) {
    .col-md-6 .CardFeature--landscape {
        > a {
            .CardFeature-logo {
                width: auto;
                height: 50px;
            }
        }
    }
}

.CardFeature--landscape .CardFeature-actions {
    @media (#{$xlarge-bp}) {
        margin-top: $v * 5;
    }
}

.col-md-6 {
    .CardFeature--landscape .CardFeature-actions {
        @media (#{$xlarge-bp}) {
            margin-top: $v * 2;
        }
    }
}

.CardFeature--download.CardFeature--landscape {
    @media (#{$medium-bp}) {
        .CardFeature-image {
            width: 50%;
        }
        .CardFeature-content {
            width: 50%;
        }
    }
}

.col-md-6 {
    .CardFeature--landscape {
        .CardFeature-title {
            font-size: $v * 2;
            line-height: $v * 3;
        }
    }
    .CardFeature-excerpt {
        display: none;
    }
}

.has-3-columns {
    .CardFeature--portrait {
        .CardFeature-title {
            font-size: $v * 2;
        }
    }
}

.CardFeature-logo {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.CardFeature-actions {
    text-align: center;
    margin-top: $v * 2;
}

.CardFeature.CardFeature--download {
    border: none;
    .CardFeature-content {
        padding-top: $v * 2;
        padding-bottom: $v * 2;
    }
}

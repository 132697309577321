.sign-up-landing-page__container-outer {
    width: 100%;
}

.sign-up-landing-page__container-inner {
    height: auto;
}

.sign-up-landing-page__form-holder-outer {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sign-up-landing-page__h1-jumbo {
    font-size: 5.5rem;
    line-height: 6.4rem;
    color: $white;
    text-align: center;
}

.sign-up-landing-page__form-holder-inner {
    width: 80%;
    max-width: 500px;
    border-radius: 7px;
    border: 1px solid $charcoal-4;
    background-color: $white;
    border-radius: 7px;
    padding: 2rem;
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
}

.sign-up-landing-page__main-bg-image {
    background-color: $white;
    inset: 0;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
}

.sign-up-landing-page__cover-holder {
    height: 100%;
    display: flex;
    background-image: none;
    flex-direction: column;
    align-items: center;
}

.sign-up-landing-page__cover-text {
    color: $white;
    text-align: center;
}

.sign-up-landing-page__cover-text-upper {
    align-content: center;
    margin-top: 10%;
    width: 70%;
    max-width: 500px;
}

.sign-up-landing-page__cover-sub-text-holder {
    width: 90%;
    max-width: 1000px;
    margin-bottom: 5rem;
}

.sign-up-landing-page__cover-sub-text {
    color: $white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sign-up-landing-page__cover-sub-text:before {
    content: '';
    background-image: url(/images/svg/tick-white.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 12px;
    height: 12px;
    margin-right: 8px; /* Adjust space between icon and text */
}

.sign-up-landing-page__form-holder-footer {
    text-align: left;
    margin-left: 28px;
}

.sign-up-landing-page__form-holder-footer a {
    color: $midnight;
    text-decoration: underline;
}

.sign-up-landing-page__loader-inner {
    width: 80%;
    height: auto;
    max-width: 1200px;
    padding-top: 0;
    color: $charcoal-0;
}

.sign-up-landing-page__mobile_image {
    display: none;
}

// ----- Form -----
.sign-up-landing-page__field-tweak {
    position: relative;
    left: -1px;
}

.sign-up-landing-page__form__card {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    border-radius: 7px;
    flex-grow: 1;
}

.sign-up-landing-page__form__card-step1 {
    border: 1px solid $charcoal-4;
}

// ----- Progress bar  -----
.sign-up-landing-page__form-step-indicator {
    list-style: none;
    display: flex;
    margin-left: 12%;
    margin-right: 12%;
    margin-bottom: 2.1rem;
    gap: 1.2rem;
    padding: 0;
}

.sign-up-landing-page__form-step-indicator-item {
    flex-grow: 1;
    height: 0.9rem;
    border: 2px solid white;
    border-radius: 8px;
    background-image: linear-gradient(
        90deg,
        $midnight 0%,
        $midnight 50%,
        $charcoal-4 50%,
        $charcoal-4 100%
    );
    background-size: 210%;
    border: none;
    background-position: 0 0;
    transition: 0.3s background-position ease-in-out;
    [aria-current='true'] ~ & {
        background-position: 100% 0;
    }
}

// ----- form fields -----
// Radio buttons
.sign-up-landing-page__form-field-radio {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid $charcoal-4;
    border-radius: 4px;
    margin-bottom: 1.5rem;
    padding-left: 0;
}

.sign-up-landing-page__form-field-radio:hover {
    background-color: $charcoal-5;
}

// Special dropdown
.sign-up-landing-page__form-field-radio-hold-1:hover {
    background-color: $white;
}

.sign-up-landing-page__form-field-terms-btn {
    cursor: pointer;
    margin-left: 2rem;
    padding: 1rem;
    border: 2px solid $charcoal-4;
}

// Terms checkbox
.sign-up-landing-page__form-field-radio-btn {
    cursor: pointer;
    margin-left: 2rem;
    padding: 0.85rem;
    border: 2px solid $charcoal-4;
}

.sign-up-landing-page__form-field-radio-btn .form-check-input:checked {
    background-color: $midnight;
    border-color: $midnight;
}

.sign-up-landing-page__form-field-radio-btn.was-validated
    .form-check-input:checked {
    background-color: $midnight;
    border-color: $midnight;
}

.sign-up-landing-page__form-field-radio-btn,
.form-check-input:focus {
    border-color: $charcoal-4;
}

.sign-up-landing-page__form-field-radio-btn.form-check-input:checked[type='checkbox'] {
    background-color: $midnight;
    border-color: $midnight;
}

.sign-up-landing-page__radio-input-helper {
    position: absolute;
    right: -40px;
    margin-top: 50px;
    pointer-events: none;
    clip: rect(0, 0, 0, 0);
}

.sign-up-landing-page__radio-input-holder {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
}

.sign-up-landing-page__form-field-radio-btn
    .was-validated
    .form-check-input:valid:checked {
    background-color: $midnight;
}

.sign-up-landing-page__form-field-radio .form-check-input:checked {
    background-image: url('/images/svg/tick-white.svg');
    background-size: 65%;
    background-color: $midnight;
    border-color: $midnight;
}

.sign-up-landing-page__form-field-radio
    .form-check-input:checked[type='radio'] {
    background-image: url('/images/svg/tick-white.svg');
    background-size: 65%;
    background-color: $midnight;
    border-color: $midnight;
    position: absolute;
    left: 20px;
}

.sign-up-landing-page__form-field-radio .form-check-input:focus {
    border-color: $charcoal-4;
    box-shadow: none;
}

.sign-up-landing-page__form-field-radio-label {
    cursor: pointer;
    padding: 1.6rem 2rem 1.6rem 5.5rem;
    width: 100%;
    height: 100%;
}

.sign-up-landing-page__form-field-radio {
    .form-check-input {
        &:checked
            + .form-check-label.sign-up-landing-page__form-field-radio-label {
            background-color: $midnight-pale;
        }
    }
}

.sign-up-landing-page__form-field-radio1 {
    .form-check-input {
        &:checked
            + .form-check-label.sign-up-landing-page__form-field-radio-label {
            background-color: $midnight-pale;
        }
    }
}

.sign-up-landing-page__form-field-radio1 {
    &:checked + .form-check-label.sign-up-landing-page__form-field-radio-label {
        background-color: $midnight-pale;
    }
}

.sign-up-landing-page__form-field-radio-hold-2 {
    .form-check-input {
        &:checked
            + .form-check-label.sign-up-landing-page__form-field-radio-label {
            background-color: $white;
        }
    }
}

.sign-up-landing-page__form-field-radio-select {
    border: 1px solid $charcoal-4;
    border-radius: 5px;
    font-size: 1.5rem;
    margin: 0.7rem;
    width: 130px;
    height: 100px;
    background-color: $white;
    color: $charcoal-0;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    flex-direction: column;
}

// Error message for radio buttons
.was-validated:has(.form-check-input:invalid) .invalid-feedback {
    display: block;
}

.sign-up-landing-page__form-field-radio-btn,
.was-validated .form-check-input:valid {
    border-color: $charcoal-4;
}

.sign-up-landing-page__form-field-radio-btn,
.was-validated .form-check-input:invalid {
    border-color: $charcoal-4;
}

.sign-up-landing-page__form-field-radio-btn,
.was-validated .form-check-input:invalid ~ .form-check-label {
    color: $charcoal-0;
}

.sign-up-landing-page__form-field-radio-select:checked {
    background-color: $midnight;
}

.sign-up-landing-page__form-field-category-outer .btn-outline-success:hover {
    background-color: $charcoal-5;
    border: 1px solid $charcoal-4;
    color: $charcoal-0;
}

.sign-up-landing-page__form-field-category-outer {
    .btn-check:active + .btn-outline-success,
    .btn-check:checked + .btn-outline-success,
    .btn-outline-success.active,
    .btn-outline-success.dropdown-toggle.show,
    .btn-outline-success:active {
        background-color: $midnight;
        color: $white;
        stroke: $white;
        box-shadow: none;
        border: 1px solid $charcoal-4;
    }
}

.sign-up-landing-page__radio-input-helper2 {
    .sign-up-landing-page__radio-input-helper {
        &:checked + .sign-up-landing-page__form-field-radio-select {
            background-color: $midnight;
            color: $white;
            stroke: $white;
            border: 1px solid $charcoal-4;
        }
    }
}

.btn-check:checked + .btn-outline-success .sign-up-landing-page__svg-colour {
    stroke: $white;
    color: $white;
}

.sign-up-landing-page__form-field-category-outer
    .btn-check:checked
    + .btn-outline-success:focus {
    box-shadow: none;
}

.sign-up-landing-page__form-field-category-outer .btn-outline-success:focus {
    box-shadow: none;
}

.sign-up-landing-page__form-field-radio-hold-1 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.sign-up-landing-page__form-field-radio-hold-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.sign-up-landing-page__form-field-radio-hold-3 {
    width: 100%;
    padding: 0 0 2rem 0;
    background-color: white;
}

// positions only radio button choices
.sign-up-landing-page__form-field-radio-btn-absolute {
    margin-left: 0;
    position: absolute;
    left: 20px;
}

.form-control.sign-up-landing-page__form-field-special {
    height: 54px;
    padding: 14px 20px;
    margin-left: 3%;
    width: 94%;
    border-radius: 3px;
    font-size: 16px;
    line-height: 1.3;
    background-color: $midnight-pale;
    border: none;
}

.sign-up-landing-page__promo-code-field-inner {
    color: $midnight;
    border: none;
    text-align: left;
    background-color: transparent;
}

.sign-up-landing-page__business-category-icon-outer {
    display: flex;
    justify-content: center;
    margin-bottom: 4px;
}

.sign-up-landing-page__business-category-icon {
    width: 32px;
    height: auto;
}

.sign-up-landing-page__special-button {
    cursor: pointer;
}

// ----- Tooltips -----
.sign-up-landing-page__tooltip-hidden.tooltip {
    display: none;
}

.sign-up-landing-page__tooltip.tooltip {
    opacity: 1;
}

.sign-up-landing-page__tooltip.tooltip .tooltip-inner {
    padding: 1rem 1rem 1rem 1rem;
    font-family: $text-font;
    border-radius: 3px;
    background-color: $midnight-pale;
    color: $charcoal-0;
    text-align: left;
    max-width: 250px;
    box-shadow: none;
}

.sign-up-landing-page__tooltip.bs-tooltip-auto[data-popper-placement^='top']
    .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
    display: none;
}
.sign-up-landing-page__tooltip.bs-tooltip-auto[data-popper-placement^='top']
    .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
    display: none;
}

.sign-up-landing-page__tooltip.bs-tooltip-auto[data-popper-placement^='right']
    .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
    display: none;
}
.sign-up-landing-page__tooltip.bs-tooltip-auto[data-popper-placement^='right']
    .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
    display: none;
}

.sign-up-landing-page__tooltip.bs-tooltip-auto[data-popper-placement^='bottom']
    .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
    display: none;
}
.sign-up-landing-page__tooltip.bs-tooltip-auto[data-popper-placement^='bottom']
    .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
    display: none;
}

.sign-up-landing-page__tooltip.bs-tooltip-auto[data-popper-placement^='left']
    .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
    display: none;
}
.sign-up-landing-page__tooltip.bs-tooltip-auto[data-popper-placement^='left']
    .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
    display: none;
}

// Tooltip serious info
.sign-up-landing-page__tooltip-serious.tooltip .tooltip-inner {
    padding: 1rem 1rem 1rem 1rem;
    font-family: $text-font;
    border-radius: 5px;
    background-color: $midnight-pale;
    color: $charcoal-0;
    text-align: center;
    max-width: 280px;
}

.sign-up-landing-page__form {
    position: relative;
}

.sign-up-landing-page__tooltip-serious.bs-tooltip-auto[data-popper-placement^='top']
    .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
    display: none;
}
.sign-up-landing-page__tooltip-serious.bs-tooltip-auto[data-popper-placement^='top']
    .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
    display: none;
}

.sign-up-landing-page__tooltip-serious.bs-tooltip-auto[data-popper-placement^='right']
    .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
    display: none;
}
.sign-up-landing-page__tooltip-serious.bs-tooltip-auto[data-popper-placement^='right']
    .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
    display: none;
}

.sign-up-landing-page__tooltip-serious.bs-tooltip-auto[data-popper-placement^='bottom']
    .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
    display: none;
}
.sign-up-landing-page__tooltip-serious.bs-tooltip-auto[data-popper-placement^='bottom']
    .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
    display: none;
}

.sign-up-landing-page__tooltip-serious.bs-tooltip-auto[data-popper-placement^='left']
    .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
    display: none;
}
.sign-up-landing-page__tooltip-serious.bs-tooltip-auto[data-popper-placement^='left']
    .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
    display: none;
}

.sign-up-landing-page__tooltip-link-midnight {
    color: $midnight;
    text-decoration: underline;
}

.sign-up-landing-page__tooltip-link-midnight:hover {
    color: $midnight;
    text-decoration: underline;
}

.sign-up-landing-page__form__step__head {
    margin-bottom: 3 * $v;
    text-align: center;
}

.sign-up-landing-page__form__step__title {
    @extend %h3;
}

.sign-up-landing-page__form__step__subtitle {
    @extend %p;
}

.sign-up-landing-page__alert {
    width: 98%;
    margin-left: 1%;
}

.sign-up-landing-page__not-valid {
    background-color: $charcoal-5;
    border-color: $charcoal-5;
    color: $charcoal-2;
    cursor: not-allowed;
}

.sign-up-landing-page__not-valid:hover {
    background-color: $charcoal-5;
    border-color: $charcoal-5;
    color: $charcoal-2;
    cursor: not-allowed;
}

.sign-up-landing-page__valid {
    background-color: $midnight;
    border-color: $midnight;
    color: $white;
}

.sign-up-landing-page__valid:hover {
    background-color: $midnight-3;
    border-color: $midnight-3;
    color: $white;
}

.sign-up-landing-page__button-charcoal-5 {
    background-color: $charcoal-5;
    border-color: $charcoal-5;
    color: $charcoal-2;
}

.sign-up-landing-page__button-charcoal-5:hover {
    background-color: $charcoal-4;
    border-color: $charcoal-4;
    color: $charcoal-2;
}

.sign-up-landing-page__back-button {
    background-color: $white;
    border-color: $white;
    color: $charcoal-0;
}

.sign-up-landing-page__back-button::before {
    content: url(/images/svg-icons/chevron-left.svg);
    padding-right: 8px;
}

.sign-up-landing-page__copy--bold {
    font-weight: 700;
}

.sign-up-landing-page__copy {
    color: $charcoal-0;
}

.sign-up-landing-page__desktop_image {
    display: block;
    width: 100%;
    height: auto;
}

.sign-up-landing-page__desktop_image1 {
    width: 100%;
    height: auto;
    overflow: hidden;
}

// ------ re-named paged SCSS - field page
.form-control.sign-up-landing-page__form-field.sign-up-landing-page__b-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    clip-path: inset(-15px 0px -15px -15px);
}

.form-control.sign-up-landing-page__form-field.sign-up-landing-page__b-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    clip-path: inset(-15px -15px -15px 0px);
}

// Bootstrap forms additional styling
.form-control.sign-up-landing-page__form-field {
    height: 54px;
    padding: 14px 20px;
    border-radius: 3px;
    font-size: 18px;
    line-height: 1.33;
    border: 1px solid $charcoal-4;
}

.sign-up-landing-page__location-field {
    text-align: center;
}

.sign-up-landing-page__location-field-inner {
    color: $charcoal-0;
    border: none;
    background-color: transparent;
}

.sign-up-landing-page__location-field-arrow {
    border: solid $charcoal-0;
    margin-bottom: 3px;
    margin-left: 5px;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
}

.sign-up-landing-page__checkbox.form-check {
    padding-left: 0;
}

.sign-up-landing-page__checkbox,
.form-control.is-valid,
.was-validated .form-control:valid {
    border-color: $charcoal-4;
    background-image: none;
}

.sign-up-landing-page__checkbox,
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    border-color: $charcoal-4;
    box-shadow: none;
}

.sign-up-landing-page__checkbox,
.was-validated .form-select:valid:not([multiple]):not([size]) {
    background-image: none;
}

.sign-up-landing-page__checkbox,
.was-validated .form-check-input:valid ~ .form-check-label {
    color: $charcoal-0;
}

.sign-up-landing-page__checkbox,
.was-validated .form-check-input:valid:checked {
    box-shadow: none;
}

.sign-up-landing-page__caution_icon {
    width: 17px;
    height: auto;
    margin-top: -5px;
    margin-right: 5px;
}

.sign-up-landing-page-captcha {
    min-height: 78px;
}

.sign-up-landing-page__spacer1 {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.sign-up-landing-page__spacer2 {
    display: flex;
    flex-direction: column;
    min-height: 480px;
}

// ------ Loading state
.sign-up-landing-page__loader {
    grid-column: 1 / 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 520px;
}

.sign-up-landing-page__loading_icon {
    width: 64px;
    height: auto;
    animation: spin 1.8s linear infinite;
}

// ----- Media queires -----
// Xtra large 1600px and up
@media only screen and (min-width: 1600px) {
    .sign-up-landing-page__main-bg-image {
        background-position: center top;
    }
}

// 1101px - 1599px
@media only screen and (min-width: 1101px) and (max-width: 1599px) {
    .sign-up-landing-page__cover-sub-text-holder {
        width: 96%;
    }
}

// 1101px - 1399px
@media only screen and (min-width: 1101px) and (max-width: 1399px) {
    .sign-up-landing-page__form-holder-inner {
        width: 92%;
        max-width: 500px;
    }
}

// 768px - 1100px
@media only screen and (min-width: 768px) and (max-width: 1100px) {
    .sign-up-landing-page__form-holder-inner {
        width: 98%;
        padding: 1.4rem;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    .sign-up-landing-page__form-field-radio-select {
        width: 120px;
        height: 100px;
        margin: 0.6rem;
    }

    .sign-up-landing-page__cover-text-upper {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    .sign-up-landing-page__cover-sub-text-holder {
        width: 100%;
        margin-bottom: 8%;
    }

    .sign-up-landing-page__form-step-indicator {
        margin-left: 5%;
        margin-right: 5%;
        gap: 1rem;
    }

    .sign-up-landing-page__desktop_image1 {
        width: 150%;
        margin-left: -130px;
    }
}

// 768px - 991px
@media ($medium-bp-only) {
    .sign-up-landing-page__form__card {
        padding: 2.5rem 2rem;
    }

    .sign-up-landing-page__form__card {
        padding: 1rem 0 1rem 0;
    }

    .sign-up-landing-page__form-field-radio-select {
        font-size: 1.4rem;
        padding: 0.8rem 0.8rem;
        margin: 0.5rem 0.4rem;
        width: 108px;
        height: 98px;
    }
}

// Max 767px Mobile breakpoint (Main mobile breakpoint)
@media ($small-bp) {
    .sign-up-landing-page__h1-jumbo {
        font-size: 4rem;
        line-height: 4.6rem;
        text-align: left;
    }

    .sign-up-landing-page__form__card {
        padding: 0;
        border-radius: 0;
        box-shadow: none;
    }

    .sign-up-landing-page__cover-holder {
        background-size: cover;
        background-position: center bottom;
        background-repeat: no-repeat;
    }

    .sign-up-landing-page__main-bg-image {
        background-image: none;
    }

    .sign-up-landing-page__spacer2 {
        display: flex;
        flex-direction: column;
        min-height: 450px;
    }

    .sign-up-landing-page__form-holder-inner {
        padding-left: var(--container-gutter);
        padding-right: var(--container-gutter);
        width: 100%;
        padding: 2rem;
        margin-top: 0;
        margin-bottom: 4rem;
        display: flex;
    }

    .sign-up-landing-page__loader {
        min-height: 480px;
    }

    .sign-up-landing-page__form-field-radio-label {
        padding: 1.5rem 2rem 1.5rem 5rem;
    }

    .sign-up-landing-page__form-holder-footer p {
        font-size: 1.4rem;
        line-height: 2.2rem;
        margin-bottom: 0;
    }

    .sign-up-landing-page__form__step__head {
        text-align: center;
    }

    .sign-up-landing-page__radio-input-helper {
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .sign-up-landing-page__tooltip.tooltip .tooltip-inner {
        padding: 1rem;
        max-width: 220px;
    }

    .sign-up-landing-page__tooltip-serious.tooltip .tooltip-inner {
        padding: 1rem;
        max-width: 220px;
    }

    .form-control.sign-up-landing-page__form-field-special {
        height: auto;
        padding: 1.5rem;
    }

    .sign-up-landing-page__form-step-indicator-item {
        height: 0.7rem;
    }

    .sign-up-landing-page__form-step-indicator {
        margin-left: 10%;
        margin-right: 10%;
        gap: 1rem;
        margin-top: 1.5rem;
    }

    .sign-up-landing-page__form__step__title {
        @extend %h3;
        font-size: 2.8rem;
        line-height: 3.5rem;
    }

    .sign-up-landing-page__container-inner {
        margin-top: 0;
    }

    .sign-up-landing-page__loader-inner {
        width: 80%;
        height: auto;
        max-width: 1200px;
        padding-top: 10rem;
        color: $charcoal-0;
    }

    // New tings
    .sign-up-landing-page__cover-text {
        text-align: left;
    }

    .sign-up-landing-page__cover-text-upper {
        width: auto;
        width: 90%;
        max-width: 100%;
        margin-top: 6rem;
        margin-bottom: 15px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .sign-up-landing-page__cover-sub-text-holder {
        width: 90%;
        align-content: end;
        margin-bottom: 3rem;
        flex-direction: column;
        padding-left: 24px;
        padding-right: 24px;
    }

    .sign-up-landing-page__cover-sub-text {
        margin-bottom: 8px;
        justify-content: start;
    }

    .sign-up-landing-page__cover-sub-text:before {
        width: 15px;
        height: 15px;
    }

    // Mobile background colour helper, used for left and right of card on large mobile 
    .sign-up-landing-page__form-holder-outer {
        --bs-gutter-x: 0;
        background-color: #f490c2;
    }

    .sign-up-landing-page__mobile_image {
        display: block;
        width: 100%;
        height: auto;
    }

    .sign-up-landing-page__mobile_image1 {
        width: 100%;
        height: auto;
    }

    .sign-up-landing-page__desktop_image {
        display: none;
    }
}

// Small Mobile
@media (max-width: 576px) {
    .sign-up-landing-page__form-field-radio-select {
        font-size: 1.6rem;
        padding: 0.8rem 0.4rem;
        margin: 0.8rem 0rem;
        width: 130px;
        height: 114px;
    }

    .sign-up-landing-page__radio-input-holder {
        justify-content: space-evenly;
    }

    .sign-up-landing-page__business-category-icon {
        width: 24px;
    }

    .sign-up-landing-page__cover-sub-text-holder {
        width: 100%;
        padding-left: 34px;
        padding-right: 34px;
    }

    .sign-up-landing-page__cover-text-upper {
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
    }

    .sign-up-landing-page__form-holder-inner {
        margin-bottom: 0;
    }
}

// V-Small Mobile
@media (max-width: 480px) {
    .sign-up-landing-page__form-field-radio-select {
        font-size: 1.6rem;
        padding: 0.8rem 0.4rem;
        margin: 0.8rem 0.1rem;
        width: 112px;
        height: 104px;
    }
}

// Mini Mobile
@media (max-width: 400px) {
    .sign-up-landing-page__form-field-radio-select {
        font-size: 1.4rem;
        padding: 0.6rem 0.4rem;
        margin: 1rem 0.1rem;
        width: 100px;
        height: 82px;
    }

    .sign-up-landing-page__business-category-icon {
        width: 20px;
    }

    .sign-up-landing-page__form-holder-inner {
        padding: 1.4rem;
    }
}

// Mini 1 Mobile
@media (max-width: 350px) {
    .sign-up-landing-page__form-field-radio-select {
        font-size: 1.4rem;
        padding: 0.6rem 0.4rem;
        margin: 1rem 0.1rem;
        width: 90px;
        height: 82px;
    }
}

// Teeny Tiny Mobile
@media (max-width: 310px) {
    .sign-up-landing-page__form-field-radio-select {
        font-size: 1.1rem;
        padding: 0.4rem 0.4rem;
        margin: 0.6rem 0.1rem;
        width: 80px;
        height: 80px;
    }
}

.pricing-cards__col-l {
    display: flex;
    justify-content: flex-end;
}

.pricing-cards__col-r {
    display: flex;
    justify-content: flex-start;
}

.pricing-cards__col {
    display: flex;
    justify-content: center;
}

.pricing_cards__gx-6 {
    --bs-gutter-x: 4.5rem;
}

.pricing_cards__card_outer {
    width: 100%;
    height: 100%;
    max-width: 650px;
    box-shadow: 8px 8px;
    border: 1px solid $charcoal-0;
    border-radius: 8px;
}

.pricing_cards__card_inner {
    width: 100%;
    height: 100%;
    color: $charcoal-0;
}

.pricing-cards__height-holder {
    height: 100%;
}

.pricing_cards__80_percent{
    display: flex;
    justify-content: center;
    align-items: center;
}

.pricing_cards__80_percent_inner{
    width: 80%;
}

.pricing_cards__heading-h3 {
    @extend %h2;
    text-align: center;
}

.pricing_cards__copy{
    color: $charcoal-0;
    text-align: center;
    padding-bottom: 2rem;
}

.pricing_cards__footer_copy{
    color: $charcoal-0;
    text-align: center;
    padding: 2rem 3.5rem 2rem 3.5rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
    border-radius: 8px;
}

.pricing_cards__title {
    font-size: 3.2rem;
    line-height: 4.1rem;
}

.pricing_cards__price {
    color: $charcoal-0;
    font-size: 4.6rem;
    line-height: 5rem;
}

.pricing_cards__price_info {
    color: $charcoal-0;
}

.pricing_cards__spacer {
    width: 100%;
    height: 2rem;
}

.pricing_cards__not-available-holder {
    display: flex;
    justify-content: center;
}

.pricing_cards__not-available-icon {
    width: 32px;
    height: auto;
}

.pricing_cards__center-align {
    text-align: center;
}

/* Small devices (tablets) */
@media ($small-bp) {
    .pricing-cards__col-l,
    .pricing-cards__col-r {
        justify-content: center;
    }

    .pricing-cards__col-r {
        padding-top: 15px;
    }

    .pricing_cards__80_percent_inner{
        width: 100%;
    }
}

$banner-bp: $medium-bp;

.promo-banner {
	// Needs to be empty, since if a countdown has run out it shouldn't show
}

.promo-banner__layout {
	text-align: center;
	font-size: 1.8 * $v;
	line-height: 2.4 * $v;
	padding: 1.2 * $v $h;
}

.promo-banner__layout-holder{
	@media (#{$banner-bp}) {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.promo-banner__message {
	display: flex;
	@extend %h6;
	text-decoration: none;
	color: $white;
	margin: 0;
	min-height: 25px;
	.promo-banner--dark-text & {
		color: $charcoal-0;
	}
	margin-bottom: 0;

	@media (max-width: $medium-breakpoint) {
		margin: 0 0 0 0;
		display: block;
	}

	p {
		margin: 0;
	}
}

// Button
.promo-banner__button-holder{
	display: flex;
	margin-left: 3rem;

	@media (max-width: $medium-breakpoint) {
		margin: 1rem 0 0 0;
		display: block;
	}
}

.promo-banner__button{
    height: auto;
}

// Countdown timer
.promo-banner__countdown {
	display: flex;
	align-items: stretch;
	justify-content: center;
	margin-left: 3rem;

	@media (max-width: $medium-breakpoint) {
		margin: 1rem 0 0 0;
		display: block;
	}
}

.promo-banner__countdown__part {
	margin-left: $v * 1.0;
	&:first-child {
		margin-left: 0;
	}

	padding: ($v * 0.7) ($h * 1.0);
	border-radius: $v * 0.3;
	background: $white;
	color: $midnight-2;
	text-align: center;
}

.promo-banner__countdown__part__number {
	display: block;
	font-family: $text-font;
	font-size: $v * 1.8;
	line-height: 1;
}

.promo-banner__countdown__part__text {
	display: block;
	margin-top: $v * 0.3;
	font-family: $text-font;
	font-size: $v * 1.0;
	line-height: 1;
}

@media screen and (max-width: 576px) {
	.promo-banner__message{
		font-size: 1.6rem;
		line-height: 2rem;
	}
}
// Added to keep a scrollbar from appearing on the flip card transition
.flip-cards__overflow{
    overflow-y: hidden;
}

.flip-cards__col {
    display: flex;
    justify-content: center;
}

.flip-cards__col-l {
    display: flex;
    justify-content: flex-end;
}

.flip-cards__col-r {
    display: flex;
    justify-content: flex-start;
}

.flip-cards__gx-6 {
    --bs-gutter-x: 1.5rem;
}

// Flipping animation based off www.w3schools.com/howto/tryit.asp?filename=tryhow_css_flip_card (Modified heavily from there)
.flip-cards__card_outer {
    transition: box-shadow 0.3s ease-out;
    height: 100%;
    width: 100%;
    max-width: 420px;
}

.flip-cards__card_outer:hover {
    box-shadow: 8px 8px $emerald;
    background-color: $emerald;
}

.flip-cards__card_inner {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: stretch;
    
    perspective: 1000px;
    transform-style: preserve-3d;
}
.flip-cards__card_inner.flipped {
    .flip-cards__front {
        transform: rotateY(180deg);
    }
    .flip-cards__back {
        transform: translateX(-100%);
    }
}

.flip-cards__back {
    transform: translateX(-100%) rotateY(-180deg);
}

.flip-cards__front,
.flip-cards__back {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    flex-shrink: 0;
    background-color: $white;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border: 1px solid $charcoal-0;
    padding: 2.5rem;
    
    transition: transform 0.5s ease-in-out;
}

.flip-cards__front-content,
.flip-cards__back-content {
    flex-grow: 1;
}

.flip-cards__image {
    width: 100%;
    max-width: 100%;
    height: auto;
}

.flip-cards__price-1 {
    color: $charcoal-2;
}

.flip-cards__button {
    color: $midnight-2;
    border-color: $midnight-2;
}

.flip-cards__button::after {
    content: '+';
    padding-left: 10px;
}

.flip-cards__button:hover {
    color: $white;
    background-color: $midnight-2;
    border-color: $midnight-2;
}

.flip-cards__button-back {
    color: $midnight-2;
    border-color: $white;
}

.flip-cards__button-back:hover {
    color: $midnight;
    background-color: $white;
    border-color: $white;
}

.flip-cards__back-icon {
    width: 7px;
    margin-right: 8px;
    padding-bottom: 3px;
}

.flip-cards__price-info {
    color: $charcoal-2;
}

.flip-cards__inline {
    display: flex;
    align-items: baseline;
}

ul.flip-cards__bullet-points {
    list-style-image: url(/images/svg/tick-midnight.svg);
}

.flip-cards__bullet-points li {
    line-height: 2rem;
    text-align: left;
    padding-bottom: 1rem;
    padding-left: 4px;
}

/* Small devices (tablets) */
@media ($large-bp-below) {
    .flip-cards__col-l,
    .flip-cards__col-r {
        justify-content: center;
    }

    .flip-cards__col-r {
        padding-top: 15px;
    }
}

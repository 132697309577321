// $mdc-text-field-focused-label-color: $charcoal-0;
// $mdc-text-field-focused-border-color: $charcoal-0;
// $mdc-text-field-outlined-hover-border: $charcoal-0;
// --mdc-theme-primary: $charcoal-0;

$checkbox-size: 24px;

// @import '@material/textfield/mdc-text-field';
// @import '@material/checkbox/mdc-checkbox';

// @include mdc-checkbox-container-colors(
//     $hot-coral,
//     #fff,
//     $hot-coral,
//     $hot-coral,
//     false
// );
// @include mdc-checkbox-focus-indicator-color($hot-coral);

// .mdc-text-field:not(.mdc-text-field--invalid) {
// @include mdc-text-field-textarea-stroke-color($charcoal-3);
// @include mdc-text-field-focused-outline-color($charcoal-0);
// @include mdc-text-field-hover-outline-color($charcoal-0);
// @include mdc-text-field-label-color($charcoal-2);
// }
.mdc-floating-label {
    font-family: Lato, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1.6rem;
    line-height: 2.75rem;
    font-weight: 400;
    letter-spacing: 0.00937em;
    text-decoration: inherit;
    text-transform: inherit;
    position: absolute;
    left: 0;
    -webkit-transform-origin: left top;
    transform-origin: left top;
    -webkit-transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1),
        -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1),
        -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1),
        color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1),
        color 0.15s cubic-bezier(0.4, 0, 0.2, 1),
        -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    line-height: 1.15rem;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: text;
    overflow: hidden;
    will-change: transform;
}
.mdc-floating-label[dir='rtl'],
[dir='rtl'] .mdc-floating-label {
    right: 0;
    left: auto;
    -webkit-transform-origin: right top;
    transform-origin: right top;
    text-align: right;
}
.mdc-floating-label--float-above {
    cursor: auto;
    -webkit-transform: translateY(-50%) scale(0.75);
    transform: translateY(-50%) scale(0.75);
}
.mdc-floating-label--shake {
    -webkit-animation: mdc-floating-label-shake-float-above-standard 0.25s 1;
    animation: mdc-floating-label-shake-float-above-standard 0.25s 1;
}
@-webkit-keyframes mdc-floating-label-shake-float-above-standard {
    0% {
        -webkit-transform: translateX(0) translateY(-50%) scale(0.75);
        transform: translateX(0) translateY(-50%) scale(0.75);
    }
    33% {
        -webkit-animation-timing-function: cubic-bezier(
            0.5,
            0,
            0.70173,
            0.49582
        );
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        -webkit-transform: translateX(4%) translateY(-50%) scale(0.75);
        transform: translateX(4%) translateY(-50%) scale(0.75);
    }
    66% {
        -webkit-animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        -webkit-transform: translateX(-4%) translateY(-50%) scale(0.75);
        transform: translateX(-4%) translateY(-50%) scale(0.75);
    }
    to {
        -webkit-transform: translateX(0) translateY(-50%) scale(0.75);
        transform: translateX(0) translateY(-50%) scale(0.75);
    }
}
@keyframes mdc-floating-label-shake-float-above-standard {
    0% {
        -webkit-transform: translateX(0) translateY(-50%) scale(0.75);
        transform: translateX(0) translateY(-50%) scale(0.75);
    }
    33% {
        -webkit-animation-timing-function: cubic-bezier(
            0.5,
            0,
            0.70173,
            0.49582
        );
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        -webkit-transform: translateX(4%) translateY(-50%) scale(0.75);
        transform: translateX(4%) translateY(-50%) scale(0.75);
    }
    66% {
        -webkit-animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        -webkit-transform: translateX(-4%) translateY(-50%) scale(0.75);
        transform: translateX(-4%) translateY(-50%) scale(0.75);
    }
    to {
        -webkit-transform: translateX(0) translateY(-50%) scale(0.75);
        transform: translateX(0) translateY(-50%) scale(0.75);
    }
}
.mdc-line-ripple {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: opacity 0.18s cubic-bezier(0.4, 0, 0.2, 1),
        -webkit-transform 0.18s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 0.18s cubic-bezier(0.4, 0, 0.2, 1),
        -webkit-transform 0.18s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1),
        opacity 0.18s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1),
        opacity 0.18s cubic-bezier(0.4, 0, 0.2, 1),
        -webkit-transform 0.18s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    z-index: 2;
}
.mdc-line-ripple--active {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
}
.mdc-line-ripple--deactivating {
    opacity: 0;
}
.mdc-notched-outline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    right: 0;
    left: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    height: 100%;
    text-align: left;
    pointer-events: none;
}
.mdc-notched-outline[dir='rtl'],
[dir='rtl'] .mdc-notched-outline {
    text-align: right;
}
.mdc-notched-outline__leading,
.mdc-notched-outline__notch,
.mdc-notched-outline__trailing {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 100%;
    -webkit-transition: border 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    transition: border 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    border-top: 1px solid;
    border-bottom: 1px solid;
    pointer-events: none;
}
.mdc-notched-outline__leading {
    border-left: 1px solid;
    border-right: none;
    width: 12px;
}
.mdc-notched-outline__leading[dir='rtl'],
[dir='rtl'] .mdc-notched-outline__leading {
    border-left: none;
    border-right: 1px solid;
}
.mdc-notched-outline__trailing {
    border-left: none;
    border-right: 1px solid;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}
.mdc-notched-outline__trailing[dir='rtl'],
[dir='rtl'] .mdc-notched-outline__trailing {
    border-left: 1px solid;
    border-right: none;
}
.mdc-notched-outline__notch {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: calc(100% - 24px);
}
.mdc-notched-outline .mdc-floating-label {
    display: inline-block;
    position: relative;
    top: 17px;
    bottom: auto;
    max-width: 100%;
}
.mdc-notched-outline .mdc-floating-label--float-above {
    text-overflow: clip;
}
.mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    max-width: 133.33333%;
}
.mdc-notched-outline--notched .mdc-notched-outline__notch {
    padding-left: 0;
    padding-right: 8px;
    border-top: none;
}
.mdc-notched-outline--notched .mdc-notched-outline__notch[dir='rtl'],
[dir='rtl'] .mdc-notched-outline--notched .mdc-notched-outline__notch {
    padding-left: 8px;
    padding-right: 0;
}
.mdc-notched-outline--no-label .mdc-notched-outline__notch {
    padding: 0;
}
@-webkit-keyframes mdc-ripple-fg-radius-in {
    0% {
        -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-transform: translate(0) scale(1);
        transform: translate(0) scale(1);
        -webkit-transform: translate(var(--mdc-ripple-fg-translate-start, 0))
            scale(1);
        transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
    }
    to {
        -webkit-transform: translate(0) scale(1);
        transform: translate(0) scale(1);
        -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0))
            scale(var(--mdc-ripple-fg-scale, 1));
        transform: translate(var(--mdc-ripple-fg-translate-end, 0))
            scale(var(--mdc-ripple-fg-scale, 1));
    }
}
@keyframes mdc-ripple-fg-radius-in {
    0% {
        -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-transform: translate(0) scale(1);
        transform: translate(0) scale(1);
        -webkit-transform: translate(var(--mdc-ripple-fg-translate-start, 0))
            scale(1);
        transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
    }
    to {
        -webkit-transform: translate(0) scale(1);
        transform: translate(0) scale(1);
        -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0))
            scale(var(--mdc-ripple-fg-scale, 1));
        transform: translate(var(--mdc-ripple-fg-translate-end, 0))
            scale(var(--mdc-ripple-fg-scale, 1));
    }
}
@-webkit-keyframes mdc-ripple-fg-opacity-in {
    0% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        opacity: 0;
    }
    to {
        opacity: 0;
        opacity: var(--mdc-ripple-fg-opacity, 0);
    }
}
@keyframes mdc-ripple-fg-opacity-in {
    0% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        opacity: 0;
    }
    to {
        opacity: 0;
        opacity: var(--mdc-ripple-fg-opacity, 0);
    }
}
@-webkit-keyframes mdc-ripple-fg-opacity-out {
    0% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        opacity: 0;
        opacity: var(--mdc-ripple-fg-opacity, 0);
    }
    to {
        opacity: 0;
    }
}
@keyframes mdc-ripple-fg-opacity-out {
    0% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        opacity: 0;
        opacity: var(--mdc-ripple-fg-opacity, 0);
    }
    to {
        opacity: 0;
    }
}
.mdc-ripple-surface--test-edge-var-bug {
    --mdc-ripple-surface-test-edge-var: 1px solid #000;
    visibility: hidden;
}
.mdc-ripple-surface--test-edge-var-bug:before {
    border: var(--mdc-ripple-surface-test-edge-var);
}
.mdc-text-field-helper-text {
    font-family: Lato, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.03333em;
    text-decoration: inherit;
    text-transform: inherit;
    display: block;
    line-height: normal;
    margin: 0;
    -webkit-transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    will-change: opacity;
}
.mdc-text-field-helper-text:before {
    display: inline-block;
    width: 0;
    height: 16px;
    content: '';
    vertical-align: 0;
}
.mdc-text-field-helper-text--persistent {
    -webkit-transition: none;
    transition: none;
    opacity: 1;
    will-change: auto;
}
.mdc-text-field-character-counter {
    font-family: Lato, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.03333em;
    text-decoration: inherit;
    text-transform: inherit;
    display: block;
    margin-top: 0;
    line-height: normal;
    margin-left: auto;
    margin-right: 0;
    padding-left: 16px;
    padding-right: 0;
    white-space: nowrap;
}
.mdc-text-field-character-counter:before {
    display: inline-block;
    width: 0;
    height: 16px;
    content: '';
    vertical-align: 0;
}
.mdc-text-field-character-counter[dir='rtl'],
[dir='rtl'] .mdc-text-field-character-counter {
    margin-left: 0;
    margin-right: auto;
}
.mdc-text-field-character-counter[dir='rtl'],
[dir='rtl'] .mdc-text-field-character-counter {
    padding-left: 0;
    padding-right: 16px;
}
.mdc-text-field--with-leading-icon .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
    position: absolute;
    bottom: 16px;
    cursor: pointer;
}
.mdc-text-field__icon:not([tabindex]),
.mdc-text-field__icon[tabindex='-1'] {
    cursor: default;
    pointer-events: none;
}
.mdc-text-field {
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    border-radius: 4px 4px 0 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 56px;
    overflow: hidden;
    will-change: opacity, transform, color;
}
.mdc-text-field:after,
.mdc-text-field:before {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: '';
}
.mdc-text-field:before {
    -webkit-transition: opacity 15ms linear, background-color 15ms linear;
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1;
}
.mdc-text-field.mdc-ripple-upgraded:before {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
    transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-text-field.mdc-ripple-upgraded:after {
    top: 0;
    left: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: center center;
    transform-origin: center center;
}
.mdc-text-field.mdc-ripple-upgraded--unbounded:after {
    top: 0;
    top: var(--mdc-ripple-top, 0);
    left: 0;
    left: var(--mdc-ripple-left, 0);
}
.mdc-text-field.mdc-ripple-upgraded--foreground-activation:after {
    -webkit-animation: mdc-ripple-fg-radius-in 225ms forwards,
        mdc-ripple-fg-opacity-in 75ms forwards;
    animation: mdc-ripple-fg-radius-in 225ms forwards,
        mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-text-field.mdc-ripple-upgraded--foreground-deactivation:after {
    -webkit-animation: mdc-ripple-fg-opacity-out 0.15s;
    animation: mdc-ripple-fg-opacity-out 0.15s;
    -webkit-transform: translate(0) scale(1);
    transform: translate(0) scale(1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0))
        scale(var(--mdc-ripple-fg-scale, 1));
    transform: translate(var(--mdc-ripple-fg-translate-end, 0))
        scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-text-field:after,
.mdc-text-field:before {
    background-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field:hover:before {
    opacity: 0.04;
}
.mdc-text-field.mdc-ripple-upgraded--background-focused:before,
.mdc-text-field:not(.mdc-ripple-upgraded):focus:before {
    -webkit-transition-duration: 75ms;
    transition-duration: 75ms;
    opacity: 0.12;
}
.mdc-text-field:after,
.mdc-text-field:before {
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
}
.mdc-text-field.mdc-ripple-upgraded:after {
    width: 100%;
    width: var(--mdc-ripple-fg-size, 100%);
    height: 100%;
    height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field .mdc-text-field__input {
    caret-color: #889095;
    caret-color: var(--mdc-theme-primary, #889095);
}
.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea)
    .mdc-text-field__input {
    border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea)
    .mdc-text-field__input:hover {
    border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field .mdc-line-ripple {
    background-color: #889095;
    background-color: var(--mdc-theme-primary, #889095);
}
.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--textarea) {
    border-bottom-color: rgba(0, 0, 0, 0.12);
}
.mdc-text-field:not(.mdc-text-field--disabled)
    + .mdc-text-field-helper-line
    .mdc-text-field-helper-text {
    color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled)
    + .mdc-text-field-helper-line
    .mdc-text-field-character-counter,
.mdc-text-field:not(.mdc-text-field--disabled)
    .mdc-text-field-character-counter {
    color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon {
    color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) {
    background-color: #f5f5f5;
}
.mdc-text-field .mdc-floating-label {
    left: 16px;
    right: auto;
    top: 18px;
    pointer-events: none;
}
.mdc-text-field .mdc-floating-label[dir='rtl'],
[dir='rtl'] .mdc-text-field .mdc-floating-label {
    left: auto;
    right: 16px;
}
.mdc-text-field--textarea .mdc-floating-label {
    left: 4px;
    right: auto;
}
.mdc-text-field--textarea .mdc-floating-label[dir='rtl'],
[dir='rtl'] .mdc-text-field--textarea .mdc-floating-label {
    left: auto;
    right: 4px;
}
.mdc-text-field--outlined .mdc-floating-label {
    left: 4px;
    right: auto;
    top: 17px;
}
.mdc-text-field--outlined .mdc-floating-label[dir='rtl'],
[dir='rtl'] .mdc-text-field--outlined .mdc-floating-label {
    left: auto;
    right: 4px;
}
.mdc-text-field--outlined--with-leading-icon .mdc-floating-label {
    left: 36px;
    right: auto;
}
.mdc-text-field--outlined--with-leading-icon .mdc-floating-label[dir='rtl'],
[dir='rtl'] .mdc-text-field--outlined--with-leading-icon .mdc-floating-label {
    left: auto;
    right: 36px;
}
.mdc-text-field--outlined--with-leading-icon .mdc-floating-label--float-above {
    left: 40px;
    right: auto;
}
.mdc-text-field--outlined--with-leading-icon
    .mdc-floating-label--float-above[dir='rtl'],
[dir='rtl']
    .mdc-text-field--outlined--with-leading-icon
    .mdc-floating-label--float-above {
    left: auto;
    right: 40px;
}
.mdc-text-field__input {
    font-family: Lato, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: 0.00937em;
    text-decoration: inherit;
    text-transform: inherit;
    -ms-flex-item-align: end;
    align-self: flex-end;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 20px 16px 6px;
    -webkit-transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.mdc-text-field__input::-webkit-input-placeholder {
    -webkit-transition: opacity 67ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 67ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field__input::-moz-placeholder {
    -moz-transition: opacity 67ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 67ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field__input:-ms-input-placeholder {
    -ms-transition: opacity 67ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 67ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field__input::-ms-input-placeholder {
    -ms-transition: opacity 67ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 67ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field__input::placeholder {
    -webkit-transition: opacity 67ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 67ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.54) !important;
}
.mdc-text-field--focused .mdc-text-field__input::-webkit-input-placeholder,
.mdc-text-field--fullwidth .mdc-text-field__input::-webkit-input-placeholder,
.mdc-text-field--no-label .mdc-text-field__input::-webkit-input-placeholder {
    -webkit-transition-delay: 40ms;
    transition-delay: 40ms;
    -webkit-transition-duration: 0.11s;
    transition-duration: 0.11s;
    opacity: 1;
}
.mdc-text-field--focused .mdc-text-field__input::-moz-placeholder,
.mdc-text-field--fullwidth .mdc-text-field__input::-moz-placeholder,
.mdc-text-field--no-label .mdc-text-field__input::-moz-placeholder {
    transition-delay: 40ms;
    transition-duration: 0.11s;
    opacity: 1;
}
.mdc-text-field--focused .mdc-text-field__input:-ms-input-placeholder,
.mdc-text-field--fullwidth .mdc-text-field__input:-ms-input-placeholder,
.mdc-text-field--no-label .mdc-text-field__input:-ms-input-placeholder {
    transition-delay: 40ms;
    transition-duration: 0.11s;
    opacity: 1;
}
.mdc-text-field--focused .mdc-text-field__input::-ms-input-placeholder,
.mdc-text-field--fullwidth .mdc-text-field__input::-ms-input-placeholder,
.mdc-text-field--no-label .mdc-text-field__input::-ms-input-placeholder {
    transition-delay: 40ms;
    transition-duration: 0.11s;
    opacity: 1;
}
.mdc-text-field--focused .mdc-text-field__input::placeholder,
.mdc-text-field--fullwidth .mdc-text-field__input::placeholder,
.mdc-text-field--no-label .mdc-text-field__input::placeholder {
    -webkit-transition-delay: 40ms;
    transition-delay: 40ms;
    -webkit-transition-duration: 0.11s;
    transition-duration: 0.11s;
    opacity: 1;
}
.mdc-text-field__input:focus {
    outline: none;
}
.mdc-text-field__input:invalid {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.mdc-text-field__input:-webkit-autofill {
    z-index: auto !important;
}
.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea)
    .mdc-text-field__input {
    padding-top: 16px;
    padding-bottom: 16px;
}
.mdc-text-field__input:-webkit-autofill + .mdc-floating-label {
    -webkit-transform: translateY(-50%) scale(0.75);
    transform: translateY(-50%) scale(0.75);
    cursor: auto;
}
.mdc-text-field--outlined {
    border: none;
    overflow: visible;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__icon:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__icon:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__icon:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__trailing,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__input:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__input:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__input:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__trailing {
    border-color: #889095;
    border-color: var(--mdc-theme-primary, #889095);
}
.mdc-text-field--outlined .mdc-floating-label--shake {
    -webkit-animation: mdc-floating-label-shake-float-above-text-field-outlined
        0.25s 1;
    animation: mdc-floating-label-shake-float-above-text-field-outlined 0.25s 1;
}
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border-radius: 4px 0 0 4px;
}
.mdc-text-field--outlined
    .mdc-notched-outline
    .mdc-notched-outline__leading[dir='rtl'],
[dir='rtl']
    .mdc-text-field--outlined
    .mdc-notched-outline
    .mdc-notched-outline__leading {
    border-radius: 0 4px 4px 0;
}
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
    border-radius: 0 4px 4px 0;
}
.mdc-text-field--outlined
    .mdc-notched-outline
    .mdc-notched-outline__trailing[dir='rtl'],
[dir='rtl']
    .mdc-text-field--outlined
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-radius: 4px 0 0 4px;
}
.mdc-text-field--outlined .mdc-floating-label--float-above {
    -webkit-transform: translateY(-144%) scale(1);
    transform: translateY(-144%) scale(1);
    font-size: 0.75rem;
}
.mdc-text-field--outlined.mdc-notched-outline--upgraded
    .mdc-floating-label--float-above,
.mdc-text-field--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    -webkit-transform: translateY(-130%) scale(0.75);
    transform: translateY(-130%) scale(0.75);
    font-size: 1rem;
}
.mdc-text-field--outlined:after,
.mdc-text-field--outlined:before {
    content: none;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) {
    background-color: transparent;
}
.mdc-text-field--outlined .mdc-text-field__input {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 12px 16px 14px;
    border: none !important;
    background-color: transparent;
    z-index: 1;
}
.mdc-text-field--outlined .mdc-text-field__icon {
    z-index: 2;
}
.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--focused
    .mdc-notched-outline__trailing {
    border-width: 2px;
}
.mdc-text-field--outlined.mdc-text-field--disabled {
    background-color: transparent;
}
.mdc-text-field--outlined.mdc-text-field--disabled
    .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--disabled
    .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
    border-bottom: none;
}
.mdc-text-field--outlined.mdc-text-field--dense {
    height: 48px;
}
.mdc-text-field--outlined.mdc-text-field--dense
    .mdc-floating-label--float-above {
    -webkit-transform: translateY(-134%) scale(1);
    transform: translateY(-134%) scale(1);
    font-size: 0.8rem;
}
.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded
    .mdc-floating-label--float-above,
.mdc-text-field--outlined.mdc-text-field--dense
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    -webkit-transform: translateY(-120%) scale(0.8);
    transform: translateY(-120%) scale(0.8);
    font-size: 1rem;
}
.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake {
    -webkit-animation: mdc-floating-label-shake-float-above-text-field-outlined-dense
        0.25s 1;
    animation: mdc-floating-label-shake-float-above-text-field-outlined-dense
        0.25s 1;
}
.mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__input {
    padding: 12px 12px 7px;
}
.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label {
    top: 14px;
}
.mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__icon {
    top: 12px;
}
.mdc-text-field--with-leading-icon .mdc-text-field__icon {
    left: 16px;
    right: auto;
}
.mdc-text-field--with-leading-icon .mdc-text-field__icon[dir='rtl'],
[dir='rtl'] .mdc-text-field--with-leading-icon .mdc-text-field__icon {
    left: auto;
    right: 16px;
}
.mdc-text-field--with-leading-icon .mdc-text-field__input {
    padding-left: 48px;
    padding-right: 16px;
}
.mdc-text-field--with-leading-icon .mdc-text-field__input[dir='rtl'],
[dir='rtl'] .mdc-text-field--with-leading-icon .mdc-text-field__input {
    padding-left: 16px;
    padding-right: 48px;
}
.mdc-text-field--with-leading-icon .mdc-floating-label {
    left: 48px;
    right: auto;
}
.mdc-text-field--with-leading-icon .mdc-floating-label[dir='rtl'],
[dir='rtl'] .mdc-text-field--with-leading-icon .mdc-floating-label {
    left: auto;
    right: 48px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined
    .mdc-text-field__icon {
    left: 16px;
    right: auto;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined
    .mdc-text-field__icon[dir='rtl'],
[dir='rtl']
    .mdc-text-field--with-leading-icon.mdc-text-field--outlined
    .mdc-text-field__icon {
    left: auto;
    right: 16px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined
    .mdc-text-field__input {
    padding-left: 48px;
    padding-right: 16px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined
    .mdc-text-field__input[dir='rtl'],
[dir='rtl']
    .mdc-text-field--with-leading-icon.mdc-text-field--outlined
    .mdc-text-field__input {
    padding-left: 16px;
    padding-right: 48px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined
    .mdc-floating-label--float-above {
    -webkit-transform: translateY(-144%) translateX(-32px) scale(1);
    transform: translateY(-144%) translateX(-32px) scale(1);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined
    .mdc-floating-label--float-above[dir='rtl'],
[dir='rtl']
    .mdc-text-field--with-leading-icon.mdc-text-field--outlined
    .mdc-floating-label--float-above {
    -webkit-transform: translateY(-144%) translateX(32px) scale(1);
    transform: translateY(-144%) translateX(32px) scale(1);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined
    .mdc-floating-label--float-above {
    font-size: 0.75rem;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded
    .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    -webkit-transform: translateY(-130%) translateX(-32px) scale(0.75);
    transform: translateY(-130%) translateX(-32px) scale(0.75);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded
    .mdc-floating-label--float-above[dir='rtl'],
.mdc-text-field--with-leading-icon.mdc-text-field--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above[dir='rtl'],
[dir='rtl']
    .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded
    .mdc-floating-label--float-above,
[dir='rtl']
    .mdc-text-field--with-leading-icon.mdc-text-field--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    -webkit-transform: translateY(-130%) translateX(32px) scale(0.75);
    transform: translateY(-130%) translateX(32px) scale(0.75);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded
    .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    font-size: 1rem;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined
    .mdc-floating-label--shake {
    -webkit-animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon
        0.25s 1;
    animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon
        0.25s 1;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir='rtl']
    .mdc-floating-label--shake,
[dir='rtl']
    .mdc-text-field--with-leading-icon.mdc-text-field--outlined
    .mdc-floating-label--shake {
    -webkit-animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl
        0.25s 1;
    animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl
        0.25s 1;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined
    .mdc-floating-label {
    left: 36px;
    right: auto;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined
    .mdc-floating-label[dir='rtl'],
[dir='rtl']
    .mdc-text-field--with-leading-icon.mdc-text-field--outlined
    .mdc-floating-label {
    left: auto;
    right: 36px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense
    .mdc-floating-label--float-above {
    -webkit-transform: translateY(-134%) translateX(-21px) scale(1);
    transform: translateY(-134%) translateX(-21px) scale(1);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense
    .mdc-floating-label--float-above[dir='rtl'],
[dir='rtl']
    .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense
    .mdc-floating-label--float-above {
    -webkit-transform: translateY(-134%) translateX(21px) scale(1);
    transform: translateY(-134%) translateX(21px) scale(1);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense
    .mdc-floating-label--float-above {
    font-size: 0.8rem;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded
    .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    -webkit-transform: translateY(-120%) translateX(-21px) scale(0.8);
    transform: translateY(-120%) translateX(-21px) scale(0.8);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded
    .mdc-floating-label--float-above[dir='rtl'],
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above[dir='rtl'],
[dir='rtl']
    .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded
    .mdc-floating-label--float-above,
[dir='rtl']
    .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    -webkit-transform: translateY(-120%) translateX(21px) scale(0.8);
    transform: translateY(-120%) translateX(21px) scale(0.8);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded
    .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    font-size: 1rem;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense
    .mdc-floating-label--shake {
    -webkit-animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense
        0.25s 1;
    animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense
        0.25s 1;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense[dir='rtl']
    .mdc-floating-label--shake,
[dir='rtl']
    .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense
    .mdc-floating-label--shake {
    -webkit-animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl
        0.25s 1;
    animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl
        0.25s 1;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense
    .mdc-floating-label {
    left: 32px;
    right: auto;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense
    .mdc-floating-label[dir='rtl'],
[dir='rtl']
    .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense
    .mdc-floating-label {
    left: auto;
    right: 32px;
}
.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
    left: auto;
    right: 12px;
}
.mdc-text-field--with-trailing-icon .mdc-text-field__icon[dir='rtl'],
[dir='rtl'] .mdc-text-field--with-trailing-icon .mdc-text-field__icon {
    left: 12px;
    right: auto;
}
.mdc-text-field--with-trailing-icon .mdc-text-field__input {
    padding-left: 16px;
    padding-right: 48px;
}
.mdc-text-field--with-trailing-icon .mdc-text-field__input[dir='rtl'],
[dir='rtl'] .mdc-text-field--with-trailing-icon .mdc-text-field__input {
    padding-left: 48px;
    padding-right: 16px;
}
.mdc-text-field--with-trailing-icon.mdc-text-field--outlined
    .mdc-text-field__icon {
    left: auto;
    right: 16px;
}
.mdc-text-field--with-trailing-icon.mdc-text-field--outlined
    .mdc-text-field__icon[dir='rtl'],
[dir='rtl']
    .mdc-text-field--with-trailing-icon.mdc-text-field--outlined
    .mdc-text-field__icon {
    left: 16px;
    right: auto;
}
.mdc-text-field--with-trailing-icon.mdc-text-field--outlined
    .mdc-text-field__input {
    padding-left: 16px;
    padding-right: 48px;
}
.mdc-text-field--with-trailing-icon.mdc-text-field--outlined
    .mdc-text-field__input[dir='rtl'],
[dir='rtl']
    .mdc-text-field--with-trailing-icon.mdc-text-field--outlined
    .mdc-text-field__input {
    padding-left: 48px;
    padding-right: 16px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon
    .mdc-text-field__icon {
    left: 16px;
    right: auto;
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon
    .mdc-text-field__icon[dir='rtl'],
[dir='rtl']
    .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon
    .mdc-text-field__icon {
    left: auto;
    right: 16px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon
    .mdc-text-field__icon
    ~ .mdc-text-field__icon {
    right: 12px;
    left: auto;
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon
    .mdc-text-field__icon
    ~ .mdc-text-field__icon[dir='rtl'],
[dir='rtl']
    .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon
    .mdc-text-field__icon
    ~ .mdc-text-field__icon {
    right: auto;
    left: 12px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon
    .mdc-text-field__input {
    padding-left: 48px;
    padding-right: 48px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon
    .mdc-text-field__input[dir='rtl'],
[dir='rtl']
    .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon
    .mdc-text-field__input {
    padding-left: 48px;
    padding-right: 48px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon.mdc-text-field--dense
    .mdc-text-field__icon {
    bottom: 16px;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
}
.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon {
    left: 12px;
    right: auto;
}
.mdc-text-field--with-leading-icon.mdc-text-field--dense
    .mdc-text-field__icon[dir='rtl'],
[dir='rtl']
    .mdc-text-field--with-leading-icon.mdc-text-field--dense
    .mdc-text-field__icon {
    left: auto;
    right: 12px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--dense
    .mdc-text-field__input {
    padding-left: 44px;
    padding-right: 16px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--dense
    .mdc-text-field__input[dir='rtl'],
[dir='rtl']
    .mdc-text-field--with-leading-icon.mdc-text-field--dense
    .mdc-text-field__input {
    padding-left: 16px;
    padding-right: 44px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label {
    left: 44px;
    right: auto;
}
.mdc-text-field--with-leading-icon.mdc-text-field--dense
    .mdc-floating-label[dir='rtl'],
[dir='rtl']
    .mdc-text-field--with-leading-icon.mdc-text-field--dense
    .mdc-floating-label {
    left: auto;
    right: 44px;
}
.mdc-text-field--with-trailing-icon.mdc-text-field--dense
    .mdc-text-field__icon {
    left: auto;
    right: 12px;
}
.mdc-text-field--with-trailing-icon.mdc-text-field--dense
    .mdc-text-field__icon[dir='rtl'],
[dir='rtl']
    .mdc-text-field--with-trailing-icon.mdc-text-field--dense
    .mdc-text-field__icon {
    left: 12px;
    right: auto;
}
.mdc-text-field--with-trailing-icon.mdc-text-field--dense
    .mdc-text-field__input {
    padding-left: 16px;
    padding-right: 44px;
}
.mdc-text-field--with-trailing-icon.mdc-text-field--dense
    .mdc-text-field__input[dir='rtl'],
[dir='rtl']
    .mdc-text-field--with-trailing-icon.mdc-text-field--dense
    .mdc-text-field__input {
    padding-left: 44px;
    padding-right: 16px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense
    .mdc-text-field__icon {
    left: 12px;
    right: auto;
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense
    .mdc-text-field__icon[dir='rtl'],
[dir='rtl']
    .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense
    .mdc-text-field__icon {
    left: auto;
    right: 12px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense
    .mdc-text-field__icon
    ~ .mdc-text-field__icon {
    right: 12px;
    left: auto;
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense
    .mdc-text-field__icon
    ~ .mdc-text-field__icon[dir='rtl'],
[dir='rtl']
    .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense
    .mdc-text-field__icon
    ~ .mdc-text-field__icon {
    right: auto;
    left: 12px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense
    .mdc-text-field__input {
    padding-left: 44px;
    padding-right: 44px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense
    .mdc-text-field__input[dir='rtl'],
[dir='rtl']
    .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense
    .mdc-text-field__input {
    padding-left: 44px;
    padding-right: 44px;
}
.mdc-text-field--dense .mdc-floating-label--float-above {
    -webkit-transform: translateY(-70%) scale(0.8);
    transform: translateY(-70%) scale(0.8);
}
.mdc-text-field--dense .mdc-floating-label--shake {
    -webkit-animation: mdc-floating-label-shake-float-above-text-field-dense
        0.25s 1;
    animation: mdc-floating-label-shake-float-above-text-field-dense 0.25s 1;
}
.mdc-text-field--dense .mdc-text-field__input {
    padding: 12px 12px 0;
}
.mdc-text-field--dense .mdc-floating-label,
.mdc-text-field--dense .mdc-floating-label--float-above {
    font-size: 0.813rem;
}
.mdc-text-field__input:required ~ .mdc-floating-label:after,
.mdc-text-field__input:required
    ~ .mdc-notched-outline
    .mdc-floating-label:after {
    margin-left: 1px;
    content: '*';
}
.mdc-text-field--textarea {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: auto;
    height: auto;
    -webkit-transition: none;
    transition: none;
    overflow: visible;
}
.mdc-text-field--textarea:not(.mdc-text-field--disabled)
    .mdc-notched-outline__leading,
.mdc-text-field--textarea:not(.mdc-text-field--disabled)
    .mdc-notched-outline__notch,
.mdc-text-field--textarea:not(.mdc-text-field--disabled)
    .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__icon:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__icon:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__icon:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__trailing,
.mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__input:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__input:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__input:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--textarea:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__leading,
.mdc-text-field--textarea:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__notch,
.mdc-text-field--textarea:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__trailing {
    border-color: #889095;
    border-color: var(--mdc-theme-primary, #889095);
}
.mdc-text-field--textarea .mdc-floating-label--shake {
    -webkit-animation: mdc-floating-label-shake-float-above-text-field-outlined
        0.25s 1;
    animation: mdc-floating-label-shake-float-above-text-field-outlined 0.25s 1;
}
.mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__leading {
    border-radius: 4px 0 0 4px;
}
.mdc-text-field--textarea
    .mdc-notched-outline
    .mdc-notched-outline__leading[dir='rtl'],
[dir='rtl']
    .mdc-text-field--textarea
    .mdc-notched-outline
    .mdc-notched-outline__leading {
    border-radius: 0 4px 4px 0;
}
.mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__trailing {
    border-radius: 0 4px 4px 0;
}
.mdc-text-field--textarea
    .mdc-notched-outline
    .mdc-notched-outline__trailing[dir='rtl'],
[dir='rtl']
    .mdc-text-field--textarea
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-radius: 4px 0 0 4px;
}
.mdc-text-field--textarea:after,
.mdc-text-field--textarea:before {
    content: none;
}
.mdc-text-field--textarea:not(.mdc-text-field--disabled) {
    background-color: transparent;
}
.mdc-text-field--textarea .mdc-floating-label--float-above {
    -webkit-transform: translateY(-144%) scale(1);
    transform: translateY(-144%) scale(1);
    font-size: 0.75rem;
}
.mdc-text-field--textarea.mdc-notched-outline--upgraded
    .mdc-floating-label--float-above,
.mdc-text-field--textarea
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    -webkit-transform: translateY(-130%) scale(0.75);
    transform: translateY(-130%) scale(0.75);
    font-size: 1rem;
}
.mdc-text-field--textarea .mdc-text-field-character-counter {
    left: auto;
    right: 16px;
    position: absolute;
    bottom: 13px;
}
.mdc-text-field--textarea .mdc-text-field-character-counter[dir='rtl'],
[dir='rtl'] .mdc-text-field--textarea .mdc-text-field-character-counter {
    left: 16px;
    right: auto;
}
.mdc-text-field--textarea .mdc-text-field__input {
    -ms-flex-item-align: auto;
    align-self: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: auto;
    margin: 8px 1px 1px 0;
    padding: 0 16px 16px;
    border: none;
}
.mdc-text-field--textarea
    .mdc-text-field-character-counter
    + .mdc-text-field__input {
    margin-bottom: 28px;
    padding-bottom: 0;
}
.mdc-text-field--textarea .mdc-floating-label {
    top: 17px;
    bottom: auto;
    width: auto;
    pointer-events: none;
}
.mdc-text-field--textarea.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--focused
    .mdc-notched-outline__trailing {
    border-width: 2px;
}
.mdc-text-field--fullwidth {
    width: 100%;
}
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
    display: block;
}
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea):after,
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea):before {
    content: none;
}
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea):not(.mdc-text-field--disabled) {
    background-color: transparent;
}
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea)
    .mdc-text-field__input {
    padding: 0;
}
.mdc-text-field--fullwidth.mdc-text-field--textarea .mdc-text-field__input {
    resize: vertical;
}
.mdc-text-field--fullwidth.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--textarea) {
    border-bottom-color: #b00020;
    border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field-helper-line {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.mdc-text-field--dense + .mdc-text-field-helper-line {
    margin-bottom: 4px;
}
.mdc-text-field + .mdc-text-field-helper-line {
    padding-right: 16px;
    padding-left: 16px;
}
.mdc-form-field > .mdc-text-field + label {
    -ms-flex-item-align: start;
    align-self: flex-start;
}
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: rgba(136, 144, 149, 0.87);
}
.mdc-text-field--focused
    .mdc-text-field__input:required
    ~ .mdc-floating-label:after,
.mdc-text-field--focused
    .mdc-text-field__input:required
    ~ .mdc-notched-outline
    .mdc-floating-label:after {
    color: rgba(136, 144, 149, 0.87);
}
.mdc-text-field--focused
    + .mdc-text-field-helper-line
    .mdc-text-field-helper-text:not(.mdc-text-field-helper-text--validation-msg) {
    opacity: 1;
}
.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled)
    .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled)
    .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled)
    .mdc-notched-outline__trailing {
    border-color: #889095;
    border-color: var(--mdc-theme-primary, #889095);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea)
    .mdc-text-field__input {
    border-bottom-color: #b00020;
    border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea)
    .mdc-text-field__input:hover {
    border-bottom-color: #b00020;
    border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple {
    background-color: #b00020;
    background-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: #b00020;
    color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid
    + .mdc-text-field-helper-line
    .mdc-text-field-helper-text--validation-msg {
    color: #b00020;
    color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid .mdc-text-field__input {
    caret-color: #b00020;
    caret-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid
    .mdc-text-field__input:required
    ~ .mdc-floating-label:after,
.mdc-text-field--invalid
    .mdc-text-field__input:required
    ~ .mdc-notched-outline
    .mdc-floating-label:after {
    color: #b00020;
    color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid.mdc-text-field--with-trailing-icon:not(.mdc-text-field--with-leading-icon):not(.mdc-text-field--disabled)
    .mdc-text-field__icon {
    color: #b00020;
    color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid.mdc-text-field--with-trailing-icon.mdc-text-field--with-leading-icon:not(.mdc-text-field--disabled)
    .mdc-text-field__icon
    ~ .mdc-text-field__icon {
    color: #b00020;
    color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid
    + .mdc-text-field-helper-line
    .mdc-text-field-helper-text--validation-msg {
    opacity: 1;
}
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled)
    .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled)
    .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled)
    .mdc-notched-outline__trailing {
    border-color: #b00020;
    border-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__icon:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__icon:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__icon:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__trailing,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__input:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__input:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__input:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-color: #b00020;
    border-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__trailing {
    border-color: #b00020;
    border-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled)
    .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled)
    .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled)
    .mdc-notched-outline__trailing {
    border-color: #b00020;
    border-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__icon:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__icon:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__icon:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__trailing,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__input:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__input:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__input:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-color: #b00020;
    border-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__trailing {
    border-color: #b00020;
    border-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--disabled {
    background-color: #fafafa;
    border-bottom: none;
    pointer-events: none;
}
.mdc-text-field--disabled .mdc-text-field__input {
    border-bottom-color: rgba(0, 0, 0, 0.06);
    color: rgba(0, 0, 0, 0.37);
}
.mdc-text-field--disabled .mdc-floating-label {
    color: rgba(0, 0, 0, 0.37);
}
.mdc-text-field--disabled
    + .mdc-text-field-helper-line
    .mdc-text-field-helper-text {
    color: rgba(0, 0, 0, 0.37);
}
.mdc-text-field--disabled
    + .mdc-text-field-helper-line
    .mdc-text-field-character-counter,
.mdc-text-field--disabled .mdc-text-field-character-counter {
    color: rgba(0, 0, 0, 0.37);
}
.mdc-text-field--disabled .mdc-text-field__icon {
    color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled:not(.mdc-text-field--textarea) {
    border-bottom-color: rgba(0, 0, 0, 0.12);
}
.mdc-text-field--disabled .mdc-floating-label {
    cursor: default;
}
.mdc-text-field--textarea.mdc-text-field--disabled {
    background-color: transparent;
    background-color: #f9f9f9;
}
.mdc-text-field--textarea.mdc-text-field--disabled
    .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--disabled
    .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--textarea.mdc-text-field--disabled .mdc-text-field__input {
    border-bottom: none;
}
@-webkit-keyframes mdc-floating-label-shake-float-above-text-field-dense {
    0% {
        -webkit-transform: translateX(0) translateY(-70%) scale(0.8);
        transform: translateX(0) translateY(-70%) scale(0.8);
    }
    33% {
        -webkit-animation-timing-function: cubic-bezier(
            0.5,
            0,
            0.70173,
            0.49582
        );
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        -webkit-transform: translateX(4%) translateY(-70%) scale(0.8);
        transform: translateX(4%) translateY(-70%) scale(0.8);
    }
    66% {
        -webkit-animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        -webkit-transform: translateX(-4%) translateY(-70%) scale(0.8);
        transform: translateX(-4%) translateY(-70%) scale(0.8);
    }
    to {
        -webkit-transform: translateX(0) translateY(-70%) scale(0.8);
        transform: translateX(0) translateY(-70%) scale(0.8);
    }
}
@keyframes mdc-floating-label-shake-float-above-text-field-dense {
    0% {
        -webkit-transform: translateX(0) translateY(-70%) scale(0.8);
        transform: translateX(0) translateY(-70%) scale(0.8);
    }
    33% {
        -webkit-animation-timing-function: cubic-bezier(
            0.5,
            0,
            0.70173,
            0.49582
        );
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        -webkit-transform: translateX(4%) translateY(-70%) scale(0.8);
        transform: translateX(4%) translateY(-70%) scale(0.8);
    }
    66% {
        -webkit-animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        -webkit-transform: translateX(-4%) translateY(-70%) scale(0.8);
        transform: translateX(-4%) translateY(-70%) scale(0.8);
    }
    to {
        -webkit-transform: translateX(0) translateY(-70%) scale(0.8);
        transform: translateX(0) translateY(-70%) scale(0.8);
    }
}
@-webkit-keyframes mdc-floating-label-shake-float-above-text-field-outlined {
    0% {
        -webkit-transform: translateX(0) translateY(-130%) scale(0.75);
        transform: translateX(0) translateY(-130%) scale(0.75);
    }
    33% {
        -webkit-animation-timing-function: cubic-bezier(
            0.5,
            0,
            0.70173,
            0.49582
        );
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        -webkit-transform: translateX(4%) translateY(-130%) scale(0.75);
        transform: translateX(4%) translateY(-130%) scale(0.75);
    }
    66% {
        -webkit-animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        -webkit-transform: translateX(-4%) translateY(-130%) scale(0.75);
        transform: translateX(-4%) translateY(-130%) scale(0.75);
    }
    to {
        -webkit-transform: translateX(0) translateY(-130%) scale(0.75);
        transform: translateX(0) translateY(-130%) scale(0.75);
    }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined {
    0% {
        -webkit-transform: translateX(0) translateY(-130%) scale(0.75);
        transform: translateX(0) translateY(-130%) scale(0.75);
    }
    33% {
        -webkit-animation-timing-function: cubic-bezier(
            0.5,
            0,
            0.70173,
            0.49582
        );
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        -webkit-transform: translateX(4%) translateY(-130%) scale(0.75);
        transform: translateX(4%) translateY(-130%) scale(0.75);
    }
    66% {
        -webkit-animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        -webkit-transform: translateX(-4%) translateY(-130%) scale(0.75);
        transform: translateX(-4%) translateY(-130%) scale(0.75);
    }
    to {
        -webkit-transform: translateX(0) translateY(-130%) scale(0.75);
        transform: translateX(0) translateY(-130%) scale(0.75);
    }
}
@-webkit-keyframes mdc-floating-label-shake-float-above-text-field-outlined-dense {
    0% {
        -webkit-transform: translateX(0) translateY(-120%) scale(0.8);
        transform: translateX(0) translateY(-120%) scale(0.8);
    }
    33% {
        -webkit-animation-timing-function: cubic-bezier(
            0.5,
            0,
            0.70173,
            0.49582
        );
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        -webkit-transform: translateX(4%) translateY(-120%) scale(0.8);
        transform: translateX(4%) translateY(-120%) scale(0.8);
    }
    66% {
        -webkit-animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        -webkit-transform: translateX(-4%) translateY(-120%) scale(0.8);
        transform: translateX(-4%) translateY(-120%) scale(0.8);
    }
    to {
        -webkit-transform: translateX(0) translateY(-120%) scale(0.8);
        transform: translateX(0) translateY(-120%) scale(0.8);
    }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-dense {
    0% {
        -webkit-transform: translateX(0) translateY(-120%) scale(0.8);
        transform: translateX(0) translateY(-120%) scale(0.8);
    }
    33% {
        -webkit-animation-timing-function: cubic-bezier(
            0.5,
            0,
            0.70173,
            0.49582
        );
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        -webkit-transform: translateX(4%) translateY(-120%) scale(0.8);
        transform: translateX(4%) translateY(-120%) scale(0.8);
    }
    66% {
        -webkit-animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        -webkit-transform: translateX(-4%) translateY(-120%) scale(0.8);
        transform: translateX(-4%) translateY(-120%) scale(0.8);
    }
    to {
        -webkit-transform: translateX(0) translateY(-120%) scale(0.8);
        transform: translateX(0) translateY(-120%) scale(0.8);
    }
}
@-webkit-keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon {
    0% {
        -webkit-transform: translateX(0) translateY(-130%) scale(0.75);
        transform: translateX(0) translateY(-130%) scale(0.75);
    }
    33% {
        -webkit-animation-timing-function: cubic-bezier(
            0.5,
            0,
            0.70173,
            0.49582
        );
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        -webkit-transform: translateX(4%) translateY(-130%) scale(0.75);
        transform: translateX(4%) translateY(-130%) scale(0.75);
    }
    66% {
        -webkit-animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        -webkit-transform: translateX(-4%) translateY(-130%) scale(0.75);
        transform: translateX(-4%) translateY(-130%) scale(0.75);
    }
    to {
        -webkit-transform: translateX(0) translateY(-130%) scale(0.75);
        transform: translateX(0) translateY(-130%) scale(0.75);
    }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon {
    0% {
        -webkit-transform: translateX(0) translateY(-130%) scale(0.75);
        transform: translateX(0) translateY(-130%) scale(0.75);
    }
    33% {
        -webkit-animation-timing-function: cubic-bezier(
            0.5,
            0,
            0.70173,
            0.49582
        );
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        -webkit-transform: translateX(4%) translateY(-130%) scale(0.75);
        transform: translateX(4%) translateY(-130%) scale(0.75);
    }
    66% {
        -webkit-animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        -webkit-transform: translateX(-4%) translateY(-130%) scale(0.75);
        transform: translateX(-4%) translateY(-130%) scale(0.75);
    }
    to {
        -webkit-transform: translateX(0) translateY(-130%) scale(0.75);
        transform: translateX(0) translateY(-130%) scale(0.75);
    }
}
@-webkit-keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense {
    0% {
        -webkit-transform: translateX(-21px) translateY(-120%) scale(0.8);
        transform: translateX(-21px) translateY(-120%) scale(0.8);
    }
    33% {
        -webkit-animation-timing-function: cubic-bezier(
            0.5,
            0,
            0.70173,
            0.49582
        );
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        -webkit-transform: translateX(calc(4% - 21px)) translateY(-120%)
            scale(0.8);
        transform: translateX(calc(4% - 21px)) translateY(-120%) scale(0.8);
    }
    66% {
        -webkit-animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        -webkit-transform: translateX(calc(-4% - 21px)) translateY(-120%)
            scale(0.8);
        transform: translateX(calc(-4% - 21px)) translateY(-120%) scale(0.8);
    }
    to {
        -webkit-transform: translateX(-21px) translateY(-120%) scale(0.8);
        transform: translateX(-21px) translateY(-120%) scale(0.8);
    }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense {
    0% {
        -webkit-transform: translateX(-21px) translateY(-120%) scale(0.8);
        transform: translateX(-21px) translateY(-120%) scale(0.8);
    }
    33% {
        -webkit-animation-timing-function: cubic-bezier(
            0.5,
            0,
            0.70173,
            0.49582
        );
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        -webkit-transform: translateX(calc(4% - 21px)) translateY(-120%)
            scale(0.8);
        transform: translateX(calc(4% - 21px)) translateY(-120%) scale(0.8);
    }
    66% {
        -webkit-animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        -webkit-transform: translateX(calc(-4% - 21px)) translateY(-120%)
            scale(0.8);
        transform: translateX(calc(-4% - 21px)) translateY(-120%) scale(0.8);
    }
    to {
        -webkit-transform: translateX(-21px) translateY(-120%) scale(0.8);
        transform: translateX(-21px) translateY(-120%) scale(0.8);
    }
}
@-webkit-keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl {
    0% {
        -webkit-transform: translateX(0) translateY(-130%) scale(0.75);
        transform: translateX(0) translateY(-130%) scale(0.75);
    }
    33% {
        -webkit-animation-timing-function: cubic-bezier(
            0.5,
            0,
            0.70173,
            0.49582
        );
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        -webkit-transform: translateX(4%) translateY(-130%) scale(0.75);
        transform: translateX(4%) translateY(-130%) scale(0.75);
    }
    66% {
        -webkit-animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        -webkit-transform: translateX(-4%) translateY(-130%) scale(0.75);
        transform: translateX(-4%) translateY(-130%) scale(0.75);
    }
    to {
        -webkit-transform: translateX(0) translateY(-130%) scale(0.75);
        transform: translateX(0) translateY(-130%) scale(0.75);
    }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl {
    0% {
        -webkit-transform: translateX(0) translateY(-130%) scale(0.75);
        transform: translateX(0) translateY(-130%) scale(0.75);
    }
    33% {
        -webkit-animation-timing-function: cubic-bezier(
            0.5,
            0,
            0.70173,
            0.49582
        );
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        -webkit-transform: translateX(4%) translateY(-130%) scale(0.75);
        transform: translateX(4%) translateY(-130%) scale(0.75);
    }
    66% {
        -webkit-animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        -webkit-transform: translateX(-4%) translateY(-130%) scale(0.75);
        transform: translateX(-4%) translateY(-130%) scale(0.75);
    }
    to {
        -webkit-transform: translateX(0) translateY(-130%) scale(0.75);
        transform: translateX(0) translateY(-130%) scale(0.75);
    }
}
@-webkit-keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl {
    0% {
        -webkit-transform: translateX(21px) translateY(-120%) scale(0.8);
        transform: translateX(21px) translateY(-120%) scale(0.8);
    }
    33% {
        -webkit-animation-timing-function: cubic-bezier(
            0.5,
            0,
            0.70173,
            0.49582
        );
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        -webkit-transform: translateX(calc(4% + 21px)) translateY(-120%)
            scale(0.8);
        transform: translateX(calc(4% + 21px)) translateY(-120%) scale(0.8);
    }
    66% {
        -webkit-animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        -webkit-transform: translateX(calc(-4% + 21px)) translateY(-120%)
            scale(0.8);
        transform: translateX(calc(-4% + 21px)) translateY(-120%) scale(0.8);
    }
    to {
        -webkit-transform: translateX(21px) translateY(-120%) scale(0.8);
        transform: translateX(21px) translateY(-120%) scale(0.8);
    }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl {
    0% {
        -webkit-transform: translateX(21px) translateY(-120%) scale(0.8);
        transform: translateX(21px) translateY(-120%) scale(0.8);
    }
    33% {
        -webkit-animation-timing-function: cubic-bezier(
            0.5,
            0,
            0.70173,
            0.49582
        );
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        -webkit-transform: translateX(calc(4% + 21px)) translateY(-120%)
            scale(0.8);
        transform: translateX(calc(4% + 21px)) translateY(-120%) scale(0.8);
    }
    66% {
        -webkit-animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        -webkit-transform: translateX(calc(-4% + 21px)) translateY(-120%)
            scale(0.8);
        transform: translateX(calc(-4% + 21px)) translateY(-120%) scale(0.8);
    }
    to {
        -webkit-transform: translateX(21px) translateY(-120%) scale(0.8);
        transform: translateX(21px) translateY(-120%) scale(0.8);
    }
}
@-webkit-keyframes mdc-floating-label-shake-float-above-textarea {
    0% {
        -webkit-transform: translateX(0) translateY(-130%) scale(0.75);
        transform: translateX(0) translateY(-130%) scale(0.75);
    }
    33% {
        -webkit-animation-timing-function: cubic-bezier(
            0.5,
            0,
            0.70173,
            0.49582
        );
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        -webkit-transform: translateX(4%) translateY(-130%) scale(0.75);
        transform: translateX(4%) translateY(-130%) scale(0.75);
    }
    66% {
        -webkit-animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        -webkit-transform: translateX(-4%) translateY(-130%) scale(0.75);
        transform: translateX(-4%) translateY(-130%) scale(0.75);
    }
    to {
        -webkit-transform: translateX(0) translateY(-130%) scale(0.75);
        transform: translateX(0) translateY(-130%) scale(0.75);
    }
}
@keyframes mdc-floating-label-shake-float-above-textarea {
    0% {
        -webkit-transform: translateX(0) translateY(-130%) scale(0.75);
        transform: translateX(0) translateY(-130%) scale(0.75);
    }
    33% {
        -webkit-animation-timing-function: cubic-bezier(
            0.5,
            0,
            0.70173,
            0.49582
        );
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        -webkit-transform: translateX(4%) translateY(-130%) scale(0.75);
        transform: translateX(4%) translateY(-130%) scale(0.75);
    }
    66% {
        -webkit-animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        animation-timing-function: cubic-bezier(
            0.30244,
            0.38135,
            0.55,
            0.95635
        );
        -webkit-transform: translateX(-4%) translateY(-130%) scale(0.75);
        transform: translateX(-4%) translateY(-130%) scale(0.75);
    }
    to {
        -webkit-transform: translateX(0) translateY(-130%) scale(0.75);
        transform: translateX(0) translateY(-130%) scale(0.75);
    }
}
@-webkit-keyframes mdc-checkbox-unchecked-checked-checkmark-path {
    0%,
    50% {
        stroke-dashoffset: 29.78334;
    }
    50% {
        -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    to {
        stroke-dashoffset: 0;
    }
}
@keyframes mdc-checkbox-unchecked-checked-checkmark-path {
    0%,
    50% {
        stroke-dashoffset: 29.78334;
    }
    50% {
        -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    to {
        stroke-dashoffset: 0;
    }
}
@-webkit-keyframes mdc-checkbox-unchecked-indeterminate-mixedmark {
    0%,
    68.2% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
    }
    68.2% {
        -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
        animation-timing-function: cubic-bezier(0, 0, 0, 1);
    }
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}
@keyframes mdc-checkbox-unchecked-indeterminate-mixedmark {
    0%,
    68.2% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
    }
    68.2% {
        -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
        animation-timing-function: cubic-bezier(0, 0, 0, 1);
    }
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}
@-webkit-keyframes mdc-checkbox-checked-unchecked-checkmark-path {
    0% {
        -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
        animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
        opacity: 1;
        stroke-dashoffset: 0;
    }
    to {
        opacity: 0;
        stroke-dashoffset: -29.78334;
    }
}
@keyframes mdc-checkbox-checked-unchecked-checkmark-path {
    0% {
        -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
        animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
        opacity: 1;
        stroke-dashoffset: 0;
    }
    to {
        opacity: 0;
        stroke-dashoffset: -29.78334;
    }
}
@-webkit-keyframes mdc-checkbox-checked-indeterminate-checkmark {
    0% {
        -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        opacity: 1;
    }
    to {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        opacity: 0;
    }
}
@keyframes mdc-checkbox-checked-indeterminate-checkmark {
    0% {
        -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        opacity: 1;
    }
    to {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        opacity: 0;
    }
}
@-webkit-keyframes mdc-checkbox-indeterminate-checked-checkmark {
    0% {
        -webkit-animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
        animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        opacity: 0;
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
        opacity: 1;
    }
}
@keyframes mdc-checkbox-indeterminate-checked-checkmark {
    0% {
        -webkit-animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
        animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        opacity: 0;
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
        opacity: 1;
    }
}
@-webkit-keyframes mdc-checkbox-checked-indeterminate-mixedmark {
    0% {
        -webkit-animation-timing-function: mdc-animation-deceleration-curve-timing-function;
        animation-timing-function: mdc-animation-deceleration-curve-timing-function;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        opacity: 0;
    }
    to {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        opacity: 1;
    }
}
@keyframes mdc-checkbox-checked-indeterminate-mixedmark {
    0% {
        -webkit-animation-timing-function: mdc-animation-deceleration-curve-timing-function;
        animation-timing-function: mdc-animation-deceleration-curve-timing-function;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        opacity: 0;
    }
    to {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        opacity: 1;
    }
}
@-webkit-keyframes mdc-checkbox-indeterminate-checked-mixedmark {
    0% {
        -webkit-animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
        animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        opacity: 1;
    }
    to {
        -webkit-transform: rotate(315deg);
        transform: rotate(315deg);
        opacity: 0;
    }
}
@keyframes mdc-checkbox-indeterminate-checked-mixedmark {
    0% {
        -webkit-animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
        animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        opacity: 1;
    }
    to {
        -webkit-transform: rotate(315deg);
        transform: rotate(315deg);
        opacity: 0;
    }
}
@-webkit-keyframes mdc-checkbox-indeterminate-unchecked-mixedmark {
    0% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1;
    }
    32.8%,
    to {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        opacity: 0;
    }
}
@keyframes mdc-checkbox-indeterminate-unchecked-mixedmark {
    0% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1;
    }
    32.8%,
    to {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        opacity: 0;
    }
}
.mdc-checkbox {
    display: inline-block;
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 18px;
    flex: 0 0 18px;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    width: 18px;
    height: 18px;
    padding: 11px;
    line-height: 0;
    white-space: nowrap;
    cursor: pointer;
    vertical-align: bottom;
}
.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate)
    ~ .mdc-checkbox__background {
    border-color: rgba(0, 0, 0, 0.54);
    background-color: transparent;
}
.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background {
    border-color: #018786;
    border-color: var(--mdc-theme-secondary, #018786);
    background-color: #018786;
    background-color: var(--mdc-theme-secondary, #018786);
}
@-webkit-keyframes mdc-checkbox-fade-in-background-0 {
    0% {
        border-color: rgba(0, 0, 0, 0.54);
        background-color: transparent;
    }
    50% {
        border-color: #018786;
        border-color: var(--mdc-theme-secondary, #018786);
        background-color: #018786;
        background-color: var(--mdc-theme-secondary, #018786);
    }
}
@keyframes mdc-checkbox-fade-in-background-0 {
    0% {
        border-color: rgba(0, 0, 0, 0.54);
        background-color: transparent;
    }
    50% {
        border-color: #018786;
        border-color: var(--mdc-theme-secondary, #018786);
        background-color: #018786;
        background-color: var(--mdc-theme-secondary, #018786);
    }
}
@-webkit-keyframes mdc-checkbox-fade-out-background-0 {
    0%,
    80% {
        border-color: #018786;
        border-color: var(--mdc-theme-secondary, #018786);
        background-color: #018786;
        background-color: var(--mdc-theme-secondary, #018786);
    }
    to {
        border-color: rgba(0, 0, 0, 0.54);
        background-color: transparent;
    }
}
@keyframes mdc-checkbox-fade-out-background-0 {
    0%,
    80% {
        border-color: #018786;
        border-color: var(--mdc-theme-secondary, #018786);
        background-color: #018786;
        background-color: var(--mdc-theme-secondary, #018786);
    }
    to {
        border-color: rgba(0, 0, 0, 0.54);
        background-color: transparent;
    }
}
.mdc-checkbox--anim-unchecked-checked
    .mdc-checkbox__native-control:enabled
    ~ .mdc-checkbox__background,
.mdc-checkbox--anim-unchecked-indeterminate
    .mdc-checkbox__native-control:enabled
    ~ .mdc-checkbox__background {
    -webkit-animation-name: mdc-checkbox-fade-in-background-0;
    animation-name: mdc-checkbox-fade-in-background-0;
}
.mdc-checkbox--anim-checked-unchecked
    .mdc-checkbox__native-control:enabled
    ~ .mdc-checkbox__background,
.mdc-checkbox--anim-indeterminate-unchecked
    .mdc-checkbox__native-control:enabled
    ~ .mdc-checkbox__background {
    -webkit-animation-name: mdc-checkbox-fade-out-background-0;
    animation-name: mdc-checkbox-fade-out-background-0;
}
.mdc-checkbox__checkmark {
    color: #fff;
}
.mdc-checkbox__mixedmark {
    border-color: #fff;
}
.mdc-checkbox__background:before {
    background-color: #018786;
}
@supports not (-ms-ime-align: auto) {
    .mdc-checkbox__background:before {
        background-color: #018786;
        background-color: var(--mdc-theme-secondary, #018786);
    }
}
.mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate)
    ~ .mdc-checkbox__background {
    border-color: rgba(0, 0, 0, 0.26);
}
.mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control[disabled]:indeterminate
    ~ .mdc-checkbox__background {
    border-color: transparent;
    background-color: rgba(0, 0, 0, 0.26);
}
.mdc-checkbox--disabled {
    cursor: default;
    pointer-events: none;
}
.mdc-checkbox__background {
    left: 11px;
    right: auto;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: absolute;
    top: 11px;
    bottom: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 45%;
    height: 45%;
    border: 2px solid;
    border-radius: 2px;
    background-color: transparent;
    pointer-events: none;
    will-change: background-color, border-color;
    -webkit-transition: background-color 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
        border-color 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    transition: background-color 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
        border-color 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}
.mdc-checkbox[dir='rtl'] .mdc-checkbox__background,
[dir='rtl'] .mdc-checkbox .mdc-checkbox__background {
    left: auto;
    right: 11px;
}
.mdc-checkbox__checkmark {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    -webkit-transition: opacity 0.18s cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    transition: opacity 0.18s cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}
.mdc-checkbox--upgraded .mdc-checkbox__checkmark {
    opacity: 1;
}
.mdc-checkbox__checkmark-path {
    -webkit-transition: stroke-dashoffset 0.18s cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    transition: stroke-dashoffset 0.18s cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    stroke: currentColor;
    stroke-width: 3.12px;
    stroke-dashoffset: 29.78334;
    stroke-dasharray: 29.78334;
}
.mdc-checkbox__mixedmark {
    width: 100%;
    height: 0;
    -webkit-transform: scaleX(0) rotate(0deg);
    transform: scaleX(0) rotate(0deg);
    border-width: 1px;
    border-style: solid;
    opacity: 0;
    -webkit-transition: opacity 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
        -webkit-transform 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    transition: opacity 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
        -webkit-transform 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    transition: opacity 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
        transform 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    transition: opacity 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
        transform 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
        -webkit-transform 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}
.mdc-checkbox--upgraded .mdc-checkbox__background,
.mdc-checkbox--upgraded .mdc-checkbox__checkmark,
.mdc-checkbox--upgraded .mdc-checkbox__checkmark-path,
.mdc-checkbox--upgraded .mdc-checkbox__mixedmark {
    -webkit-transition: none !important;
    transition: none !important;
}
.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__background,
.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__background,
.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__background,
.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__background {
    -webkit-animation-duration: 0.18s;
    animation-duration: 0.18s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}
.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__checkmark-path {
    -webkit-animation: mdc-checkbox-unchecked-checked-checkmark-path 0.18s
        linear 0s;
    animation: mdc-checkbox-unchecked-checked-checkmark-path 0.18s linear 0s;
    -webkit-transition: none;
    transition: none;
}
.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__mixedmark {
    -webkit-animation: mdc-checkbox-unchecked-indeterminate-mixedmark 90ms
        linear 0s;
    animation: mdc-checkbox-unchecked-indeterminate-mixedmark 90ms linear 0s;
    -webkit-transition: none;
    transition: none;
}
.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__checkmark-path {
    -webkit-animation: mdc-checkbox-checked-unchecked-checkmark-path 90ms linear
        0s;
    animation: mdc-checkbox-checked-unchecked-checkmark-path 90ms linear 0s;
    -webkit-transition: none;
    transition: none;
}
.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__checkmark {
    -webkit-animation: mdc-checkbox-checked-indeterminate-checkmark 90ms linear
        0s;
    animation: mdc-checkbox-checked-indeterminate-checkmark 90ms linear 0s;
    -webkit-transition: none;
    transition: none;
}
.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__mixedmark {
    -webkit-animation: mdc-checkbox-checked-indeterminate-mixedmark 90ms linear
        0s;
    animation: mdc-checkbox-checked-indeterminate-mixedmark 90ms linear 0s;
    -webkit-transition: none;
    transition: none;
}
.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__checkmark {
    -webkit-animation: mdc-checkbox-indeterminate-checked-checkmark 0.5s linear
        0s;
    animation: mdc-checkbox-indeterminate-checked-checkmark 0.5s linear 0s;
    -webkit-transition: none;
    transition: none;
}
.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__mixedmark {
    -webkit-animation: mdc-checkbox-indeterminate-checked-mixedmark 0.5s linear
        0s;
    animation: mdc-checkbox-indeterminate-checked-mixedmark 0.5s linear 0s;
    -webkit-transition: none;
    transition: none;
}
.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__mixedmark {
    -webkit-animation: mdc-checkbox-indeterminate-unchecked-mixedmark 0.3s
        linear 0s;
    animation: mdc-checkbox-indeterminate-unchecked-mixedmark 0.3s linear 0s;
    -webkit-transition: none;
    transition: none;
}
.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background {
    -webkit-transition: border-color 90ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        background-color 90ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transition: border-color 90ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        background-color 90ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.mdc-checkbox__native-control:checked
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark-path,
.mdc-checkbox__native-control:indeterminate
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark-path {
    stroke-dashoffset: 0;
}
.mdc-checkbox__background:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0);
    transform: scale(0);
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: '';
    will-change: opacity, transform;
    -webkit-transition: opacity 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
        -webkit-transform 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    transition: opacity 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
        -webkit-transform 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    transition: opacity 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
        transform 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    transition: opacity 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
        transform 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
        -webkit-transform 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}
.mdc-checkbox__native-control:focus ~ .mdc-checkbox__background:before {
    -webkit-transform: scale(2.75);
    transform: scale(2.75);
    opacity: 0.12;
    -webkit-transition: opacity 80ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        -webkit-transform 80ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transition: opacity 80ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        -webkit-transform 80ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transition: opacity 80ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        transform 80ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transition: opacity 80ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        transform 80ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        -webkit-transform 80ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.mdc-checkbox__native-control {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
    cursor: inherit;
}
.mdc-checkbox__native-control:disabled {
    cursor: default;
    pointer-events: none;
}
.mdc-checkbox__native-control:checked
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark {
    -webkit-transition: opacity 0.18s cubic-bezier(0, 0, 0.2, 1) 0ms,
        -webkit-transform 0.18s cubic-bezier(0, 0, 0.2, 1) 0ms;
    transition: opacity 0.18s cubic-bezier(0, 0, 0.2, 1) 0ms,
        -webkit-transform 0.18s cubic-bezier(0, 0, 0.2, 1) 0ms;
    transition: opacity 0.18s cubic-bezier(0, 0, 0.2, 1) 0ms,
        transform 0.18s cubic-bezier(0, 0, 0.2, 1) 0ms;
    transition: opacity 0.18s cubic-bezier(0, 0, 0.2, 1) 0ms,
        transform 0.18s cubic-bezier(0, 0, 0.2, 1) 0ms,
        -webkit-transform 0.18s cubic-bezier(0, 0, 0.2, 1) 0ms;
    opacity: 1;
}
.mdc-checkbox__native-control:checked
    ~ .mdc-checkbox__background
    .mdc-checkbox__mixedmark {
    -webkit-transform: scaleX(1) rotate(-45deg);
    transform: scaleX(1) rotate(-45deg);
}
.mdc-checkbox__native-control:indeterminate
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
    -webkit-transition: opacity 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
        -webkit-transform 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    transition: opacity 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
        -webkit-transform 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    transition: opacity 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
        transform 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    transition: opacity 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
        transform 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
        -webkit-transform 90ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}
.mdc-checkbox__native-control:indeterminate
    ~ .mdc-checkbox__background
    .mdc-checkbox__mixedmark {
    -webkit-transform: scaleX(1) rotate(0deg);
    transform: scaleX(1) rotate(0deg);
    opacity: 1;
}
.mdc-checkbox {
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mdc-checkbox:after,
.mdc-checkbox:before {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: '';
}
.mdc-checkbox:before {
    -webkit-transition: opacity 15ms linear, background-color 15ms linear;
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1;
}
.mdc-checkbox.mdc-ripple-upgraded:before {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
    transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-checkbox.mdc-ripple-upgraded:after {
    top: 0;
    left: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: center center;
    transform-origin: center center;
}
.mdc-checkbox.mdc-ripple-upgraded--unbounded:after {
    top: 0;
    top: var(--mdc-ripple-top, 0);
    left: 0;
    left: var(--mdc-ripple-left, 0);
}
.mdc-checkbox.mdc-ripple-upgraded--foreground-activation:after {
    -webkit-animation: mdc-ripple-fg-radius-in 225ms forwards,
        mdc-ripple-fg-opacity-in 75ms forwards;
    animation: mdc-ripple-fg-radius-in 225ms forwards,
        mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-checkbox.mdc-ripple-upgraded--foreground-deactivation:after {
    -webkit-animation: mdc-ripple-fg-opacity-out 0.15s;
    animation: mdc-ripple-fg-opacity-out 0.15s;
    -webkit-transform: translate(0) scale(1);
    transform: translate(0) scale(1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0))
        scale(var(--mdc-ripple-fg-scale, 1));
    transform: translate(var(--mdc-ripple-fg-translate-end, 0))
        scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-checkbox:after,
.mdc-checkbox:before {
    background-color: #018786;
}
@supports not (-ms-ime-align: auto) {
    .mdc-checkbox:after,
    .mdc-checkbox:before {
        background-color: #018786;
        background-color: var(--mdc-theme-secondary, #018786);
    }
}
.mdc-checkbox:hover:before {
    opacity: 0.04;
}
.mdc-checkbox.mdc-ripple-upgraded--background-focused:before,
.mdc-checkbox:not(.mdc-ripple-upgraded):focus:before {
    -webkit-transition-duration: 75ms;
    transition-duration: 75ms;
    opacity: 0.12;
}
.mdc-checkbox:not(.mdc-ripple-upgraded):after {
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}
.mdc-checkbox:not(.mdc-ripple-upgraded):active:after {
    -webkit-transition-duration: 75ms;
    transition-duration: 75ms;
    opacity: 0.12;
}
.mdc-checkbox.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.12;
}
.mdc-checkbox:after,
.mdc-checkbox:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.mdc-checkbox.mdc-ripple-upgraded:after,
.mdc-checkbox.mdc-ripple-upgraded:before {
    top: 0;
    top: var(--mdc-ripple-top, 0);
    left: 0;
    left: var(--mdc-ripple-left, 0);
    width: 100%;
    width: var(--mdc-ripple-fg-size, 100%);
    height: 100%;
    height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-checkbox.mdc-ripple-upgraded:after {
    width: 100%;
    width: var(--mdc-ripple-fg-size, 100%);
    height: 100%;
    height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-ripple-upgraded--background-focused .mdc-checkbox__background:before {
    content: none;
}
.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate)
    ~ .mdc-checkbox__background {
    border-color: #fc5f64;
    background-color: #fff;
}
.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background {
    border-color: #fc5f64;
    background-color: #fc5f64;
}
.mdc-checkbox__background:before {
    background-color: #fc5f64;
}
.mdc-text-field:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled)
    .mdc-notched-outline__leading,
.mdc-text-field:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled)
    .mdc-notched-outline__notch,
.mdc-text-field:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled)
    .mdc-notched-outline__trailing {
    border-color: #b0b5b8;
}
.mdc-text-field:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__leading,
.mdc-text-field:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__notch,
.mdc-text-field:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__trailing {
    border-color: #39464e;
}
.mdc-text-field:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__icon:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-text-field:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__icon:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-text-field:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__icon:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__trailing,
.mdc-text-field:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__input:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-text-field:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__input:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-text-field:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled):not(.mdc-text-field--focused)
    .mdc-text-field__input:hover
    ~ .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-color: #39464e;
}
.mdc-text-field:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled)
    .mdc-floating-label {
    color: #889095;
}
.mdc-text-field.mdc-text-field--invalid .mdc-text-field__input {
    color: $error-red;
}

.mdc-text-field__input {
    font-size: $v * 1.7;
}

.mdc-text-field--fullWidth {
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.mdc-floating-label--float-above.mdc-floating-label {
    font-size: $v * 1.7;
    line-height: $v * 2.4;
    color: $charcoal-0;
}

.mdc-floating-label {
    font-size: $v * 1.7;
    line-height: $v * 2.4;
    // color: $charcoal-2;
}

.mdc-floating-label.mdc-floating-label--float-above,
.mdc-text-field--outlined.mdc-notched-outline--upgraded.mdc-floating-label--float-above {
    font-size: $v * 1.7;
}

.mdc-select-helper-text,
.mdc-text-field-helper-text {
    font-size: $v * 1.2;
    opacity: 1;
}

.mdc-checkbox__background {
    border-width: 1px;
}

.mdc-checkbox,
.mdc-checkbox__background {
    height: $checkbox-size;
    width: $checkbox-size;
}

.mdc-checkbox {
    flex-basis: $checkbox-size;
    align-self: flex-start;
}

.mdc-form-field {
    display: flex;
    align-items: center;
    justify-content: center;

    label {
        &,
        a {
            font-weight: normal;
            margin-bottom: 3px;
            font-size: $v * 1.4;
        }
    }
}

.mdc-checkbox__native-control:focus ~ .mdc-checkbox__background:before {
    opacity: 0;
}

.mdc-floating-label {
    &::after {
        content: '' !important;
    }
}
.mdc-text-field--outlined .mdc-floating-label {
    top: 16px;
}

.mdc-checkbox__checkmark {
    width: 80%;
    height: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.mdc-checkbox:after,
.mdc-checkbox:before {
    background-color: $coral-3;
}

textarea {
    box-shadow: none;
}


.HeroStack {
  position: relative;
  margin: $v * 2 $v * 4;

  @media (#{$small-bp}) {
    color: $charcoal;
    margin-left: $v * 2;
    margin-right: $v * 2;
  }


  .HeroBanner-actions {
    justify-content: center;
    @media (#{$small-bp}) {
      .Button {
        margin-right: $v;
      }
    }
    .Button:last-child {
      margin-right: 0;
    }
  }

  .HeroBlock-title {
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: calc(#{$v} * 2 - 10px);
    @media (#{$medium-bp}) {
      margin-bottom: calc(#{$v} * 4 - 15px);
    }
  }

  .HeroBanner-description {
    padding: 0 $v;
    margin-top: 0;
    @media (#{$small-bp}) {
      margin-bottom: $v * 2;
    }
  }

  @media (#{$small-bp}) {
    .HeroStack-wrap {
      padding-top: $v * 4;
    }
    .col-md-6,
    .HeroBanner-container {
      padding: 0;
    }
  }
}

.HeroStack-wrap {
  padding-top: $v * 9;
  padding-bottom: $v * 9;
  z-index: 12;

  @media (#{$large-bp}) {
    padding: 0;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
  }
}

.HeroStack-image-md,
.HeroStack-image-sm {
  max-width: 100%;
  width: 100%;
  float: right;
  position: relative;
  z-index: 1;
}

.HeroStack-image-sm {
  display: block;
  @media (#{$medium-bp}) {
    display: none;
  }
}

.HeroStack-image-md {
  display: none;
  @media (#{$medium-bp}) {
    display: block;
  }
}

.HeroStack--onDark {
  color: $charcoal;

  .Button--white {
    color: $charcoal;
    border-color: $charcoal;
    &:hover {
      background-color: $charcoal;
      color: $white;
    }

    @media (#{$large-bp}) {
      color: $white;
      border-color: $white;
      &:hover {
        background-color: $white;
        color: $charcoal;
      }
    }
  }

  @media (#{$large-bp}) {
    color: $white;
  }
}

.HeroStack-container {
  max-width: 1500px;
  @media (min-width: 1580px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.HeroStack-gradient {
  @media (min-width: 1580px) {
    background: linear-gradient(to right, rgb(61, 54, 96), rgb(61, 54, 96), rgb(224, 73, 88), rgb(224, 73, 88));
    width: 100%;
    height: 811px;
    position: absolute;
    top: calc(#{$v} * 2 - 1px);
  }
}
// ----- Header section -----
.pricing-page__header-outer {
    width: 72%;
    margin: 0 auto;
    text-align: center;
    padding: 18rem 0 18rem 0;
}

.pricing-page__header-top {
    display: block;
    @extend %h4;
    margin-bottom: 4rem;
    text-transform: uppercase;
}

.pricing-page__header-main {
    @extend %h1;

    @media ($large-bp-below) {
        text-wrap: balance;
    }
    @media ($large-bp) {
        // Calibrated for specific text on desktop
        max-width: 700px;
        margin-inline: auto;
    }
}

.pricing-page__header-counter-wrapper {
    display: inline-block;
    position: relative;
}

.pricing-page__header-counter,
.pricing-page__header-counter-max {
    all: unset;
    appearance: none;
    -webkit-appearance: none;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    padding: 0;
    margin: 0 12px;
    width: 100px;
    display: inline-block;
    background-color: $midnight-pale;
    border: 0;
    border-bottom: solid 2px $charcoal-0;
    color: $midnight;
}

.pricing-page__header-counter-max {
    position: absolute;
    inset: 0;
    pointer-events: none;

    .pricing-page__header-counter:focus + & {
        display: none;
    }
}

.pricing-page__info {
    width: 2rem;
    height: auto;
    margin-left: 1.2rem;
    margin-bottom: 0.6rem;
}

.pricing-page__info-mini {
    width: 1.5rem;
    height: auto;
    margin-left: 0.7rem;
}

.pricing-page__tooltip_feature {
    border-bottom: 1px dashed $charcoal-3;
    text-decoration: none;
}

// ----- range input -----
.pricing-page__header-input-range-holder {
    margin-top: 5rem;
}

// Reset
.pricing-page__header-input-range,
input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 80%;
}

@mixin pricing-page-slider-track {
    background: $midnight;
    height: 0.8rem;
    border-radius: 10px;

    background-image: linear-gradient(
        90deg,
        $midnight 0,
        $midnight calc(var(--_range-fill) * 100%),
        $midnight-pale calc(var(--_range-fill) * 100%),
        $midnight-pale 100%
    );
}

/***** Chrome, Safari, Opera, and Edge Chromium *****/
.pricing-page__header-input-range,
input[type='range']::-webkit-slider-runnable-track {
    @include pricing-page-slider-track;
}

/******** Firefox ********/
.pricing-page__header-input-range,
input[type='range']::-moz-range-track {
    @include pricing-page-slider-track;
}

@mixin pricing-page-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-color: $midnight;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba($charcoal-0, 0.4);
}

/***** Thumb Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type='range']::-webkit-slider-thumb {
    margin-top: -16px; /* Centers thumb on the track */
    height: 3.8rem;
    width: 3.8rem;

    @include pricing-page-slider-thumb;
}

/***** Firefox *****/
input[type='range']::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/
    height: 4rem;
    width: 4rem;

    @include pricing-page-slider-thumb;
}

/***** Focus Styles *****/
/* Removes default focus */
.pricing-page__header-input-range,
input[type='range']:focus {
    outline: none;
}

.pricing-page__header-notice {
    text-align: center;
    background-color: $midnight-pale;
    border-radius: 7px;
}

.pricing-page__header-notice-a {
    color: $midnight;
    text-decoration: underline;
}

// Tooltip styling
.pricing-page__tooltip.tooltip .tooltip-inner {
    padding: 1rem 1rem 1rem 1rem;
    font-family: $text-font;
    border-radius: 3px;
    background-color: $charcoal-0;
    background-color: $charcoal-0;
    color: $white;
    width: auto;
    max-width: 200px;
    height: auto;
    opacity: 1;
    border: none;
    text-align: left;
}

.pricing-page__tooltip.tooltip .tooltip-arrow {
    display: none;
}

//  ----- Comparison table section -----
.pricing-page__row {
    margin-bottom: 3rem;
}

.pricing-page__body-col-layout {
    // Override padding applied by Bootstrap grid styles
    padding-inline: $h;
}

.pricing-page__body-col-outer {
    border: 1px solid $charcoal-0;
    border-radius: 7px;
    padding: 3.5rem 3.5rem 2rem 3.5rem;
    width: 100%;
    height: 100%;
}

// Used when selected plan is un-available
.pricing-page__body-outer-grey {
    background-color: $charcoal-5;
    border-color: $charcoal-3;
}

.pricing-page__body-outer-regular {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.pricing-page__body-col-outer.pricing-page__body-build {
    border-top: 16px solid $rose;
}

.pricing-page__body-col-outer.pricing-page__body-elevate {
    border-top: 16px solid $coral-2;
}

.pricing-page__body-col-outer.pricing-page__body-innovate {
    border-top: 16px solid $midnight;
}

// horizontally aligned div
.pricing-page__body-initial-col-container {
    display: flex;
    margin-top: 4rem;
    margin-bottom: 2rem;

    .pricing-page__body-initial-sm-col-1 {
        display: flex;
        flex: 1;
        align-items: center;
    }

    .pricing-page__body-initial-sm-col-2 {
        flex: 6;
        background-color: $cream-0;
        border-radius: 7px;
    }
}

.pricing-page__min-height-sm {
    min-height: 90px;
}

.pricing-page__min-height-md {
    min-height: 86px;
}

.pricing-page__body-initial-price {
    @extend %h3;
    font-size: 4.2rem;
    line-height: 5rem;
    text-align: center;
}

.pricing-page__body-initial-p {
    @extend %p;
    padding: 1.5rem;
}

.pricing-page__body-badge {
    padding: 5px 7px 5px 7px;
    border-radius: 7px;
    border-style: solid;
    border-width: 1px;
    font-size: $v * 1.4;
    font-family: $text-font;
    font-weight: 600;
    line-height: 24px;
    color: $midnight;
    border-color: $midnight-pale;
    background-color: $midnight-pale;
    text-align: center;
    white-space: nowrap;
    letter-spacing: 0;
    .fa {
        position: relative;
        left: -2px;
    }
}

// Badge flexbox columns
.pricing-page__body-badge-col-container {
    display: flex;

    .pricing-page__body-badge-sm-col-1 {
        flex: 1;
    }

    .pricing-page__body-badge-sm-col-2 {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.pricing-page__body-price-holder {
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.pricing-page__body-price {
    @extend %h3;
    font-size: 4.2rem;
    line-height: 5rem;
}

.pricing-page__body-h3 {
    @extend %h3;
}

.pricing-page__body-price-detail {
    margin-bottom: 1rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
}

.pricing-page__body-link {
    color: $charcoal-2;
    text-decoration: none;
    display: inline-block;
    border-bottom: 1px solid #b0b5b8;
}

.pricing-page__body-link:hover {
    color: $midnight;
    border-bottom: 1px solid $midnight;
}

.pricing-page__body-link:focus {
    color: $midnight;
    border-bottom: 1px solid $midnight;
}

.pricing-page__body-price-select-holder {
    display: flex;
    justify-content: flex-end;
}

.pricing-page__body-price-select {
    padding: 0.5rem 28px 0.5rem 0.7rem;
    width: auto;
    border: none;
    font-size: 1.6rem;
    line-height: 3.4rem;
    font-weight: 700;
    text-align: right;
}

.pricing-page__body-p-bold {
    @extend %p;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.8rem;
}

.pricing-page__button {
    margin-bottom: 5rem;
}

.pricing-page__button:focus {
    color: $white;
}

ul.pricing-page__body-bullet-points {
    list-style-image: url(/images/svg/tick-midnight.svg);
}

.pricing-page__body-bullet-points {
    margin-bottom: 5rem;
}

.pricing-page__body-bullet-points li {
    line-height: 2rem;
    text-align: left;
    padding-bottom: 1rem;
    padding-left: 4px;
}

.pricing-page__body-sms-wrapper {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
}

.pricing-page__body-sms-wrapper-border{
    border-top: 1px solid $charcoal-0;
    border-bottom: 1px solid $charcoal-0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 5rem;
}

.pricing-page__body-sms {
    @extend %p;
    flex-grow: 1;
}

.pricing-page__body-sms::before {
    content: url(/images/svg-icons/plus-midnight.svg);
    margin-right: 8px;
}

// ----- Footer -----
.pricing-page__footer-button::after {
    margin-left: 1rem;
    content: url(/images/svg-icons/ic-down-white.svg);
}

.pricing-page__footer-p {
    text-align: center;
}

.pricing-page__footer-a {
    display: inline-block;
}

#pricing-page {
    & .pricing-page__footer-a {
        color: $midnight;
        text-decoration: underline;
    }
}

#pricing-page .pricing-page__footer-a {
    color: $midnight;
    text-decoration: none;
    border-bottom: 1px solid $midnight;
}

.pricing-page__footer-p .pricing-page__footer-a {
    color: $midnight;
    text-decoration: underline;
}

// Slick slider
.slick-slide .pricing-page__body-col-outer img {
    display: inline-flex;
}

//  ----- Media Queries -----
@media (min-width: 992px) and (max-width: 1199px) {
    .pricing-page__body-col-outer {
        padding: 3.5rem 2.5rem 2rem 2.5rem;
    }

    .pricing-page__body-col-holder {
        width: 96%;
    }

    .pricing-page__min-height-sm {
        min-height: 120px;
    }

    .pricing-page__min-height-md {
        min-height: 100px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .pricing-page__body-col-outer {
        padding: 2.5rem 2.5rem 2rem 2.5rem;
    }

    .pricing-page__body-col-holder {
        width: 98%;
    }

    .pricing-page__body-badge-col-container {
        flex-direction: column;
    }

    .pricing-page__body-badge-col-container .pricing-page__body-badge-sm-col-2 {
        margin-top: 1rem;
        justify-content: left;
    }

    .pricing-page__min-height-sm {
        min-height: 120px;
    }

    .pricing-page__min-height-md {
        min-height: 100px;
    }

    .pricing-page__body-link {
        text-decoration: underline;
        border-bottom: none;
    }
}

// max 767px
@media ($small-bp) {
    // header
    .pricing-page__header-outer {
        width: 100%;
        padding: 9rem 0 6rem 0;
    }

    .pricing-page__header-h3 {
        font-size: 3.8rem;
        line-height: 5.2rem;
    }

    .pricing-page__header-input-range,
    input[type='range'] {
        width: 94%;
    }

    .pricing-page__body-price-select-holder {
        justify-content: center;
    }

    .pricing-page__body-col-outer {
        padding: 2.5rem 2.5rem 2rem 2.5rem;
        // padding: 3.5rem 3.5rem 2rem 3.5rem;
    }

    .pricing-page__body-bullet-points li {
        padding-bottom: 1.5rem;
    }

    .pricing-page__body-h3 {
        font-size: 2.8rem;
        line-height: 3.5rem;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .pricing-page__body-col-outer {
        padding: 3.5rem 3.5rem 2rem 3.5rem;
    }

    .pricing-page__min-height-sm {
        min-height: 86px;
    }

    .pricing-page__min-height-md {
        min-height: 60px;
    }
}

@media (max-width: 576px) {
    .pricing-page__header-counter,
    .pricing-page__header-counter-max {
        padding: 0;
        margin: 0 8px;
        width: 84px;
    }

    .pricing-page__header-main{
        font-size: 3.2rem;
    }
}

// Slick slider overrides to make slides all full height
.pricing-page {
    .slick-slider {
        // Override Bootstrap styles applying padding to any child of a .row
        padding-inline: 0;
    }

    .slick-track {
        display: flex;
        align-items: stretch;
    }

    .slick-slide {
        height: inherit;

        // I think the React port inserts a naked div?
        > div:not([class]) {
            height: 100%;
        }

        .pricing-page__body-col-layout {
            height: 100%;
        }
    }

    .slick-dots {
        margin-top: 4 * $v;

        button {
            border-color: $charcoal-0;
        }
        .slick-active button {
            background-color: $charcoal-0;
        }
    }
}

.StickyListItem {
    margin-top: $v * 5;
    padding-top: $v * 3;
    border-top: 1px solid $charcoal-4;
}

h3.StickyListItem-title {
    padding-top: 0;
    padding-bottom: $v;
    color: $charcoal;
}
a:hover {
    h3.StickyListItem-title {
        text-decoration: underline;
    }
}

.page-wrapper .StickyListItem a.StickyListItem-link {
    color: $charcoal-0;
    text-decoration: underline;
}

.StickyListItem-image {
    margin-top: 0;
}

.StickyListItem-imageWrap {
    position: relative;
}

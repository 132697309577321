.CampaignNav {
    width: 100%;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    z-index: 9;
    position: absolute;

    .Button {
        font-weight: 700;
        @media (#{$medium-bp}) {
            margin-left: $v * 2;
        }
    }

    .Button--link {
        color: $charcoal;
        box-shadow: none;
        font-weight: 400;
        padding: 0;
        font-size: $v * 1.6;
        line-height: $v * 2.4;
        @media (#{$medium-bp}) {
            margin-left: 0;
        }

        &:hover {
            color: $black;
            box-shadow: inset 0 -0.1rem $black;
        }
    }

    .Button--white {
        border-color: $white;
        display: inline-block;
    }
}

.HeroBanner--onDark {
    // .Button--link {
    // color: $dusk-1;
    // &:hover {
    // color: $dusk-3;
    //   box-shadow:
    //     inset 0 -0.1rem $dusk-3;
    // }
    // }
    .CampaignNav {
        color: $white;
    }
}

.CampaignNav-right {
    display: flex;
    align-items: center;
}

.CampaignNav-credit {
    display: none;
    @media (#{$medium-bp}) {
        display: inline-block;
        margin-bottom: 0;
    }
}

.CampaignNav-logo {
    height: 47px;
}

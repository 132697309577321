// Media Query screen sizes
$medium-bp: 'min-width: 768px';
$large-bp: 'min-width: 992px';
$xlarge-bp: 'min-width: 1200px';
$xxlarge-bp: 'min-width: 1920px';
$small-bp: 'max-width: 767px';
$medium-bp-only: 'min-width: 768px) and (max-width: 991px';
$large-bp-below: 'max-width: 992px';

// Media Query breakpoint values 
$medium-breakpoint: '768px';
$large-breakpoint: '992px';
$xlarge-breakpoint: '1200px';
$xxlarge-breakpoint: '1920px';

// Spacing constants
$v: 1rem;
$h: 1rem;
$border-color-nav: #e4e1e1;

// Colors based on style sheet
$dusk-0: #fbd1cc;
$dusk-1: #fcdad6;
$dusk-2: #fde4e1;
$dusk-3: #feedeb;
$dusk-4: #fef5f4;

$cream-0: #f9f7f5;

$dusk-base: $dusk-0;
$dusk-hover: $dusk-2;

$charcoal-0: #39464e;
$charcoal-1: #616b71;
$charcoal-2: #889095;
$charcoal-3: #b0b5b8;
$charcoal-4: #d7dadc;
$charcoal-5: #f8f8f8;

$charcoal-pricing: #b6aeb2;

$charcoal-base: $charcoal-0;

$coral-0: #b02f37;
$coral-1: #e43c42;
$coral-2: #fc5f64;
$coral-3: #ffc0c3;
$coral-base: $coral-2;

$rose: #F7ABE2;
$rose-light: #FFE4F7;

$gold-base: #ffd354;

$blue-0: #0076a5;
$blue-1: #0296d5;

// Variables from Hair salon / Afterpay work
$dusky-pink: $dusk-2;
$charcoal: $charcoal-base;

// Homepage refresh
$aubergine: #7b264a;
$emerald: #005E4F;

$hot-coral: $coral-base;
$light-coral: $coral-3;
$coral-hover: $coral-1;

$gold: $gold-base;

$timely-blue: #46e7ff;
$spa: #ABD4D6;
$spa-pale: #e5f4f5;
$midnight: #4F4DB0;
$midnight-2: #494cbd;
$midnight-3: #3F3E8D;
$midnight-pale: #f0effc;
$white: #ffffff;
$black: #000000;

$lightest-grey: $charcoal-3;
$light-grey: $charcoal-5;
$mid-grey: $charcoal-2;
$bg-grey: $charcoal-2;
$border-grey: $charcoal-4;

$card-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.08);
$card-border-radius: 4px;
$light-midnight: #d8d9f7;
$error-red: #b00020;
$light-error-red: $dusk-0;

// Icons
$icon-arrow-right-chevron: "\f105";

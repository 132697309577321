.video-course-hero {}

.video-course-hero__title {
	@extend %h1;

	margin-top: 0;
}

.video-course-hero__figure {
	margin: 0;
	aspect-ratio: 16 / 9;
	position: relative;
}

.video-course-hero__embed {
	position: absolute;
	width: 100%;
	height: 100%;
}

.video-course-hero__introduction {
	max-width: 700px;
	margin: (5 * $v) auto;
}

.video-course-hero__introduction__heading {
	@extend %h2;
}

.video-course-hero__introduction__text {
	@extend %p;
}

.video-course-hero__introduction__action {}

.Breadcrumb {
  position: relative;
  z-index: 1;
  padding-top: $v * 3;
  width: 100%;
}

.Breadcrumb-list {
  display: flex;
  margin: 0;
  padding-left: $v * 1.5;
  padding-right: $v * 1.5;
  list-style: none;
  @media (#{$medium-bp}) {
    padding: 0;
  }
}

.Breadcrumb-item {
  display: flex;
  padding: 0 $v * 0.5 0 0;
  color: $charcoal;
  &,
  a {
    font-size: 12px; //
  }

  &::after {
    content: ">";
    color: $mid-grey;
    margin-left: $v * 0.5;
  }

  &:last-child::after {
    display: none;
  }

  a,
  a:visited {
    transition: all 0.2s ease-out;
    color: $mid-grey;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }

  a:hover {
    padding-bottom: 0;
    border-bottom: 1px solid $charcoal;
    color: $charcoal;
  }
}

.HeroBanner--onDark {
  .Breadcrumb-item {
    color: $white;

    a,
    a:visited {
      color: $lightest-grey;
    }

    a:hover {
      padding-bottom: 0;
      border-bottom: 1px solid $white;
      color: $white;
    }
  }
}

.Breadcrumb--absolute {
  position: absolute;
}

blockquote {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: normal;
    line-height: 1.5;
    line-height: 1.2;
    font-size: 34px;
    line-height: 1.25;
    margin: 0 0 1em 0;
    font-weight: 300;
    display: block;
    font-style: normal;
    margin: 1.75em 0 2em 0;
    padding: 0 0 0 1.25em;
}

blockquote {
    margin: 50px 0;
    hanging-punctuation: first;
    font-family: DomaineDisplay, Lato, 'Sans-serif';
    border-left: 5px solid $hot-coral;

    p:first-child {
        position: relative;
        display: inline;
        text-indent: -1rem;
        &::before {
            position: absolute;
            font-family: DomaineDisplay, Lato, 'Sans-serif';
            font-size: 1.8em;
            top: -0.7rem;
            content: '\201C';
            left: -1.5rem;
            display: block;
            width: 10px;
            text-align: center;
        }

        &::after {
            position: absolute;
            font-family: DomaineDisplay, Lato, 'Sans-serif';
            font-size: 1.8em;
            bottom: -2rem;
            right: -2.5rem;
            content: '\201D';
            display: block;
            width: 10px;
            text-align: right;
        }
    }

    cite {
        display: block;
        text-transform: uppercase;
        color: $charcoal-2;
        font-family: Lato, 'Sans-serif';
        font-size: $v * 1.6;
        margin-top: $v * 2;
        font-weight: 700;
        letter-spacing: 1px;
    }
}

blockquote.small-quote {
    border-left: 5px solid $hot-coral;
    p:first-child {
        font-size: $v * 1.8;
        line-height: 1.75;
        font-family: Lato, 'Sans-serif';
        display: block;
        font-weight: 400 !important;

        &::before {
            font-family: Lato, 'Sans-serif';
            position: static;
            font-size: initial;
            font-weight: 400 !important;
        }

        &::after {
            position: static;
            font-family: Lato, 'Sans-serif';
            font-size: initial;
            font-weight: 400 !important;
        }
    }

    cite {
        display: block;
        text-transform: uppercase;
        color: $charcoal-2;
        font-family: Lato, 'Sans-serif';
        font-size: $v * 1.6;
        margin-top: 0;
        font-weight: 700;
        letter-spacing: 1px;
    }
}
.post-single {
    blockquote.wp-block-quote:not(.is-style-large) {
        padding-left: 0;
        padding-top: $v * 6;
        padding-bottom: $v * 6;
        border-left: 0;
        margin: 0;

        @media (#{$medium-bp}) {
            padding-top: 0;
            padding-bottom: 0;
        }

        h2,
        h3,
        p {
            color: $black;
            font-family: $domaindisplay;
            font-size: $v * 3;
            font-weight: 400;
            letter-spacing: -0.75px;
            line-height: $v * 4;
            border-left: none;
            position: relative;
            width: 100%;
            text-align: center;

            @media (#{$medium-bp}) {
                text-align: left;
                width: 40%;
                min-width: 200px;
                float: right;
                margin: $v * 2.5 0 $v * 2.5 $v * 4;
            }

            @media (#{$large-bp}) {
                margin-right: -13%;
            }

            &::first-letter {
                float: none;
                font-size: inherit;
                line-height: inherit;
                margin: 0;
                padding: 0;
            }
            &::after {
                font-family: $domaindisplay;
                font-weight: 400;
                font-size: 12rem;
                color: $dusk-2;
                position: absolute;
                bottom: initial;
                top: -2rem;
                right: 50%;
                content: '\201D';
                height: 0;
                display: block;
                z-index: -1;

                @media (#{$medium-bp}) {
                    position: relative;
                    top: initial;
                    bottom: -5rem;
                    right: 0;
                    line-height: 0;
                    float: right;
                }
            }
        }
    }

    blockquote.wp-block-quote.is-style-large {
        font-family: $domaindisplay;
        background-color: $coral-2;
        margin-top: $v * 6;
        margin-bottom: $v * 6;
        // margin-left: -$v * 1.5;
        // margin-right: -$v * 1.5;
        border-left: none;
        width: 100vw;
        position: relative;
        overflow: hidden;
        text-align: center;

        p {
            font-family: $domaindisplay;
            position: relative;
            margin: 0;
            color: $white;
            font-weight: 400;
            font-style: normal;
            border-left: none;
            padding: $v * 1.5;
            font-size: $v * 3;
            line-height: $v * 4;
            letter-spacing: -0.75px;

            @media (#{$medium-bp}) {
                font-size: $v * 4;
                letter-spacing: -1px;
                line-height: $v * 4.8;
            }

            &::first-letter {
                float: none;
                font-size: inherit;
                line-height: inherit;
                margin: 0;
                padding: 0;
            }
        }

        &::before {
            font-family: $domaindisplay;
            font-weight: 400;
            position: absolute;
            color: $dusk-2;
            font-size: 52rem;
            line-height: 0;
            top: 16rem;
            left: -1.2rem;
            content: '\201C';
            opacity: 0.3;
        }

        &::after {
            font-family: $domaindisplay;
            font-weight: 400;
            color: $dusk-2;
            position: absolute;
            font-size: 52rem;
            line-height: 0;
            bottom: -3rem;
            right: -1.2rem;
            content: '\201D';
            opacity: 0.3;
        }

        width: 100vw;
        margin-left: -1.5rem;
        padding: $v * 6 $v;

        @media (#{$medium-bp}) {
            padding-top: $v * 6;
            padding-bottom: $v * 6;
            text-align: left;
            margin-left: -3rem;
            width: calc(970px * 0.83 - 4rem);
        }

        @media (#{$large-bp}) {
            margin-left: -13%;
            width: calc(1200px * 0.83 - 4rem);
            padding: $v * 5;
        }

        @media (#{$xxlarge-bp}) {
            width: calc(1800px * 0.83 - 7.5rem);
            padding: $v * 10;
        }
    }
}

blockquote.wp-block-quote {
    p {
        font-family: $domaindisplay;
        @extend %h3;
    }
}

blockquote.wp-block-quote {
    padding: 1.25rem 3.5rem;
}

.Endorsement {
  // margin-top: $v * 6;
  min-height: 116px;
  transition: opacity .2s ease-out;

  h3 {
    text-align: center;
    font-size: 2.5rem;
    line-height: 3.4rem;
    @media (#{$medium-bp}) {
      font-size: 3.5rem;
      line-height: 4.6rem;
    }
  }

  .Flag {
    justify-content: center;
    align-items: center;
    // margin: 0 auto;
    margin-top: $v * 4;

    @media (#{$medium-bp}) {
      margin-top: $v * 6;
    }
  }

  .Flag-body {
      width: auto;
  }
}

.Endorsement--left {
  @media (#{$large-bp}) {
    h3 {
      text-align: left;
    }

    .Flag {
      justify-content: flex-start;
    }
  }
}
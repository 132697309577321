.ThreeColumn {
  @extend .u-vSpacingLG-top;

  @media (#{$small-bp}) {
    padding-top: 0 !important;
  }

  .StandardContent {
    margin-bottom: $v * 4;
    @media (#{$medium-bp}) {
      margin-bottom: 0;
    }
  }
}

.ThreeColumn-container {
  .wp-block-column {
    padding-left: $v * 1.5;
    padding-right: $v * 1.5;

    @media (#{$medium-bp}) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  // text-align: center;
  // > div {
  //   &:first-child {
  //     padding-bottom: 4rem;
  //   }
  // }

  // @media (#{$medium-bp}) {
  //   text-align: left;
  //   display: flex;
  //   align-items: flex-start;

  //   > div {
  //     &:first-child {
  //       padding-bottom: 0;
  //     }
  //   }
  // }
  // @media (#{$large-bp}) {
  //   align-items: center;
  //   padding: 0;
  //   > div {
  //     padding: 0;
  //     &:first-child {
  //       padding: 0;
  //     }
  //   }
  // }
}
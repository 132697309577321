.Badge--red {
    color: $hot-coral;
    border-color: $hot-coral;
    background-color: $dusk-4;
}

.Badge {
    padding: 4px 7px 5px 7px;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
}

.Badge--mostPopular {
    font-size: $v * 1.4;
    font-family: $text-font;
    font-weight: 600;
    line-height: 24px;
    color: $hot-coral;
    border-color: $dusk-4;
    background-color: $dusk-4;
    text-align: center;
    white-space: nowrap;
    margin-left: $v * 0.5;
    letter-spacing: 0;
    .fa {
        position: relative;
        left: -2px;
    }
}

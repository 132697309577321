@use "sass:math";

$hero-carousel__bp: $medium-bp;
$hero-carousel__bp--large: $xlarge-bp;

$hero-carousel__transition-speed: 0.8s;

.hero-carousel {
	background-repeat: no-repeat;
	background-size: cover;

	background-color: $charcoal-0;
	background-image: url("/images/background-images/bg-colour-charcoal.jpg");
	color: $white;

	display: flex;
	align-items: center;

	min-height: calc(100vh - var(--nav-height));
	@supports (min-height: 100dvh) {
		min-height: calc(100dvh - var(--nav-height));
	}

	--hero-carousel-padding: #{8 * $v};
	padding: (8 * $v) 0;
	padding: var(--hero-carousel-padding) 0;
	@media (#{$hero-carousel__bp}) {
		--hero-carousel-padding: #{8 * $v};
	}
}

.hero-carousel__container {
	@extend %container;

	@media (#{$hero-carousel__bp--large}) {
		width: 80%;
		padding-left: 0;
		padding-right: 0;
	}
}

.hero-carousel__viewport {
	position: relative;
}

.hero-carousel__slides {
	list-style: none;
	margin: 0;
	padding: 0;

	display: flex;
	isolation: isolate;
}

.hero-carousel__slide {
	display: flex;
	flex-direction: column;
	row-gap: 4 * $v;
	@media (#{$hero-carousel__bp}) {
		flex-direction: row;
		justify-content: space-between;
		column-gap: 4 * $h;
	}
	@media (#{$hero-carousel__bp--large}) {
		column-gap: 8 * $h;
	}

	width: 100%;
	flex-shrink: 0;
	&:nth-child(n+2) {
		// Stack the slides in place, while still letting them take up height with `visibility: hidden;`
		margin-left: -100%;
	}

	// Appropriate blend mode for cross-fading. Relies on parent having `isolation: isolate;`
	mix-blend-mode: plus-lighter;

	visibility: visible;
	&[aria-hidden="true"] {
		animation: $hero-carousel__transition-speed stayVisible;
		visibility: hidden;
	}
}

.hero-carousel__slide__body {
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (#{$hero-carousel__bp}) {
		align-items: flex-start;
		max-width: rem(700px);
	}

	flex-grow: 1;

	padding: (4 * $v) 0 0;
	@media (#{$hero-carousel__bp}) {
		padding: (8 * $v) 0 0;
	}

	opacity: 0;
	transform: translateX(var(--hero-carousel-padding));
	@media (#{$hero-carousel__bp}) {
		transform: translateY(var(--hero-carousel-padding));
	}
	@media (prefers-reduced-motion: reduce) {
		transform: none;
	}
	transition:
		opacity math.div($hero-carousel__transition-speed, 2) ease-in-out,
		transform math.div($hero-carousel__transition-speed, 2) ease-in-out
	;

	.hero-carousel__slide[aria-hidden="false"] & {
		opacity: 1;
		transform: none;
		transition:
			opacity math.div($hero-carousel__transition-speed, 2) math.div($hero-carousel__transition-speed, 2) ease-in-out,
			transform math.div($hero-carousel__transition-speed, 2) math.div($hero-carousel__transition-speed, 2) ease-in-out
		;
	}
}

.hero-carousel__slide__title {
	@extend %h1--jumbo;
	text-align: center;

	display: block;
	margin: 0;
	@media (#{$hero-carousel__bp}) {
		text-align: initial;
		margin: 0 0 (4 * $v);
	}
}

.hero-carousel__slide__action {
	@include button__theme("primary");
	@include button__size("large");
}

.hero-carousel__slide__action--mobile {
	align-self: center;
	@media (#{$hero-carousel__bp}) {
		display: none;
	}

	opacity: 0;
	animation: $hero-carousel__transition-speed stayVisible;
	visibility: hidden;
	.hero-carousel__slide[aria-hidden="false"] & {
		opacity: 1;
		animation: none;
		visibility: visible;
	}
	transition: opacity $hero-carousel__transition-speed ease-in-out;
}

.hero-carousel__slide__action--desktop {
	display: none;
	@media (#{$hero-carousel__bp}) {
		display: inline-block;
	}
}

.hero-carousel__slide__figure {
	display: flex;
	margin: 0;
	flex-shrink: 0;

	align-items: center;
	justify-content: center;

	width: 100%;
	@media (#{$hero-carousel__bp}) {
		width: 50%;
	}

	opacity: 0;
	transform: translateX(calc(-1 * var(--hero-carousel-padding)));
	@media (#{$hero-carousel__bp}) {
		transform: translateY(calc(-1 * var(--hero-carousel-padding)));
	}
	@media (prefers-reduced-motion: reduce) {
		transform: none;
	}
	transition:
		opacity math.div($hero-carousel__transition-speed, 2) ease-in-out,
		transform math.div($hero-carousel__transition-speed, 2) ease-in-out
	;

	.hero-carousel__slide[aria-hidden="false"] & {
		opacity: 1;
		transform: none;
		transition:
			opacity math.div($hero-carousel__transition-speed, 2) math.div($hero-carousel__transition-speed, 2) ease-in-out,
			transform math.div($hero-carousel__transition-speed, 2) math.div($hero-carousel__transition-speed, 2) ease-in-out
		;
	}
}

.hero-carousel__slide__image {
	display: block;
	object-fit: cover;
	object-position: center;
}

.hero-carousel__controls {
	display: flex;
	justify-content: center;

	@media (#{$hero-carousel__bp}) {
		justify-content: flex-start;
		width: 50%;
		position: absolute;
		z-index: 1;
		top: 2 * $v;
	}
}

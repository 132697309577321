.Breakout {
  clear: both;
}

.Breakout-text {
  font-family: 'DomaineDisplay';
  font-size: 3.5rem;
  line-height: 4.5rem;
  text-align: center;
  text-transform: uppercase;

  @media (#{$medium-bp}) {
    font-size: 7.5rem;
    line-height: 8rem;
  }

  &::before,
  &::after {
    content: '';
    height: $v * 21;
    width: 1px;
    display: block;
    background: $charcoal;
    margin: $v * 4 auto;
  }
}

.Breakout--noTransform {
  .Breakout-text {
    text-transform: none;

    &::after {
      display: none;
    }
  }
}

.Breakout--noFrills {
  .Breakout-text {
    font-size: 2.8rem;
    line-height: 3.5rem;

    @media (#{$medium-bp}) {
      font-size: 3.5rem;
      line-height: 4.5rem;
    }

    &::before {
      display: none;
    }
  }
}

.Breakout--grey {
  color: $charcoal-3;
}

.Breakout--empty {
  .Breakout-text {
    margin-bottom: 0;
    &::after {
      display: none;
    }
  }
}